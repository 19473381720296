/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Descriptions of a property.
 * @export
 * @interface HotelContentDescriptionsInner
 */
export interface HotelContentDescriptionsInner {
    /**
     * Description type. Example - amenities. dining, renovation, business amenities, rooms, attraction, location
     * @type {string}
     * @memberof HotelContentDescriptionsInner
     */
    type?: string;
    /**
     * Description type details.
     * @type {string}
     * @memberof HotelContentDescriptionsInner
     */
    text?: string;
}

/**
 * Check if a given object implements the HotelContentDescriptionsInner interface.
 */
export function instanceOfHotelContentDescriptionsInner(value: object): value is HotelContentDescriptionsInner {
    return true;
}

export function HotelContentDescriptionsInnerFromJSON(json: any): HotelContentDescriptionsInner {
    return HotelContentDescriptionsInnerFromJSONTyped(json, false);
}

export function HotelContentDescriptionsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentDescriptionsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['Type'] == null ? undefined : json['Type'],
        'text': json['Text'] == null ? undefined : json['Text'],
    };
}

export function HotelContentDescriptionsInnerToJSON(value?: HotelContentDescriptionsInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Type': value['type'],
        'Text': value['text'],
    };
}

