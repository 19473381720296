/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CardOption
 */
export interface CardOption {
    /**
     * Brand name for the accepted credit or debit card. Use this value to determine which cards to display on your checkout page.
     * @type {string}
     * @memberof CardOption
     */
    name?: string;
    /**
     * The country in which the payment will be processed.
     * @type {string}
     * @memberof CardOption
     */
    processingCountry?: string;
}

/**
 * Check if a given object implements the CardOption interface.
 */
export function instanceOfCardOption(value: object): value is CardOption {
    return true;
}

export function CardOptionFromJSON(json: any): CardOption {
    return CardOptionFromJSONTyped(json, false);
}

export function CardOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardOption {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'processingCountry': json['processing_country'] == null ? undefined : json['processing_country'],
    };
}

export function CardOptionToJSON(value?: CardOption | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'processing_country': value['processingCountry'],
    };
}

