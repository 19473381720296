/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelSearchResults } from './HotelSearchResults';
import {
    HotelSearchResultsFromJSON,
    HotelSearchResultsFromJSONTyped,
    HotelSearchResultsToJSON,
} from './HotelSearchResults';
import type { SearchSessionStatus } from './SearchSessionStatus';
import {
    SearchSessionStatusFromJSON,
    SearchSessionStatusFromJSONTyped,
    SearchSessionStatusToJSON,
} from './SearchSessionStatus';
import type { Viewport } from './Viewport';
import {
    ViewportFromJSON,
    ViewportFromJSONTyped,
    ViewportToJSON,
} from './Viewport';
import type { SearchLocationType } from './SearchLocationType';
import {
    SearchLocationTypeFromJSON,
    SearchLocationTypeFromJSONTyped,
    SearchLocationTypeToJSON,
} from './SearchLocationType';
import type { SearchRequestV3 } from './SearchRequestV3';
import {
    SearchRequestV3FromJSON,
    SearchRequestV3FromJSONTyped,
    SearchRequestV3ToJSON,
} from './SearchRequestV3';

/**
 * A search session
 * @export
 * @interface SearchSessionV3
 */
export interface SearchSessionV3 {
    /**
     * The search session id
     * @type {string}
     * @memberof SearchSessionV3
     */
    searchSessionId: string;
    /**
     * 
     * @type {SearchSessionStatus}
     * @memberof SearchSessionV3
     */
    sessionStatus: SearchSessionStatus;
    /**
     * 
     * @type {SearchRequestV3}
     * @memberof SearchSessionV3
     */
    searchRequest: SearchRequestV3;
    /**
     * 
     * @type {HotelSearchResults}
     * @memberof SearchSessionV3
     */
    searchResults: HotelSearchResults;
    /**
     * 
     * @type {SearchLocationType}
     * @memberof SearchSessionV3
     */
    searchLocationType?: SearchLocationType;
    /**
     * 
     * @type {Viewport}
     * @memberof SearchSessionV3
     */
    recommendedViewport?: Viewport;
}

/**
 * Check if a given object implements the SearchSessionV3 interface.
 */
export function instanceOfSearchSessionV3(value: object): value is SearchSessionV3 {
    if (!('searchSessionId' in value) || value['searchSessionId'] === undefined) return false;
    if (!('sessionStatus' in value) || value['sessionStatus'] === undefined) return false;
    if (!('searchRequest' in value) || value['searchRequest'] === undefined) return false;
    if (!('searchResults' in value) || value['searchResults'] === undefined) return false;
    return true;
}

export function SearchSessionV3FromJSON(json: any): SearchSessionV3 {
    return SearchSessionV3FromJSONTyped(json, false);
}

export function SearchSessionV3FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSessionV3 {
    if (json == null) {
        return json;
    }
    return {
        
        'searchSessionId': json['search_session_id'],
        'sessionStatus': SearchSessionStatusFromJSON(json['session_status']),
        'searchRequest': SearchRequestV3FromJSON(json['search_request']),
        'searchResults': HotelSearchResultsFromJSON(json['search_results']),
        'searchLocationType': json['search_location_type'] == null ? undefined : SearchLocationTypeFromJSON(json['search_location_type']),
        'recommendedViewport': json['recommended_viewport'] == null ? undefined : ViewportFromJSON(json['recommended_viewport']),
    };
}

export function SearchSessionV3ToJSON(value?: SearchSessionV3 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'search_session_id': value['searchSessionId'],
        'session_status': SearchSessionStatusToJSON(value['sessionStatus']),
        'search_request': SearchRequestV3ToJSON(value['searchRequest']),
        'search_results': HotelSearchResultsToJSON(value['searchResults']),
        'search_location_type': SearchLocationTypeToJSON(value['searchLocationType']),
        'recommended_viewport': ViewportToJSON(value['recommendedViewport']),
    };
}

