/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NightChargeType } from './NightChargeType';
import {
    NightChargeTypeFromJSON,
    NightChargeTypeFromJSONTyped,
    NightChargeTypeToJSON,
} from './NightChargeType';

/**
 * 
 * @export
 * @interface NightCharge
 */
export interface NightCharge {
    /**
     * 
     * @type {NightChargeType}
     * @memberof NightCharge
     */
    type?: NightChargeType;
    /**
     * The value of the amount object. Decimal point inline with correct precision.
     * @type {string}
     * @memberof NightCharge
     */
    value?: string;
    /**
     * Currency of the amount object.
     * @type {string}
     * @memberof NightCharge
     */
    currency?: string;
}

/**
 * Check if a given object implements the NightCharge interface.
 */
export function instanceOfNightCharge(value: object): value is NightCharge {
    return true;
}

export function NightChargeFromJSON(json: any): NightCharge {
    return NightChargeFromJSONTyped(json, false);
}

export function NightChargeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NightCharge {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : NightChargeTypeFromJSON(json['type']),
        'value': json['value'] == null ? undefined : json['value'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function NightChargeToJSON(value?: NightCharge | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': NightChargeTypeToJSON(value['type']),
        'value': value['value'],
        'currency': value['currency'],
    };
}

