/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Viewport } from './Viewport';
import {
    ViewportFromJSON,
    ViewportFromJSONTyped,
    ViewportToJSON,
} from './Viewport';
import type { SearchLocationType } from './SearchLocationType';
import {
    SearchLocationTypeFromJSON,
    SearchLocationTypeFromJSONTyped,
    SearchLocationTypeToJSON,
} from './SearchLocationType';
import type { SearchPromptRequest } from './SearchPromptRequest';
import {
    SearchPromptRequestFromJSON,
    SearchPromptRequestFromJSONTyped,
    SearchPromptRequestToJSON,
} from './SearchPromptRequest';

/**
 * The response for a search prompt request
 * @export
 * @interface SearchPromptResponse
 */
export interface SearchPromptResponse {
    /**
     * The prompt ID
     * @type {string}
     * @memberof SearchPromptResponse
     */
    promptId: string;
    /**
     * 
     * @type {Viewport}
     * @memberof SearchPromptResponse
     */
    viewport: Viewport;
    /**
     * The vervotech property ID, if a single property
     * @type {number}
     * @memberof SearchPromptResponse
     */
    vervotechPropertyId?: number;
    /**
     * The region IDs
     * @type {Array<number>}
     * @memberof SearchPromptResponse
     */
    regionIds?: Array<number>;
    /**
     * 
     * @type {SearchPromptRequest}
     * @memberof SearchPromptResponse
     */
    request: SearchPromptRequest;
    /**
     * 
     * @type {SearchLocationType}
     * @memberof SearchPromptResponse
     */
    searchLocationType: SearchLocationType;
}

/**
 * Check if a given object implements the SearchPromptResponse interface.
 */
export function instanceOfSearchPromptResponse(value: object): value is SearchPromptResponse {
    if (!('promptId' in value) || value['promptId'] === undefined) return false;
    if (!('viewport' in value) || value['viewport'] === undefined) return false;
    if (!('request' in value) || value['request'] === undefined) return false;
    if (!('searchLocationType' in value) || value['searchLocationType'] === undefined) return false;
    return true;
}

export function SearchPromptResponseFromJSON(json: any): SearchPromptResponse {
    return SearchPromptResponseFromJSONTyped(json, false);
}

export function SearchPromptResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPromptResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'promptId': json['prompt_id'],
        'viewport': ViewportFromJSON(json['viewport']),
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'regionIds': json['region_ids'] == null ? undefined : json['region_ids'],
        'request': SearchPromptRequestFromJSON(json['request']),
        'searchLocationType': SearchLocationTypeFromJSON(json['search_location_type']),
    };
}

export function SearchPromptResponseToJSON(value?: SearchPromptResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'prompt_id': value['promptId'],
        'viewport': ViewportToJSON(value['viewport']),
        'vervotech_property_id': value['vervotechPropertyId'],
        'region_ids': value['regionIds'],
        'request': SearchPromptRequestToJSON(value['request']),
        'search_location_type': SearchLocationTypeToJSON(value['searchLocationType']),
    };
}

