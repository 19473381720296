/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferServiceMetadata } from './OfferServiceMetadata';
import {
    OfferServiceMetadataFromJSON,
    OfferServiceMetadataFromJSONTyped,
    OfferServiceMetadataToJSON,
} from './OfferServiceMetadata';

/**
 * 
 * @export
 * @interface OfferService
 */
export interface OfferService {
    /**
     * Duffel's unique identifier for the resource
     * @type {string}
     * @memberof OfferService
     */
    id?: string;
    /**
     * The maximum quantity of this service that can be booked with an order
     * @type {number}
     * @memberof OfferService
     */
    maximumQuantity?: number;
    /**
     * 
     * @type {OfferServiceMetadata}
     * @memberof OfferService
     */
    metadata?: OfferServiceMetadata;
    /**
     * The list of passenger `id`s the service applies to. If you add this service to an order it will apply to all the passengers in this list. For services where the type is `baggage`, this list will include only a single passenger.
     * @type {Array<string>}
     * @memberof OfferService
     */
    passengerIds?: Array<string>;
    /**
     * The list of segment `id`s the service applies to. If you add this service to an order it will apply to all the segments in this list. For services where the type is `baggage`, depending on the airline, this list includes all the segments of all slices or all the segments of a single slice.
     * @type {Array<string>}
     * @memberof OfferService
     */
    segmentIds?: Array<string>;
    /**
     * The total price of the service for all passengers and segments it
     * applies to, including taxes. This price is for a single unit of the
     * service.
     * 
     * @type {string}
     * @memberof OfferService
     */
    totalAmount?: string;
    /**
     * The currency of the `total_amount`, as an [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code.
     * It will match your organisation's billing currency unless you’re using Duffel as an accredited IATA agent, in which case it will be in the currency provided by the airline (which will usually be based on the country where your IATA agency is registered).
     * 
     * @type {string}
     * @memberof OfferService
     */
    totalCurrency?: string;
    /**
     * The type of the service
     * @type {string}
     * @memberof OfferService
     */
    type?: OfferServiceTypeEnum;
}


/**
 * @export
 */
export const OfferServiceTypeEnum = {
    Baggage: 'baggage',
    Seat: 'seat',
    Meal: 'meal',
    CancelForAnyReason: 'cancel_for_any_reason'
} as const;
export type OfferServiceTypeEnum = typeof OfferServiceTypeEnum[keyof typeof OfferServiceTypeEnum];


/**
 * Check if a given object implements the OfferService interface.
 */
export function instanceOfOfferService(value: object): value is OfferService {
    return true;
}

export function OfferServiceFromJSON(json: any): OfferService {
    return OfferServiceFromJSONTyped(json, false);
}

export function OfferServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferService {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'maximumQuantity': json['maximum_quantity'] == null ? undefined : json['maximum_quantity'],
        'metadata': json['metadata'] == null ? undefined : OfferServiceMetadataFromJSON(json['metadata']),
        'passengerIds': json['passenger_ids'] == null ? undefined : json['passenger_ids'],
        'segmentIds': json['segment_ids'] == null ? undefined : json['segment_ids'],
        'totalAmount': json['total_amount'] == null ? undefined : json['total_amount'],
        'totalCurrency': json['total_currency'] == null ? undefined : json['total_currency'],
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function OfferServiceToJSON(value?: OfferService | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'maximum_quantity': value['maximumQuantity'],
        'metadata': OfferServiceMetadataToJSON(value['metadata']),
        'passenger_ids': value['passengerIds'],
        'segment_ids': value['segmentIds'],
        'total_amount': value['totalAmount'],
        'total_currency': value['totalCurrency'],
        'type': value['type'],
    };
}

