/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreditCardMerchant } from './CreditCardMerchant';
import {
    CreditCardMerchantFromJSON,
    CreditCardMerchantFromJSONTyped,
    CreditCardMerchantToJSON,
} from './CreditCardMerchant';
import type { CardOption } from './CardOption';
import {
    CardOptionFromJSON,
    CardOptionFromJSONTyped,
    CardOptionToJSON,
} from './CardOption';

/**
 * 
 * @export
 * @interface CreditCard1
 */
export interface CreditCard1 {
    /**
     * 
     * @type {Array<CardOption>}
     * @memberof CreditCard1
     */
    cardOptions?: Array<CardOption>;
    /**
     * 
     * @type {CreditCardMerchant}
     * @memberof CreditCard1
     */
    merchant?: CreditCardMerchant;
    /**
     * Display name of payment option.
     * @type {string}
     * @memberof CreditCard1
     */
    name?: string;
}

/**
 * Check if a given object implements the CreditCard1 interface.
 */
export function instanceOfCreditCard1(value: object): value is CreditCard1 {
    return true;
}

export function CreditCard1FromJSON(json: any): CreditCard1 {
    return CreditCard1FromJSONTyped(json, false);
}

export function CreditCard1FromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditCard1 {
    if (json == null) {
        return json;
    }
    return {
        
        'cardOptions': json['card_options'] == null ? undefined : ((json['card_options'] as Array<any>).map(CardOptionFromJSON)),
        'merchant': json['merchant'] == null ? undefined : CreditCardMerchantFromJSON(json['merchant']),
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function CreditCard1ToJSON(value?: CreditCard1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'card_options': value['cardOptions'] == null ? undefined : ((value['cardOptions'] as Array<any>).map(CardOptionToJSON)),
        'merchant': CreditCardMerchantToJSON(value['merchant']),
        'name': value['name'],
    };
}

