/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The companion category for any travelers that accompanied this reviewer.
 * @export
 */
export const TravelCompanion = {
    Family: 'family',
    FamilyWithChildren: 'family_with_children',
    Partner: 'partner',
    Self: 'self',
    Friends: 'friends',
    Pet: 'pet'
} as const;
export type TravelCompanion = typeof TravelCompanion[keyof typeof TravelCompanion];


export function instanceOfTravelCompanion(value: any): boolean {
    for (const key in TravelCompanion) {
        if (Object.prototype.hasOwnProperty.call(TravelCompanion, key)) {
            if ((TravelCompanion as Record<string, TravelCompanion>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TravelCompanionFromJSON(json: any): TravelCompanion {
    return TravelCompanionFromJSONTyped(json, false);
}

export function TravelCompanionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelCompanion {
    return json as TravelCompanion;
}

export function TravelCompanionToJSON(value?: TravelCompanion | null): any {
    return value as any;
}
