/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A search date range
 * @export
 * @interface SearchDates
 */
export interface SearchDates {
    /**
     * 
     * @type {Date}
     * @memberof SearchDates
     */
    checkin: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchDates
     */
    checkout: Date;
}

/**
 * Check if a given object implements the SearchDates interface.
 */
export function instanceOfSearchDates(value: object): value is SearchDates {
    if (!('checkin' in value) || value['checkin'] === undefined) return false;
    if (!('checkout' in value) || value['checkout'] === undefined) return false;
    return true;
}

export function SearchDatesFromJSON(json: any): SearchDates {
    return SearchDatesFromJSONTyped(json, false);
}

export function SearchDatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchDates {
    if (json == null) {
        return json;
    }
    return {
        
        'checkin': (new Date(json['checkin'])),
        'checkout': (new Date(json['checkout'])),
    };
}

export function SearchDatesToJSON(value?: SearchDates | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'checkin': ((value['checkin']).toISOString()),
        'checkout': ((value['checkout']).toISOString()),
    };
}

