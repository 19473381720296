/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CategoryValueAdd } from './CategoryValueAdd';
import {
    CategoryValueAddFromJSON,
    CategoryValueAddFromJSONTyped,
    CategoryValueAddToJSON,
} from './CategoryValueAdd';
import type { Frequency } from './Frequency';
import {
    FrequencyFromJSON,
    FrequencyFromJSONTyped,
    FrequencyToJSON,
} from './Frequency';
import type { OfferType } from './OfferType';
import {
    OfferTypeFromJSON,
    OfferTypeFromJSONTyped,
    OfferTypeToJSON,
} from './OfferType';

/**
 * An individual value add.
 * @export
 * @interface ValueAdd
 */
export interface ValueAdd {
    /**
     * Unique identifier for the value add promotion.
     * @type {string}
     * @memberof ValueAdd
     */
    id?: string;
    /**
     * A localized description of the value add promotion.
     * @type {string}
     * @memberof ValueAdd
     */
    description?: string;
    /**
     * 
     * @type {CategoryValueAdd}
     * @memberof ValueAdd
     */
    category?: CategoryValueAdd;
    /**
     * 
     * @type {OfferType}
     * @memberof ValueAdd
     */
    offerType?: OfferType;
    /**
     * 
     * @type {Frequency}
     * @memberof ValueAdd
     */
    frequency?: Frequency;
    /**
     * Indicates how many guests the value add promotion applies to.
     * @type {number}
     * @memberof ValueAdd
     */
    personCount?: number;
}

/**
 * Check if a given object implements the ValueAdd interface.
 */
export function instanceOfValueAdd(value: object): value is ValueAdd {
    return true;
}

export function ValueAddFromJSON(json: any): ValueAdd {
    return ValueAddFromJSONTyped(json, false);
}

export function ValueAddFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValueAdd {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'description': json['description'] == null ? undefined : json['description'],
        'category': json['category'] == null ? undefined : CategoryValueAddFromJSON(json['category']),
        'offerType': json['offer_type'] == null ? undefined : OfferTypeFromJSON(json['offer_type']),
        'frequency': json['frequency'] == null ? undefined : FrequencyFromJSON(json['frequency']),
        'personCount': json['person_count'] == null ? undefined : json['person_count'],
    };
}

export function ValueAddToJSON(value?: ValueAdd | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'description': value['description'],
        'category': CategoryValueAddToJSON(value['category']),
        'offer_type': OfferTypeToJSON(value['offerType']),
        'frequency': FrequencyToJSON(value['frequency']),
        'person_count': value['personCount'],
    };
}

