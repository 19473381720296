/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Room area.
 * @export
 * @interface RoomArea
 */
export interface RoomArea {
    /**
     * Square meters
     * @type {string}
     * @memberof RoomArea
     */
    squareMeters?: string;
    /**
     * Square feet.
     * @type {string}
     * @memberof RoomArea
     */
    squareFeet?: string;
    /**
     * Room area details.
     * @type {string}
     * @memberof RoomArea
     */
    text?: string;
}

/**
 * Check if a given object implements the RoomArea interface.
 */
export function instanceOfRoomArea(value: object): value is RoomArea {
    return true;
}

export function RoomAreaFromJSON(json: any): RoomArea {
    return RoomAreaFromJSONTyped(json, false);
}

export function RoomAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomArea {
    if (json == null) {
        return json;
    }
    return {
        
        'squareMeters': json['SquareMeters'] == null ? undefined : json['SquareMeters'],
        'squareFeet': json['SquareFeet'] == null ? undefined : json['SquareFeet'],
        'text': json['Text'] == null ? undefined : json['Text'],
    };
}

export function RoomAreaToJSON(value?: RoomArea | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'SquareMeters': value['squareMeters'],
        'SquareFeet': value['squareFeet'],
        'Text': value['text'],
    };
}

