/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';

/**
 * A map of links, including links to resume or cancel a held booking. This is only included for held bookings.
 * @export
 * @interface ItineraryLinks
 */
export interface ItineraryLinks {
    [key: string]: Link | any;
    /**
     * 
     * @type {Link}
     * @memberof ItineraryLinks
     */
    resume?: Link;
    /**
     * 
     * @type {Link}
     * @memberof ItineraryLinks
     */
    cancel?: Link;
}

/**
 * Check if a given object implements the ItineraryLinks interface.
 */
export function instanceOfItineraryLinks(value: object): value is ItineraryLinks {
    return true;
}

export function ItineraryLinksFromJSON(json: any): ItineraryLinks {
    return ItineraryLinksFromJSONTyped(json, false);
}

export function ItineraryLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItineraryLinks {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'resume': json['resume'] == null ? undefined : LinkFromJSON(json['resume']),
        'cancel': json['cancel'] == null ? undefined : LinkFromJSON(json['cancel']),
    };
}

export function ItineraryLinksToJSON(value?: ItineraryLinks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'resume': LinkToJSON(value['resume']),
        'cancel': LinkToJSON(value['cancel']),
    };
}

