/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * A search location. One of address, coordinates, property_id, or name must be provided
 * @export
 * @interface SearchLocation
 */
export interface SearchLocation {
    /**
     * An address that will be geocoded on the server and used as a central location for property search
     * @type {string}
     * @memberof SearchLocation
     */
    address?: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof SearchLocation
     */
    coordinates?: Coordinates;
    /**
     * An array of vervotech property_ids used in the search. Search will only return results for these properties
     * @type {Array<number>}
     * @memberof SearchLocation
     */
    vervotechPropertyIds?: Array<number>;
    /**
     * A name used to search for a location.
     * @type {string}
     * @memberof SearchLocation
     */
    name?: string;
    /**
     * A chain name used to search for locations, e.g. Marriott, Hilton
     * @type {string}
     * @memberof SearchLocation
     */
    chainName?: string;
    /**
     * Number of km around search location to include in search
     * @type {number}
     * @memberof SearchLocation
     */
    searchRadius?: number;
}

/**
 * Check if a given object implements the SearchLocation interface.
 */
export function instanceOfSearchLocation(value: object): value is SearchLocation {
    return true;
}

export function SearchLocationFromJSON(json: any): SearchLocation {
    return SearchLocationFromJSONTyped(json, false);
}

export function SearchLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchLocation {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : json['address'],
        'coordinates': json['coordinates'] == null ? undefined : CoordinatesFromJSON(json['coordinates']),
        'vervotechPropertyIds': json['vervotech_property_ids'] == null ? undefined : json['vervotech_property_ids'],
        'name': json['name'] == null ? undefined : json['name'],
        'chainName': json['chain_name'] == null ? undefined : json['chain_name'],
        'searchRadius': json['search_radius'] == null ? undefined : json['search_radius'],
    };
}

export function SearchLocationToJSON(value?: SearchLocation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'coordinates': CoordinatesToJSON(value['coordinates']),
        'vervotech_property_ids': value['vervotechPropertyIds'],
        'name': value['name'],
        'chain_name': value['chainName'],
        'search_radius': value['searchRadius'],
    };
}

