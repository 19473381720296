/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicates the status of the rate. If the rate is still available then available will be returned. If the rate is no longer available at that price then price_changed will be returned. If the rate is no longer available at all then sold_out will be returned.
 * @export
 */
export const StatusPriceCheck = {
    Available: 'available',
    PriceChanged: 'price_changed',
    SoldOut: 'sold_out'
} as const;
export type StatusPriceCheck = typeof StatusPriceCheck[keyof typeof StatusPriceCheck];


export function instanceOfStatusPriceCheck(value: any): boolean {
    for (const key in StatusPriceCheck) {
        if (Object.prototype.hasOwnProperty.call(StatusPriceCheck, key)) {
            if ((StatusPriceCheck as Record<string, StatusPriceCheck>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StatusPriceCheckFromJSON(json: any): StatusPriceCheck {
    return StatusPriceCheckFromJSONTyped(json, false);
}

export function StatusPriceCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPriceCheck {
    return json as StatusPriceCheck;
}

export function StatusPriceCheckToJSON(value?: StatusPriceCheck | null): any {
    return value as any;
}
