/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Address1
 */
export interface Address1 {
    /**
     * First line of street address.
     * @type {string}
     * @memberof Address1
     */
    line1?: string;
    /**
     * Second line of street address.
     * @type {string}
     * @memberof Address1
     */
    line2?: string;
    /**
     * Third line of street address.
     * @type {string}
     * @memberof Address1
     */
    line3?: string;
    /**
     * City where address is located.
     * @type {string}
     * @memberof Address1
     */
    city?: string;
    /**
     * State or province code of address, if applicable.
     * @type {string}
     * @memberof Address1
     */
    stateProvinceCode?: string;
    /**
     * Postal code of address, if applicable.
     * @type {string}
     * @memberof Address1
     */
    postalCode?: string;
    /**
     * Country code, in two-letter ISO 3166-1 alpha-2 format.
     * @type {string}
     * @memberof Address1
     */
    countryCode?: string;
}

/**
 * Check if a given object implements the Address1 interface.
 */
export function instanceOfAddress1(value: object): value is Address1 {
    return true;
}

export function Address1FromJSON(json: any): Address1 {
    return Address1FromJSONTyped(json, false);
}

export function Address1FromJSONTyped(json: any, ignoreDiscriminator: boolean): Address1 {
    if (json == null) {
        return json;
    }
    return {
        
        'line1': json['line_1'] == null ? undefined : json['line_1'],
        'line2': json['line_2'] == null ? undefined : json['line_2'],
        'line3': json['line_3'] == null ? undefined : json['line_3'],
        'city': json['city'] == null ? undefined : json['city'],
        'stateProvinceCode': json['state_province_code'] == null ? undefined : json['state_province_code'],
        'postalCode': json['postal_code'] == null ? undefined : json['postal_code'],
        'countryCode': json['country_code'] == null ? undefined : json['country_code'],
    };
}

export function Address1ToJSON(value?: Address1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'line_1': value['line1'],
        'line_2': value['line2'],
        'line_3': value['line3'],
        'city': value['city'],
        'state_province_code': value['stateProvinceCode'],
        'postal_code': value['postalCode'],
        'country_code': value['countryCode'],
    };
}

