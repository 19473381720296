/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferSliceSegment } from './OfferSliceSegment';
import {
    OfferSliceSegmentFromJSON,
    OfferSliceSegmentFromJSONTyped,
    OfferSliceSegmentToJSON,
} from './OfferSliceSegment';
import type { OfferSliceDestination } from './OfferSliceDestination';
import {
    OfferSliceDestinationFromJSON,
    OfferSliceDestinationFromJSONTyped,
    OfferSliceDestinationToJSON,
} from './OfferSliceDestination';
import type { OfferSliceOrigin } from './OfferSliceOrigin';
import {
    OfferSliceOriginFromJSON,
    OfferSliceOriginFromJSONTyped,
    OfferSliceOriginToJSON,
} from './OfferSliceOrigin';
import type { PlaceType } from './PlaceType';
import {
    PlaceTypeFromJSON,
    PlaceTypeFromJSONTyped,
    PlaceTypeToJSON,
} from './PlaceType';
import type { OfferSliceConditions } from './OfferSliceConditions';
import {
    OfferSliceConditionsFromJSON,
    OfferSliceConditionsFromJSONTyped,
    OfferSliceConditionsToJSON,
} from './OfferSliceConditions';

/**
 * 
 * @export
 * @interface OfferSlice
 */
export interface OfferSlice {
    /**
     * 
     * @type {OfferSliceConditions}
     * @memberof OfferSlice
     */
    conditions?: OfferSliceConditions;
    /**
     * 
     * @type {OfferSliceDestination}
     * @memberof OfferSlice
     */
    destination?: OfferSliceDestination;
    /**
     * 
     * @type {PlaceType}
     * @memberof OfferSlice
     */
    destinationType?: PlaceType;
    /**
     * The duration of the slice, represented as a [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601#Durations) duration
     * @type {string}
     * @memberof OfferSlice
     */
    duration?: string;
    /**
     * The name of the fare brand associated with this slice.
     * A fare brand specifies the travel conditions you get on your slice made available by the airline.
     * e.g. a British Airways Economy Basic fare will only include a hand baggage allowance.
     * It is worth noting that the fare brand names are defined by the airlines themselves and
     * therefore they are subject to change without any prior notice.
     * We’re in the process of adding support for `fare_brand_name` across all our airlines,
     * so for now, this field may be `null` in some offers.
     * This will become a non-nullable attribute in the near future.
     * 
     * @type {string}
     * @memberof OfferSlice
     */
    fareBrandName?: string;
    /**
     * Duffel's unique identifier for the resource
     * @type {string}
     * @memberof OfferSlice
     */
    id?: string;
    /**
     * 
     * @type {OfferSliceOrigin}
     * @memberof OfferSlice
     */
    origin?: OfferSliceOrigin;
    /**
     * 
     * @type {PlaceType}
     * @memberof OfferSlice
     */
    originType?: PlaceType;
    /**
     * Cost in points
     * @type {number}
     * @memberof OfferSlice
     */
    pointsCost?: number;
    /**
     * Cost in cash
     * @type {number}
     * @memberof OfferSlice
     */
    cashCost?: number;
    /**
     * Currency code for cash cost
     * @type {string}
     * @memberof OfferSlice
     */
    currencyCode?: string;
    /**
     * The segments - that is, specific flights - that the airline is offering to get the passengers from the `origin` to the `destination`
     * @type {Array<OfferSliceSegment>}
     * @memberof OfferSlice
     */
    segments?: Array<OfferSliceSegment>;
}

/**
 * Check if a given object implements the OfferSlice interface.
 */
export function instanceOfOfferSlice(value: object): value is OfferSlice {
    return true;
}

export function OfferSliceFromJSON(json: any): OfferSlice {
    return OfferSliceFromJSONTyped(json, false);
}

export function OfferSliceFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSlice {
    if (json == null) {
        return json;
    }
    return {
        
        'conditions': json['conditions'] == null ? undefined : OfferSliceConditionsFromJSON(json['conditions']),
        'destination': json['destination'] == null ? undefined : OfferSliceDestinationFromJSON(json['destination']),
        'destinationType': json['destination_type'] == null ? undefined : PlaceTypeFromJSON(json['destination_type']),
        'duration': json['duration'] == null ? undefined : json['duration'],
        'fareBrandName': json['fare_brand_name'] == null ? undefined : json['fare_brand_name'],
        'id': json['id'] == null ? undefined : json['id'],
        'origin': json['origin'] == null ? undefined : OfferSliceOriginFromJSON(json['origin']),
        'originType': json['origin_type'] == null ? undefined : PlaceTypeFromJSON(json['origin_type']),
        'pointsCost': json['points_cost'] == null ? undefined : json['points_cost'],
        'cashCost': json['cash_cost'] == null ? undefined : json['cash_cost'],
        'currencyCode': json['currency_code'] == null ? undefined : json['currency_code'],
        'segments': json['segments'] == null ? undefined : ((json['segments'] as Array<any>).map(OfferSliceSegmentFromJSON)),
    };
}

export function OfferSliceToJSON(value?: OfferSlice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conditions': OfferSliceConditionsToJSON(value['conditions']),
        'destination': OfferSliceDestinationToJSON(value['destination']),
        'destination_type': PlaceTypeToJSON(value['destinationType']),
        'duration': value['duration'],
        'fare_brand_name': value['fareBrandName'],
        'id': value['id'],
        'origin': OfferSliceOriginToJSON(value['origin']),
        'origin_type': PlaceTypeToJSON(value['originType']),
        'points_cost': value['pointsCost'],
        'cash_cost': value['cashCost'],
        'currency_code': value['currencyCode'],
        'segments': value['segments'] == null ? undefined : ((value['segments'] as Array<any>).map(OfferSliceSegmentToJSON)),
    };
}

