/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CardProvider } from './CardProvider';
import {
    CardProviderFromJSON,
    CardProviderFromJSONTyped,
    CardProviderToJSON,
} from './CardProvider';
import type { BillingContact } from './BillingContact';
import {
    BillingContactFromJSON,
    BillingContactFromJSONTyped,
    BillingContactToJSON,
} from './BillingContact';

/**
 * A credit card
 * @export
 * @interface CreditCard
 */
export interface CreditCard {
    /**
     * Credit card number
     * @type {string}
     * @memberof CreditCard
     */
    cardNumber: string;
    /**
     * Card security code
     * @type {string}
     * @memberof CreditCard
     */
    securityCode: string;
    /**
     * Card expiration month
     * @type {string}
     * @memberof CreditCard
     */
    expirationMonth: string;
    /**
     * Card expiration year
     * @type {string}
     * @memberof CreditCard
     */
    expirationYear: string;
    /**
     * 
     * @type {CardProvider}
     * @memberof CreditCard
     */
    cardProvider?: CardProvider;
    /**
     * 
     * @type {BillingContact}
     * @memberof CreditCard
     */
    billingContact: BillingContact;
}

/**
 * Check if a given object implements the CreditCard interface.
 */
export function instanceOfCreditCard(value: object): value is CreditCard {
    if (!('cardNumber' in value) || value['cardNumber'] === undefined) return false;
    if (!('securityCode' in value) || value['securityCode'] === undefined) return false;
    if (!('expirationMonth' in value) || value['expirationMonth'] === undefined) return false;
    if (!('expirationYear' in value) || value['expirationYear'] === undefined) return false;
    if (!('billingContact' in value) || value['billingContact'] === undefined) return false;
    return true;
}

export function CreditCardFromJSON(json: any): CreditCard {
    return CreditCardFromJSONTyped(json, false);
}

export function CreditCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditCard {
    if (json == null) {
        return json;
    }
    return {
        
        'cardNumber': json['card_number'],
        'securityCode': json['security_code'],
        'expirationMonth': json['expiration_month'],
        'expirationYear': json['expiration_year'],
        'cardProvider': json['card_provider'] == null ? undefined : CardProviderFromJSON(json['card_provider']),
        'billingContact': BillingContactFromJSON(json['billing_contact']),
    };
}

export function CreditCardToJSON(value?: CreditCard | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'card_number': value['cardNumber'],
        'security_code': value['securityCode'],
        'expiration_month': value['expirationMonth'],
        'expiration_year': value['expirationYear'],
        'card_provider': CardProviderToJSON(value['cardProvider']),
        'billing_contact': BillingContactToJSON(value['billingContact']),
    };
}

