/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A search param
 * @export
 * @interface SearchParams
 */
export interface SearchParams {
    /**
     * 
     * @type {number}
     * @memberof SearchParams
     */
    numberOfRates?: number;
}

/**
 * Check if a given object implements the SearchParams interface.
 */
export function instanceOfSearchParams(value: object): value is SearchParams {
    return true;
}

export function SearchParamsFromJSON(json: any): SearchParams {
    return SearchParamsFromJSONTyped(json, false);
}

export function SearchParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchParams {
    if (json == null) {
        return json;
    }
    return {
        
        'numberOfRates': json['number_of_rates'] == null ? undefined : json['number_of_rates'],
    };
}

export function SearchParamsToJSON(value?: SearchParams | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'number_of_rates': value['numberOfRates'],
    };
}

