/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A date range
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * 
     * @type {Date}
     * @memberof DateRange
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof DateRange
     */
    endDate: Date;
}

/**
 * Check if a given object implements the DateRange interface.
 */
export function instanceOfDateRange(value: object): value is DateRange {
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    return true;
}

export function DateRangeFromJSON(json: any): DateRange {
    return DateRangeFromJSONTyped(json, false);
}

export function DateRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateRange {
    if (json == null) {
        return json;
    }
    return {
        
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
    };
}

export function DateRangeToJSON(value?: DateRange | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start_date': ((value['startDate']).toISOString().substring(0,10)),
        'end_date': ((value['endDate']).toISOString().substring(0,10)),
    };
}

