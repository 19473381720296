/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelContentContactAddress } from './HotelContentContactAddress';
import {
    HotelContentContactAddressFromJSON,
    HotelContentContactAddressFromJSONTyped,
    HotelContentContactAddressToJSON,
} from './HotelContentContactAddress';

/**
 * Container for the property's contact information.
 * @export
 * @interface HotelContentContact
 */
export interface HotelContentContact {
    /**
     * 
     * @type {HotelContentContactAddress}
     * @memberof HotelContentContact
     */
    address?: HotelContentContactAddress;
    /**
     * Property's phone numbers.
     * @type {Array<string>}
     * @memberof HotelContentContact
     */
    phones?: Array<string>;
    /**
     * The property's fax number.
     * @type {string}
     * @memberof HotelContentContact
     */
    fax?: string;
    /**
     * Property's email addresses.
     * @type {Array<string>}
     * @memberof HotelContentContact
     */
    emails?: Array<string>;
    /**
     * Hotel website.
     * @type {string}
     * @memberof HotelContentContact
     */
    web?: string;
}

/**
 * Check if a given object implements the HotelContentContact interface.
 */
export function instanceOfHotelContentContact(value: object): value is HotelContentContact {
    return true;
}

export function HotelContentContactFromJSON(json: any): HotelContentContact {
    return HotelContentContactFromJSONTyped(json, false);
}

export function HotelContentContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentContact {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['Address'] == null ? undefined : HotelContentContactAddressFromJSON(json['Address']),
        'phones': json['Phones'] == null ? undefined : json['Phones'],
        'fax': json['Fax'] == null ? undefined : json['Fax'],
        'emails': json['Emails'] == null ? undefined : json['Emails'],
        'web': json['Web'] == null ? undefined : json['Web'],
    };
}

export function HotelContentContactToJSON(value?: HotelContentContact | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Address': HotelContentContactAddressToJSON(value['address']),
        'Phones': value['phones'],
        'Fax': value['fax'],
        'Emails': value['emails'],
        'Web': value['web'],
    };
}

