/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A request for static flight route metadata based on source, destination_airport, and arrival_airport
 * @export
 * @interface FlightRouteRequest
 */
export interface FlightRouteRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof FlightRouteRequest
     */
    source?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlightRouteRequest
     */
    originAirport?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlightRouteRequest
     */
    destinationAirport?: Array<string>;
}

/**
 * Check if a given object implements the FlightRouteRequest interface.
 */
export function instanceOfFlightRouteRequest(value: object): value is FlightRouteRequest {
    return true;
}

export function FlightRouteRequestFromJSON(json: any): FlightRouteRequest {
    return FlightRouteRequestFromJSONTyped(json, false);
}

export function FlightRouteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightRouteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'source': json['source'] == null ? undefined : json['source'],
        'originAirport': json['origin_airport'] == null ? undefined : json['origin_airport'],
        'destinationAirport': json['destination_airport'] == null ? undefined : json['destination_airport'],
    };
}

export function FlightRouteRequestToJSON(value?: FlightRouteRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'source': value['source'],
        'origin_airport': value['originAirport'],
        'destination_airport': value['destinationAirport'],
    };
}

