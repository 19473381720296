/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Viewport
 */
export interface Viewport {
    /**
     * The north latitude (max)
     * @type {number}
     * @memberof Viewport
     */
    north: number;
    /**
     * The east longitude (max)
     * @type {number}
     * @memberof Viewport
     */
    east: number;
    /**
     * The south latitude (min)
     * @type {number}
     * @memberof Viewport
     */
    south: number;
    /**
     * The west longitude (min)
     * @type {number}
     * @memberof Viewport
     */
    west: number;
}

/**
 * Check if a given object implements the Viewport interface.
 */
export function instanceOfViewport(value: object): value is Viewport {
    if (!('north' in value) || value['north'] === undefined) return false;
    if (!('east' in value) || value['east'] === undefined) return false;
    if (!('south' in value) || value['south'] === undefined) return false;
    if (!('west' in value) || value['west'] === undefined) return false;
    return true;
}

export function ViewportFromJSON(json: any): Viewport {
    return ViewportFromJSONTyped(json, false);
}

export function ViewportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Viewport {
    if (json == null) {
        return json;
    }
    return {
        
        'north': json['north'],
        'east': json['east'],
        'south': json['south'],
        'west': json['west'],
    };
}

export function ViewportToJSON(value?: Viewport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'north': value['north'],
        'east': value['east'],
        'south': value['south'],
        'west': value['west'],
    };
}

