/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditCardMerchant
 */
export interface CreditCardMerchant {
    /**
     * Name of the merchant to use during 3rd party authorization for PSD2.
     * @type {string}
     * @memberof CreditCardMerchant
     */
    name?: string;
}

/**
 * Check if a given object implements the CreditCardMerchant interface.
 */
export function instanceOfCreditCardMerchant(value: object): value is CreditCardMerchant {
    return true;
}

export function CreditCardMerchantFromJSON(json: any): CreditCardMerchant {
    return CreditCardMerchantFromJSONTyped(json, false);
}

export function CreditCardMerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditCardMerchant {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function CreditCardMerchantToJSON(value?: CreditCardMerchant | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

