/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserLoyalty } from './UserLoyalty';
import {
    UserLoyaltyFromJSON,
    UserLoyaltyFromJSONTyped,
    UserLoyaltyToJSON,
} from './UserLoyalty';
import type { UserEnvironment } from './UserEnvironment';
import {
    UserEnvironmentFromJSON,
    UserEnvironmentFromJSONTyped,
    UserEnvironmentToJSON,
} from './UserEnvironment';

/**
 * A user making a search
 * @export
 * @interface SearchUser
 */
export interface SearchUser {
    /**
     * 
     * @type {string}
     * @memberof SearchUser
     */
    userId: string;
    /**
     * 
     * @type {UserEnvironment}
     * @memberof SearchUser
     */
    userEnvironment: UserEnvironment;
    /**
     * 
     * @type {Array<UserLoyalty>}
     * @memberof SearchUser
     */
    userLoyalty: Array<UserLoyalty>;
}

/**
 * Check if a given object implements the SearchUser interface.
 */
export function instanceOfSearchUser(value: object): value is SearchUser {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('userEnvironment' in value) || value['userEnvironment'] === undefined) return false;
    if (!('userLoyalty' in value) || value['userLoyalty'] === undefined) return false;
    return true;
}

export function SearchUserFromJSON(json: any): SearchUser {
    return SearchUserFromJSONTyped(json, false);
}

export function SearchUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchUser {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'userEnvironment': UserEnvironmentFromJSON(json['user_environment']),
        'userLoyalty': ((json['user_loyalty'] as Array<any>).map(UserLoyaltyFromJSON)),
    };
}

export function SearchUserToJSON(value?: SearchUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_id': value['userId'],
        'user_environment': UserEnvironmentToJSON(value['userEnvironment']),
        'user_loyalty': ((value['userLoyalty'] as Array<any>).map(UserLoyaltyToJSON)),
    };
}

