/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Deal } from './Deal';
import {
    DealFromJSON,
    DealFromJSONTyped,
    DealToJSON,
} from './Deal';
import type { ValueAdd } from './ValueAdd';
import {
    ValueAddFromJSON,
    ValueAddFromJSONTyped,
    ValueAddToJSON,
} from './ValueAdd';

/**
 * Available promotions that apply to this rate.
 * @export
 * @interface Promotions
 */
export interface Promotions {
    /**
     * A collection of value adds that apply to this rate.
     * @type {{ [key: string]: ValueAdd; }}
     * @memberof Promotions
     */
    valueAdds?: { [key: string]: ValueAdd; };
    /**
     * 
     * @type {Deal}
     * @memberof Promotions
     */
    deal?: Deal;
}

/**
 * Check if a given object implements the Promotions interface.
 */
export function instanceOfPromotions(value: object): value is Promotions {
    return true;
}

export function PromotionsFromJSON(json: any): Promotions {
    return PromotionsFromJSONTyped(json, false);
}

export function PromotionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Promotions {
    if (json == null) {
        return json;
    }
    return {
        
        'valueAdds': json['value_adds'] == null ? undefined : (mapValues(json['value_adds'], ValueAddFromJSON)),
        'deal': json['deal'] == null ? undefined : DealFromJSON(json['deal']),
    };
}

export function PromotionsToJSON(value?: Promotions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value_adds': value['valueAdds'] == null ? undefined : (mapValues(value['valueAdds'], ValueAddToJSON)),
        'deal': DealToJSON(value['deal']),
    };
}

