/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Cash Cost Percentile
 * @export
 * @interface CashCostPercentile
 */
export interface CashCostPercentile {
    /**
     * 
     * @type {number}
     * @memberof CashCostPercentile
     */
    nigthlyCashCostUsd: number;
    /**
     * 
     * @type {number}
     * @memberof CashCostPercentile
     */
    probability?: number;
    /**
     * 
     * @type {number}
     * @memberof CashCostPercentile
     */
    percentile: number;
}

/**
 * Check if a given object implements the CashCostPercentile interface.
 */
export function instanceOfCashCostPercentile(value: object): value is CashCostPercentile {
    if (!('nigthlyCashCostUsd' in value) || value['nigthlyCashCostUsd'] === undefined) return false;
    if (!('percentile' in value) || value['percentile'] === undefined) return false;
    return true;
}

export function CashCostPercentileFromJSON(json: any): CashCostPercentile {
    return CashCostPercentileFromJSONTyped(json, false);
}

export function CashCostPercentileFromJSONTyped(json: any, ignoreDiscriminator: boolean): CashCostPercentile {
    if (json == null) {
        return json;
    }
    return {
        
        'nigthlyCashCostUsd': json['nigthly_cash_cost_usd'],
        'probability': json['probability'] == null ? undefined : json['probability'],
        'percentile': json['percentile'],
    };
}

export function CashCostPercentileToJSON(value?: CashCostPercentile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nigthly_cash_cost_usd': value['nigthlyCashCostUsd'],
        'probability': value['probability'],
        'percentile': value['percentile'],
    };
}

