/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The property's check-in information.
 * @export
 * @interface HotelContentCheckin
 */
export interface HotelContentCheckin {
    /**
     * The time at which a property begins to allow check-ins.
     * @type {string}
     * @memberof HotelContentCheckin
     */
    beginTime?: string;
    /**
     * The time at which a property stops allowing check-ins.
     * @type {string}
     * @memberof HotelContentCheckin
     */
    endTime?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HotelContentCheckin
     */
    instructions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HotelContentCheckin
     */
    specialInstructions?: Array<string>;
    /**
     * The minimum age for a customer to be able to check-in at a property.
     * @type {number}
     * @memberof HotelContentCheckin
     */
    minAge?: number;
}

/**
 * Check if a given object implements the HotelContentCheckin interface.
 */
export function instanceOfHotelContentCheckin(value: object): value is HotelContentCheckin {
    return true;
}

export function HotelContentCheckinFromJSON(json: any): HotelContentCheckin {
    return HotelContentCheckinFromJSONTyped(json, false);
}

export function HotelContentCheckinFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentCheckin {
    if (json == null) {
        return json;
    }
    return {
        
        'beginTime': json['BeginTime'] == null ? undefined : json['BeginTime'],
        'endTime': json['EndTime'] == null ? undefined : json['EndTime'],
        'instructions': json['Instructions'] == null ? undefined : json['Instructions'],
        'specialInstructions': json['SpecialInstructions'] == null ? undefined : json['SpecialInstructions'],
        'minAge': json['MinAge'] == null ? undefined : json['MinAge'],
    };
}

export function HotelContentCheckinToJSON(value?: HotelContentCheckin | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'BeginTime': value['beginTime'],
        'EndTime': value['endTime'],
        'Instructions': value['instructions'],
        'SpecialInstructions': value['specialInstructions'],
        'MinAge': value['minAge'],
    };
}

