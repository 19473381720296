/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';

/**
 * A map of links, including links to retrieve a booking, resume a held booking, cancel a held booking, or complete a payment session if a challenge is used.
 * @export
 * @interface ItineraryCreationLinks
 */
export interface ItineraryCreationLinks {
    [key: string]: Link | any;
    /**
     * 
     * @type {Link}
     * @memberof ItineraryCreationLinks
     */
    retrieve?: Link;
    /**
     * 
     * @type {Link}
     * @memberof ItineraryCreationLinks
     */
    resume?: Link;
    /**
     * 
     * @type {Link}
     * @memberof ItineraryCreationLinks
     */
    completePaymentSession?: Link;
    /**
     * 
     * @type {Link}
     * @memberof ItineraryCreationLinks
     */
    cancel?: Link;
}

/**
 * Check if a given object implements the ItineraryCreationLinks interface.
 */
export function instanceOfItineraryCreationLinks(value: object): value is ItineraryCreationLinks {
    return true;
}

export function ItineraryCreationLinksFromJSON(json: any): ItineraryCreationLinks {
    return ItineraryCreationLinksFromJSONTyped(json, false);
}

export function ItineraryCreationLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItineraryCreationLinks {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'retrieve': json['retrieve'] == null ? undefined : LinkFromJSON(json['retrieve']),
        'resume': json['resume'] == null ? undefined : LinkFromJSON(json['resume']),
        'completePaymentSession': json['complete_payment_session'] == null ? undefined : LinkFromJSON(json['complete_payment_session']),
        'cancel': json['cancel'] == null ? undefined : LinkFromJSON(json['cancel']),
    };
}

export function ItineraryCreationLinksToJSON(value?: ItineraryCreationLinks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'retrieve': LinkToJSON(value['retrieve']),
        'resume': LinkToJSON(value['resume']),
        'complete_payment_session': LinkToJSON(value['completePaymentSession']),
        'cancel': LinkToJSON(value['cancel']),
    };
}

