/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchSessionResultsFlights } from './SearchSessionResultsFlights';
import {
    SearchSessionResultsFlightsFromJSON,
    SearchSessionResultsFlightsFromJSONTyped,
    SearchSessionResultsFlightsToJSON,
} from './SearchSessionResultsFlights';
import type { SearchSessionV2ResultsHotels } from './SearchSessionV2ResultsHotels';
import {
    SearchSessionV2ResultsHotelsFromJSON,
    SearchSessionV2ResultsHotelsFromJSONTyped,
    SearchSessionV2ResultsHotelsToJSON,
} from './SearchSessionV2ResultsHotels';

/**
 * The results of the search
 * @export
 * @interface SearchSessionV2Results
 */
export interface SearchSessionV2Results {
    /**
     * 
     * @type {SearchSessionV2ResultsHotels}
     * @memberof SearchSessionV2Results
     */
    hotels?: SearchSessionV2ResultsHotels;
    /**
     * 
     * @type {SearchSessionResultsFlights}
     * @memberof SearchSessionV2Results
     */
    flights?: SearchSessionResultsFlights;
}

/**
 * Check if a given object implements the SearchSessionV2Results interface.
 */
export function instanceOfSearchSessionV2Results(value: object): value is SearchSessionV2Results {
    return true;
}

export function SearchSessionV2ResultsFromJSON(json: any): SearchSessionV2Results {
    return SearchSessionV2ResultsFromJSONTyped(json, false);
}

export function SearchSessionV2ResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSessionV2Results {
    if (json == null) {
        return json;
    }
    return {
        
        'hotels': json['hotels'] == null ? undefined : SearchSessionV2ResultsHotelsFromJSON(json['hotels']),
        'flights': json['flights'] == null ? undefined : SearchSessionResultsFlightsFromJSON(json['flights']),
    };
}

export function SearchSessionV2ResultsToJSON(value?: SearchSessionV2Results | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotels': SearchSessionV2ResultsHotelsToJSON(value['hotels']),
        'flights': SearchSessionResultsFlightsToJSON(value['flights']),
    };
}

