/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Whether this slice can be changed before the departure.
 * If the slice can be changed for all of the passengers then the `allowed` property will be `true` and information will be provided about any penalties.
 * If none of the passengers on the slice can be changed then the `allowed` property will be `false`.
 * In all other cases this property will be `null` indicating we can't provide the information for this slice.
 * The `penalty_amount` is specific to changing this slice and may not be the penalty that is applied if the entire offer is changed.
 * 
 * @export
 * @interface OfferSliceConditionsChangeBeforeDeparture
 */
export interface OfferSliceConditionsChangeBeforeDeparture {
    /**
     * Whether this kind of modification is allowed post-booking
     * @type {boolean}
     * @memberof OfferSliceConditionsChangeBeforeDeparture
     */
    allowed?: boolean;
    /**
     * If the modification is `allowed` then this is the amount payable to apply the modification to all passengers. If there is no penalty, the value will be zero.
     * If the modification isn't `allowed` or the penalty is not known then this field will be `null`.
     * If this is `null` then the `penalty_currency` will also be `null`.
     * 
     * @type {string}
     * @memberof OfferSliceConditionsChangeBeforeDeparture
     */
    penaltyAmount?: string;
    /**
     * The currency of the `penalty_amount` as an [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code.
     * This will be in a currency determined by the airline, which is not necessarily the same as the currency of the offer or order.
     * If this is `null` then `penalty_amount` will also be `null`.
     * 
     * @type {string}
     * @memberof OfferSliceConditionsChangeBeforeDeparture
     */
    penaltyCurrency?: string;
}

/**
 * Check if a given object implements the OfferSliceConditionsChangeBeforeDeparture interface.
 */
export function instanceOfOfferSliceConditionsChangeBeforeDeparture(value: object): value is OfferSliceConditionsChangeBeforeDeparture {
    return true;
}

export function OfferSliceConditionsChangeBeforeDepartureFromJSON(json: any): OfferSliceConditionsChangeBeforeDeparture {
    return OfferSliceConditionsChangeBeforeDepartureFromJSONTyped(json, false);
}

export function OfferSliceConditionsChangeBeforeDepartureFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceConditionsChangeBeforeDeparture {
    if (json == null) {
        return json;
    }
    return {
        
        'allowed': json['allowed'] == null ? undefined : json['allowed'],
        'penaltyAmount': json['penalty_amount'] == null ? undefined : json['penalty_amount'],
        'penaltyCurrency': json['penalty_currency'] == null ? undefined : json['penalty_currency'],
    };
}

export function OfferSliceConditionsChangeBeforeDepartureToJSON(value?: OfferSliceConditionsChangeBeforeDeparture | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allowed': value['allowed'],
        'penalty_amount': value['penaltyAmount'],
        'penalty_currency': value['penaltyCurrency'],
    };
}

