/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferPrivateFares
 */
export interface OfferPrivateFares {
    /**
     * The corporate code that was applied, if any
     * @type {string}
     * @memberof OfferPrivateFares
     */
    corporateCode: string | null;
    /**
     * The tour code that was applied, if any
     * @type {string}
     * @memberof OfferPrivateFares
     */
    tourCode: string | null;
    /**
     * The tracking reference that was applied, if any
     * @type {string}
     * @memberof OfferPrivateFares
     */
    trackingReference: string | null;
    /**
     * The type of private fare applied
     * @type {string}
     * @memberof OfferPrivateFares
     */
    type: OfferPrivateFaresTypeEnum;
}


/**
 * @export
 */
export const OfferPrivateFaresTypeEnum = {
    Corporate: 'corporate',
    Leisure: 'leisure',
    Negotiated: 'negotiated'
} as const;
export type OfferPrivateFaresTypeEnum = typeof OfferPrivateFaresTypeEnum[keyof typeof OfferPrivateFaresTypeEnum];


/**
 * Check if a given object implements the OfferPrivateFares interface.
 */
export function instanceOfOfferPrivateFares(value: object): value is OfferPrivateFares {
    if (!('corporateCode' in value) || value['corporateCode'] === undefined) return false;
    if (!('tourCode' in value) || value['tourCode'] === undefined) return false;
    if (!('trackingReference' in value) || value['trackingReference'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function OfferPrivateFaresFromJSON(json: any): OfferPrivateFares {
    return OfferPrivateFaresFromJSONTyped(json, false);
}

export function OfferPrivateFaresFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferPrivateFares {
    if (json == null) {
        return json;
    }
    return {
        
        'corporateCode': json['corporate_code'],
        'tourCode': json['tour_code'],
        'trackingReference': json['tracking_reference'],
        'type': json['type'],
    };
}

export function OfferPrivateFaresToJSON(value?: OfferPrivateFares | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'corporate_code': value['corporateCode'],
        'tour_code': value['tourCode'],
        'tracking_reference': value['trackingReference'],
        'type': value['type'],
    };
}

