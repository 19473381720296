/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BookingStatus } from './BookingStatus';
import {
    BookingStatusFromJSON,
    BookingStatusFromJSONTyped,
    BookingStatusToJSON,
} from './BookingStatus';

/**
 * 
 * @export
 * @interface BookingStatuses
 */
export interface BookingStatuses {
    /**
     * 
     * @type {Array<BookingStatus>}
     * @memberof BookingStatuses
     */
    items: Array<BookingStatus>;
}

/**
 * Check if a given object implements the BookingStatuses interface.
 */
export function instanceOfBookingStatuses(value: object): value is BookingStatuses {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function BookingStatusesFromJSON(json: any): BookingStatuses {
    return BookingStatusesFromJSONTyped(json, false);
}

export function BookingStatusesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingStatuses {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(BookingStatusFromJSON)),
    };
}

export function BookingStatusesToJSON(value?: BookingStatuses | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(BookingStatusToJSON)),
    };
}

