/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Information about property policies that guests need to be aware of.
 * @export
 * @interface HotelContentPoliciesInner
 */
export interface HotelContentPoliciesInner {
    /**
     * Description of information that may be helpful when planning a trip to this property.
     * @type {string}
     * @memberof HotelContentPoliciesInner
     */
    text?: string;
    /**
     * Policy title
     * @type {string}
     * @memberof HotelContentPoliciesInner
     */
    title?: string;
}

/**
 * Check if a given object implements the HotelContentPoliciesInner interface.
 */
export function instanceOfHotelContentPoliciesInner(value: object): value is HotelContentPoliciesInner {
    return true;
}

export function HotelContentPoliciesInnerFromJSON(json: any): HotelContentPoliciesInner {
    return HotelContentPoliciesInnerFromJSONTyped(json, false);
}

export function HotelContentPoliciesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentPoliciesInner {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['Text'] == null ? undefined : json['Text'],
        'title': json['Title'] == null ? undefined : json['Title'],
    };
}

export function HotelContentPoliciesInnerToJSON(value?: HotelContentPoliciesInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Text': value['text'],
        'Title': value['title'],
    };
}

