/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status of the search session
 * @export
 */
export const SearchSessionStatus = {
    Pending: 'pending',
    Complete: 'complete'
} as const;
export type SearchSessionStatus = typeof SearchSessionStatus[keyof typeof SearchSessionStatus];


export function instanceOfSearchSessionStatus(value: any): boolean {
    for (const key in SearchSessionStatus) {
        if (Object.prototype.hasOwnProperty.call(SearchSessionStatus, key)) {
            if ((SearchSessionStatus as Record<string, SearchSessionStatus>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchSessionStatusFromJSON(json: any): SearchSessionStatus {
    return SearchSessionStatusFromJSONTyped(json, false);
}

export function SearchSessionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSessionStatus {
    return json as SearchSessionStatus;
}

export function SearchSessionStatusToJSON(value?: SearchSessionStatus | null): any {
    return value as any;
}
