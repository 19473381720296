/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Redemption Rate Percentile
 * @export
 * @interface RedemptionRatePercentile
 */
export interface RedemptionRatePercentile {
    /**
     * 
     * @type {number}
     * @memberof RedemptionRatePercentile
     */
    redemptionRate: number;
    /**
     * 
     * @type {number}
     * @memberof RedemptionRatePercentile
     */
    probability?: number;
    /**
     * 
     * @type {number}
     * @memberof RedemptionRatePercentile
     */
    percentile: number;
}

/**
 * Check if a given object implements the RedemptionRatePercentile interface.
 */
export function instanceOfRedemptionRatePercentile(value: object): value is RedemptionRatePercentile {
    if (!('redemptionRate' in value) || value['redemptionRate'] === undefined) return false;
    if (!('percentile' in value) || value['percentile'] === undefined) return false;
    return true;
}

export function RedemptionRatePercentileFromJSON(json: any): RedemptionRatePercentile {
    return RedemptionRatePercentileFromJSONTyped(json, false);
}

export function RedemptionRatePercentileFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedemptionRatePercentile {
    if (json == null) {
        return json;
    }
    return {
        
        'redemptionRate': json['redemption_rate'],
        'probability': json['probability'] == null ? undefined : json['probability'],
        'percentile': json['percentile'],
    };
}

export function RedemptionRatePercentileToJSON(value?: RedemptionRatePercentile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'redemption_rate': value['redemptionRate'],
        'probability': value['probability'],
        'percentile': value['percentile'],
    };
}

