/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An individual amenity.
 * @export
 * @interface Amenity
 */
export interface Amenity {
    /**
     * The amenity definition ID for this amenity.
     * @type {string}
     * @memberof Amenity
     */
    id?: string;
    /**
     * Amenity name.
     * @type {string}
     * @memberof Amenity
     */
    name?: string;
    /**
     * Amenity value.
     * @type {string}
     * @memberof Amenity
     */
    value?: string;
    /**
     * This is an optional field and will be present only if the amenity falls into one or more of these amenity categories.<br>
     * See the Amenity Categories section of the
     * [Content Reference Lists](https://developers.expediagroup.com/docs/rapid/lodging/content/content-reference-lists)
     * for a list of values.
     * 
     * @type {Array<string>}
     * @memberof Amenity
     */
    categories?: Array<string>;
}

/**
 * Check if a given object implements the Amenity interface.
 */
export function instanceOfAmenity(value: object): value is Amenity {
    return true;
}

export function AmenityFromJSON(json: any): Amenity {
    return AmenityFromJSONTyped(json, false);
}

export function AmenityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Amenity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'value': json['value'] == null ? undefined : json['value'],
        'categories': json['categories'] == null ? undefined : json['categories'],
    };
}

export function AmenityToJSON(value?: Amenity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'value': value['value'],
        'categories': value['categories'],
    };
}

