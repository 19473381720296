/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MerchantOfRecord } from './MerchantOfRecord';
import {
    MerchantOfRecordFromJSON,
    MerchantOfRecordFromJSONTyped,
    MerchantOfRecordToJSON,
} from './MerchantOfRecord';

/**
 * 
 * @export
 * @interface CancellationPolicySummary
 */
export interface CancellationPolicySummary {
    /**
     * 
     * @type {string}
     * @memberof CancellationPolicySummary
     */
    rateId?: string;
    /**
     * 
     * @type {MerchantOfRecord}
     * @memberof CancellationPolicySummary
     */
    merchantOfRecord?: MerchantOfRecord;
    /**
     * 
     * @type {Array<string>}
     * @memberof CancellationPolicySummary
     */
    policies?: Array<string>;
}

/**
 * Check if a given object implements the CancellationPolicySummary interface.
 */
export function instanceOfCancellationPolicySummary(value: object): value is CancellationPolicySummary {
    return true;
}

export function CancellationPolicySummaryFromJSON(json: any): CancellationPolicySummary {
    return CancellationPolicySummaryFromJSONTyped(json, false);
}

export function CancellationPolicySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancellationPolicySummary {
    if (json == null) {
        return json;
    }
    return {
        
        'rateId': json['rate_id'] == null ? undefined : json['rate_id'],
        'merchantOfRecord': json['merchant_of_record'] == null ? undefined : MerchantOfRecordFromJSON(json['merchant_of_record']),
        'policies': json['policies'] == null ? undefined : json['policies'],
    };
}

export function CancellationPolicySummaryToJSON(value?: CancellationPolicySummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rate_id': value['rateId'],
        'merchant_of_record': MerchantOfRecordToJSON(value['merchantOfRecord']),
        'policies': value['policies'],
    };
}

