/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A deposit policy associated with the rate.
 * @export
 * @interface Deposit
 */
export interface Deposit {
    /**
     * The amount that must be paid as a deposit.
     * @type {string}
     * @memberof Deposit
     */
    value?: string;
    /**
     * The due date in ISO 8601 format.
     * @type {string}
     * @memberof Deposit
     */
    due?: string;
    /**
     * The currency for the deposit amount.
     * @type {string}
     * @memberof Deposit
     */
    currency?: string;
}

/**
 * Check if a given object implements the Deposit interface.
 */
export function instanceOfDeposit(value: object): value is Deposit {
    return true;
}

export function DepositFromJSON(json: any): Deposit {
    return DepositFromJSONTyped(json, false);
}

export function DepositFromJSONTyped(json: any, ignoreDiscriminator: boolean): Deposit {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'due': json['due'] == null ? undefined : json['due'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function DepositToJSON(value?: Deposit | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'due': value['due'],
        'currency': value['currency'],
    };
}

