/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StaySummary } from './StaySummary';
import {
    StaySummaryFromJSON,
    StaySummaryFromJSONTyped,
    StaySummaryToJSON,
} from './StaySummary';
import type { RecommendationSummary } from './RecommendationSummary';
import {
    RecommendationSummaryFromJSON,
    RecommendationSummaryFromJSONTyped,
    RecommendationSummaryToJSON,
} from './RecommendationSummary';
import type { UserSummary } from './UserSummary';
import {
    UserSummaryFromJSON,
    UserSummaryFromJSONTyped,
    UserSummaryToJSON,
} from './UserSummary';
import type { PropertySummary } from './PropertySummary';
import {
    PropertySummaryFromJSON,
    PropertySummaryFromJSONTyped,
    PropertySummaryToJSON,
} from './PropertySummary';

/**
 * A hotel summary - contains a property summary and a user summary
 * @export
 * @interface HotelSummaryV2
 */
export interface HotelSummaryV2 {
    /**
     * 
     * @type {PropertySummary}
     * @memberof HotelSummaryV2
     */
    propertySummary?: PropertySummary;
    /**
     * 
     * @type {UserSummary}
     * @memberof HotelSummaryV2
     */
    userSummary?: UserSummary;
    /**
     * 
     * @type {StaySummary}
     * @memberof HotelSummaryV2
     */
    staySummary?: StaySummary;
    /**
     * 
     * @type {RecommendationSummary}
     * @memberof HotelSummaryV2
     */
    recommendationSummary?: RecommendationSummary;
}

/**
 * Check if a given object implements the HotelSummaryV2 interface.
 */
export function instanceOfHotelSummaryV2(value: object): value is HotelSummaryV2 {
    return true;
}

export function HotelSummaryV2FromJSON(json: any): HotelSummaryV2 {
    return HotelSummaryV2FromJSONTyped(json, false);
}

export function HotelSummaryV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelSummaryV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'propertySummary': json['property_summary'] == null ? undefined : PropertySummaryFromJSON(json['property_summary']),
        'userSummary': json['user_summary'] == null ? undefined : UserSummaryFromJSON(json['user_summary']),
        'staySummary': json['stay_summary'] == null ? undefined : StaySummaryFromJSON(json['stay_summary']),
        'recommendationSummary': json['recommendation_summary'] == null ? undefined : RecommendationSummaryFromJSON(json['recommendation_summary']),
    };
}

export function HotelSummaryV2ToJSON(value?: HotelSummaryV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'property_summary': PropertySummaryToJSON(value['propertySummary']),
        'user_summary': UserSummaryToJSON(value['userSummary']),
        'stay_summary': StaySummaryToJSON(value['staySummary']),
        'recommendation_summary': RecommendationSummaryToJSON(value['recommendationSummary']),
    };
}

