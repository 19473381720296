/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Information on the standard seat in this cabin class. Exceptions may apply, such as on exit rows.
 * @export
 * @interface OfferSliceSegmentPassengerCabinAmenitiesSeat
 */
export interface OfferSliceSegmentPassengerCabinAmenitiesSeat {
    /**
     * The available legroom for the passenger
     * @type {string}
     * @memberof OfferSliceSegmentPassengerCabinAmenitiesSeat
     */
    legroom?: OfferSliceSegmentPassengerCabinAmenitiesSeatLegroomEnum;
    /**
     * The distance from a point on a seat to the seat front/behind it, in inches, or "n/a" if not available
     * @type {string}
     * @memberof OfferSliceSegmentPassengerCabinAmenitiesSeat
     */
    pitch?: string;
}


/**
 * @export
 */
export const OfferSliceSegmentPassengerCabinAmenitiesSeatLegroomEnum = {
    Less: 'less',
    More: 'more',
    Standard: 'standard',
    NA: 'n/a'
} as const;
export type OfferSliceSegmentPassengerCabinAmenitiesSeatLegroomEnum = typeof OfferSliceSegmentPassengerCabinAmenitiesSeatLegroomEnum[keyof typeof OfferSliceSegmentPassengerCabinAmenitiesSeatLegroomEnum];


/**
 * Check if a given object implements the OfferSliceSegmentPassengerCabinAmenitiesSeat interface.
 */
export function instanceOfOfferSliceSegmentPassengerCabinAmenitiesSeat(value: object): value is OfferSliceSegmentPassengerCabinAmenitiesSeat {
    return true;
}

export function OfferSliceSegmentPassengerCabinAmenitiesSeatFromJSON(json: any): OfferSliceSegmentPassengerCabinAmenitiesSeat {
    return OfferSliceSegmentPassengerCabinAmenitiesSeatFromJSONTyped(json, false);
}

export function OfferSliceSegmentPassengerCabinAmenitiesSeatFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentPassengerCabinAmenitiesSeat {
    if (json == null) {
        return json;
    }
    return {
        
        'legroom': json['legroom'] == null ? undefined : json['legroom'],
        'pitch': json['pitch'] == null ? undefined : json['pitch'],
    };
}

export function OfferSliceSegmentPassengerCabinAmenitiesSeatToJSON(value?: OfferSliceSegmentPassengerCabinAmenitiesSeat | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'legroom': value['legroom'],
        'pitch': value['pitch'],
    };
}

