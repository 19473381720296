/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelImage } from './HotelImage';
import {
    HotelImageFromJSON,
    HotelImageFromJSONTyped,
    HotelImageToJSON,
} from './HotelImage';
import type { RatingForCategory } from './RatingForCategory';
import {
    RatingForCategoryFromJSON,
    RatingForCategoryFromJSONTyped,
    RatingForCategoryToJSON,
} from './RatingForCategory';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';
import type { HotelTheme } from './HotelTheme';
import {
    HotelThemeFromJSON,
    HotelThemeFromJSONTyped,
    HotelThemeToJSON,
} from './HotelTheme';
import type { RoomInfo } from './RoomInfo';
import {
    RoomInfoFromJSON,
    RoomInfoFromJSONTyped,
    RoomInfoToJSON,
} from './RoomInfo';
import type { HotelAmenity } from './HotelAmenity';
import {
    HotelAmenityFromJSON,
    HotelAmenityFromJSONTyped,
    HotelAmenityToJSON,
} from './HotelAmenity';
import type { ExternalReview } from './ExternalReview';
import {
    ExternalReviewFromJSON,
    ExternalReviewFromJSONTyped,
    ExternalReviewToJSON,
} from './ExternalReview';

/**
 * A property summary - does not include any user-specific attributes
 * @export
 * @interface PropertySummary
 */
export interface PropertySummary {
    /**
     * 
     * @type {number}
     * @memberof PropertySummary
     */
    vervotechPropertyId?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertySummary
     */
    providerPropertyId?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    propertyName?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    propertyAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    chainName?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    brandName?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    supplierId?: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof PropertySummary
     */
    coordinates?: Coordinates;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertySummary
     */
    yearBuilt?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertySummary
     */
    yearLastRenovated?: number;
    /**
     * Number of stars
     * @type {number}
     * @memberof PropertySummary
     */
    stars?: number;
    /**
     * Average guest rating
     * @type {number}
     * @memberof PropertySummary
     */
    averageGuestRating?: number;
    /**
     * The number of reviews for the property
     * @type {number}
     * @memberof PropertySummary
     */
    reviewCount?: number;
    /**
     * Average ratings across various review categories, e.g. cleanliness, service, etc
     * @type {Array<RatingForCategory>}
     * @memberof PropertySummary
     */
    categoryRatings?: Array<RatingForCategory>;
    /**
     * URL of hero image
     * @type {string}
     * @memberof PropertySummary
     */
    heroImage?: string;
    /**
     * Name of loyalty program
     * @type {string}
     * @memberof PropertySummary
     */
    loyaltyProgram?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    checkinTime?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertySummary
     */
    checkoutTime?: string;
    /**
     * 
     * @type {Array<HotelAmenity>}
     * @memberof PropertySummary
     */
    amenities?: Array<HotelAmenity>;
    /**
     * 
     * @type {Array<HotelAmenity>}
     * @memberof PropertySummary
     */
    fullAmenities?: Array<HotelAmenity>;
    /**
     * 
     * @type {Array<HotelImage>}
     * @memberof PropertySummary
     */
    images?: Array<HotelImage>;
    /**
     * 
     * @type {Array<ExternalReview>}
     * @memberof PropertySummary
     */
    externalReviews?: Array<ExternalReview>;
    /**
     * 
     * @type {Array<HotelTheme>}
     * @memberof PropertySummary
     */
    hotelThemes?: Array<HotelTheme>;
    /**
     * 
     * @type {Array<RoomInfo>}
     * @memberof PropertySummary
     */
    rooms?: Array<RoomInfo>;
}

/**
 * Check if a given object implements the PropertySummary interface.
 */
export function instanceOfPropertySummary(value: object): value is PropertySummary {
    return true;
}

export function PropertySummaryFromJSON(json: any): PropertySummary {
    return PropertySummaryFromJSONTyped(json, false);
}

export function PropertySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertySummary {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'providerPropertyId': json['provider_property_id'] == null ? undefined : json['provider_property_id'],
        'propertyName': json['property_name'] == null ? undefined : json['property_name'],
        'propertyAddress': json['property_address'] == null ? undefined : json['property_address'],
        'cityName': json['city_name'] == null ? undefined : json['city_name'],
        'countryCode': json['country_code'] == null ? undefined : json['country_code'],
        'chainName': json['chain_name'] == null ? undefined : json['chain_name'],
        'brandName': json['brand_name'] == null ? undefined : json['brand_name'],
        'supplierId': json['supplier_id'] == null ? undefined : json['supplier_id'],
        'coordinates': json['coordinates'] == null ? undefined : CoordinatesFromJSON(json['coordinates']),
        'description': json['description'] == null ? undefined : json['description'],
        'yearBuilt': json['year_built'] == null ? undefined : json['year_built'],
        'yearLastRenovated': json['year_last_renovated'] == null ? undefined : json['year_last_renovated'],
        'stars': json['stars'] == null ? undefined : json['stars'],
        'averageGuestRating': json['average_guest_rating'] == null ? undefined : json['average_guest_rating'],
        'reviewCount': json['review_count'] == null ? undefined : json['review_count'],
        'categoryRatings': json['category_ratings'] == null ? undefined : ((json['category_ratings'] as Array<any>).map(RatingForCategoryFromJSON)),
        'heroImage': json['hero_image'] == null ? undefined : json['hero_image'],
        'loyaltyProgram': json['loyalty_program'] == null ? undefined : json['loyalty_program'],
        'checkinTime': json['checkin_time'] == null ? undefined : json['checkin_time'],
        'checkoutTime': json['checkout_time'] == null ? undefined : json['checkout_time'],
        'amenities': json['amenities'] == null ? undefined : ((json['amenities'] as Array<any>).map(HotelAmenityFromJSON)),
        'fullAmenities': json['full_amenities'] == null ? undefined : ((json['full_amenities'] as Array<any>).map(HotelAmenityFromJSON)),
        'images': json['images'] == null ? undefined : ((json['images'] as Array<any>).map(HotelImageFromJSON)),
        'externalReviews': json['external_reviews'] == null ? undefined : ((json['external_reviews'] as Array<any>).map(ExternalReviewFromJSON)),
        'hotelThemes': json['hotel_themes'] == null ? undefined : ((json['hotel_themes'] as Array<any>).map(HotelThemeFromJSON)),
        'rooms': json['rooms'] == null ? undefined : ((json['rooms'] as Array<any>).map(RoomInfoFromJSON)),
    };
}

export function PropertySummaryToJSON(value?: PropertySummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_id': value['vervotechPropertyId'],
        'provider_property_id': value['providerPropertyId'],
        'property_name': value['propertyName'],
        'property_address': value['propertyAddress'],
        'city_name': value['cityName'],
        'country_code': value['countryCode'],
        'chain_name': value['chainName'],
        'brand_name': value['brandName'],
        'supplier_id': value['supplierId'],
        'coordinates': CoordinatesToJSON(value['coordinates']),
        'description': value['description'],
        'year_built': value['yearBuilt'],
        'year_last_renovated': value['yearLastRenovated'],
        'stars': value['stars'],
        'average_guest_rating': value['averageGuestRating'],
        'review_count': value['reviewCount'],
        'category_ratings': value['categoryRatings'] == null ? undefined : ((value['categoryRatings'] as Array<any>).map(RatingForCategoryToJSON)),
        'hero_image': value['heroImage'],
        'loyalty_program': value['loyaltyProgram'],
        'checkin_time': value['checkinTime'],
        'checkout_time': value['checkoutTime'],
        'amenities': value['amenities'] == null ? undefined : ((value['amenities'] as Array<any>).map(HotelAmenityToJSON)),
        'full_amenities': value['fullAmenities'] == null ? undefined : ((value['fullAmenities'] as Array<any>).map(HotelAmenityToJSON)),
        'images': value['images'] == null ? undefined : ((value['images'] as Array<any>).map(HotelImageToJSON)),
        'external_reviews': value['externalReviews'] == null ? undefined : ((value['externalReviews'] as Array<any>).map(ExternalReviewToJSON)),
        'hotel_themes': value['hotelThemes'] == null ? undefined : ((value['hotelThemes'] as Array<any>).map(HotelThemeToJSON)),
        'rooms': value['rooms'] == null ? undefined : ((value['rooms'] as Array<any>).map(RoomInfoToJSON)),
    };
}

