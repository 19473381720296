/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SupplyContact } from './SupplyContact';
import {
    SupplyContactFromJSON,
    SupplyContactFromJSONTyped,
    SupplyContactToJSON,
} from './SupplyContact';
import type { Essential } from './Essential';
import {
    EssentialFromJSON,
    EssentialFromJSONTyped,
    EssentialToJSON,
} from './Essential';

/**
 * Essential information, including the supply contact information and any other essential information.
 * @export
 * @interface EssentialInformation
 */
export interface EssentialInformation {
    /**
     * 
     * @type {SupplyContact}
     * @memberof EssentialInformation
     */
    contact?: SupplyContact;
    /**
     * 
     * @type {Array<Essential>}
     * @memberof EssentialInformation
     */
    essentials?: Array<Essential>;
    /**
     * The date and time when new essential information is available for retrieval, in extended ISO 8601 format, with ±hh:mm timezone offset.
     * @type {string}
     * @memberof EssentialInformation
     */
    updateAvailableDate?: string;
}

/**
 * Check if a given object implements the EssentialInformation interface.
 */
export function instanceOfEssentialInformation(value: object): value is EssentialInformation {
    return true;
}

export function EssentialInformationFromJSON(json: any): EssentialInformation {
    return EssentialInformationFromJSONTyped(json, false);
}

export function EssentialInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EssentialInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'contact': json['contact'] == null ? undefined : SupplyContactFromJSON(json['contact']),
        'essentials': json['essentials'] == null ? undefined : ((json['essentials'] as Array<any>).map(EssentialFromJSON)),
        'updateAvailableDate': json['update_available_date'] == null ? undefined : json['update_available_date'],
    };
}

export function EssentialInformationToJSON(value?: EssentialInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contact': SupplyContactToJSON(value['contact']),
        'essentials': value['essentials'] == null ? undefined : ((value['essentials'] as Array<any>).map(EssentialToJSON)),
        'update_available_date': value['updateAvailableDate'],
    };
}

