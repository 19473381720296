/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `expedia` - Payment is taken by Expedia.
 * * `property` - Payment is taken by the property.
 * 
 * @export
 */
export const MerchantOfRecord = {
    Expedia: 'expedia',
    Property: 'property'
} as const;
export type MerchantOfRecord = typeof MerchantOfRecord[keyof typeof MerchantOfRecord];


export function instanceOfMerchantOfRecord(value: any): boolean {
    for (const key in MerchantOfRecord) {
        if (Object.prototype.hasOwnProperty.call(MerchantOfRecord, key)) {
            if ((MerchantOfRecord as Record<string, MerchantOfRecord>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MerchantOfRecordFromJSON(json: any): MerchantOfRecord {
    return MerchantOfRecordFromJSONTyped(json, false);
}

export function MerchantOfRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantOfRecord {
    return json as MerchantOfRecord;
}

export function MerchantOfRecordToJSON(value?: MerchantOfRecord | null): any {
    return value as any;
}
