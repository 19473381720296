/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of search location
 * @export
 */
export const SearchLocationType = {
    Region: 'region',
    SpecificLocation: 'specific location'
} as const;
export type SearchLocationType = typeof SearchLocationType[keyof typeof SearchLocationType];


export function instanceOfSearchLocationType(value: any): boolean {
    for (const key in SearchLocationType) {
        if (Object.prototype.hasOwnProperty.call(SearchLocationType, key)) {
            if ((SearchLocationType as Record<string, SearchLocationType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchLocationTypeFromJSON(json: any): SearchLocationType {
    return SearchLocationTypeFromJSONTyped(json, false);
}

export function SearchLocationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchLocationType {
    return json as SearchLocationType;
}

export function SearchLocationTypeToJSON(value?: SearchLocationType | null): any {
    return value as any;
}
