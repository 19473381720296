/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Booking } from './Booking';
import {
    BookingFromJSON,
    BookingFromJSONTyped,
    BookingToJSON,
} from './Booking';

/**
 * 
 * @export
 * @interface HotelBookingUpdateResponse
 */
export interface HotelBookingUpdateResponse {
    /**
     * 
     * @type {Booking}
     * @memberof HotelBookingUpdateResponse
     */
    booking?: Booking;
}

/**
 * Check if a given object implements the HotelBookingUpdateResponse interface.
 */
export function instanceOfHotelBookingUpdateResponse(value: object): value is HotelBookingUpdateResponse {
    return true;
}

export function HotelBookingUpdateResponseFromJSON(json: any): HotelBookingUpdateResponse {
    return HotelBookingUpdateResponseFromJSONTyped(json, false);
}

export function HotelBookingUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelBookingUpdateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'booking': json['booking'] == null ? undefined : BookingFromJSON(json['booking']),
    };
}

export function HotelBookingUpdateResponseToJSON(value?: HotelBookingUpdateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'booking': BookingToJSON(value['booking']),
    };
}

