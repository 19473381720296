/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoyaltyProgrammeAccount } from './LoyaltyProgrammeAccount';
import {
    LoyaltyProgrammeAccountFromJSON,
    LoyaltyProgrammeAccountFromJSONTyped,
    LoyaltyProgrammeAccountToJSON,
} from './LoyaltyProgrammeAccount';

/**
 * 
 * @export
 * @interface OfferPassenger
 */
export interface OfferPassenger {
    /**
     * The age of the passenger on the `departure_date` of the final slice
     * @type {number}
     * @memberof OfferPassenger
     */
    age?: number;
    /**
     * The passenger's family name
     * @type {string}
     * @memberof OfferPassenger
     */
    familyName?: string;
    /**
     * The fare type of the passenger
     * @type {string}
     * @memberof OfferPassenger
     */
    fareType?: string;
    /**
     * The passenger's given name
     * @type {string}
     * @memberof OfferPassenger
     */
    givenName?: string;
    /**
     * The identifier for the passenger. This ID will be generated by Duffel
     * @type {string}
     * @memberof OfferPassenger
     */
    id?: string;
    /**
     * The loyalty programme accounts for this passenger
     * @type {Array<LoyaltyProgrammeAccount>}
     * @memberof OfferPassenger
     */
    loyaltyProgrammeAccounts?: Array<LoyaltyProgrammeAccount>;
    /**
     * The type of the passenger
     * @type {string}
     * @memberof OfferPassenger
     */
    type?: OfferPassengerTypeEnum;
}


/**
 * @export
 */
export const OfferPassengerTypeEnum = {
    Adult: 'adult',
    Child: 'child',
    InfantWithoutSeat: 'infant_without_seat'
} as const;
export type OfferPassengerTypeEnum = typeof OfferPassengerTypeEnum[keyof typeof OfferPassengerTypeEnum];


/**
 * Check if a given object implements the OfferPassenger interface.
 */
export function instanceOfOfferPassenger(value: object): value is OfferPassenger {
    return true;
}

export function OfferPassengerFromJSON(json: any): OfferPassenger {
    return OfferPassengerFromJSONTyped(json, false);
}

export function OfferPassengerFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferPassenger {
    if (json == null) {
        return json;
    }
    return {
        
        'age': json['age'] == null ? undefined : json['age'],
        'familyName': json['family_name'] == null ? undefined : json['family_name'],
        'fareType': json['fare_type'] == null ? undefined : json['fare_type'],
        'givenName': json['given_name'] == null ? undefined : json['given_name'],
        'id': json['id'] == null ? undefined : json['id'],
        'loyaltyProgrammeAccounts': json['loyalty_programme_accounts'] == null ? undefined : ((json['loyalty_programme_accounts'] as Array<any>).map(LoyaltyProgrammeAccountFromJSON)),
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function OfferPassengerToJSON(value?: OfferPassenger | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'age': value['age'],
        'family_name': value['familyName'],
        'fare_type': value['fareType'],
        'given_name': value['givenName'],
        'id': value['id'],
        'loyalty_programme_accounts': value['loyaltyProgrammeAccounts'] == null ? undefined : ((value['loyaltyProgrammeAccounts'] as Array<any>).map(LoyaltyProgrammeAccountToJSON)),
        'type': value['type'],
    };
}

