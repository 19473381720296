/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Amount } from './Amount';
import {
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';

/**
 * An itinerary history change event, representing a charge or refund made to the itinerary and not a specific room.
 * @export
 * @interface ItineraryHistoryItem
 */
export interface ItineraryHistoryItem {
    /**
     * Itinerary history id for particular change.
     * @type {string}
     * @memberof ItineraryHistoryItem
     */
    historyId?: string;
    /**
     * Date and time in UTC of the change event, in extended ISO 8601 format.
     * @type {string}
     * @memberof ItineraryHistoryItem
     */
    eventTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ItineraryHistoryItem
     */
    eventType?: ItineraryHistoryItemEventTypeEnum;
    /**
     * 
     * @type {Amount}
     * @memberof ItineraryHistoryItem
     */
    amount?: Amount;
    /**
     * An agent user id number associated with a modification.
     * @type {number}
     * @memberof ItineraryHistoryItem
     */
    agentId?: number;
}


/**
 * @export
 */
export const ItineraryHistoryItemEventTypeEnum = {
    Adjustment: 'adjustment',
    Coupon: 'coupon'
} as const;
export type ItineraryHistoryItemEventTypeEnum = typeof ItineraryHistoryItemEventTypeEnum[keyof typeof ItineraryHistoryItemEventTypeEnum];


/**
 * Check if a given object implements the ItineraryHistoryItem interface.
 */
export function instanceOfItineraryHistoryItem(value: object): value is ItineraryHistoryItem {
    return true;
}

export function ItineraryHistoryItemFromJSON(json: any): ItineraryHistoryItem {
    return ItineraryHistoryItemFromJSONTyped(json, false);
}

export function ItineraryHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItineraryHistoryItem {
    if (json == null) {
        return json;
    }
    return {
        
        'historyId': json['history_id'] == null ? undefined : json['history_id'],
        'eventTimestamp': json['event_timestamp'] == null ? undefined : json['event_timestamp'],
        'eventType': json['event_type'] == null ? undefined : json['event_type'],
        'amount': json['amount'] == null ? undefined : AmountFromJSON(json['amount']),
        'agentId': json['agent_id'] == null ? undefined : json['agent_id'],
    };
}

export function ItineraryHistoryItemToJSON(value?: ItineraryHistoryItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'history_id': value['historyId'],
        'event_timestamp': value['eventTimestamp'],
        'event_type': value['eventType'],
        'amount': AmountToJSON(value['amount']),
        'agent_id': value['agentId'],
    };
}

