/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Phone } from './Phone';
import {
    PhoneFromJSON,
    PhoneFromJSONTyped,
    PhoneToJSON,
} from './Phone';
import type { Address1 } from './Address1';
import {
    Address1FromJSON,
    Address1FromJSONTyped,
    Address1ToJSON,
} from './Address1';

/**
 * The supply contact information. Note that full details may not be displayed until a short time prior to checkin.
 * @export
 * @interface SupplyContact
 */
export interface SupplyContact {
    /**
     * The contact name.
     * @type {string}
     * @memberof SupplyContact
     */
    name?: string;
    /**
     * 
     * @type {Phone}
     * @memberof SupplyContact
     */
    phone?: Phone;
    /**
     * Email address for the contact.
     * @type {string}
     * @memberof SupplyContact
     */
    email?: string;
    /**
     * 
     * @type {Address1}
     * @memberof SupplyContact
     */
    address?: Address1;
}

/**
 * Check if a given object implements the SupplyContact interface.
 */
export function instanceOfSupplyContact(value: object): value is SupplyContact {
    return true;
}

export function SupplyContactFromJSON(json: any): SupplyContact {
    return SupplyContactFromJSONTyped(json, false);
}

export function SupplyContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplyContact {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'phone': json['phone'] == null ? undefined : PhoneFromJSON(json['phone']),
        'email': json['email'] == null ? undefined : json['email'],
        'address': json['address'] == null ? undefined : Address1FromJSON(json['address']),
    };
}

export function SupplyContactToJSON(value?: SupplyContact | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'phone': PhoneToJSON(value['phone']),
        'email': value['email'],
        'address': Address1ToJSON(value['address']),
    };
}

