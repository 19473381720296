/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchRequest } from './SearchRequest';
import {
    SearchRequestFromJSON,
    SearchRequestFromJSONTyped,
    SearchRequestToJSON,
} from './SearchRequest';
import type { SearchSessionStatus } from './SearchSessionStatus';
import {
    SearchSessionStatusFromJSON,
    SearchSessionStatusFromJSONTyped,
    SearchSessionStatusToJSON,
} from './SearchSessionStatus';
import type { SearchSessionResults } from './SearchSessionResults';
import {
    SearchSessionResultsFromJSON,
    SearchSessionResultsFromJSONTyped,
    SearchSessionResultsToJSON,
} from './SearchSessionResults';

/**
 * A search session
 * @export
 * @interface SearchSession
 */
export interface SearchSession {
    /**
     * The search session id
     * @type {string}
     * @memberof SearchSession
     */
    id?: string;
    /**
     * 
     * @type {SearchSessionStatus}
     * @memberof SearchSession
     */
    sessionStatus?: SearchSessionStatus;
    /**
     * 
     * @type {SearchRequest}
     * @memberof SearchSession
     */
    request?: SearchRequest;
    /**
     * 
     * @type {SearchSessionResults}
     * @memberof SearchSession
     */
    results?: SearchSessionResults;
    /**
     * The time the search session was created
     * @type {Date}
     * @memberof SearchSession
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the SearchSession interface.
 */
export function instanceOfSearchSession(value: object): value is SearchSession {
    return true;
}

export function SearchSessionFromJSON(json: any): SearchSession {
    return SearchSessionFromJSONTyped(json, false);
}

export function SearchSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSession {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'sessionStatus': json['session_status'] == null ? undefined : SearchSessionStatusFromJSON(json['session_status']),
        'request': json['request'] == null ? undefined : SearchRequestFromJSON(json['request']),
        'results': json['results'] == null ? undefined : SearchSessionResultsFromJSON(json['results']),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
    };
}

export function SearchSessionToJSON(value?: SearchSession | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'session_status': SearchSessionStatusToJSON(value['sessionStatus']),
        'request': SearchRequestToJSON(value['request']),
        'results': SearchSessionResultsToJSON(value['results']),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}

