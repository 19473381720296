/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Hotel stats request
 * @export
 * @interface HotelStatsRequest
 */
export interface HotelStatsRequest {
    /**
     * 
     * @type {number}
     * @memberof HotelStatsRequest
     */
    vervotechPropertyId: number;
    /**
     * 
     * @type {number}
     * @memberof HotelStatsRequest
     */
    nightlyCashCost: number;
    /**
     * 
     * @type {string}
     * @memberof HotelStatsRequest
     */
    nightlyCashCostCurrency: string;
    /**
     * 
     * @type {number}
     * @memberof HotelStatsRequest
     */
    nightlyPointsCost?: number;
}

/**
 * Check if a given object implements the HotelStatsRequest interface.
 */
export function instanceOfHotelStatsRequest(value: object): value is HotelStatsRequest {
    if (!('vervotechPropertyId' in value) || value['vervotechPropertyId'] === undefined) return false;
    if (!('nightlyCashCost' in value) || value['nightlyCashCost'] === undefined) return false;
    if (!('nightlyCashCostCurrency' in value) || value['nightlyCashCostCurrency'] === undefined) return false;
    return true;
}

export function HotelStatsRequestFromJSON(json: any): HotelStatsRequest {
    return HotelStatsRequestFromJSONTyped(json, false);
}

export function HotelStatsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelStatsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyId': json['vervotech_property_id'],
        'nightlyCashCost': json['nightly_cash_cost'],
        'nightlyCashCostCurrency': json['nightly_cash_cost_currency'],
        'nightlyPointsCost': json['nightly_points_cost'] == null ? undefined : json['nightly_points_cost'],
    };
}

export function HotelStatsRequestToJSON(value?: HotelStatsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_id': value['vervotechPropertyId'],
        'nightly_cash_cost': value['nightlyCashCost'],
        'nightly_cash_cost_currency': value['nightlyCashCostCurrency'],
        'nightly_points_cost': value['nightlyPointsCost'],
    };
}

