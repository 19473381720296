/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HotelContentStatisticsInner
 */
export interface HotelContentStatisticsInner {
    /**
     * Id of the statistic.
     * @type {number}
     * @memberof HotelContentStatisticsInner
     */
    id?: number;
    /**
     * Name of the statistic.
     * @type {string}
     * @memberof HotelContentStatisticsInner
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelContentStatisticsInner
     */
    value?: string;
}

/**
 * Check if a given object implements the HotelContentStatisticsInner interface.
 */
export function instanceOfHotelContentStatisticsInner(value: object): value is HotelContentStatisticsInner {
    return true;
}

export function HotelContentStatisticsInnerFromJSON(json: any): HotelContentStatisticsInner {
    return HotelContentStatisticsInnerFromJSONTyped(json, false);
}

export function HotelContentStatisticsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentStatisticsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'] == null ? undefined : json['Id'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'value': json['Value'] == null ? undefined : json['Value'],
    };
}

export function HotelContentStatisticsInnerToJSON(value?: HotelContentStatisticsInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'Value': value['value'],
    };
}

