/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Image1 } from './Image1';
import {
    Image1FromJSON,
    Image1FromJSONTyped,
    Image1ToJSON,
} from './Image1';

/**
 * 
 * @export
 * @interface Essential
 */
export interface Essential {
    /**
     * The name of the essential item.
     * @type {string}
     * @memberof Essential
     */
    name?: string;
    /**
     * The instructions for use of the essential item.
     * @type {string}
     * @memberof Essential
     */
    instructions?: string;
    /**
     * A map of additional information that needs to be conveyed to customer.
     * @type {{ [key: string]: string; }}
     * @memberof Essential
     */
    additionalInfo?: { [key: string]: string; };
    /**
     * An array of images needed for the essential item.
     * @type {Array<Image1>}
     * @memberof Essential
     */
    images?: Array<Image1>;
}

/**
 * Check if a given object implements the Essential interface.
 */
export function instanceOfEssential(value: object): value is Essential {
    return true;
}

export function EssentialFromJSON(json: any): Essential {
    return EssentialFromJSONTyped(json, false);
}

export function EssentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): Essential {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'instructions': json['instructions'] == null ? undefined : json['instructions'],
        'additionalInfo': json['additional_info'] == null ? undefined : json['additional_info'],
        'images': json['images'] == null ? undefined : ((json['images'] as Array<any>).map(Image1FromJSON)),
    };
}

export function EssentialToJSON(value?: Essential | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'instructions': value['instructions'],
        'additional_info': value['additionalInfo'],
        'images': value['images'] == null ? undefined : ((value['images'] as Array<any>).map(Image1ToJSON)),
    };
}

