/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A search occupant
 * @export
 * @interface SearchOccupants
 */
export interface SearchOccupants {
    /**
     * 
     * @type {number}
     * @memberof SearchOccupants
     */
    numberOfAdults: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchOccupants
     */
    childrenAges?: Array<number>;
}

/**
 * Check if a given object implements the SearchOccupants interface.
 */
export function instanceOfSearchOccupants(value: object): value is SearchOccupants {
    if (!('numberOfAdults' in value) || value['numberOfAdults'] === undefined) return false;
    return true;
}

export function SearchOccupantsFromJSON(json: any): SearchOccupants {
    return SearchOccupantsFromJSONTyped(json, false);
}

export function SearchOccupantsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchOccupants {
    if (json == null) {
        return json;
    }
    return {
        
        'numberOfAdults': json['number_of_adults'],
        'childrenAges': json['children_ages'] == null ? undefined : json['children_ages'],
    };
}

export function SearchOccupantsToJSON(value?: SearchOccupants | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'number_of_adults': value['numberOfAdults'],
        'children_ages': value['childrenAges'],
    };
}

