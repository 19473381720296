/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NonrefundableDateRange
 */
export interface NonrefundableDateRange {
    /**
     * Start date of nonrefundable date range in ISO 8601 format.
     * @type {string}
     * @memberof NonrefundableDateRange
     */
    start?: string;
    /**
     * End date of nonrefundable date range in ISO 8601 format.
     * @type {string}
     * @memberof NonrefundableDateRange
     */
    end?: string;
}

/**
 * Check if a given object implements the NonrefundableDateRange interface.
 */
export function instanceOfNonrefundableDateRange(value: object): value is NonrefundableDateRange {
    return true;
}

export function NonrefundableDateRangeFromJSON(json: any): NonrefundableDateRange {
    return NonrefundableDateRangeFromJSONTyped(json, false);
}

export function NonrefundableDateRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NonrefundableDateRange {
    if (json == null) {
        return json;
    }
    return {
        
        'start': json['start'] == null ? undefined : json['start'],
        'end': json['end'] == null ? undefined : json['end'],
    };
}

export function NonrefundableDateRangeToJSON(value?: NonrefundableDateRange | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'start': value['start'],
        'end': value['end'],
    };
}

