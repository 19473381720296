/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DepositItinerary } from './DepositItinerary';
import {
    DepositItineraryFromJSON,
    DepositItineraryFromJSONTyped,
    DepositItineraryToJSON,
} from './DepositItinerary';
import type { PricingInformation } from './PricingInformation';
import {
    PricingInformationFromJSON,
    PricingInformationFromJSONTyped,
    PricingInformationToJSON,
} from './PricingInformation';
import type { CancelPenalty } from './CancelPenalty';
import {
    CancelPenaltyFromJSON,
    CancelPenaltyFromJSONTyped,
    CancelPenaltyToJSON,
} from './CancelPenalty';
import type { PromotionsItinerary } from './PromotionsItinerary';
import {
    PromotionsItineraryFromJSON,
    PromotionsItineraryFromJSONTyped,
    PromotionsItineraryToJSON,
} from './PromotionsItinerary';

/**
 * The rate information associated with the itinerary.
 * @export
 * @interface RateHistory
 */
export interface RateHistory {
    /**
     * The id of the rate.
     * @type {string}
     * @memberof RateHistory
     */
    id?: string;
    /**
     * 
     * @type {PromotionsItinerary}
     * @memberof RateHistory
     */
    promotions?: PromotionsItinerary;
    /**
     * The cancel penalties associated with the itinerary.
     * @type {Array<CancelPenalty>}
     * @memberof RateHistory
     */
    cancelPenalties?: Array<CancelPenalty>;
    /**
     * 
     * @type {Array<DepositItinerary>}
     * @memberof RateHistory
     */
    deposits?: Array<DepositItinerary>;
    /**
     * 
     * @type {PricingInformation}
     * @memberof RateHistory
     */
    pricing?: PricingInformation;
}

/**
 * Check if a given object implements the RateHistory interface.
 */
export function instanceOfRateHistory(value: object): value is RateHistory {
    return true;
}

export function RateHistoryFromJSON(json: any): RateHistory {
    return RateHistoryFromJSONTyped(json, false);
}

export function RateHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'promotions': json['promotions'] == null ? undefined : PromotionsItineraryFromJSON(json['promotions']),
        'cancelPenalties': json['cancel_penalties'] == null ? undefined : ((json['cancel_penalties'] as Array<any>).map(CancelPenaltyFromJSON)),
        'deposits': json['deposits'] == null ? undefined : ((json['deposits'] as Array<any>).map(DepositItineraryFromJSON)),
        'pricing': json['pricing'] == null ? undefined : PricingInformationFromJSON(json['pricing']),
    };
}

export function RateHistoryToJSON(value?: RateHistory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'promotions': PromotionsItineraryToJSON(value['promotions']),
        'cancel_penalties': value['cancelPenalties'] == null ? undefined : ((value['cancelPenalties'] as Array<any>).map(CancelPenaltyToJSON)),
        'deposits': value['deposits'] == null ? undefined : ((value['deposits'] as Array<any>).map(DepositItineraryToJSON)),
        'pricing': PricingInformationToJSON(value['pricing']),
    };
}

