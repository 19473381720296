/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelContent } from './HotelContent';
import {
    HotelContentFromJSON,
    HotelContentFromJSONTyped,
    HotelContentToJSON,
} from './HotelContent';
import type { HotelSummary } from './HotelSummary';
import {
    HotelSummaryFromJSON,
    HotelSummaryFromJSONTyped,
    HotelSummaryToJSON,
} from './HotelSummary';
import type { Itinerary } from './Itinerary';
import {
    ItineraryFromJSON,
    ItineraryFromJSONTyped,
    ItineraryToJSON,
} from './Itinerary';
import type { ItineraryCreation } from './ItineraryCreation';
import {
    ItineraryCreationFromJSON,
    ItineraryCreationFromJSONTyped,
    ItineraryCreationToJSON,
} from './ItineraryCreation';
import type { RateSummary } from './RateSummary';
import {
    RateSummaryFromJSON,
    RateSummaryFromJSONTyped,
    RateSummaryToJSON,
} from './RateSummary';
import type { CancellationPolicySummary } from './CancellationPolicySummary';
import {
    CancellationPolicySummaryFromJSON,
    CancellationPolicySummaryFromJSONTyped,
    CancellationPolicySummaryToJSON,
} from './CancellationPolicySummary';
import type { RawRateSummary } from './RawRateSummary';
import {
    RawRateSummaryFromJSON,
    RawRateSummaryFromJSONTyped,
    RawRateSummaryToJSON,
} from './RawRateSummary';
import type { RoomSummary } from './RoomSummary';
import {
    RoomSummaryFromJSON,
    RoomSummaryFromJSONTyped,
    RoomSummaryToJSON,
} from './RoomSummary';

/**
 * A booking
 * @export
 * @interface Booking
 */
export interface Booking {
    /**
     * Gondola-internal booking ID. For user-facing Gondola confirmation number, use gondola_booking_id instead.
     * @type {number}
     * @memberof Booking
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    vervotechPropertyId: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    providerPropertyId: number;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    providerBookingId?: string;
    /**
     * User-facing Gondola booking confirmation number.
     * @type {string}
     * @memberof Booking
     */
    gondolaBookingId?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    providerBookingToken?: string;
    /**
     * 
     * @type {ItineraryCreation}
     * @memberof Booking
     */
    providerBookingContent?: ItineraryCreation;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    hotelStayId?: string;
    /**
     * If the booking has been processed. Most relevant for manual direct bookings
     * @type {boolean}
     * @memberof Booking
     */
    processed?: boolean;
    /**
     * The process used to book the hotel
     * @type {string}
     * @memberof Booking
     */
    bookingProcess?: string;
    /**
     * 
     * @type {Itinerary}
     * @memberof Booking
     */
    itinerary?: Itinerary;
    /**
     * 
     * @type {HotelContent}
     * @memberof Booking
     */
    propertyContent?: HotelContent;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    chainUrl?: string;
    /**
     * 
     * @type {Date}
     * @memberof Booking
     */
    canceledAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Booking
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Booking
     */
    updatedAt?: Date;
    /**
     * `true` if the reservation was made using Gondola credits, `false` otherwise.
     * @type {boolean}
     * @memberof Booking
     */
    usedGondolaCredits?: boolean;
    /**
     * 
     * @type {RateSummary}
     * @memberof Booking
     */
    rateSummary?: RateSummary;
    /**
     * 
     * @type {RawRateSummary}
     * @memberof Booking
     */
    rawRateSummary?: RawRateSummary;
    /**
     * 
     * @type {CancellationPolicySummary}
     * @memberof Booking
     */
    cancellationPolicySummary?: CancellationPolicySummary;
    /**
     * 
     * @type {HotelSummary}
     * @memberof Booking
     */
    hotelSummary?: HotelSummary;
    /**
     * 
     * @type {Array<RoomSummary>}
     * @memberof Booking
     */
    roomSummaries?: Array<RoomSummary>;
    /**
     * true if this booking itinerary is being accessed by the user that booked it.  False if this booking itinerary is being shared.
     * @type {boolean}
     * @memberof Booking
     */
    isBookedUser?: boolean;
}

/**
 * Check if a given object implements the Booking interface.
 */
export function instanceOfBooking(value: object): value is Booking {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('vervotechPropertyId' in value) || value['vervotechPropertyId'] === undefined) return false;
    if (!('providerPropertyId' in value) || value['providerPropertyId'] === undefined) return false;
    return true;
}

export function BookingFromJSON(json: any): Booking {
    return BookingFromJSONTyped(json, false);
}

export function BookingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Booking {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'vervotechPropertyId': json['vervotech_property_id'],
        'providerPropertyId': json['provider_property_id'],
        'providerBookingId': json['provider_booking_id'] == null ? undefined : json['provider_booking_id'],
        'gondolaBookingId': json['gondola_booking_id'] == null ? undefined : json['gondola_booking_id'],
        'providerBookingToken': json['provider_booking_token'] == null ? undefined : json['provider_booking_token'],
        'providerBookingContent': json['provider_booking_content'] == null ? undefined : ItineraryCreationFromJSON(json['provider_booking_content']),
        'hotelStayId': json['hotel_stay_id'] == null ? undefined : json['hotel_stay_id'],
        'processed': json['processed'] == null ? undefined : json['processed'],
        'bookingProcess': json['booking_process'] == null ? undefined : json['booking_process'],
        'itinerary': json['itinerary'] == null ? undefined : ItineraryFromJSON(json['itinerary']),
        'propertyContent': json['property_content'] == null ? undefined : HotelContentFromJSON(json['property_content']),
        'chainUrl': json['chain_url'] == null ? undefined : json['chain_url'],
        'canceledAt': json['canceled_at'] == null ? undefined : (new Date(json['canceled_at'])),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'usedGondolaCredits': json['used_gondola_credits'] == null ? undefined : json['used_gondola_credits'],
        'rateSummary': json['rate_summary'] == null ? undefined : RateSummaryFromJSON(json['rate_summary']),
        'rawRateSummary': json['raw_rate_summary'] == null ? undefined : RawRateSummaryFromJSON(json['raw_rate_summary']),
        'cancellationPolicySummary': json['cancellation_policy_summary'] == null ? undefined : CancellationPolicySummaryFromJSON(json['cancellation_policy_summary']),
        'hotelSummary': json['hotel_summary'] == null ? undefined : HotelSummaryFromJSON(json['hotel_summary']),
        'roomSummaries': json['room_summaries'] == null ? undefined : ((json['room_summaries'] as Array<any>).map(RoomSummaryFromJSON)),
        'isBookedUser': json['is_booked_user'] == null ? undefined : json['is_booked_user'],
    };
}

export function BookingToJSON(value?: Booking | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'vervotech_property_id': value['vervotechPropertyId'],
        'provider_property_id': value['providerPropertyId'],
        'provider_booking_id': value['providerBookingId'],
        'gondola_booking_id': value['gondolaBookingId'],
        'provider_booking_token': value['providerBookingToken'],
        'provider_booking_content': ItineraryCreationToJSON(value['providerBookingContent']),
        'hotel_stay_id': value['hotelStayId'],
        'processed': value['processed'],
        'booking_process': value['bookingProcess'],
        'itinerary': ItineraryToJSON(value['itinerary']),
        'property_content': HotelContentToJSON(value['propertyContent']),
        'chain_url': value['chainUrl'],
        'canceled_at': value['canceledAt'] == null ? undefined : ((value['canceledAt']).toISOString()),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
        'used_gondola_credits': value['usedGondolaCredits'],
        'rate_summary': RateSummaryToJSON(value['rateSummary']),
        'raw_rate_summary': RawRateSummaryToJSON(value['rawRateSummary']),
        'cancellation_policy_summary': CancellationPolicySummaryToJSON(value['cancellationPolicySummary']),
        'hotel_summary': HotelSummaryToJSON(value['hotelSummary']),
        'room_summaries': value['roomSummaries'] == null ? undefined : ((value['roomSummaries'] as Array<any>).map(RoomSummaryToJSON)),
        'is_booked_user': value['isBookedUser'],
    };
}

