/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HotelContentImagesInnerLinksInner
 */
export interface HotelContentImagesInnerLinksInner {
    /**
     * Size of image
     * @type {string}
     * @memberof HotelContentImagesInnerLinksInner
     */
    size?: HotelContentImagesInnerLinksInnerSizeEnum;
    /**
     * This is the image URL which is provided by the Unica. Unica can optionally download the provider image, store it on Unica's cloud storage and give the URL for that downloaded image in "Href" field.
     * @type {string}
     * @memberof HotelContentImagesInnerLinksInner
     */
    href?: string;
    /**
     * The image URL given by the provider. It is recommended to use "ProviderHref" over "Href".
     * @type {string}
     * @memberof HotelContentImagesInnerLinksInner
     */
    providerHref?: string;
}


/**
 * @export
 */
export const HotelContentImagesInnerLinksInnerSizeEnum = {
    Unknown: 'Unknown',
    Standard: 'Standard',
    Xl: 'XL',
    Xxl: 'XXL'
} as const;
export type HotelContentImagesInnerLinksInnerSizeEnum = typeof HotelContentImagesInnerLinksInnerSizeEnum[keyof typeof HotelContentImagesInnerLinksInnerSizeEnum];


/**
 * Check if a given object implements the HotelContentImagesInnerLinksInner interface.
 */
export function instanceOfHotelContentImagesInnerLinksInner(value: object): value is HotelContentImagesInnerLinksInner {
    return true;
}

export function HotelContentImagesInnerLinksInnerFromJSON(json: any): HotelContentImagesInnerLinksInner {
    return HotelContentImagesInnerLinksInnerFromJSONTyped(json, false);
}

export function HotelContentImagesInnerLinksInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentImagesInnerLinksInner {
    if (json == null) {
        return json;
    }
    return {
        
        'size': json['Size'] == null ? undefined : json['Size'],
        'href': json['Href'] == null ? undefined : json['Href'],
        'providerHref': json['ProviderHref'] == null ? undefined : json['ProviderHref'],
    };
}

export function HotelContentImagesInnerLinksInnerToJSON(value?: HotelContentImagesInnerLinksInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Size': value['size'],
        'Href': value['href'],
        'ProviderHref': value['providerHref'],
    };
}

