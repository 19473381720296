/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * an individual category rating
 * @export
 * @interface CategoryRating
 */
export interface CategoryRating {
    /**
     * The category of rating.
     * @type {string}
     * @memberof CategoryRating
     */
    category?: string;
    /**
     * The rating for this category.
     * @type {number}
     * @memberof CategoryRating
     */
    rating?: number;
}

/**
 * Check if a given object implements the CategoryRating interface.
 */
export function instanceOfCategoryRating(value: object): value is CategoryRating {
    return true;
}

export function CategoryRatingFromJSON(json: any): CategoryRating {
    return CategoryRatingFromJSONTyped(json, false);
}

export function CategoryRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryRating {
    if (json == null) {
        return json;
    }
    return {
        
        'category': json['Category'] == null ? undefined : json['Category'],
        'rating': json['Rating'] == null ? undefined : json['Rating'],
    };
}

export function CategoryRatingToJSON(value?: CategoryRating | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Category': value['category'],
        'Rating': value['rating'],
    };
}

