/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of place, either an airport or a city
 * @export
 */
export const PlaceType = {
    Airport: 'airport',
    City: 'city'
} as const;
export type PlaceType = typeof PlaceType[keyof typeof PlaceType];


export function instanceOfPlaceType(value: any): boolean {
    for (const key in PlaceType) {
        if (Object.prototype.hasOwnProperty.call(PlaceType, key)) {
            if ((PlaceType as Record<string, PlaceType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PlaceTypeFromJSON(json: any): PlaceType {
    return PlaceTypeFromJSONTyped(json, false);
}

export function PlaceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceType {
    return json as PlaceType;
}

export function PlaceTypeToJSON(value?: PlaceType | null): any {
    return value as any;
}
