/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An object containing metadata about the service, like the designator of the 
 * seat.
 * 
 * @export
 * @interface ServiceMetadataForASeat
 */
export interface ServiceMetadataForASeat {
    /**
     * The designator used to uniquely identify the seat, usually made up of a 
     * row number and a column letter
     * 
     * @type {string}
     * @memberof ServiceMetadataForASeat
     */
    designator: string;
    /**
     * Each disclosure is text, in English, provided by the airline that 
     * describes the terms and conditions of this seat. We recommend showing 
     * this in your user interface to make sure that customers understand any 
     * restrictions and limitations.
     * 
     * @type {Array<string>}
     * @memberof ServiceMetadataForASeat
     */
    disclosures: Array<string>;
    /**
     * A name which describes the type of seat, which you can display in your 
     * user interface to help customers to understand its features
     * 
     * @type {string}
     * @memberof ServiceMetadataForASeat
     */
    name: string | null;
}

/**
 * Check if a given object implements the ServiceMetadataForASeat interface.
 */
export function instanceOfServiceMetadataForASeat(value: object): value is ServiceMetadataForASeat {
    if (!('designator' in value) || value['designator'] === undefined) return false;
    if (!('disclosures' in value) || value['disclosures'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ServiceMetadataForASeatFromJSON(json: any): ServiceMetadataForASeat {
    return ServiceMetadataForASeatFromJSONTyped(json, false);
}

export function ServiceMetadataForASeatFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMetadataForASeat {
    if (json == null) {
        return json;
    }
    return {
        
        'designator': json['designator'],
        'disclosures': json['disclosures'],
        'name': json['name'],
    };
}

export function ServiceMetadataForASeatToJSON(value?: ServiceMetadataForASeat | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'designator': value['designator'],
        'disclosures': value['disclosures'],
        'name': value['name'],
    };
}

