/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FlightSearchRequest } from './FlightSearchRequest';
import {
    FlightSearchRequestFromJSON,
    FlightSearchRequestFromJSONTyped,
    FlightSearchRequestToJSON,
} from './FlightSearchRequest';
import type { Viewport } from './Viewport';
import {
    ViewportFromJSON,
    ViewportFromJSONTyped,
    ViewportToJSON,
} from './Viewport';
import type { UserEnvironment } from './UserEnvironment';
import {
    UserEnvironmentFromJSON,
    UserEnvironmentFromJSONTyped,
    UserEnvironmentToJSON,
} from './UserEnvironment';
import type { HotelSearchRequest } from './HotelSearchRequest';
import {
    HotelSearchRequestFromJSON,
    HotelSearchRequestFromJSONTyped,
    HotelSearchRequestToJSON,
} from './HotelSearchRequest';

/**
 * A generic search request for flights, hotels or both
 * @export
 * @interface SearchRequestV2
 */
export interface SearchRequestV2 {
    /**
     * 
     * @type {UserEnvironment}
     * @memberof SearchRequestV2
     */
    userEnvironment: UserEnvironment;
    /**
     * 
     * @type {FlightSearchRequest}
     * @memberof SearchRequestV2
     */
    flights?: FlightSearchRequest;
    /**
     * 
     * @type {HotelSearchRequest}
     * @memberof SearchRequestV2
     */
    hotels?: HotelSearchRequest;
    /**
     * The prompt ID
     * @type {string}
     * @memberof SearchRequestV2
     */
    promptId: string;
    /**
     * 
     * @type {Viewport}
     * @memberof SearchRequestV2
     */
    viewport?: Viewport;
}

/**
 * Check if a given object implements the SearchRequestV2 interface.
 */
export function instanceOfSearchRequestV2(value: object): value is SearchRequestV2 {
    if (!('userEnvironment' in value) || value['userEnvironment'] === undefined) return false;
    if (!('promptId' in value) || value['promptId'] === undefined) return false;
    return true;
}

export function SearchRequestV2FromJSON(json: any): SearchRequestV2 {
    return SearchRequestV2FromJSONTyped(json, false);
}

export function SearchRequestV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequestV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'userEnvironment': UserEnvironmentFromJSON(json['user_environment']),
        'flights': json['flights'] == null ? undefined : FlightSearchRequestFromJSON(json['flights']),
        'hotels': json['hotels'] == null ? undefined : HotelSearchRequestFromJSON(json['hotels']),
        'promptId': json['prompt_id'],
        'viewport': json['viewport'] == null ? undefined : ViewportFromJSON(json['viewport']),
    };
}

export function SearchRequestV2ToJSON(value?: SearchRequestV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_environment': UserEnvironmentToJSON(value['userEnvironment']),
        'flights': FlightSearchRequestToJSON(value['flights']),
        'hotels': HotelSearchRequestToJSON(value['hotels']),
        'prompt_id': value['promptId'],
        'viewport': ViewportToJSON(value['viewport']),
    };
}

