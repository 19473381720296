/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';

/**
 * A map of links, including links to request additional rates. Note that the recommendations feature has been retired and will return an error if the link is followed.
 * @export
 * @interface PropertyAvailabilityLinks
 */
export interface PropertyAvailabilityLinks {
    [key: string]: Link | any;
    /**
     * 
     * @type {Link}
     * @memberof PropertyAvailabilityLinks
     */
    additionalRates?: Link;
    /**
     * 
     * @type {Link}
     * @memberof PropertyAvailabilityLinks
     */
    recommendations?: Link;
}

/**
 * Check if a given object implements the PropertyAvailabilityLinks interface.
 */
export function instanceOfPropertyAvailabilityLinks(value: object): value is PropertyAvailabilityLinks {
    return true;
}

export function PropertyAvailabilityLinksFromJSON(json: any): PropertyAvailabilityLinks {
    return PropertyAvailabilityLinksFromJSONTyped(json, false);
}

export function PropertyAvailabilityLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyAvailabilityLinks {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'additionalRates': json['additional_rates'] == null ? undefined : LinkFromJSON(json['additional_rates']),
        'recommendations': json['recommendations'] == null ? undefined : LinkFromJSON(json['recommendations']),
    };
}

export function PropertyAvailabilityLinksToJSON(value?: PropertyAvailabilityLinks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'additional_rates': LinkToJSON(value['additionalRates']),
        'recommendations': LinkToJSON(value['recommendations']),
    };
}

