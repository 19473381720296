/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferSliceConditionsChangeBeforeDeparture } from './OfferSliceConditionsChangeBeforeDeparture';
import {
    OfferSliceConditionsChangeBeforeDepartureFromJSON,
    OfferSliceConditionsChangeBeforeDepartureFromJSONTyped,
    OfferSliceConditionsChangeBeforeDepartureToJSON,
} from './OfferSliceConditionsChangeBeforeDeparture';

/**
 * The conditions associated with this slice, describing the kinds of modifications you can make post-booking and any penalties that will apply to those modifications.
 * This condition is applied only to this slice and to all the passengers associated with this offer - for information at the offer level (e.g. "what happens if I want to change all the slices?") refer to the `conditions` at the top level.
 * If a particular kind of modification is allowed, you may not always be able to take action through the Duffel API. In some cases, you may need to contact the Duffel support team or the airline directly.
 * 
 * @export
 * @interface OfferSliceConditions
 */
export interface OfferSliceConditions {
    /**
     * 
     * @type {OfferSliceConditionsChangeBeforeDeparture}
     * @memberof OfferSliceConditions
     */
    changeBeforeDeparture?: OfferSliceConditionsChangeBeforeDeparture;
}

/**
 * Check if a given object implements the OfferSliceConditions interface.
 */
export function instanceOfOfferSliceConditions(value: object): value is OfferSliceConditions {
    return true;
}

export function OfferSliceConditionsFromJSON(json: any): OfferSliceConditions {
    return OfferSliceConditionsFromJSONTyped(json, false);
}

export function OfferSliceConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceConditions {
    if (json == null) {
        return json;
    }
    return {
        
        'changeBeforeDeparture': json['change_before_departure'] == null ? undefined : OfferSliceConditionsChangeBeforeDepartureFromJSON(json['change_before_departure']),
    };
}

export function OfferSliceConditionsToJSON(value?: OfferSliceConditions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'change_before_departure': OfferSliceConditionsChangeBeforeDepartureToJSON(value['changeBeforeDeparture']),
    };
}

