/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelChain } from './HotelChain';
import {
    HotelChainFromJSON,
    HotelChainFromJSONTyped,
    HotelChainToJSON,
} from './HotelChain';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';
import type { HotelTheme } from './HotelTheme';
import {
    HotelThemeFromJSON,
    HotelThemeFromJSONTyped,
    HotelThemeToJSON,
} from './HotelTheme';
import type { Viewport } from './Viewport';
import {
    ViewportFromJSON,
    ViewportFromJSONTyped,
    ViewportToJSON,
} from './Viewport';
import type { Region } from './Region';
import {
    RegionFromJSON,
    RegionFromJSONTyped,
    RegionToJSON,
} from './Region';
import type { SearchLocationType } from './SearchLocationType';
import {
    SearchLocationTypeFromJSON,
    SearchLocationTypeFromJSONTyped,
    SearchLocationTypeToJSON,
} from './SearchLocationType';

/**
 * A search location. One of address, coordinates, property_id, or name must be provided
 * @export
 * @interface SearchLocationV2
 */
export interface SearchLocationV2 {
    /**
     * The google place ID of the search location
     * @type {string}
     * @memberof SearchLocationV2
     */
    placeId?: string;
    /**
     * The main query string, corresponds to structured_formatting.main_text
     * @type {string}
     * @memberof SearchLocationV2
     */
    mainQuery?: string;
    /**
     * The secondary query string, corresponds to structured_formatting.secondary_text
     * @type {string}
     * @memberof SearchLocationV2
     */
    secondaryQuery?: string;
    /**
     * The google place types of the place ID - used to determine if location is a hotel or something else
     * @type {Array<string>}
     * @memberof SearchLocationV2
     */
    types?: Array<string>;
    /**
     * 
     * @type {Coordinates}
     * @memberof SearchLocationV2
     */
    coordinates?: Coordinates;
    /**
     * A name used to search for a location.
     * @type {string}
     * @memberof SearchLocationV2
     */
    name?: string;
    /**
     * 
     * @type {Array<HotelTheme>}
     * @memberof SearchLocationV2
     */
    themes?: Array<HotelTheme>;
    /**
     * 
     * @type {Array<HotelChain>}
     * @memberof SearchLocationV2
     */
    chains?: Array<HotelChain>;
    /**
     * 
     * @type {Region}
     * @memberof SearchLocationV2
     */
    region?: Region;
    /**
     * 
     * @type {SearchLocationType}
     * @memberof SearchLocationV2
     */
    searchLocationType?: SearchLocationType;
    /**
     * 
     * @type {Viewport}
     * @memberof SearchLocationV2
     */
    searchViewport?: Viewport;
}

/**
 * Check if a given object implements the SearchLocationV2 interface.
 */
export function instanceOfSearchLocationV2(value: object): value is SearchLocationV2 {
    return true;
}

export function SearchLocationV2FromJSON(json: any): SearchLocationV2 {
    return SearchLocationV2FromJSONTyped(json, false);
}

export function SearchLocationV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchLocationV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'placeId': json['place_id'] == null ? undefined : json['place_id'],
        'mainQuery': json['main_query'] == null ? undefined : json['main_query'],
        'secondaryQuery': json['secondary_query'] == null ? undefined : json['secondary_query'],
        'types': json['types'] == null ? undefined : json['types'],
        'coordinates': json['coordinates'] == null ? undefined : CoordinatesFromJSON(json['coordinates']),
        'name': json['name'] == null ? undefined : json['name'],
        'themes': json['themes'] == null ? undefined : ((json['themes'] as Array<any>).map(HotelThemeFromJSON)),
        'chains': json['chains'] == null ? undefined : ((json['chains'] as Array<any>).map(HotelChainFromJSON)),
        'region': json['region'] == null ? undefined : RegionFromJSON(json['region']),
        'searchLocationType': json['search_location_type'] == null ? undefined : SearchLocationTypeFromJSON(json['search_location_type']),
        'searchViewport': json['search_viewport'] == null ? undefined : ViewportFromJSON(json['search_viewport']),
    };
}

export function SearchLocationV2ToJSON(value?: SearchLocationV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'place_id': value['placeId'],
        'main_query': value['mainQuery'],
        'secondary_query': value['secondaryQuery'],
        'types': value['types'],
        'coordinates': CoordinatesToJSON(value['coordinates']),
        'name': value['name'],
        'themes': value['themes'] == null ? undefined : ((value['themes'] as Array<any>).map(HotelThemeToJSON)),
        'chains': value['chains'] == null ? undefined : ((value['chains'] as Array<any>).map(HotelChainToJSON)),
        'region': RegionToJSON(value['region']),
        'search_location_type': SearchLocationTypeToJSON(value['searchLocationType']),
        'search_viewport': ViewportToJSON(value['searchViewport']),
    };
}

