/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    policyTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    policyText?: string;
}

/**
 * Check if a given object implements the Policy interface.
 */
export function instanceOfPolicy(value: object): value is Policy {
    return true;
}

export function PolicyFromJSON(json: any): Policy {
    return PolicyFromJSONTyped(json, false);
}

export function PolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Policy {
    if (json == null) {
        return json;
    }
    return {
        
        'policyTitle': json['policy_title'] == null ? undefined : json['policy_title'],
        'policyText': json['policy_text'] == null ? undefined : json['policy_text'],
    };
}

export function PolicyToJSON(value?: Policy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'policy_title': value['policyTitle'],
        'policy_text': value['policyText'],
    };
}

