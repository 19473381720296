/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * If power (AC and/or USB) is available, information on what is available
 * @export
 * @interface OfferSliceSegmentPassengerCabinAmenitiesPower
 */
export interface OfferSliceSegmentPassengerCabinAmenitiesPower {
    /**
     * Whether there is power available or not in this cabin
     * @type {boolean}
     * @memberof OfferSliceSegmentPassengerCabinAmenitiesPower
     */
    available?: boolean;
}

/**
 * Check if a given object implements the OfferSliceSegmentPassengerCabinAmenitiesPower interface.
 */
export function instanceOfOfferSliceSegmentPassengerCabinAmenitiesPower(value: object): value is OfferSliceSegmentPassengerCabinAmenitiesPower {
    return true;
}

export function OfferSliceSegmentPassengerCabinAmenitiesPowerFromJSON(json: any): OfferSliceSegmentPassengerCabinAmenitiesPower {
    return OfferSliceSegmentPassengerCabinAmenitiesPowerFromJSONTyped(json, false);
}

export function OfferSliceSegmentPassengerCabinAmenitiesPowerFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentPassengerCabinAmenitiesPower {
    if (json == null) {
        return json;
    }
    return {
        
        'available': json['available'] == null ? undefined : json['available'],
    };
}

export function OfferSliceSegmentPassengerCabinAmenitiesPowerToJSON(value?: OfferSliceSegmentPassengerCabinAmenitiesPower | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'available': value['available'],
    };
}

