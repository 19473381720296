/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Image1
 */
export interface Image1 {
    /**
     * The url of the image.
     * @type {string}
     * @memberof Image1
     */
    url?: string;
    /**
     * The width of the image.
     * @type {string}
     * @memberof Image1
     */
    width?: string;
    /**
     * The height of the image.
     * @type {string}
     * @memberof Image1
     */
    height?: string;
}

/**
 * Check if a given object implements the Image1 interface.
 */
export function instanceOfImage1(value: object): value is Image1 {
    return true;
}

export function Image1FromJSON(json: any): Image1 {
    return Image1FromJSONTyped(json, false);
}

export function Image1FromJSONTyped(json: any, ignoreDiscriminator: boolean): Image1 {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'] == null ? undefined : json['url'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
    };
}

export function Image1ToJSON(value?: Image1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'width': value['width'],
        'height': value['height'],
    };
}

