/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RateSummaryCharge
 */
export interface RateSummaryCharge {
    /**
     * 
     * @type {string}
     * @memberof RateSummaryCharge
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RateSummaryCharge
     */
    value: string;
}

/**
 * Check if a given object implements the RateSummaryCharge interface.
 */
export function instanceOfRateSummaryCharge(value: object): value is RateSummaryCharge {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function RateSummaryChargeFromJSON(json: any): RateSummaryCharge {
    return RateSummaryChargeFromJSONTyped(json, false);
}

export function RateSummaryChargeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateSummaryCharge {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': json['value'],
    };
}

export function RateSummaryChargeToJSON(value?: RateSummaryCharge | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'value': value['value'],
    };
}

