/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RoomLayoutType = {
    Standard: 'Standard',
    Suite: 'Suite',
    Studio: 'Studio'
} as const;
export type RoomLayoutType = typeof RoomLayoutType[keyof typeof RoomLayoutType];


export function instanceOfRoomLayoutType(value: any): boolean {
    for (const key in RoomLayoutType) {
        if (Object.prototype.hasOwnProperty.call(RoomLayoutType, key)) {
            if ((RoomLayoutType as Record<string, RoomLayoutType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RoomLayoutTypeFromJSON(json: any): RoomLayoutType {
    return RoomLayoutTypeFromJSONTyped(json, false);
}

export function RoomLayoutTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomLayoutType {
    return json as RoomLayoutType;
}

export function RoomLayoutTypeToJSON(value?: RoomLayoutType | null): any {
    return value as any;
}
