/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A credit card brand which may have multiple credit card types
 * @export
 */
export const CreditCardBrands = {
    Chase: 'Chase',
    AmericanExpress: 'American Express',
    Bilt: 'Bilt',
    CapitalOne: 'Capital One',
    Citi: 'Citi',
    Discover: 'Discover',
    WellsFargo: 'Wells Fargo',
    BankOfAmerica: 'Bank of America',
    UsBank: 'US Bank'
} as const;
export type CreditCardBrands = typeof CreditCardBrands[keyof typeof CreditCardBrands];


export function instanceOfCreditCardBrands(value: any): boolean {
    for (const key in CreditCardBrands) {
        if (Object.prototype.hasOwnProperty.call(CreditCardBrands, key)) {
            if ((CreditCardBrands as Record<string, CreditCardBrands>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CreditCardBrandsFromJSON(json: any): CreditCardBrands {
    return CreditCardBrandsFromJSONTyped(json, false);
}

export function CreditCardBrandsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditCardBrands {
    return json as CreditCardBrands;
}

export function CreditCardBrandsToJSON(value?: CreditCardBrands | null): any {
    return value as any;
}
