/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An object containing metadata about the service
 * @export
 * @interface ServiceMetadataForCancelForAnyReason
 */
export interface ServiceMetadataForCancelForAnyReason {
    /**
     * Details of what this CFAR service entails and how it can be used
     * @type {string}
     * @memberof ServiceMetadataForCancelForAnyReason
     */
    merchantCopy: string;
    /**
     * The amount of money that will be returned
     * @type {string}
     * @memberof ServiceMetadataForCancelForAnyReason
     */
    refundAmount: string;
    /**
     * The URL where you can find the terms and conditions for this CFAR service
     * @type {string}
     * @memberof ServiceMetadataForCancelForAnyReason
     */
    termsAndConditionsUrl: string;
}

/**
 * Check if a given object implements the ServiceMetadataForCancelForAnyReason interface.
 */
export function instanceOfServiceMetadataForCancelForAnyReason(value: object): value is ServiceMetadataForCancelForAnyReason {
    if (!('merchantCopy' in value) || value['merchantCopy'] === undefined) return false;
    if (!('refundAmount' in value) || value['refundAmount'] === undefined) return false;
    if (!('termsAndConditionsUrl' in value) || value['termsAndConditionsUrl'] === undefined) return false;
    return true;
}

export function ServiceMetadataForCancelForAnyReasonFromJSON(json: any): ServiceMetadataForCancelForAnyReason {
    return ServiceMetadataForCancelForAnyReasonFromJSONTyped(json, false);
}

export function ServiceMetadataForCancelForAnyReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMetadataForCancelForAnyReason {
    if (json == null) {
        return json;
    }
    return {
        
        'merchantCopy': json['merchant_copy'],
        'refundAmount': json['refund_amount'],
        'termsAndConditionsUrl': json['terms_and_conditions_url'],
    };
}

export function ServiceMetadataForCancelForAnyReasonToJSON(value?: ServiceMetadataForCancelForAnyReason | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'merchant_copy': value['merchantCopy'],
        'refund_amount': value['refundAmount'],
        'terms_and_conditions_url': value['termsAndConditionsUrl'],
    };
}

