/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The property's location information.
 * @export
 * @interface HotelContentGeoCode
 */
export interface HotelContentGeoCode {
    /**
     * Latitude
     * @type {number}
     * @memberof HotelContentGeoCode
     */
    lat?: number;
    /**
     * Longitude
     * @type {number}
     * @memberof HotelContentGeoCode
     */
    _long?: number;
}

/**
 * Check if a given object implements the HotelContentGeoCode interface.
 */
export function instanceOfHotelContentGeoCode(value: object): value is HotelContentGeoCode {
    return true;
}

export function HotelContentGeoCodeFromJSON(json: any): HotelContentGeoCode {
    return HotelContentGeoCodeFromJSONTyped(json, false);
}

export function HotelContentGeoCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentGeoCode {
    if (json == null) {
        return json;
    }
    return {
        
        'lat': json['Lat'] == null ? undefined : json['Lat'],
        '_long': json['Long'] == null ? undefined : json['Long'],
    };
}

export function HotelContentGeoCodeToJSON(value?: HotelContentGeoCode | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Lat': value['lat'],
        'Long': value['_long'],
    };
}

