/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DepositItinerary } from './DepositItinerary';
import {
    DepositItineraryFromJSON,
    DepositItineraryFromJSONTyped,
    DepositItineraryToJSON,
} from './DepositItinerary';
import type { PricingInformation } from './PricingInformation';
import {
    PricingInformationFromJSON,
    PricingInformationFromJSONTyped,
    PricingInformationToJSON,
} from './PricingInformation';
import type { CancelRefund } from './CancelRefund';
import {
    CancelRefundFromJSON,
    CancelRefundFromJSONTyped,
    CancelRefundToJSON,
} from './CancelRefund';
import type { CancelPenalty } from './CancelPenalty';
import {
    CancelPenaltyFromJSON,
    CancelPenaltyFromJSONTyped,
    CancelPenaltyToJSON,
} from './CancelPenalty';
import type { NonrefundableDateRange } from './NonrefundableDateRange';
import {
    NonrefundableDateRangeFromJSON,
    NonrefundableDateRangeFromJSONTyped,
    NonrefundableDateRangeToJSON,
} from './NonrefundableDateRange';
import type { Amount } from './Amount';
import {
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import type { MerchantOfRecord } from './MerchantOfRecord';
import {
    MerchantOfRecordFromJSON,
    MerchantOfRecordFromJSONTyped,
    MerchantOfRecordToJSON,
} from './MerchantOfRecord';
import type { PromotionsItinerary } from './PromotionsItinerary';
import {
    PromotionsItineraryFromJSON,
    PromotionsItineraryFromJSONTyped,
    PromotionsItineraryToJSON,
} from './PromotionsItinerary';

/**
 * The rate information associated with the itinerary.
 * @export
 * @interface RateItinerary
 */
export interface RateItinerary {
    /**
     * The id of the rate.
     * @type {string}
     * @memberof RateItinerary
     */
    id?: string;
    /**
     * 
     * @type {MerchantOfRecord}
     * @memberof RateItinerary
     */
    merchantOfRecord?: MerchantOfRecord;
    /**
     * Indicates whether the itinerary is refundable or not.
     * @type {boolean}
     * @memberof RateItinerary
     */
    refundable?: boolean;
    /**
     * 
     * @type {CancelRefund}
     * @memberof RateItinerary
     */
    cancelRefund?: CancelRefund;
    /**
     * 
     * @type {Array<string>}
     * @memberof RateItinerary
     */
    amenities?: Array<string>;
    /**
     * 
     * @type {PromotionsItinerary}
     * @memberof RateItinerary
     */
    promotions?: PromotionsItinerary;
    /**
     * The cancel penalties associated with the itinerary.
     * @type {Array<CancelPenalty>}
     * @memberof RateItinerary
     */
    cancelPenalties?: Array<CancelPenalty>;
    /**
     * A list of date exceptions. Dates within these ranges provide no refund on cancellation, regardless of
     * cancel penalty windows. Nonrefundable range begins at 00:00:00 on the start date, and ends at 23:59:59 on
     * the end date, in the local time zone of the property.
     * 
     * @type {Array<NonrefundableDateRange>}
     * @memberof RateItinerary
     */
    nonrefundableDateRanges?: Array<NonrefundableDateRange>;
    /**
     * 
     * @type {Array<DepositItinerary>}
     * @memberof RateItinerary
     */
    deposits?: Array<DepositItinerary>;
    /**
     * 
     * @type {Amount}
     * @memberof RateItinerary
     */
    cardOnFileLimit?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof RateItinerary
     */
    refundableDamageDeposit?: Amount;
    /**
     * 
     * @type {PricingInformation}
     * @memberof RateItinerary
     */
    pricing?: PricingInformation;
}

/**
 * Check if a given object implements the RateItinerary interface.
 */
export function instanceOfRateItinerary(value: object): value is RateItinerary {
    return true;
}

export function RateItineraryFromJSON(json: any): RateItinerary {
    return RateItineraryFromJSONTyped(json, false);
}

export function RateItineraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateItinerary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'merchantOfRecord': json['merchant_of_record'] == null ? undefined : MerchantOfRecordFromJSON(json['merchant_of_record']),
        'refundable': json['refundable'] == null ? undefined : json['refundable'],
        'cancelRefund': json['cancel_refund'] == null ? undefined : CancelRefundFromJSON(json['cancel_refund']),
        'amenities': json['amenities'] == null ? undefined : json['amenities'],
        'promotions': json['promotions'] == null ? undefined : PromotionsItineraryFromJSON(json['promotions']),
        'cancelPenalties': json['cancel_penalties'] == null ? undefined : ((json['cancel_penalties'] as Array<any>).map(CancelPenaltyFromJSON)),
        'nonrefundableDateRanges': json['nonrefundable_date_ranges'] == null ? undefined : ((json['nonrefundable_date_ranges'] as Array<any>).map(NonrefundableDateRangeFromJSON)),
        'deposits': json['deposits'] == null ? undefined : ((json['deposits'] as Array<any>).map(DepositItineraryFromJSON)),
        'cardOnFileLimit': json['card_on_file_limit'] == null ? undefined : AmountFromJSON(json['card_on_file_limit']),
        'refundableDamageDeposit': json['refundable_damage_deposit'] == null ? undefined : AmountFromJSON(json['refundable_damage_deposit']),
        'pricing': json['pricing'] == null ? undefined : PricingInformationFromJSON(json['pricing']),
    };
}

export function RateItineraryToJSON(value?: RateItinerary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'merchant_of_record': MerchantOfRecordToJSON(value['merchantOfRecord']),
        'refundable': value['refundable'],
        'cancel_refund': CancelRefundToJSON(value['cancelRefund']),
        'amenities': value['amenities'],
        'promotions': PromotionsItineraryToJSON(value['promotions']),
        'cancel_penalties': value['cancelPenalties'] == null ? undefined : ((value['cancelPenalties'] as Array<any>).map(CancelPenaltyToJSON)),
        'nonrefundable_date_ranges': value['nonrefundableDateRanges'] == null ? undefined : ((value['nonrefundableDateRanges'] as Array<any>).map(NonrefundableDateRangeToJSON)),
        'deposits': value['deposits'] == null ? undefined : ((value['deposits'] as Array<any>).map(DepositItineraryToJSON)),
        'card_on_file_limit': AmountToJSON(value['cardOnFileLimit']),
        'refundable_damage_deposit': AmountToJSON(value['refundableDamageDeposit']),
        'pricing': PricingInformationToJSON(value['pricing']),
    };
}

