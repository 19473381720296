/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SliceSegmentPassengerBaggage } from './SliceSegmentPassengerBaggage';
import {
    SliceSegmentPassengerBaggageFromJSON,
    SliceSegmentPassengerBaggageFromJSONTyped,
    SliceSegmentPassengerBaggageToJSON,
} from './SliceSegmentPassengerBaggage';
import type { OfferSliceSegmentPassengerCabin } from './OfferSliceSegmentPassengerCabin';
import {
    OfferSliceSegmentPassengerCabinFromJSON,
    OfferSliceSegmentPassengerCabinFromJSONTyped,
    OfferSliceSegmentPassengerCabinToJSON,
} from './OfferSliceSegmentPassengerCabin';

/**
 * 
 * @export
 * @interface OfferSliceSegmentPassenger
 */
export interface OfferSliceSegmentPassenger {
    /**
     * The baggage allowances for the passenger on this segment included in the offer. Some airlines may allow additional baggage to be booked as a service - see the offer's `available_services`.
     * @type {Array<SliceSegmentPassengerBaggage>}
     * @memberof OfferSliceSegmentPassenger
     */
    baggages?: Array<SliceSegmentPassengerBaggage>;
    /**
     * 
     * @type {OfferSliceSegmentPassengerCabin}
     * @memberof OfferSliceSegmentPassenger
     */
    cabin?: OfferSliceSegmentPassengerCabin;
    /**
     * The cabin class that the passenger will travel in on this segment
     * @type {string}
     * @memberof OfferSliceSegmentPassenger
     */
    cabinClass?: OfferSliceSegmentPassengerCabinClassEnum;
    /**
     * The name that the marketing carrier uses to market this cabin class
     * @type {string}
     * @memberof OfferSliceSegmentPassenger
     */
    cabinClassMarketingName?: string;
    /**
     * The airline's alphanumeric code for the fare that the passenger is using to travel. Where this is `null`, it means that either the fare basis code is not available or the airline does not use fare basis codes.
     * @type {string}
     * @memberof OfferSliceSegmentPassenger
     */
    fareBasisCode?: string;
    /**
     * The identifier for the passenger. You may have specified this ID yourself when creating the offer request, or otherwise, Duffel will have generated its own random ID.
     * @type {string}
     * @memberof OfferSliceSegmentPassenger
     */
    passengerId?: string;
}


/**
 * @export
 */
export const OfferSliceSegmentPassengerCabinClassEnum = {
    First: 'first',
    Business: 'business',
    PremiumEconomy: 'premium_economy',
    Economy: 'economy'
} as const;
export type OfferSliceSegmentPassengerCabinClassEnum = typeof OfferSliceSegmentPassengerCabinClassEnum[keyof typeof OfferSliceSegmentPassengerCabinClassEnum];


/**
 * Check if a given object implements the OfferSliceSegmentPassenger interface.
 */
export function instanceOfOfferSliceSegmentPassenger(value: object): value is OfferSliceSegmentPassenger {
    return true;
}

export function OfferSliceSegmentPassengerFromJSON(json: any): OfferSliceSegmentPassenger {
    return OfferSliceSegmentPassengerFromJSONTyped(json, false);
}

export function OfferSliceSegmentPassengerFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentPassenger {
    if (json == null) {
        return json;
    }
    return {
        
        'baggages': json['baggages'] == null ? undefined : ((json['baggages'] as Array<any>).map(SliceSegmentPassengerBaggageFromJSON)),
        'cabin': json['cabin'] == null ? undefined : OfferSliceSegmentPassengerCabinFromJSON(json['cabin']),
        'cabinClass': json['cabin_class'] == null ? undefined : json['cabin_class'],
        'cabinClassMarketingName': json['cabin_class_marketing_name'] == null ? undefined : json['cabin_class_marketing_name'],
        'fareBasisCode': json['fare_basis_code'] == null ? undefined : json['fare_basis_code'],
        'passengerId': json['passenger_id'] == null ? undefined : json['passenger_id'],
    };
}

export function OfferSliceSegmentPassengerToJSON(value?: OfferSliceSegmentPassenger | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'baggages': value['baggages'] == null ? undefined : ((value['baggages'] as Array<any>).map(SliceSegmentPassengerBaggageToJSON)),
        'cabin': OfferSliceSegmentPassengerCabinToJSON(value['cabin']),
        'cabin_class': value['cabinClass'],
        'cabin_class_marketing_name': value['cabinClassMarketingName'],
        'fare_basis_code': value['fareBasisCode'],
        'passenger_id': value['passengerId'],
    };
}

