/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PropertyAvailabilityLinks } from './PropertyAvailabilityLinks';
import {
    PropertyAvailabilityLinksFromJSON,
    PropertyAvailabilityLinksFromJSONTyped,
    PropertyAvailabilityLinksToJSON,
} from './PropertyAvailabilityLinks';
import type { Property } from './Property';
import {
    PropertyFromJSON,
    PropertyFromJSONTyped,
    PropertyToJSON,
} from './Property';
import type { RoomAvailability } from './RoomAvailability';
import {
    RoomAvailabilityFromJSON,
    RoomAvailabilityFromJSONTyped,
    RoomAvailabilityToJSON,
} from './RoomAvailability';

/**
 * 
 * @export
 * @interface PropertyAvailability
 */
export interface PropertyAvailability extends Property {
    /**
     * Array of objects containing room information.
     * @type {Array<RoomAvailability>}
     * @memberof PropertyAvailability
     */
    rooms?: Array<RoomAvailability>;
    /**
     * 
     * @type {PropertyAvailabilityLinks}
     * @memberof PropertyAvailability
     */
    links?: PropertyAvailabilityLinks;
}



/**
 * Check if a given object implements the PropertyAvailability interface.
 */
export function instanceOfPropertyAvailability(value: object): value is PropertyAvailability {
    return true;
}

export function PropertyAvailabilityFromJSON(json: any): PropertyAvailability {
    return PropertyAvailabilityFromJSONTyped(json, false);
}

export function PropertyAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyAvailability {
    if (json == null) {
        return json;
    }
    return {
        ...PropertyFromJSONTyped(json, ignoreDiscriminator),
        'rooms': json['rooms'] == null ? undefined : ((json['rooms'] as Array<any>).map(RoomAvailabilityFromJSON)),
        'links': json['links'] == null ? undefined : PropertyAvailabilityLinksFromJSON(json['links']),
    };
}

export function PropertyAvailabilityToJSON(value?: PropertyAvailability | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...PropertyToJSON(value),
        'rooms': value['rooms'] == null ? undefined : ((value['rooms'] as Array<any>).map(RoomAvailabilityToJSON)),
        'links': PropertyAvailabilityLinksToJSON(value['links']),
    };
}

