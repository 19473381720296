/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicates the status of the rate. If the rate is still available then available will be returned. If the rate is no longer available at that price then price_changed will be returned. If the rate is no longer available at all then sold_out will be returned.
 * @export
 */
export const Status1 = {
    Available: 'available',
    PriceChanged: 'price_changed',
    SoldOut: 'sold_out'
} as const;
export type Status1 = typeof Status1[keyof typeof Status1];


export function instanceOfStatus1(value: any): boolean {
    for (const key in Status1) {
        if (Object.prototype.hasOwnProperty.call(Status1, key)) {
            if ((Status1 as Record<string, Status1>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function Status1FromJSON(json: any): Status1 {
    return Status1FromJSONTyped(json, false);
}

export function Status1FromJSONTyped(json: any, ignoreDiscriminator: boolean): Status1 {
    return json as Status1;
}

export function Status1ToJSON(value?: Status1 | null): any {
    return value as any;
}
