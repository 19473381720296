/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferSliceSegmentPassengerCabinAmenities } from './OfferSliceSegmentPassengerCabinAmenities';
import {
    OfferSliceSegmentPassengerCabinAmenitiesFromJSON,
    OfferSliceSegmentPassengerCabinAmenitiesFromJSONTyped,
    OfferSliceSegmentPassengerCabinAmenitiesToJSON,
} from './OfferSliceSegmentPassengerCabinAmenities';

/**
 * Data about the cabin that the passenger will be flying in for this segment
 * @export
 * @interface OfferSliceSegmentPassengerCabin
 */
export interface OfferSliceSegmentPassengerCabin {
    /**
     * 
     * @type {OfferSliceSegmentPassengerCabinAmenities}
     * @memberof OfferSliceSegmentPassengerCabin
     */
    amenities?: OfferSliceSegmentPassengerCabinAmenities;
    /**
     * The name that the marketing carrier uses to market this cabin class
     * @type {string}
     * @memberof OfferSliceSegmentPassengerCabin
     */
    marketingName?: string;
    /**
     * The name of the cabin class
     * @type {string}
     * @memberof OfferSliceSegmentPassengerCabin
     */
    name?: OfferSliceSegmentPassengerCabinNameEnum;
}


/**
 * @export
 */
export const OfferSliceSegmentPassengerCabinNameEnum = {
    First: 'first',
    Business: 'business',
    PremiumEconomy: 'premium_economy',
    Economy: 'economy'
} as const;
export type OfferSliceSegmentPassengerCabinNameEnum = typeof OfferSliceSegmentPassengerCabinNameEnum[keyof typeof OfferSliceSegmentPassengerCabinNameEnum];


/**
 * Check if a given object implements the OfferSliceSegmentPassengerCabin interface.
 */
export function instanceOfOfferSliceSegmentPassengerCabin(value: object): value is OfferSliceSegmentPassengerCabin {
    return true;
}

export function OfferSliceSegmentPassengerCabinFromJSON(json: any): OfferSliceSegmentPassengerCabin {
    return OfferSliceSegmentPassengerCabinFromJSONTyped(json, false);
}

export function OfferSliceSegmentPassengerCabinFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentPassengerCabin {
    if (json == null) {
        return json;
    }
    return {
        
        'amenities': json['amenities'] == null ? undefined : OfferSliceSegmentPassengerCabinAmenitiesFromJSON(json['amenities']),
        'marketingName': json['marketing_name'] == null ? undefined : json['marketing_name'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function OfferSliceSegmentPassengerCabinToJSON(value?: OfferSliceSegmentPassengerCabin | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amenities': OfferSliceSegmentPassengerCabinAmenitiesToJSON(value['amenities']),
        'marketing_name': value['marketingName'],
        'name': value['name'],
    };
}

