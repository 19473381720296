/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Bed } from './Bed';
import {
    BedFromJSON,
    BedFromJSONTyped,
    BedToJSON,
} from './Bed';
import type { RoomArea } from './RoomArea';
import {
    RoomAreaFromJSON,
    RoomAreaFromJSONTyped,
    RoomAreaToJSON,
} from './RoomArea';
import type { Facility } from './Facility';
import {
    FacilityFromJSON,
    FacilityFromJSONTyped,
    FacilityToJSON,
} from './Facility';

/**
 * 
 * @export
 * @interface Room
 */
export interface Room {
    /**
     * Unique room id
     * @type {string}
     * @memberof Room
     */
    roomId?: string;
    /**
     * Room type
     * @type {string}
     * @memberof Room
     */
    type?: string;
    /**
     * Room Category
     * @type {string}
     * @memberof Room
     */
    category?: string;
    /**
     * Room Occupancy
     * @type {string}
     * @memberof Room
     */
    occupancy?: string;
    /**
     * Description of Room
     * @type {string}
     * @memberof Room
     */
    description?: string;
    /**
     * 
     * @type {Array<Facility>}
     * @memberof Room
     */
    facilities?: Array<Facility>;
    /**
     * 
     * @type {Array<Bed>}
     * @memberof Room
     */
    beds?: Array<Bed>;
    /**
     * 
     * @type {RoomArea}
     * @memberof Room
     */
    area?: RoomArea;
    /**
     * 
     * @type {Array<string>}
     * @memberof Room
     */
    views?: Array<string>;
    /**
     * Maximum guest allowed.
     * @type {number}
     * @memberof Room
     */
    maxGuestAllowed?: number;
    /**
     * Maximum adult allowed.
     * @type {number}
     * @memberof Room
     */
    maxAdultAllowed?: number;
    /**
     * Maximum children allowed.
     * @type {number}
     * @memberof Room
     */
    maxChildrenAllowed?: number;
}

/**
 * Check if a given object implements the Room interface.
 */
export function instanceOfRoom(value: object): value is Room {
    return true;
}

export function RoomFromJSON(json: any): Room {
    return RoomFromJSONTyped(json, false);
}

export function RoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): Room {
    if (json == null) {
        return json;
    }
    return {
        
        'roomId': json['RoomId'] == null ? undefined : json['RoomId'],
        'type': json['Type'] == null ? undefined : json['Type'],
        'category': json['Category'] == null ? undefined : json['Category'],
        'occupancy': json['Occupancy'] == null ? undefined : json['Occupancy'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'facilities': json['Facilities'] == null ? undefined : ((json['Facilities'] as Array<any>).map(FacilityFromJSON)),
        'beds': json['Beds'] == null ? undefined : ((json['Beds'] as Array<any>).map(BedFromJSON)),
        'area': json['Area'] == null ? undefined : RoomAreaFromJSON(json['Area']),
        'views': json['Views'] == null ? undefined : json['Views'],
        'maxGuestAllowed': json['MaxGuestAllowed'] == null ? undefined : json['MaxGuestAllowed'],
        'maxAdultAllowed': json['MaxAdultAllowed'] == null ? undefined : json['MaxAdultAllowed'],
        'maxChildrenAllowed': json['MaxChildrenAllowed'] == null ? undefined : json['MaxChildrenAllowed'],
    };
}

export function RoomToJSON(value?: Room | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'RoomId': value['roomId'],
        'Type': value['type'],
        'Category': value['category'],
        'Occupancy': value['occupancy'],
        'Description': value['description'],
        'Facilities': value['facilities'] == null ? undefined : ((value['facilities'] as Array<any>).map(FacilityToJSON)),
        'Beds': value['beds'] == null ? undefined : ((value['beds'] as Array<any>).map(BedToJSON)),
        'Area': RoomAreaToJSON(value['area']),
        'Views': value['views'],
        'MaxGuestAllowed': value['maxGuestAllowed'],
        'MaxAdultAllowed': value['maxAdultAllowed'],
        'MaxChildrenAllowed': value['maxChildrenAllowed'],
    };
}

