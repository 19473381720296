/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ItineraryCreationLinks } from './ItineraryCreationLinks';
import {
    ItineraryCreationLinksFromJSON,
    ItineraryCreationLinksFromJSONTyped,
    ItineraryCreationLinksToJSON,
} from './ItineraryCreationLinks';
import type { TraderInformation } from './TraderInformation';
import {
    TraderInformationFromJSON,
    TraderInformationFromJSONTyped,
    TraderInformationToJSON,
} from './TraderInformation';

/**
 * The book response.
 * @export
 * @interface ItineraryCreation
 */
export interface ItineraryCreation {
    /**
     * The itinerary id.
     * @type {string}
     * @memberof ItineraryCreation
     */
    itineraryId?: string;
    /**
     * 
     * @type {ItineraryCreationLinks}
     * @memberof ItineraryCreation
     */
    links?: ItineraryCreationLinks;
    /**
     * The challenge config that is required to perform payment challenge. This field will be available when payment challenge is needed.
     * @type {string}
     * @memberof ItineraryCreation
     */
    encodedChallengeConfig?: string;
    /**
     * 
     * @type {TraderInformation}
     * @memberof ItineraryCreation
     */
    traderInformation?: TraderInformation;
}

/**
 * Check if a given object implements the ItineraryCreation interface.
 */
export function instanceOfItineraryCreation(value: object): value is ItineraryCreation {
    return true;
}

export function ItineraryCreationFromJSON(json: any): ItineraryCreation {
    return ItineraryCreationFromJSONTyped(json, false);
}

export function ItineraryCreationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItineraryCreation {
    if (json == null) {
        return json;
    }
    return {
        
        'itineraryId': json['itinerary_id'] == null ? undefined : json['itinerary_id'],
        'links': json['links'] == null ? undefined : ItineraryCreationLinksFromJSON(json['links']),
        'encodedChallengeConfig': json['encoded_challenge_config'] == null ? undefined : json['encoded_challenge_config'],
        'traderInformation': json['trader_information'] == null ? undefined : TraderInformationFromJSON(json['trader_information']),
    };
}

export function ItineraryCreationToJSON(value?: ItineraryCreation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'itinerary_id': value['itineraryId'],
        'links': ItineraryCreationLinksToJSON(value['links']),
        'encoded_challenge_config': value['encodedChallengeConfig'],
        'trader_information': TraderInformationToJSON(value['traderInformation']),
    };
}

