/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An image of a hotel and its rooms
 * @export
 * @interface HotelImage
 */
export interface HotelImage {
    /**
     * Category of the image
     * @type {string}
     * @memberof HotelImage
     */
    category: string;
    /**
     * URL of the image
     * @type {string}
     * @memberof HotelImage
     */
    url: string;
}

/**
 * Check if a given object implements the HotelImage interface.
 */
export function instanceOfHotelImage(value: object): value is HotelImage {
    if (!('category' in value) || value['category'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function HotelImageFromJSON(json: any): HotelImage {
    return HotelImageFromJSONTyped(json, false);
}

export function HotelImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelImage {
    if (json == null) {
        return json;
    }
    return {
        
        'category': json['category'],
        'url': json['url'],
    };
}

export function HotelImageToJSON(value?: HotelImage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'category': value['category'],
        'url': value['url'],
    };
}

