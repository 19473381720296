/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A request for airport metadata based on an array of IATA codes
 * @export
 * @interface AirportRequest
 */
export interface AirportRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirportRequest
     */
    iataCodes: Array<string>;
}

/**
 * Check if a given object implements the AirportRequest interface.
 */
export function instanceOfAirportRequest(value: object): value is AirportRequest {
    if (!('iataCodes' in value) || value['iataCodes'] === undefined) return false;
    return true;
}

export function AirportRequestFromJSON(json: any): AirportRequest {
    return AirportRequestFromJSONTyped(json, false);
}

export function AirportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirportRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'iataCodes': json['iata_codes'],
    };
}

export function AirportRequestToJSON(value?: AirportRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'iata_codes': value['iataCodes'],
    };
}

