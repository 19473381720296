/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelPenalty
 */
export interface CancelPenalty {
    /**
     * Currency of the amount object.
     * @type {string}
     * @memberof CancelPenalty
     */
    currency?: string;
    /**
     * Effective date and time of cancellation penalty in extended ISO 8601 format, with ±hh:mm timezone offset
     * @type {string}
     * @memberof CancelPenalty
     */
    start?: string;
    /**
     * End date and time of cancellation penalty in extended ISO 8601 format, with ±hh:mm timezone offset
     * @type {string}
     * @memberof CancelPenalty
     */
    end?: string;
    /**
     * The monetary amount of the penalty.
     * @type {string}
     * @memberof CancelPenalty
     */
    amount?: string;
    /**
     * Number of nights charged for as penalty.
     * @type {string}
     * @memberof CancelPenalty
     */
    nights?: string;
    /**
     * Percentage of total booking charged for as penalty. A thirty percent penalty would be returned as 30%
     * @type {string}
     * @memberof CancelPenalty
     */
    percent?: string;
}

/**
 * Check if a given object implements the CancelPenalty interface.
 */
export function instanceOfCancelPenalty(value: object): value is CancelPenalty {
    return true;
}

export function CancelPenaltyFromJSON(json: any): CancelPenalty {
    return CancelPenaltyFromJSONTyped(json, false);
}

export function CancelPenaltyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelPenalty {
    if (json == null) {
        return json;
    }
    return {
        
        'currency': json['currency'] == null ? undefined : json['currency'],
        'start': json['start'] == null ? undefined : json['start'],
        'end': json['end'] == null ? undefined : json['end'],
        'amount': json['amount'] == null ? undefined : json['amount'],
        'nights': json['nights'] == null ? undefined : json['nights'],
        'percent': json['percent'] == null ? undefined : json['percent'],
    };
}

export function CancelPenaltyToJSON(value?: CancelPenalty | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currency': value['currency'],
        'start': value['start'],
        'end': value['end'],
        'amount': value['amount'],
        'nights': value['nights'],
        'percent': value['percent'],
    };
}

