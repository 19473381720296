/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Amount } from './Amount';
import {
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';

/**
 * An object representing a charge. Information about the charge is provided in both the billable currency and the request currency.
 * @export
 * @interface ChargeCalculated
 */
export interface ChargeCalculated {
    /**
     * 
     * @type {Amount}
     * @memberof ChargeCalculated
     */
    billableCurrency?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof ChargeCalculated
     */
    requestCurrency?: Amount;
}

/**
 * Check if a given object implements the ChargeCalculated interface.
 */
export function instanceOfChargeCalculated(value: object): value is ChargeCalculated {
    return true;
}

export function ChargeCalculatedFromJSON(json: any): ChargeCalculated {
    return ChargeCalculatedFromJSONTyped(json, false);
}

export function ChargeCalculatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChargeCalculated {
    if (json == null) {
        return json;
    }
    return {
        
        'billableCurrency': json['billable_currency'] == null ? undefined : AmountFromJSON(json['billable_currency']),
        'requestCurrency': json['request_currency'] == null ? undefined : AmountFromJSON(json['request_currency']),
    };
}

export function ChargeCalculatedToJSON(value?: ChargeCalculated | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'billable_currency': AmountToJSON(value['billableCurrency']),
        'request_currency': AmountToJSON(value['requestCurrency']),
    };
}

