/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Amenity } from './Amenity';
import {
    AmenityFromJSON,
    AmenityFromJSONTyped,
    AmenityToJSON,
} from './Amenity';
import type { Deposit } from './Deposit';
import {
    DepositFromJSON,
    DepositFromJSONTyped,
    DepositToJSON,
} from './Deposit';
import type { PricingInformation } from './PricingInformation';
import {
    PricingInformationFromJSON,
    PricingInformationFromJSONTyped,
    PricingInformationToJSON,
} from './PricingInformation';
import type { CancelPenalty } from './CancelPenalty';
import {
    CancelPenaltyFromJSON,
    CancelPenaltyFromJSONTyped,
    CancelPenaltyToJSON,
} from './CancelPenalty';
import type { RateLinks } from './RateLinks';
import {
    RateLinksFromJSON,
    RateLinksFromJSONTyped,
    RateLinksToJSON,
} from './RateLinks';
import type { Promotions } from './Promotions';
import {
    PromotionsFromJSON,
    PromotionsFromJSONTyped,
    PromotionsToJSON,
} from './Promotions';
import type { NonrefundableDateRange } from './NonrefundableDateRange';
import {
    NonrefundableDateRangeFromJSON,
    NonrefundableDateRangeFromJSONTyped,
    NonrefundableDateRangeToJSON,
} from './NonrefundableDateRange';
import type { Amount } from './Amount';
import {
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import type { BedGroupAvailability } from './BedGroupAvailability';
import {
    BedGroupAvailabilityFromJSON,
    BedGroupAvailabilityFromJSONTyped,
    BedGroupAvailabilityToJSON,
} from './BedGroupAvailability';
import type { MerchantOfRecord } from './MerchantOfRecord';
import {
    MerchantOfRecordFromJSON,
    MerchantOfRecordFromJSONTyped,
    MerchantOfRecordToJSON,
} from './MerchantOfRecord';
import type { Status1 } from './Status1';
import {
    Status1FromJSON,
    Status1FromJSONTyped,
    Status1ToJSON,
} from './Status1';
import type { SaleScenario } from './SaleScenario';
import {
    SaleScenarioFromJSON,
    SaleScenarioFromJSONTyped,
    SaleScenarioToJSON,
} from './SaleScenario';

/**
 * A rate.
 * @export
 * @interface Rate
 */
export interface Rate {
    /**
     * Unique Identifier for a rate.
     * @type {string}
     * @memberof Rate
     */
    id?: string;
    /**
     * 
     * @type {Status1}
     * @memberof Rate
     */
    status?: Status1;
    /**
     * The number of bookable rooms remaining with this rate in EPS inventory. Use this value to create rules for urgency messaging to alert users to low availability on busy travel dates or at popular properties.
     * If the value returns as 2147483647 (max int value), the actual value could not be determined. Ensure your urgency messaging ignores such instances when returned.
     * @type {number}
     * @memberof Rate
     */
    availableRooms?: number;
    /**
     * Indicates if the rate is fully refundable at the time of booking. Cancel penalties may still apply. Please refer to the cancel penalties section for reference.
     * @type {boolean}
     * @memberof Rate
     */
    refundable?: boolean;
    /**
     * Indicates if a "Member Only Deal" is available for this rate.
     * @type {boolean}
     * @memberof Rate
     */
    memberDealAvailable?: boolean;
    /**
     * 
     * @type {SaleScenario}
     * @memberof Rate
     */
    saleScenario?: SaleScenario;
    /**
     * 
     * @type {MerchantOfRecord}
     * @memberof Rate
     */
    merchantOfRecord?: MerchantOfRecord;
    /**
     * Room amenities.
     * @type {{ [key: string]: Amenity; }}
     * @memberof Rate
     */
    amenities?: { [key: string]: Amenity; };
    /**
     * 
     * @type {RateLinks}
     * @memberof Rate
     */
    links?: RateLinks;
    /**
     * A map of the room's bed groups.
     * @type {{ [key: string]: BedGroupAvailability; }}
     * @memberof Rate
     */
    bedGroups?: { [key: string]: BedGroupAvailability; };
    /**
     * Array of `cancel_penalty` objects containing cancel penalty information.
     * @type {Array<CancelPenalty>}
     * @memberof Rate
     */
    cancelPenalties?: Array<CancelPenalty>;
    /**
     * An array of stay date ranges within this check-in / check-out range that are not refundable. Stay dates
     * within these ranges provide no refund on cancellation, regardless of cancel penalty windows. The stay dates
     * are determined by the would be check-in of that night. With a check-in date of 2023-09-01, and a check-out
     * date of 2023-09-06, this would be a 5 night stay. A `nonrefundable_date_range` with start: 2023-09-02 and
     * end: 2023-09-03 would mean 2 of the nights are nonrefundable. The 1st night is refundable, the 2nd and 3rd
     * nights are nonrefundable, and the 4th and 5th nights are refundable, subject to `cancel_penalties`
     * restrictions.
     * 
     * @type {Array<NonrefundableDateRange>}
     * @memberof Rate
     */
    nonrefundableDateRanges?: Array<NonrefundableDateRange>;
    /**
     * A map of room information by occupancy.
     * @type {{ [key: string]: PricingInformation; }}
     * @memberof Rate
     */
    occupancyPricing?: { [key: string]: PricingInformation; };
    /**
     * 
     * @type {Promotions}
     * @memberof Rate
     */
    promotions?: Promotions;
    /**
     * 
     * @type {Amount}
     * @memberof Rate
     */
    cardOnFileLimit?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof Rate
     */
    refundableDamageDeposit?: Amount;
    /**
     * Array of deposits for the rate.
     * @type {Array<Deposit>}
     * @memberof Rate
     */
    deposits?: Array<Deposit>;
}

/**
 * Check if a given object implements the Rate interface.
 */
export function instanceOfRate(value: object): value is Rate {
    return true;
}

export function RateFromJSON(json: any): Rate {
    return RateFromJSONTyped(json, false);
}

export function RateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Rate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'status': json['status'] == null ? undefined : Status1FromJSON(json['status']),
        'availableRooms': json['available_rooms'] == null ? undefined : json['available_rooms'],
        'refundable': json['refundable'] == null ? undefined : json['refundable'],
        'memberDealAvailable': json['member_deal_available'] == null ? undefined : json['member_deal_available'],
        'saleScenario': json['sale_scenario'] == null ? undefined : SaleScenarioFromJSON(json['sale_scenario']),
        'merchantOfRecord': json['merchant_of_record'] == null ? undefined : MerchantOfRecordFromJSON(json['merchant_of_record']),
        'amenities': json['amenities'] == null ? undefined : (mapValues(json['amenities'], AmenityFromJSON)),
        'links': json['links'] == null ? undefined : RateLinksFromJSON(json['links']),
        'bedGroups': json['bed_groups'] == null ? undefined : (mapValues(json['bed_groups'], BedGroupAvailabilityFromJSON)),
        'cancelPenalties': json['cancel_penalties'] == null ? undefined : ((json['cancel_penalties'] as Array<any>).map(CancelPenaltyFromJSON)),
        'nonrefundableDateRanges': json['nonrefundable_date_ranges'] == null ? undefined : ((json['nonrefundable_date_ranges'] as Array<any>).map(NonrefundableDateRangeFromJSON)),
        'occupancyPricing': json['occupancy_pricing'] == null ? undefined : (mapValues(json['occupancy_pricing'], PricingInformationFromJSON)),
        'promotions': json['promotions'] == null ? undefined : PromotionsFromJSON(json['promotions']),
        'cardOnFileLimit': json['card_on_file_limit'] == null ? undefined : AmountFromJSON(json['card_on_file_limit']),
        'refundableDamageDeposit': json['refundable_damage_deposit'] == null ? undefined : AmountFromJSON(json['refundable_damage_deposit']),
        'deposits': json['deposits'] == null ? undefined : ((json['deposits'] as Array<any>).map(DepositFromJSON)),
    };
}

export function RateToJSON(value?: Rate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'status': Status1ToJSON(value['status']),
        'available_rooms': value['availableRooms'],
        'refundable': value['refundable'],
        'member_deal_available': value['memberDealAvailable'],
        'sale_scenario': SaleScenarioToJSON(value['saleScenario']),
        'merchant_of_record': MerchantOfRecordToJSON(value['merchantOfRecord']),
        'amenities': value['amenities'] == null ? undefined : (mapValues(value['amenities'], AmenityToJSON)),
        'links': RateLinksToJSON(value['links']),
        'bed_groups': value['bedGroups'] == null ? undefined : (mapValues(value['bedGroups'], BedGroupAvailabilityToJSON)),
        'cancel_penalties': value['cancelPenalties'] == null ? undefined : ((value['cancelPenalties'] as Array<any>).map(CancelPenaltyToJSON)),
        'nonrefundable_date_ranges': value['nonrefundableDateRanges'] == null ? undefined : ((value['nonrefundableDateRanges'] as Array<any>).map(NonrefundableDateRangeToJSON)),
        'occupancy_pricing': value['occupancyPricing'] == null ? undefined : (mapValues(value['occupancyPricing'], PricingInformationToJSON)),
        'promotions': PromotionsToJSON(value['promotions']),
        'card_on_file_limit': AmountToJSON(value['cardOnFileLimit']),
        'refundable_damage_deposit': AmountToJSON(value['refundableDamageDeposit']),
        'deposits': value['deposits'] == null ? undefined : ((value['deposits'] as Array<any>).map(DepositToJSON)),
    };
}

