/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ValueAdd } from './ValueAdd';
import {
    ValueAddFromJSON,
    ValueAddFromJSONTyped,
    ValueAddToJSON,
} from './ValueAdd';

/**
 * Promotions that apply to the booked room.
 * @export
 * @interface PromotionsItinerary
 */
export interface PromotionsItinerary {
    /**
     * Promotions provided by the property that add value to the stay, but don’t affect the booking price (i.e., ski lift tickets or premium wifi).
     * @type {{ [key: string]: ValueAdd; }}
     * @memberof PromotionsItinerary
     */
    valueAdds?: { [key: string]: ValueAdd; };
}

/**
 * Check if a given object implements the PromotionsItinerary interface.
 */
export function instanceOfPromotionsItinerary(value: object): value is PromotionsItinerary {
    return true;
}

export function PromotionsItineraryFromJSON(json: any): PromotionsItinerary {
    return PromotionsItineraryFromJSONTyped(json, false);
}

export function PromotionsItineraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromotionsItinerary {
    if (json == null) {
        return json;
    }
    return {
        
        'valueAdds': json['value_adds'] == null ? undefined : (mapValues(json['value_adds'], ValueAddFromJSON)),
    };
}

export function PromotionsItineraryToJSON(value?: PromotionsItinerary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value_adds': value['valueAdds'] == null ? undefined : (mapValues(value['valueAdds'], ValueAddToJSON)),
    };
}

