/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StayType } from './StayType';
import {
    StayTypeFromJSON,
    StayTypeFromJSONTyped,
    StayTypeToJSON,
} from './StayType';

/**
 * 
 * @export
 * @interface Stay
 */
export interface Stay {
    /**
     * 
     * @type {StayType}
     * @memberof Stay
     */
    type?: StayType;
    /**
     * The value of the amount object. Decimal point inline with correct precision.
     * @type {string}
     * @memberof Stay
     */
    value?: string;
    /**
     * Currency of the amount object.
     * @type {string}
     * @memberof Stay
     */
    currency?: string;
}

/**
 * Check if a given object implements the Stay interface.
 */
export function instanceOfStay(value: object): value is Stay {
    return true;
}

export function StayFromJSON(json: any): Stay {
    return StayFromJSONTyped(json, false);
}

export function StayFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stay {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : StayTypeFromJSON(json['type']),
        'value': json['value'] == null ? undefined : json['value'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function StayToJSON(value?: Stay | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': StayTypeToJSON(value['type']),
        'value': value['value'],
        'currency': value['currency'],
    };
}

