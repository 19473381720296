/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelDetails } from './HotelDetails';
import {
    HotelDetailsFromJSON,
    HotelDetailsFromJSONTyped,
    HotelDetailsToJSON,
} from './HotelDetails';

/**
 * 
 * @export
 * @interface HotelDetailsResponse
 */
export interface HotelDetailsResponse {
    /**
     * 
     * @type {HotelDetails}
     * @memberof HotelDetailsResponse
     */
    hotelDetails: HotelDetails;
}

/**
 * Check if a given object implements the HotelDetailsResponse interface.
 */
export function instanceOfHotelDetailsResponse(value: object): value is HotelDetailsResponse {
    if (!('hotelDetails' in value) || value['hotelDetails'] === undefined) return false;
    return true;
}

export function HotelDetailsResponseFromJSON(json: any): HotelDetailsResponse {
    return HotelDetailsResponseFromJSONTyped(json, false);
}

export function HotelDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hotelDetails': HotelDetailsFromJSON(json['hotel_details']),
    };
}

export function HotelDetailsResponseToJSON(value?: HotelDetailsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotel_details': HotelDetailsToJSON(value['hotelDetails']),
    };
}

