/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdditionalRateResponse,
  Booking,
  BookingRequest,
  BookingStatuses,
  BookingStatusesRequest,
  HotelBookingResponse,
  HotelBookingResponseError,
  HotelBookingUpdateRequest,
  HotelBookingUpdateResponse,
  HotelDetailsResponse,
  HotelReview,
  HotelSearchRequest,
  HotelSearchResponse,
  HotelStatsRequest,
  HotelStatsResponse,
  LandingPageResponse,
  PaymentOption,
  RateValidationRequest,
  RateValidationResponse,
  RoomRate,
} from '../models/index';
import {
    AdditionalRateResponseFromJSON,
    AdditionalRateResponseToJSON,
    BookingFromJSON,
    BookingToJSON,
    BookingRequestFromJSON,
    BookingRequestToJSON,
    BookingStatusesFromJSON,
    BookingStatusesToJSON,
    BookingStatusesRequestFromJSON,
    BookingStatusesRequestToJSON,
    HotelBookingResponseFromJSON,
    HotelBookingResponseToJSON,
    HotelBookingResponseErrorFromJSON,
    HotelBookingResponseErrorToJSON,
    HotelBookingUpdateRequestFromJSON,
    HotelBookingUpdateRequestToJSON,
    HotelBookingUpdateResponseFromJSON,
    HotelBookingUpdateResponseToJSON,
    HotelDetailsResponseFromJSON,
    HotelDetailsResponseToJSON,
    HotelReviewFromJSON,
    HotelReviewToJSON,
    HotelSearchRequestFromJSON,
    HotelSearchRequestToJSON,
    HotelSearchResponseFromJSON,
    HotelSearchResponseToJSON,
    HotelStatsRequestFromJSON,
    HotelStatsRequestToJSON,
    HotelStatsResponseFromJSON,
    HotelStatsResponseToJSON,
    LandingPageResponseFromJSON,
    LandingPageResponseToJSON,
    PaymentOptionFromJSON,
    PaymentOptionToJSON,
    RateValidationRequestFromJSON,
    RateValidationRequestToJSON,
    RateValidationResponseFromJSON,
    RateValidationResponseToJSON,
    RoomRateFromJSON,
    RoomRateToJSON,
} from '../models/index';

export interface BookingBookingIdDeleteRequest {
    bookingId: string;
}

export interface BookingBookingIdGetRequest {
    bookingId: string;
}

export interface BookingBookingIdPutRequest {
    bookingId: string;
    hotelBookingUpdateRequest?: HotelBookingUpdateRequest;
}

export interface BookingsStatusesPostRequest {
    bookingStatusesRequest?: BookingStatusesRequest;
}

export interface HotelHotelIdDetailsGetRequest {
    hotelId: number;
    checkin: Date;
    checkout: Date;
    occupancy: string;
    userEnvironment: string;
}

export interface HotelHotelIdPaymentOptionsGetRequest {
    hotelId: string;
    availabilityToken: string;
}

export interface HotelHotelIdReviewsGetRequest {
    hotelId: string;
}

export interface HotelHotelIdRoomRoomIdRateRateIdGetRequest {
    hotelId: string;
    roomId: string;
    rateId: string;
    rateToken: string;
}

export interface HotelRateValidationPostRequest {
    rateValidationRequest?: RateValidationRequest;
}

export interface HotelStatsPostRequest {
    hotelStatsRequest?: HotelStatsRequest;
}

export interface SearchHotelsPostRequest {
    hotelSearchRequest: HotelSearchRequest;
}

export interface V2AdditionalRatesHotelIdGetRequest {
    hotelId: number;
    checkin: Date;
    checkout: Date;
    occupancy: string;
    userEnvironment: string;
}

export interface V2HotelHotelIdBookPostRequest {
    hotelId: string;
    bookingRequest?: BookingRequest;
}

/**
 * 
 */
export class HotelsApi extends runtime.BaseAPI {

    /**
     * Cancel a booking
     */
    async bookingBookingIdDeleteRaw(requestParameters: BookingBookingIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Booking>> {
        if (requestParameters['bookingId'] == null) {
            throw new runtime.RequiredError(
                'bookingId',
                'Required parameter "bookingId" was null or undefined when calling bookingBookingIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/booking/{booking_id}`.replace(`{${"booking_id"}}`, encodeURIComponent(String(requestParameters['bookingId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingFromJSON(jsonValue));
    }

    /**
     * Cancel a booking
     */
    async bookingBookingIdDelete(requestParameters: BookingBookingIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Booking> {
        const response = await this.bookingBookingIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a booking
     */
    async bookingBookingIdGetRaw(requestParameters: BookingBookingIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Booking>> {
        if (requestParameters['bookingId'] == null) {
            throw new runtime.RequiredError(
                'bookingId',
                'Required parameter "bookingId" was null or undefined when calling bookingBookingIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/booking/{booking_id}`.replace(`{${"booking_id"}}`, encodeURIComponent(String(requestParameters['bookingId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingFromJSON(jsonValue));
    }

    /**
     * Get a booking
     */
    async bookingBookingIdGet(requestParameters: BookingBookingIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Booking> {
        const response = await this.bookingBookingIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update booking.
     */
    async bookingBookingIdPutRaw(requestParameters: BookingBookingIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HotelBookingUpdateResponse>> {
        if (requestParameters['bookingId'] == null) {
            throw new runtime.RequiredError(
                'bookingId',
                'Required parameter "bookingId" was null or undefined when calling bookingBookingIdPut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/booking/{booking_id}`.replace(`{${"booking_id"}}`, encodeURIComponent(String(requestParameters['bookingId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HotelBookingUpdateRequestToJSON(requestParameters['hotelBookingUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HotelBookingUpdateResponseFromJSON(jsonValue));
    }

    /**
     * Update booking.
     */
    async bookingBookingIdPut(requestParameters: BookingBookingIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HotelBookingUpdateResponse> {
        const response = await this.bookingBookingIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get booking statuses
     */
    async bookingsStatusesPostRaw(requestParameters: BookingsStatusesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BookingStatuses>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/bookings/statuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookingStatusesRequestToJSON(requestParameters['bookingStatusesRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingStatusesFromJSON(jsonValue));
    }

    /**
     * Get booking statuses
     */
    async bookingsStatusesPost(requestParameters: BookingsStatusesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BookingStatuses> {
        const response = await this.bookingsStatusesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get details for a hotel.
     */
    async hotelHotelIdDetailsGetRaw(requestParameters: HotelHotelIdDetailsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HotelDetailsResponse>> {
        if (requestParameters['hotelId'] == null) {
            throw new runtime.RequiredError(
                'hotelId',
                'Required parameter "hotelId" was null or undefined when calling hotelHotelIdDetailsGet().'
            );
        }

        if (requestParameters['checkin'] == null) {
            throw new runtime.RequiredError(
                'checkin',
                'Required parameter "checkin" was null or undefined when calling hotelHotelIdDetailsGet().'
            );
        }

        if (requestParameters['checkout'] == null) {
            throw new runtime.RequiredError(
                'checkout',
                'Required parameter "checkout" was null or undefined when calling hotelHotelIdDetailsGet().'
            );
        }

        if (requestParameters['occupancy'] == null) {
            throw new runtime.RequiredError(
                'occupancy',
                'Required parameter "occupancy" was null or undefined when calling hotelHotelIdDetailsGet().'
            );
        }

        if (requestParameters['userEnvironment'] == null) {
            throw new runtime.RequiredError(
                'userEnvironment',
                'Required parameter "userEnvironment" was null or undefined when calling hotelHotelIdDetailsGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['checkin'] != null) {
            queryParameters['checkin'] = (requestParameters['checkin'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['checkout'] != null) {
            queryParameters['checkout'] = (requestParameters['checkout'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['occupancy'] != null) {
            queryParameters['occupancy'] = requestParameters['occupancy'];
        }

        if (requestParameters['userEnvironment'] != null) {
            queryParameters['user_environment'] = requestParameters['userEnvironment'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/hotel/{hotel_id}/details`.replace(`{${"hotel_id"}}`, encodeURIComponent(String(requestParameters['hotelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HotelDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Get details for a hotel.
     */
    async hotelHotelIdDetailsGet(requestParameters: HotelHotelIdDetailsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HotelDetailsResponse> {
        const response = await this.hotelHotelIdDetailsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get payment options for a property
     */
    async hotelHotelIdPaymentOptionsGetRaw(requestParameters: HotelHotelIdPaymentOptionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentOption>> {
        if (requestParameters['hotelId'] == null) {
            throw new runtime.RequiredError(
                'hotelId',
                'Required parameter "hotelId" was null or undefined when calling hotelHotelIdPaymentOptionsGet().'
            );
        }

        if (requestParameters['availabilityToken'] == null) {
            throw new runtime.RequiredError(
                'availabilityToken',
                'Required parameter "availabilityToken" was null or undefined when calling hotelHotelIdPaymentOptionsGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['availabilityToken'] != null) {
            queryParameters['availability_token'] = requestParameters['availabilityToken'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/hotel/{hotel_id}/payment_options`.replace(`{${"hotel_id"}}`, encodeURIComponent(String(requestParameters['hotelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentOptionFromJSON(jsonValue));
    }

    /**
     * Get payment options for a property
     */
    async hotelHotelIdPaymentOptionsGet(requestParameters: HotelHotelIdPaymentOptionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentOption> {
        const response = await this.hotelHotelIdPaymentOptionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get reviews for a property
     */
    async hotelHotelIdReviewsGetRaw(requestParameters: HotelHotelIdReviewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HotelReview>> {
        if (requestParameters['hotelId'] == null) {
            throw new runtime.RequiredError(
                'hotelId',
                'Required parameter "hotelId" was null or undefined when calling hotelHotelIdReviewsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/hotel/{hotel_id}/reviews`.replace(`{${"hotel_id"}}`, encodeURIComponent(String(requestParameters['hotelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HotelReviewFromJSON(jsonValue));
    }

    /**
     * Get reviews for a property
     */
    async hotelHotelIdReviewsGet(requestParameters: HotelHotelIdReviewsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HotelReview> {
        const response = await this.hotelHotelIdReviewsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run a price check for a room rate and return the booking link and refreshed pricing
     */
    async hotelHotelIdRoomRoomIdRateRateIdGetRaw(requestParameters: HotelHotelIdRoomRoomIdRateRateIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoomRate>> {
        if (requestParameters['hotelId'] == null) {
            throw new runtime.RequiredError(
                'hotelId',
                'Required parameter "hotelId" was null or undefined when calling hotelHotelIdRoomRoomIdRateRateIdGet().'
            );
        }

        if (requestParameters['roomId'] == null) {
            throw new runtime.RequiredError(
                'roomId',
                'Required parameter "roomId" was null or undefined when calling hotelHotelIdRoomRoomIdRateRateIdGet().'
            );
        }

        if (requestParameters['rateId'] == null) {
            throw new runtime.RequiredError(
                'rateId',
                'Required parameter "rateId" was null or undefined when calling hotelHotelIdRoomRoomIdRateRateIdGet().'
            );
        }

        if (requestParameters['rateToken'] == null) {
            throw new runtime.RequiredError(
                'rateToken',
                'Required parameter "rateToken" was null or undefined when calling hotelHotelIdRoomRoomIdRateRateIdGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['rateToken'] != null) {
            queryParameters['rate_token'] = requestParameters['rateToken'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/hotel/{hotel_id}/room/{room_id}/rate/{rate_id}`.replace(`{${"hotel_id"}}`, encodeURIComponent(String(requestParameters['hotelId']))).replace(`{${"room_id"}}`, encodeURIComponent(String(requestParameters['roomId']))).replace(`{${"rate_id"}}`, encodeURIComponent(String(requestParameters['rateId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoomRateFromJSON(jsonValue));
    }

    /**
     * Run a price check for a room rate and return the booking link and refreshed pricing
     */
    async hotelHotelIdRoomRoomIdRateRateIdGet(requestParameters: HotelHotelIdRoomRoomIdRateRateIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoomRate> {
        const response = await this.hotelHotelIdRoomRoomIdRateRateIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get properties to display on the landing page
     */
    async hotelLandingPageGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/hotel/landing_page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageResponseFromJSON(jsonValue));
    }

    /**
     * Get properties to display on the landing page
     */
    async hotelLandingPageGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageResponse> {
        const response = await this.hotelLandingPageGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Validate a rate before a booking is made with it
     */
    async hotelRateValidationPostRaw(requestParameters: HotelRateValidationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RateValidationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/hotel/rate_validation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RateValidationRequestToJSON(requestParameters['rateValidationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RateValidationResponseFromJSON(jsonValue));
    }

    /**
     * Validate a rate before a booking is made with it
     */
    async hotelRateValidationPost(requestParameters: HotelRateValidationPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RateValidationResponse> {
        const response = await this.hotelRateValidationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get stats for a hotel
     */
    async hotelStatsPostRaw(requestParameters: HotelStatsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HotelStatsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/hotel/stats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HotelStatsRequestToJSON(requestParameters['hotelStatsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HotelStatsResponseFromJSON(jsonValue));
    }

    /**
     * Get stats for a hotel
     */
    async hotelStatsPost(requestParameters: HotelStatsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HotelStatsResponse> {
        const response = await this.hotelStatsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list hotels and their availability
     */
    async searchHotelsPostRaw(requestParameters: SearchHotelsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HotelSearchResponse>> {
        if (requestParameters['hotelSearchRequest'] == null) {
            throw new runtime.RequiredError(
                'hotelSearchRequest',
                'Required parameter "hotelSearchRequest" was null or undefined when calling searchHotelsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/search/hotels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HotelSearchRequestToJSON(requestParameters['hotelSearchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HotelSearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list hotels and their availability
     */
    async searchHotelsPost(requestParameters: SearchHotelsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HotelSearchResponse> {
        const response = await this.searchHotelsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get additional rates
     */
    async v2AdditionalRatesHotelIdGetRaw(requestParameters: V2AdditionalRatesHotelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdditionalRateResponse>> {
        if (requestParameters['hotelId'] == null) {
            throw new runtime.RequiredError(
                'hotelId',
                'Required parameter "hotelId" was null or undefined when calling v2AdditionalRatesHotelIdGet().'
            );
        }

        if (requestParameters['checkin'] == null) {
            throw new runtime.RequiredError(
                'checkin',
                'Required parameter "checkin" was null or undefined when calling v2AdditionalRatesHotelIdGet().'
            );
        }

        if (requestParameters['checkout'] == null) {
            throw new runtime.RequiredError(
                'checkout',
                'Required parameter "checkout" was null or undefined when calling v2AdditionalRatesHotelIdGet().'
            );
        }

        if (requestParameters['occupancy'] == null) {
            throw new runtime.RequiredError(
                'occupancy',
                'Required parameter "occupancy" was null or undefined when calling v2AdditionalRatesHotelIdGet().'
            );
        }

        if (requestParameters['userEnvironment'] == null) {
            throw new runtime.RequiredError(
                'userEnvironment',
                'Required parameter "userEnvironment" was null or undefined when calling v2AdditionalRatesHotelIdGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['checkin'] != null) {
            queryParameters['checkin'] = (requestParameters['checkin'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['checkout'] != null) {
            queryParameters['checkout'] = (requestParameters['checkout'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['occupancy'] != null) {
            queryParameters['occupancy'] = requestParameters['occupancy'];
        }

        if (requestParameters['userEnvironment'] != null) {
            queryParameters['user_environment'] = requestParameters['userEnvironment'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/v2/additional_rates/{hotel_id}`.replace(`{${"hotel_id"}}`, encodeURIComponent(String(requestParameters['hotelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalRateResponseFromJSON(jsonValue));
    }

    /**
     * Get additional rates
     */
    async v2AdditionalRatesHotelIdGet(requestParameters: V2AdditionalRatesHotelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdditionalRateResponse> {
        const response = await this.v2AdditionalRatesHotelIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Book a room at a particular rate
     */
    async v2HotelHotelIdBookPostRaw(requestParameters: V2HotelHotelIdBookPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HotelBookingResponse>> {
        if (requestParameters['hotelId'] == null) {
            throw new runtime.RequiredError(
                'hotelId',
                'Required parameter "hotelId" was null or undefined when calling v2HotelHotelIdBookPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/v2/hotel/{hotel_id}/book`.replace(`{${"hotel_id"}}`, encodeURIComponent(String(requestParameters['hotelId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookingRequestToJSON(requestParameters['bookingRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HotelBookingResponseFromJSON(jsonValue));
    }

    /**
     * Book a room at a particular rate
     */
    async v2HotelHotelIdBookPost(requestParameters: V2HotelHotelIdBookPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HotelBookingResponse> {
        const response = await this.v2HotelHotelIdBookPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
