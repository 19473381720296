/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ServiceMetadataForABaggage } from './ServiceMetadataForABaggage';
import {
    instanceOfServiceMetadataForABaggage,
    ServiceMetadataForABaggageFromJSON,
    ServiceMetadataForABaggageFromJSONTyped,
    ServiceMetadataForABaggageToJSON,
} from './ServiceMetadataForABaggage';
import type { ServiceMetadataForAMeal } from './ServiceMetadataForAMeal';
import {
    instanceOfServiceMetadataForAMeal,
    ServiceMetadataForAMealFromJSON,
    ServiceMetadataForAMealFromJSONTyped,
    ServiceMetadataForAMealToJSON,
} from './ServiceMetadataForAMeal';
import type { ServiceMetadataForASeat } from './ServiceMetadataForASeat';
import {
    instanceOfServiceMetadataForASeat,
    ServiceMetadataForASeatFromJSON,
    ServiceMetadataForASeatFromJSONTyped,
    ServiceMetadataForASeatToJSON,
} from './ServiceMetadataForASeat';
import type { ServiceMetadataForCancelForAnyReason } from './ServiceMetadataForCancelForAnyReason';
import {
    instanceOfServiceMetadataForCancelForAnyReason,
    ServiceMetadataForCancelForAnyReasonFromJSON,
    ServiceMetadataForCancelForAnyReasonFromJSONTyped,
    ServiceMetadataForCancelForAnyReasonToJSON,
} from './ServiceMetadataForCancelForAnyReason';

/**
 * @type OfferServiceMetadata
 * The metadata varies by the type of service. It includes further data
 * about the service. For example, for baggages, it may have data about
 * size and weight restrictions.
 * 
 * @export
 */
export type OfferServiceMetadata = ServiceMetadataForABaggage | ServiceMetadataForAMeal | ServiceMetadataForASeat | ServiceMetadataForCancelForAnyReason;

export function OfferServiceMetadataFromJSON(json: any): OfferServiceMetadata {
    return OfferServiceMetadataFromJSONTyped(json, false);
}

export function OfferServiceMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferServiceMetadata {
    if (json == null) {
        return json;
    }
    return ServiceMetadataForABaggageFromJSONTyped(json, true) || ServiceMetadataForAMealFromJSONTyped(json, true) || ServiceMetadataForASeatFromJSONTyped(json, true) || ServiceMetadataForCancelForAnyReasonFromJSONTyped(json, true);
}

export function OfferServiceMetadataToJSON(value?: OfferServiceMetadata | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfServiceMetadataForABaggage(value)) {
        return ServiceMetadataForABaggageToJSON(value as ServiceMetadataForABaggage);
    }
    if (instanceOfServiceMetadataForAMeal(value)) {
        return ServiceMetadataForAMealToJSON(value as ServiceMetadataForAMeal);
    }
    if (instanceOfServiceMetadataForASeat(value)) {
        return ServiceMetadataForASeatToJSON(value as ServiceMetadataForASeat);
    }
    if (instanceOfServiceMetadataForCancelForAnyReason(value)) {
        return ServiceMetadataForCancelForAnyReasonToJSON(value as ServiceMetadataForCancelForAnyReason);
    }

    return {};
}
