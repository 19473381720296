/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The price breakout type.
 * @export
 */
export const StayType = {
    BaseRate: 'base_rate',
    TaxAndServiceFee: 'tax_and_service_fee',
    ExtraPersonFee: 'extra_person_fee',
    PropertyFee: 'property_fee',
    SalesTax: 'sales_tax',
    Adjustment: 'adjustment',
    RecoveryChargesAndFees: 'recovery_charges_and_fees',
    TravelerServiceFee: 'traveler_service_fee'
} as const;
export type StayType = typeof StayType[keyof typeof StayType];


export function instanceOfStayType(value: any): boolean {
    for (const key in StayType) {
        if (Object.prototype.hasOwnProperty.call(StayType, key)) {
            if ((StayType as Record<string, StayType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StayTypeFromJSON(json: any): StayType {
    return StayTypeFromJSONTyped(json, false);
}

export function StayTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StayType {
    return json as StayType;
}

export function StayTypeToJSON(value?: StayType | null): any {
    return value as any;
}
