/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Airport,
  AirportRequest,
  FlightRouteRequest,
  FlightRoutes,
} from '../models/index';
import {
    AirportFromJSON,
    AirportToJSON,
    AirportRequestFromJSON,
    AirportRequestToJSON,
    FlightRouteRequestFromJSON,
    FlightRouteRequestToJSON,
    FlightRoutesFromJSON,
    FlightRoutesToJSON,
} from '../models/index';

export interface SearchAirportsPostRequest {
    airportRequest: AirportRequest;
}

export interface SearchFlightRoutesPostRequest {
    flightRouteRequest: FlightRouteRequest;
}

/**
 * 
 */
export class StaticContentApi extends runtime.BaseAPI {

    /**
     * Returns a list of airports matching the given query
     */
    async searchAirportsPostRaw(requestParameters: SearchAirportsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Airport>>> {
        if (requestParameters['airportRequest'] == null) {
            throw new runtime.RequiredError(
                'airportRequest',
                'Required parameter "airportRequest" was null or undefined when calling searchAirportsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/search/airports`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AirportRequestToJSON(requestParameters['airportRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AirportFromJSON));
    }

    /**
     * Returns a list of airports matching the given query
     */
    async searchAirportsPost(requestParameters: SearchAirportsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Airport>> {
        const response = await this.searchAirportsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of flight routes matching the given query
     */
    async searchFlightRoutesPostRaw(requestParameters: SearchFlightRoutesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FlightRoutes>>> {
        if (requestParameters['flightRouteRequest'] == null) {
            throw new runtime.RequiredError(
                'flightRouteRequest',
                'Required parameter "flightRouteRequest" was null or undefined when calling searchFlightRoutesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/search/flight_routes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FlightRouteRequestToJSON(requestParameters['flightRouteRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FlightRoutesFromJSON));
    }

    /**
     * Returns a list of flight routes matching the given query
     */
    async searchFlightRoutesPost(requestParameters: SearchFlightRoutesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FlightRoutes>> {
        const response = await this.searchFlightRoutesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
