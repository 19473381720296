/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelSearchRequest } from './HotelSearchRequest';
import {
    HotelSearchRequestFromJSON,
    HotelSearchRequestFromJSONTyped,
    HotelSearchRequestToJSON,
} from './HotelSearchRequest';
import type { Hotel } from './Hotel';
import {
    HotelFromJSON,
    HotelFromJSONTyped,
    HotelToJSON,
} from './Hotel';

/**
 * The response for a hotel search
 * @export
 * @interface HotelSearchResponse
 */
export interface HotelSearchResponse {
    /**
     * 
     * @type {Array<Hotel>}
     * @memberof HotelSearchResponse
     */
    items?: Array<Hotel>;
    /**
     * 
     * @type {HotelSearchRequest}
     * @memberof HotelSearchResponse
     */
    request: HotelSearchRequest;
    /**
     * 
     * @type {Error}
     * @memberof HotelSearchResponse
     */
    error?: Error;
}

/**
 * Check if a given object implements the HotelSearchResponse interface.
 */
export function instanceOfHotelSearchResponse(value: object): value is HotelSearchResponse {
    if (!('request' in value) || value['request'] === undefined) return false;
    return true;
}

export function HotelSearchResponseFromJSON(json: any): HotelSearchResponse {
    return HotelSearchResponseFromJSONTyped(json, false);
}

export function HotelSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelSearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(HotelFromJSON)),
        'request': HotelSearchRequestFromJSON(json['request']),
        'error': json['error'] == null ? undefined : json['error'],
    };
}

export function HotelSearchResponseToJSON(value?: HotelSearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(HotelToJSON)),
        'request': HotelSearchRequestToJSON(value['request']),
        'error': value['error'],
    };
}

