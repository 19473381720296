/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CheckinInstructions } from './CheckinInstructions';
import {
    CheckinInstructionsFromJSON,
    CheckinInstructionsFromJSONTyped,
    CheckinInstructionsToJSON,
} from './CheckinInstructions';
import type { Policy } from './Policy';
import {
    PolicyFromJSON,
    PolicyFromJSONTyped,
    PolicyToJSON,
} from './Policy';
import type { CheckoutInstructions } from './CheckoutInstructions';
import {
    CheckoutInstructionsFromJSON,
    CheckoutInstructionsFromJSONTyped,
    CheckoutInstructionsToJSON,
} from './CheckoutInstructions';
import type { PropertyFee } from './PropertyFee';
import {
    PropertyFeeFromJSON,
    PropertyFeeFromJSONTyped,
    PropertyFeeToJSON,
} from './PropertyFee';
import type { HotelSummary } from './HotelSummary';
import {
    HotelSummaryFromJSON,
    HotelSummaryFromJSONTyped,
    HotelSummaryToJSON,
} from './HotelSummary';
import type { RoomSummary } from './RoomSummary';
import {
    RoomSummaryFromJSON,
    RoomSummaryFromJSONTyped,
    RoomSummaryToJSON,
} from './RoomSummary';

/**
 * Hotel details
 * @export
 * @interface HotelDetails
 */
export interface HotelDetails {
    /**
     * 
     * @type {HotelSummary}
     * @memberof HotelDetails
     */
    hotelSummary: HotelSummary;
    /**
     * 
     * @type {Array<RoomSummary>}
     * @memberof HotelDetails
     */
    roomSummaries?: Array<RoomSummary>;
    /**
     * 
     * @type {CheckinInstructions}
     * @memberof HotelDetails
     */
    checkinInstructions: CheckinInstructions;
    /**
     * 
     * @type {CheckoutInstructions}
     * @memberof HotelDetails
     */
    checkoutInstructions: CheckoutInstructions;
    /**
     * 
     * @type {string}
     * @memberof HotelDetails
     */
    mandatoryFeeInformation?: string;
    /**
     * 
     * @type {Array<Policy>}
     * @memberof HotelDetails
     */
    policies: Array<Policy>;
    /**
     * 
     * @type {Array<PropertyFee>}
     * @memberof HotelDetails
     */
    propertyFees?: Array<PropertyFee>;
    /**
     * The token to pass to the additional rates route
     * @type {string}
     * @memberof HotelDetails
     */
    additionalRateToken?: string;
}

/**
 * Check if a given object implements the HotelDetails interface.
 */
export function instanceOfHotelDetails(value: object): value is HotelDetails {
    if (!('hotelSummary' in value) || value['hotelSummary'] === undefined) return false;
    if (!('checkinInstructions' in value) || value['checkinInstructions'] === undefined) return false;
    if (!('checkoutInstructions' in value) || value['checkoutInstructions'] === undefined) return false;
    if (!('policies' in value) || value['policies'] === undefined) return false;
    return true;
}

export function HotelDetailsFromJSON(json: any): HotelDetails {
    return HotelDetailsFromJSONTyped(json, false);
}

export function HotelDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'hotelSummary': HotelSummaryFromJSON(json['hotel_summary']),
        'roomSummaries': json['room_summaries'] == null ? undefined : ((json['room_summaries'] as Array<any>).map(RoomSummaryFromJSON)),
        'checkinInstructions': CheckinInstructionsFromJSON(json['checkin_instructions']),
        'checkoutInstructions': CheckoutInstructionsFromJSON(json['checkout_instructions']),
        'mandatoryFeeInformation': json['mandatory_fee_information'] == null ? undefined : json['mandatory_fee_information'],
        'policies': ((json['policies'] as Array<any>).map(PolicyFromJSON)),
        'propertyFees': json['property_fees'] == null ? undefined : ((json['property_fees'] as Array<any>).map(PropertyFeeFromJSON)),
        'additionalRateToken': json['additional_rate_token'] == null ? undefined : json['additional_rate_token'],
    };
}

export function HotelDetailsToJSON(value?: HotelDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotel_summary': HotelSummaryToJSON(value['hotelSummary']),
        'room_summaries': value['roomSummaries'] == null ? undefined : ((value['roomSummaries'] as Array<any>).map(RoomSummaryToJSON)),
        'checkin_instructions': CheckinInstructionsToJSON(value['checkinInstructions']),
        'checkout_instructions': CheckoutInstructionsToJSON(value['checkoutInstructions']),
        'mandatory_fee_information': value['mandatoryFeeInformation'],
        'policies': ((value['policies'] as Array<any>).map(PolicyToJSON)),
        'property_fees': value['propertyFees'] == null ? undefined : ((value['propertyFees'] as Array<any>).map(PropertyFeeToJSON)),
        'additional_rate_token': value['additionalRateToken'],
    };
}

