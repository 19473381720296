/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The property's check-out information.
 * @export
 * @interface HotelContentCheckout
 */
export interface HotelContentCheckout {
    /**
     * The time by which a guest must check out.
     * @type {string}
     * @memberof HotelContentCheckout
     */
    time?: string;
}

/**
 * Check if a given object implements the HotelContentCheckout interface.
 */
export function instanceOfHotelContentCheckout(value: object): value is HotelContentCheckout {
    return true;
}

export function HotelContentCheckoutFromJSON(json: any): HotelContentCheckout {
    return HotelContentCheckoutFromJSONTyped(json, false);
}

export function HotelContentCheckoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentCheckout {
    if (json == null) {
        return json;
    }
    return {
        
        'time': json['Time'] == null ? undefined : json['Time'],
    };
}

export function HotelContentCheckoutToJSON(value?: HotelContentCheckout | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Time': value['time'],
    };
}

