/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConfirmationId } from './ConfirmationId';
import {
    ConfirmationIdFromJSON,
    ConfirmationIdFromJSONTyped,
    ConfirmationIdToJSON,
} from './ConfirmationId';
import type { StatusItinerary } from './StatusItinerary';
import {
    StatusItineraryFromJSON,
    StatusItineraryFromJSONTyped,
    StatusItineraryToJSON,
} from './StatusItinerary';
import type { RoomItineraryLinks } from './RoomItineraryLinks';
import {
    RoomItineraryLinksFromJSON,
    RoomItineraryLinksFromJSONTyped,
    RoomItineraryLinksToJSON,
} from './RoomItineraryLinks';
import type { RateItinerary } from './RateItinerary';
import {
    RateItineraryFromJSON,
    RateItineraryFromJSONTyped,
    RateItineraryToJSON,
} from './RateItinerary';

/**
 * The room information.
 * @export
 * @interface RoomItinerary
 */
export interface RoomItinerary {
    /**
     * The room id.
     * @type {string}
     * @memberof RoomItinerary
     */
    id?: string;
    /**
     * 
     * @type {ConfirmationId}
     * @memberof RoomItinerary
     */
    confirmationId?: ConfirmationId;
    /**
     * Unique identifier for a bed type.
     * @type {string}
     * @memberof RoomItinerary
     */
    bedGroupId?: string;
    /**
     * The check-in date of the itinerary.
     * @type {string}
     * @memberof RoomItinerary
     */
    checkin?: string;
    /**
     * The check-out date of the itinerary.
     * @type {string}
     * @memberof RoomItinerary
     */
    checkout?: string;
    /**
     * The number of adults staying in the room.
     * @type {number}
     * @memberof RoomItinerary
     */
    numberOfAdults?: number;
    /**
     * The ages of children for the room.
     * @type {Array<number>}
     * @memberof RoomItinerary
     */
    childAges?: Array<number>;
    /**
     * The first name of the main guest staying in the room.
     * @type {string}
     * @memberof RoomItinerary
     */
    givenName?: string;
    /**
     * The last name of the main guest staying in the room.
     * @type {string}
     * @memberof RoomItinerary
     */
    familyName?: string;
    /**
     * 
     * @type {StatusItinerary}
     * @memberof RoomItinerary
     */
    status?: StatusItinerary;
    /**
     * Any special request info associated with the room.
     * @type {string}
     * @memberof RoomItinerary
     */
    specialRequest?: string;
    /**
     * Indicates if the room is smoking or non-smoking.
     * @type {boolean}
     * @memberof RoomItinerary
     */
    smoking?: boolean;
    /**
     * A loyalty identifier for a hotel loyalty program associated with this room guest.
     * @type {string}
     * @memberof RoomItinerary
     */
    loyaltyId?: string;
    /**
     * 
     * @type {RateItinerary}
     * @memberof RoomItinerary
     */
    rate?: RateItinerary;
    /**
     * 
     * @type {RoomItineraryLinks}
     * @memberof RoomItinerary
     */
    links?: RoomItineraryLinks;
}

/**
 * Check if a given object implements the RoomItinerary interface.
 */
export function instanceOfRoomItinerary(value: object): value is RoomItinerary {
    return true;
}

export function RoomItineraryFromJSON(json: any): RoomItinerary {
    return RoomItineraryFromJSONTyped(json, false);
}

export function RoomItineraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomItinerary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'confirmationId': json['confirmation_id'] == null ? undefined : ConfirmationIdFromJSON(json['confirmation_id']),
        'bedGroupId': json['bed_group_id'] == null ? undefined : json['bed_group_id'],
        'checkin': json['checkin'] == null ? undefined : json['checkin'],
        'checkout': json['checkout'] == null ? undefined : json['checkout'],
        'numberOfAdults': json['number_of_adults'] == null ? undefined : json['number_of_adults'],
        'childAges': json['child_ages'] == null ? undefined : json['child_ages'],
        'givenName': json['given_name'] == null ? undefined : json['given_name'],
        'familyName': json['family_name'] == null ? undefined : json['family_name'],
        'status': json['status'] == null ? undefined : StatusItineraryFromJSON(json['status']),
        'specialRequest': json['special_request'] == null ? undefined : json['special_request'],
        'smoking': json['smoking'] == null ? undefined : json['smoking'],
        'loyaltyId': json['loyalty_id'] == null ? undefined : json['loyalty_id'],
        'rate': json['rate'] == null ? undefined : RateItineraryFromJSON(json['rate']),
        'links': json['links'] == null ? undefined : RoomItineraryLinksFromJSON(json['links']),
    };
}

export function RoomItineraryToJSON(value?: RoomItinerary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'confirmation_id': ConfirmationIdToJSON(value['confirmationId']),
        'bed_group_id': value['bedGroupId'],
        'checkin': value['checkin'],
        'checkout': value['checkout'],
        'number_of_adults': value['numberOfAdults'],
        'child_ages': value['childAges'],
        'given_name': value['givenName'],
        'family_name': value['familyName'],
        'status': StatusItineraryToJSON(value['status']),
        'special_request': value['specialRequest'],
        'smoking': value['smoking'],
        'loyalty_id': value['loyaltyId'],
        'rate': RateItineraryToJSON(value['rate']),
        'links': RoomItineraryLinksToJSON(value['links']),
    };
}

