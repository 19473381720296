/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HotelContentAttributesInner
 */
export interface HotelContentAttributesInner {
    /**
     * Key of the attribute.
     * @type {string}
     * @memberof HotelContentAttributesInner
     */
    key?: string;
    /**
     * Description of the attribute.
     * @type {string}
     * @memberof HotelContentAttributesInner
     */
    desc?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelContentAttributesInner
     */
    value?: string;
}

/**
 * Check if a given object implements the HotelContentAttributesInner interface.
 */
export function instanceOfHotelContentAttributesInner(value: object): value is HotelContentAttributesInner {
    return true;
}

export function HotelContentAttributesInnerFromJSON(json: any): HotelContentAttributesInner {
    return HotelContentAttributesInnerFromJSONTyped(json, false);
}

export function HotelContentAttributesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentAttributesInner {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['Key'] == null ? undefined : json['Key'],
        'desc': json['Desc'] == null ? undefined : json['Desc'],
        'value': json['Value'] == null ? undefined : json['Value'],
    };
}

export function HotelContentAttributesInnerToJSON(value?: HotelContentAttributesInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Key': value['key'],
        'Desc': value['desc'],
        'Value': value['value'],
    };
}

