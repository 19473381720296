/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelContent } from './HotelContent';
import {
    HotelContentFromJSON,
    HotelContentFromJSONTyped,
    HotelContentToJSON,
} from './HotelContent';
import type { PointCost } from './PointCost';
import {
    PointCostFromJSON,
    PointCostFromJSONTyped,
    PointCostToJSON,
} from './PointCost';
import type { HotelSummary } from './HotelSummary';
import {
    HotelSummaryFromJSON,
    HotelSummaryFromJSONTyped,
    HotelSummaryToJSON,
} from './HotelSummary';
import type { RateSummary } from './RateSummary';
import {
    RateSummaryFromJSON,
    RateSummaryFromJSONTyped,
    RateSummaryToJSON,
} from './RateSummary';
import type { CancellationPolicySummary } from './CancellationPolicySummary';
import {
    CancellationPolicySummaryFromJSON,
    CancellationPolicySummaryFromJSONTyped,
    CancellationPolicySummaryToJSON,
} from './CancellationPolicySummary';
import type { PropertyAvailability } from './PropertyAvailability';
import {
    PropertyAvailabilityFromJSON,
    PropertyAvailabilityFromJSONTyped,
    PropertyAvailabilityToJSON,
} from './PropertyAvailability';

/**
 * A single Hotel
 * @export
 * @interface Hotel
 */
export interface Hotel {
    /**
     * 
     * @type {number}
     * @memberof Hotel
     */
    vervotechPropertyId: number;
    /**
     * 
     * @type {number}
     * @memberof Hotel
     */
    providerPropertyId: number;
    /**
     * 
     * @type {string}
     * @memberof Hotel
     */
    supplierId?: string;
    /**
     * 
     * @type {HotelSummary}
     * @memberof Hotel
     */
    summary: HotelSummary;
    /**
     * 
     * @type {PropertyAvailability}
     * @memberof Hotel
     */
    availability?: PropertyAvailability;
    /**
     * 
     * @type {HotelContent}
     * @memberof Hotel
     */
    content: HotelContent;
    /**
     * 
     * @type {Array<PointCost>}
     * @memberof Hotel
     */
    points?: Array<PointCost>;
    /**
     * 
     * @type {Array<RateSummary>}
     * @memberof Hotel
     */
    ratesSummary?: Array<RateSummary>;
    /**
     * 
     * @type {Array<CancellationPolicySummary>}
     * @memberof Hotel
     */
    cancellationPoliciesSummary?: Array<CancellationPolicySummary>;
}

/**
 * Check if a given object implements the Hotel interface.
 */
export function instanceOfHotel(value: object): value is Hotel {
    if (!('vervotechPropertyId' in value) || value['vervotechPropertyId'] === undefined) return false;
    if (!('providerPropertyId' in value) || value['providerPropertyId'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function HotelFromJSON(json: any): Hotel {
    return HotelFromJSONTyped(json, false);
}

export function HotelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hotel {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyId': json['vervotech_property_id'],
        'providerPropertyId': json['provider_property_id'],
        'supplierId': json['supplier_id'] == null ? undefined : json['supplier_id'],
        'summary': HotelSummaryFromJSON(json['summary']),
        'availability': json['availability'] == null ? undefined : PropertyAvailabilityFromJSON(json['availability']),
        'content': HotelContentFromJSON(json['content']),
        'points': json['points'] == null ? undefined : ((json['points'] as Array<any>).map(PointCostFromJSON)),
        'ratesSummary': json['rates_summary'] == null ? undefined : ((json['rates_summary'] as Array<any>).map(RateSummaryFromJSON)),
        'cancellationPoliciesSummary': json['cancellation_policies_summary'] == null ? undefined : ((json['cancellation_policies_summary'] as Array<any>).map(CancellationPolicySummaryFromJSON)),
    };
}

export function HotelToJSON(value?: Hotel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_id': value['vervotechPropertyId'],
        'provider_property_id': value['providerPropertyId'],
        'supplier_id': value['supplierId'],
        'summary': HotelSummaryToJSON(value['summary']),
        'availability': PropertyAvailabilityToJSON(value['availability']),
        'content': HotelContentToJSON(value['content']),
        'points': value['points'] == null ? undefined : ((value['points'] as Array<any>).map(PointCostToJSON)),
        'rates_summary': value['ratesSummary'] == null ? undefined : ((value['ratesSummary'] as Array<any>).map(RateSummaryToJSON)),
        'cancellation_policies_summary': value['cancellationPoliciesSummary'] == null ? undefined : ((value['cancellationPoliciesSummary'] as Array<any>).map(CancellationPolicySummaryToJSON)),
    };
}

