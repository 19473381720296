/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TraderAddress } from './TraderAddress';
import {
    TraderAddressFromJSON,
    TraderAddressFromJSONTyped,
    TraderAddressToJSON,
} from './TraderAddress';

/**
 * Information of the professional entity that sells the property inventory or related services.
 * @export
 * @interface TraderDetailsInner
 */
export interface TraderDetailsInner {
    /**
     * The trader name.
     * @type {string}
     * @memberof TraderDetailsInner
     */
    name?: string;
    /**
     * 
     * @type {TraderAddress}
     * @memberof TraderDetailsInner
     */
    address?: TraderAddress;
    /**
     * Name of the register where the trader is registered, and is related to the `business_register_number`<br>
     * <i><b>NOTE:</b> See our [Digital Services Act](https://developers.expediagroup.com/docs/rapid/resources/reference/digital-services-act)
     * information on Developer Hub.</i>
     * 
     * @type {string}
     * @memberof TraderDetailsInner
     */
    businessRegisterName?: string;
    /**
     * Business registration number<br>
     * <i><b>NOTE:</b> See our [Digital Services Act](https://developers.expediagroup.com/docs/rapid/resources/reference/digital-services-act)
     * information on Developer Hub.</i>
     * 
     * @type {string}
     * @memberof TraderDetailsInner
     */
    businessRegisterNumber?: string;
    /**
     * Certification that the trader has confirmed their commitment to only offer products or services that
     * comply with the applicable rules of Union law.<br>
     * <i><b>NOTE:</b> See our [Digital Services Act](https://developers.expediagroup.com/docs/rapid/resources/reference/digital-services-act)
     * information on Developer Hub.</i>
     * 
     * @type {boolean}
     * @memberof TraderDetailsInner
     */
    selfCertification?: boolean;
    /**
     * The trader contact message.
     * @type {string}
     * @memberof TraderDetailsInner
     */
    contactMessage: string;
    /**
     * The trader right to withdraw message.
     * @type {string}
     * @memberof TraderDetailsInner
     */
    rightToWithdrawMessage?: string;
    /**
     * The trader email address.
     * @type {string}
     * @memberof TraderDetailsInner
     */
    email?: string;
    /**
     * The trader phone number.
     * @type {string}
     * @memberof TraderDetailsInner
     */
    phone?: string;
}

/**
 * Check if a given object implements the TraderDetailsInner interface.
 */
export function instanceOfTraderDetailsInner(value: object): value is TraderDetailsInner {
    if (!('contactMessage' in value) || value['contactMessage'] === undefined) return false;
    return true;
}

export function TraderDetailsInnerFromJSON(json: any): TraderDetailsInner {
    return TraderDetailsInnerFromJSONTyped(json, false);
}

export function TraderDetailsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TraderDetailsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'address': json['address'] == null ? undefined : TraderAddressFromJSON(json['address']),
        'businessRegisterName': json['business_register_name'] == null ? undefined : json['business_register_name'],
        'businessRegisterNumber': json['business_register_number'] == null ? undefined : json['business_register_number'],
        'selfCertification': json['self_certification'] == null ? undefined : json['self_certification'],
        'contactMessage': json['contact_message'],
        'rightToWithdrawMessage': json['right_to_withdraw_message'] == null ? undefined : json['right_to_withdraw_message'],
        'email': json['email'] == null ? undefined : json['email'],
        'phone': json['phone'] == null ? undefined : json['phone'],
    };
}

export function TraderDetailsInnerToJSON(value?: TraderDetailsInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'address': TraderAddressToJSON(value['address']),
        'business_register_name': value['businessRegisterName'],
        'business_register_number': value['businessRegisterNumber'],
        'self_certification': value['selfCertification'],
        'contact_message': value['contactMessage'],
        'right_to_withdraw_message': value['rightToWithdrawMessage'],
        'email': value['email'],
        'phone': value['phone'],
    };
}

