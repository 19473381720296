/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Deposit } from './Deposit';
import {
    DepositFromJSON,
    DepositFromJSONTyped,
    DepositToJSON,
} from './Deposit';
import type { PricingInformation } from './PricingInformation';
import {
    PricingInformationFromJSON,
    PricingInformationFromJSONTyped,
    PricingInformationToJSON,
} from './PricingInformation';
import type { StatusPriceCheck } from './StatusPriceCheck';
import {
    StatusPriceCheckFromJSON,
    StatusPriceCheckFromJSONTyped,
    StatusPriceCheckToJSON,
} from './StatusPriceCheck';
import type { Amount } from './Amount';
import {
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import type { Charge } from './Charge';
import {
    ChargeFromJSON,
    ChargeFromJSONTyped,
    ChargeToJSON,
} from './Charge';
import type { TraderInformation } from './TraderInformation';
import {
    TraderInformationFromJSON,
    TraderInformationFromJSONTyped,
    TraderInformationToJSON,
} from './TraderInformation';
import type { RoomPriceCheckLinks } from './RoomPriceCheckLinks';
import {
    RoomPriceCheckLinksFromJSON,
    RoomPriceCheckLinksFromJSONTyped,
    RoomPriceCheckLinksToJSON,
} from './RoomPriceCheckLinks';

/**
 * The price check response.
 * @export
 * @interface RoomPriceCheck
 */
export interface RoomPriceCheck {
    /**
     * 
     * @type {StatusPriceCheck}
     * @memberof RoomPriceCheck
     */
    status?: StatusPriceCheck;
    /**
     * A map of room information by occupancy.
     * @type {{ [key: string]: PricingInformation; }}
     * @memberof RoomPriceCheck
     */
    occupancyPricing?: { [key: string]: PricingInformation; };
    /**
     * 
     * @type {RoomPriceCheckLinks}
     * @memberof RoomPriceCheck
     */
    links?: RoomPriceCheckLinks;
    /**
     * 
     * @type {Amount}
     * @memberof RoomPriceCheck
     */
    cardOnFileLimit?: Amount;
    /**
     * 
     * @type {Amount}
     * @memberof RoomPriceCheck
     */
    refundableDamageDeposit?: Amount;
    /**
     * Array of deposits.
     * @type {Array<Deposit>}
     * @memberof RoomPriceCheck
     */
    deposits?: Array<Deposit>;
    /**
     * 
     * @type {Charge}
     * @memberof RoomPriceCheck
     */
    refund?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof RoomPriceCheck
     */
    amountOwed?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof RoomPriceCheck
     */
    penalty?: Charge;
    /**
     * 
     * @type {TraderInformation}
     * @memberof RoomPriceCheck
     */
    traderInformation?: TraderInformation;
}

/**
 * Check if a given object implements the RoomPriceCheck interface.
 */
export function instanceOfRoomPriceCheck(value: object): value is RoomPriceCheck {
    return true;
}

export function RoomPriceCheckFromJSON(json: any): RoomPriceCheck {
    return RoomPriceCheckFromJSONTyped(json, false);
}

export function RoomPriceCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomPriceCheck {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : StatusPriceCheckFromJSON(json['status']),
        'occupancyPricing': json['occupancy_pricing'] == null ? undefined : (mapValues(json['occupancy_pricing'], PricingInformationFromJSON)),
        'links': json['links'] == null ? undefined : RoomPriceCheckLinksFromJSON(json['links']),
        'cardOnFileLimit': json['card_on_file_limit'] == null ? undefined : AmountFromJSON(json['card_on_file_limit']),
        'refundableDamageDeposit': json['refundable_damage_deposit'] == null ? undefined : AmountFromJSON(json['refundable_damage_deposit']),
        'deposits': json['deposits'] == null ? undefined : ((json['deposits'] as Array<any>).map(DepositFromJSON)),
        'refund': json['refund'] == null ? undefined : ChargeFromJSON(json['refund']),
        'amountOwed': json['amount_owed'] == null ? undefined : ChargeFromJSON(json['amount_owed']),
        'penalty': json['penalty'] == null ? undefined : ChargeFromJSON(json['penalty']),
        'traderInformation': json['trader_information'] == null ? undefined : TraderInformationFromJSON(json['trader_information']),
    };
}

export function RoomPriceCheckToJSON(value?: RoomPriceCheck | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': StatusPriceCheckToJSON(value['status']),
        'occupancy_pricing': value['occupancyPricing'] == null ? undefined : (mapValues(value['occupancyPricing'], PricingInformationToJSON)),
        'links': RoomPriceCheckLinksToJSON(value['links']),
        'card_on_file_limit': AmountToJSON(value['cardOnFileLimit']),
        'refundable_damage_deposit': AmountToJSON(value['refundableDamageDeposit']),
        'deposits': value['deposits'] == null ? undefined : ((value['deposits'] as Array<any>).map(DepositToJSON)),
        'refund': ChargeToJSON(value['refund']),
        'amount_owed': ChargeToJSON(value['amountOwed']),
        'penalty': ChargeToJSON(value['penalty']),
        'trader_information': TraderInformationToJSON(value['traderInformation']),
    };
}

