/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferSliceSegmentMarketingCarrier
 */
export interface OfferSliceSegmentMarketingCarrier {
    /**
     * URL to the airline's conditions of carriage.
     * @type {string}
     * @memberof OfferSliceSegmentMarketingCarrier
     */
    conditionsOfCarriageUrl?: string;
    /**
     * The two-character IATA code for the airline. This may be `null` for non-IATA carriers.
     * @type {string}
     * @memberof OfferSliceSegmentMarketingCarrier
     */
    iataCode?: string;
    /**
     * Duffel's unique identifier for the airline
     * @type {string}
     * @memberof OfferSliceSegmentMarketingCarrier
     */
    id?: string;
    /**
     * Path to a svg of the airline lockup logo.
     * A lockup logo is also called a combination logo, in which it combines the logotype and logomark.
     * This may be `null` if no logo is available.
     * @type {string}
     * @memberof OfferSliceSegmentMarketingCarrier
     */
    logoLockupUrl?: string;
    /**
     * Path to a svg of the airline logo.
     * This may be `null` if no logo is available.
     * @type {string}
     * @memberof OfferSliceSegmentMarketingCarrier
     */
    logoSymbolUrl?: string;
    /**
     * The name of the airline
     * @type {string}
     * @memberof OfferSliceSegmentMarketingCarrier
     */
    name?: string;
}

/**
 * Check if a given object implements the OfferSliceSegmentMarketingCarrier interface.
 */
export function instanceOfOfferSliceSegmentMarketingCarrier(value: object): value is OfferSliceSegmentMarketingCarrier {
    return true;
}

export function OfferSliceSegmentMarketingCarrierFromJSON(json: any): OfferSliceSegmentMarketingCarrier {
    return OfferSliceSegmentMarketingCarrierFromJSONTyped(json, false);
}

export function OfferSliceSegmentMarketingCarrierFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentMarketingCarrier {
    if (json == null) {
        return json;
    }
    return {
        
        'conditionsOfCarriageUrl': json['conditions_of_carriage_url'] == null ? undefined : json['conditions_of_carriage_url'],
        'iataCode': json['iata_code'] == null ? undefined : json['iata_code'],
        'id': json['id'] == null ? undefined : json['id'],
        'logoLockupUrl': json['logo_lockup_url'] == null ? undefined : json['logo_lockup_url'],
        'logoSymbolUrl': json['logo_symbol_url'] == null ? undefined : json['logo_symbol_url'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function OfferSliceSegmentMarketingCarrierToJSON(value?: OfferSliceSegmentMarketingCarrier | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conditions_of_carriage_url': value['conditionsOfCarriageUrl'],
        'iata_code': value['iataCode'],
        'id': value['id'],
        'logo_lockup_url': value['logoLockupUrl'],
        'logo_symbol_url': value['logoSymbolUrl'],
        'name': value['name'],
    };
}

