/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PointsAmount } from './PointsAmount';
import {
    PointsAmountFromJSON,
    PointsAmountFromJSONTyped,
    PointsAmountToJSON,
} from './PointsAmount';

/**
 * A user summary - used in conjunction with a hotel summary
 * @export
 * @interface UserSummary
 */
export interface UserSummary {
    /**
     * User's status in the loyalty program. If None, the user is not a member of the program
     * @type {string}
     * @memberof UserSummary
     */
    userLoyaltyProgramStatus?: string;
    /**
     * Number of loyalty points the user has with the associated program
     * @type {number}
     * @memberof UserSummary
     */
    userLoyaltyPoints?: number;
    /**
     * Index of the user's tier in the loyalty program. If None, the user is not a member of the program
     * @type {number}
     * @memberof UserSummary
     */
    programTierIndex?: number;
    /**
     * If this hotel is in the top rankings for the search
     * @type {boolean}
     * @memberof UserSummary
     */
    gondolaRecommended?: boolean;
    /**
     * 
     * @type {PointsAmount}
     * @memberof UserSummary
     */
    earningsPoints?: PointsAmount;
    /**
     * The number of nights earned for a given stay
     * @type {number}
     * @memberof UserSummary
     */
    statusNights?: number;
}

/**
 * Check if a given object implements the UserSummary interface.
 */
export function instanceOfUserSummary(value: object): value is UserSummary {
    return true;
}

export function UserSummaryFromJSON(json: any): UserSummary {
    return UserSummaryFromJSONTyped(json, false);
}

export function UserSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'userLoyaltyProgramStatus': json['user_loyalty_program_status'] == null ? undefined : json['user_loyalty_program_status'],
        'userLoyaltyPoints': json['user_loyalty_points'] == null ? undefined : json['user_loyalty_points'],
        'programTierIndex': json['program_tier_index'] == null ? undefined : json['program_tier_index'],
        'gondolaRecommended': json['gondola_recommended'] == null ? undefined : json['gondola_recommended'],
        'earningsPoints': json['earnings_points'] == null ? undefined : PointsAmountFromJSON(json['earnings_points']),
        'statusNights': json['status_nights'] == null ? undefined : json['status_nights'],
    };
}

export function UserSummaryToJSON(value?: UserSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_loyalty_program_status': value['userLoyaltyProgramStatus'],
        'user_loyalty_points': value['userLoyaltyPoints'],
        'program_tier_index': value['programTierIndex'],
        'gondola_recommended': value['gondolaRecommended'],
        'earnings_points': PointsAmountToJSON(value['earningsPoints']),
        'status_nights': value['statusNights'],
    };
}

