/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RateResponseStatus } from './RateResponseStatus';
import {
    RateResponseStatusFromJSON,
    RateResponseStatusFromJSONTyped,
    RateResponseStatusToJSON,
} from './RateResponseStatus';
import type { RoomSummary } from './RoomSummary';
import {
    RoomSummaryFromJSON,
    RoomSummaryFromJSONTyped,
    RoomSummaryToJSON,
} from './RoomSummary';

/**
 * Additional Rate Response
 * @export
 * @interface AdditionalRateResponse
 */
export interface AdditionalRateResponse {
    /**
     * 
     * @type {Array<RoomSummary>}
     * @memberof AdditionalRateResponse
     */
    additionalRates?: Array<RoomSummary>;
    /**
     * 
     * @type {RateResponseStatus}
     * @memberof AdditionalRateResponse
     */
    status?: RateResponseStatus;
}

/**
 * Check if a given object implements the AdditionalRateResponse interface.
 */
export function instanceOfAdditionalRateResponse(value: object): value is AdditionalRateResponse {
    return true;
}

export function AdditionalRateResponseFromJSON(json: any): AdditionalRateResponse {
    return AdditionalRateResponseFromJSONTyped(json, false);
}

export function AdditionalRateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalRateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'additionalRates': json['additional_rates'] == null ? undefined : ((json['additional_rates'] as Array<any>).map(RoomSummaryFromJSON)),
        'status': json['status'] == null ? undefined : RateResponseStatusFromJSON(json['status']),
    };
}

export function AdditionalRateResponseToJSON(value?: AdditionalRateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'additional_rates': value['additionalRates'] == null ? undefined : ((value['additionalRates'] as Array<any>).map(RoomSummaryToJSON)),
        'status': RateResponseStatusToJSON(value['status']),
    };
}

