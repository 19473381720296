/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Airport
 */
export interface Airport {
    /**
     * Unique identifier for the airport
     * @type {string}
     * @memberof Airport
     */
    id?: string;
    /**
     * Time zone of the airport
     * @type {string}
     * @memberof Airport
     */
    timeZone?: string;
    /**
     * Name of the airport
     * @type {string}
     * @memberof Airport
     */
    name?: string;
    /**
     * Longitude of the airport
     * @type {number}
     * @memberof Airport
     */
    longitude?: number;
    /**
     * Latitude of the airport
     * @type {number}
     * @memberof Airport
     */
    latitude?: number;
    /**
     * ICAO code of the airport
     * @type {string}
     * @memberof Airport
     */
    icaoCode?: string;
    /**
     * IATA country code of the airport
     * @type {string}
     * @memberof Airport
     */
    iataCountryCode?: string;
    /**
     * IATA code of the airport
     * @type {string}
     * @memberof Airport
     */
    iataCode?: string;
    /**
     * IATA city code of the airport
     * @type {string}
     * @memberof Airport
     */
    iataCityCode?: string;
    /**
     * Name of the city where the airport is located
     * @type {string}
     * @memberof Airport
     */
    cityName?: string;
}

/**
 * Check if a given object implements the Airport interface.
 */
export function instanceOfAirport(value: object): value is Airport {
    return true;
}

export function AirportFromJSON(json: any): Airport {
    return AirportFromJSONTyped(json, false);
}

export function AirportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Airport {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'timeZone': json['time_zone'] == null ? undefined : json['time_zone'],
        'name': json['name'] == null ? undefined : json['name'],
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
        'icaoCode': json['icao_code'] == null ? undefined : json['icao_code'],
        'iataCountryCode': json['iata_country_code'] == null ? undefined : json['iata_country_code'],
        'iataCode': json['iata_code'] == null ? undefined : json['iata_code'],
        'iataCityCode': json['iata_city_code'] == null ? undefined : json['iata_city_code'],
        'cityName': json['city_name'] == null ? undefined : json['city_name'],
    };
}

export function AirportToJSON(value?: Airport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'time_zone': value['timeZone'],
        'name': value['name'],
        'longitude': value['longitude'],
        'latitude': value['latitude'],
        'icao_code': value['icaoCode'],
        'iata_country_code': value['iataCountryCode'],
        'iata_code': value['iataCode'],
        'iata_city_code': value['iataCityCode'],
        'city_name': value['cityName'],
    };
}

