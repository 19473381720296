/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An individual facility/ amenity.
 * @export
 * @interface Facility
 */
export interface Facility {
    /**
     * Numeric identifier for the facility/ amenity.
     * @type {number}
     * @memberof Facility
     */
    id?: number;
    /**
     * Facility/ Amenity name.
     * @type {string}
     * @memberof Facility
     */
    name?: string;
    /**
     * Master facility/ amenities id.
     * @type {number}
     * @memberof Facility
     */
    groupId?: number;
}

/**
 * Check if a given object implements the Facility interface.
 */
export function instanceOfFacility(value: object): value is Facility {
    return true;
}

export function FacilityFromJSON(json: any): Facility {
    return FacilityFromJSONTyped(json, false);
}

export function FacilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Facility {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'] == null ? undefined : json['Id'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'groupId': json['GroupId'] == null ? undefined : json['GroupId'],
    };
}

export function FacilityToJSON(value?: Facility | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'GroupId': value['groupId'],
    };
}

