/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RateType = {
    DirectCash: 'direct cash',
    DirectPoints: 'direct points',
    Expedia: 'expedia',
    ExpediaEarnings: 'expedia earnings',
    Travelport: 'travelport',
    TravelportPromotional: 'travelport promotional',
    TravelportMember: 'travelport member',
    TravelportAaa: 'travelport AAA',
    TravelportAarp: 'travelport Aarp',
    TravelportSenior: 'travelport Senior',
    TravelportGovernment: 'travelport government',
    TravelportMilitary: 'travelport military',
    TravelportBookingCom: 'travelport booking.com',
    TravelportPackage: 'travelport package',
    TravelportAbcVip: 'travelport abc vip',
    TravelportAbc: 'travelport abc'
} as const;
export type RateType = typeof RateType[keyof typeof RateType];


export function instanceOfRateType(value: any): boolean {
    for (const key in RateType) {
        if (Object.prototype.hasOwnProperty.call(RateType, key)) {
            if ((RateType as Record<string, RateType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RateTypeFromJSON(json: any): RateType {
    return RateTypeFromJSONTyped(json, false);
}

export function RateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateType {
    return json as RateType;
}

export function RateTypeToJSON(value?: RateType | null): any {
    return value as any;
}
