/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferConditionsRefundBeforeDeparture } from './OfferConditionsRefundBeforeDeparture';
import {
    OfferConditionsRefundBeforeDepartureFromJSON,
    OfferConditionsRefundBeforeDepartureFromJSONTyped,
    OfferConditionsRefundBeforeDepartureToJSON,
} from './OfferConditionsRefundBeforeDeparture';
import type { OfferConditionsChangeBeforeDeparture } from './OfferConditionsChangeBeforeDeparture';
import {
    OfferConditionsChangeBeforeDepartureFromJSON,
    OfferConditionsChangeBeforeDepartureFromJSONTyped,
    OfferConditionsChangeBeforeDepartureToJSON,
} from './OfferConditionsChangeBeforeDeparture';

/**
 * The conditions associated with this offer, describing the kinds of modifications you can make post-booking and any penalties that will apply to those modifications.
 * This information assumes the condition is applied to all of the slices and passengers associated with this offer - for information at the slice level (e.g. "what happens if I just want to change the first slice?") refer to the `slices`.
 * If a particular kind of modification is allowed, you may not always be able to take action through the Duffel API. In some cases, you may need to contact the Duffel support team or the airline directly.
 * 
 * @export
 * @interface OfferConditions
 */
export interface OfferConditions {
    /**
     * 
     * @type {OfferConditionsChangeBeforeDeparture}
     * @memberof OfferConditions
     */
    changeBeforeDeparture?: OfferConditionsChangeBeforeDeparture;
    /**
     * 
     * @type {OfferConditionsRefundBeforeDeparture}
     * @memberof OfferConditions
     */
    refundBeforeDeparture?: OfferConditionsRefundBeforeDeparture;
}

/**
 * Check if a given object implements the OfferConditions interface.
 */
export function instanceOfOfferConditions(value: object): value is OfferConditions {
    return true;
}

export function OfferConditionsFromJSON(json: any): OfferConditions {
    return OfferConditionsFromJSONTyped(json, false);
}

export function OfferConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferConditions {
    if (json == null) {
        return json;
    }
    return {
        
        'changeBeforeDeparture': json['change_before_departure'] == null ? undefined : OfferConditionsChangeBeforeDepartureFromJSON(json['change_before_departure']),
        'refundBeforeDeparture': json['refund_before_departure'] == null ? undefined : OfferConditionsRefundBeforeDepartureFromJSON(json['refund_before_departure']),
    };
}

export function OfferConditionsToJSON(value?: OfferConditions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'change_before_departure': OfferConditionsChangeBeforeDepartureToJSON(value['changeBeforeDeparture']),
        'refund_before_departure': OfferConditionsRefundBeforeDepartureToJSON(value['refundBeforeDeparture']),
    };
}

