/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GuestReview } from './GuestReview';
import {
    GuestReviewFromJSON,
    GuestReviewFromJSONTyped,
    GuestReviewToJSON,
} from './GuestReview';
import type { CategoryRating } from './CategoryRating';
import {
    CategoryRatingFromJSON,
    CategoryRatingFromJSONTyped,
    CategoryRatingToJSON,
} from './CategoryRating';

/**
 * Information about the user's rating
 * @export
 * @interface HotelContentReviewsInner
 */
export interface HotelContentReviewsInner {
    /**
     * The number of ratings.
     * @type {number}
     * @memberof HotelContentReviewsInner
     */
    count?: number;
    /**
     * Rating image URL
     * @type {string}
     * @memberof HotelContentReviewsInner
     */
    ratingImage?: string;
    /**
     * Ratings provider name like TripAdvisor
     * @type {string}
     * @memberof HotelContentReviewsInner
     */
    provider?: string;
    /**
     * Category Rating
     * @type {Array<CategoryRating>}
     * @memberof HotelContentReviewsInner
     */
    categoryRatings?: Array<CategoryRating>;
    /**
     * Guest Reviews.
     * @type {Array<GuestReview>}
     * @memberof HotelContentReviewsInner
     */
    guestReviews?: Array<GuestReview>;
}

/**
 * Check if a given object implements the HotelContentReviewsInner interface.
 */
export function instanceOfHotelContentReviewsInner(value: object): value is HotelContentReviewsInner {
    return true;
}

export function HotelContentReviewsInnerFromJSON(json: any): HotelContentReviewsInner {
    return HotelContentReviewsInnerFromJSONTyped(json, false);
}

export function HotelContentReviewsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentReviewsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['Count'] == null ? undefined : json['Count'],
        'ratingImage': json['RatingImage'] == null ? undefined : json['RatingImage'],
        'provider': json['Provider'] == null ? undefined : json['Provider'],
        'categoryRatings': json['CategoryRatings'] == null ? undefined : ((json['CategoryRatings'] as Array<any>).map(CategoryRatingFromJSON)),
        'guestReviews': json['GuestReviews'] == null ? undefined : ((json['GuestReviews'] as Array<any>).map(GuestReviewFromJSON)),
    };
}

export function HotelContentReviewsInnerToJSON(value?: HotelContentReviewsInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Count': value['count'],
        'RatingImage': value['ratingImage'],
        'Provider': value['provider'],
        'CategoryRatings': value['categoryRatings'] == null ? undefined : ((value['categoryRatings'] as Array<any>).map(CategoryRatingToJSON)),
        'GuestReviews': value['guestReviews'] == null ? undefined : ((value['guestReviews'] as Array<any>).map(GuestReviewToJSON)),
    };
}

