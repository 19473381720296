/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Provides the special scenarios that need to be taken into account when using this rate.
 * @export
 * @interface SaleScenario
 */
export interface SaleScenario {
    /**
     * If true, this rate has been provided to be bundled with car, air, etc. and displayed as a total package price.
     * 
     * If shopping in a cross-sell scenario and using the cross-sell rate option, this indicates that the rate is a package rate available to be sold as an add-on to an existing itinerary.
     * 
     * @type {boolean}
     * @memberof SaleScenario
     */
    _package?: boolean;
    /**
     * If true, this rate has a "Member Only Deal" discount applied to the rate.
     * 
     * Partners must be enabled to receive "Member Only Deals". If interested, partners should speak to their account representatives.
     * 
     * This parameter can be used to merchandise if a "Member Only Deal" has been applied, which will help drive loyalty. In addition, it can be used by OTA's to create an opaque, but public shopping experience.
     * 
     * This value will always be false for requests where the sales_environment is equal to "hotel_package".
     * 
     * @type {boolean}
     * @memberof SaleScenario
     */
    member?: boolean;
    /**
     * If true, this rate is a corporate negotiated rate.  These rates provide additional value adds (e.g. free breakfast, free wifi, discount) and same-day cancellation.
     * 
     * @type {boolean}
     * @memberof SaleScenario
     */
    corporate?: boolean;
    /**
     * If true, this rate is an EPS Optimized Distribution rate. These rates are procured exclusively for EPS distribution and may contain benefits such as larger marketing fee, less restrictive cancellation policies, additional value adds, or unique availability.
     * 
     * @type {boolean}
     * @memberof SaleScenario
     */
    distribution?: boolean;
}

/**
 * Check if a given object implements the SaleScenario interface.
 */
export function instanceOfSaleScenario(value: object): value is SaleScenario {
    return true;
}

export function SaleScenarioFromJSON(json: any): SaleScenario {
    return SaleScenarioFromJSONTyped(json, false);
}

export function SaleScenarioFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaleScenario {
    if (json == null) {
        return json;
    }
    return {
        
        '_package': json['package'] == null ? undefined : json['package'],
        'member': json['member'] == null ? undefined : json['member'],
        'corporate': json['corporate'] == null ? undefined : json['corporate'],
        'distribution': json['distribution'] == null ? undefined : json['distribution'],
    };
}

export function SaleScenarioToJSON(value?: SaleScenario | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'package': value['_package'],
        'member': value['member'],
        'corporate': value['corporate'],
        'distribution': value['distribution'],
    };
}

