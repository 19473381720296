/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The deposit policy associated with the itinerary.
 * @export
 * @interface DepositItinerary
 */
export interface DepositItinerary {
    /**
     * The currency of the deposit.
     * @type {string}
     * @memberof DepositItinerary
     */
    currency?: string;
    /**
     * The amount required as deposit.
     * @type {string}
     * @memberof DepositItinerary
     */
    value?: string;
    /**
     * The due date/time of the deposit.
     * @type {string}
     * @memberof DepositItinerary
     */
    due?: string;
}

/**
 * Check if a given object implements the DepositItinerary interface.
 */
export function instanceOfDepositItinerary(value: object): value is DepositItinerary {
    return true;
}

export function DepositItineraryFromJSON(json: any): DepositItinerary {
    return DepositItineraryFromJSONTyped(json, false);
}

export function DepositItineraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepositItinerary {
    if (json == null) {
        return json;
    }
    return {
        
        'currency': json['currency'] == null ? undefined : json['currency'],
        'value': json['value'] == null ? undefined : json['value'],
        'due': json['due'] == null ? undefined : json['due'],
    };
}

export function DepositItineraryToJSON(value?: DepositItinerary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currency': value['currency'],
        'value': value['value'],
        'due': value['due'],
    };
}

