/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HotelBookingUpdateRequest
 */
export interface HotelBookingUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof HotelBookingUpdateRequest
     */
    confirmationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelBookingUpdateRequest
     */
    cancellationSource?: HotelBookingUpdateRequestCancellationSourceEnum;
    /**
     * Cancellation datetime, which is not null only when cancellation_source is not null.
     * @type {Date}
     * @memberof HotelBookingUpdateRequest
     */
    cancelledAt?: Date;
}


/**
 * @export
 */
export const HotelBookingUpdateRequestCancellationSourceEnum = {
    Email: 'email'
} as const;
export type HotelBookingUpdateRequestCancellationSourceEnum = typeof HotelBookingUpdateRequestCancellationSourceEnum[keyof typeof HotelBookingUpdateRequestCancellationSourceEnum];


/**
 * Check if a given object implements the HotelBookingUpdateRequest interface.
 */
export function instanceOfHotelBookingUpdateRequest(value: object): value is HotelBookingUpdateRequest {
    return true;
}

export function HotelBookingUpdateRequestFromJSON(json: any): HotelBookingUpdateRequest {
    return HotelBookingUpdateRequestFromJSONTyped(json, false);
}

export function HotelBookingUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelBookingUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'confirmationNumber': json['confirmation_number'] == null ? undefined : json['confirmation_number'],
        'cancellationSource': json['cancellation_source'] == null ? undefined : json['cancellation_source'],
        'cancelledAt': json['cancelled_at'] == null ? undefined : (new Date(json['cancelled_at'])),
    };
}

export function HotelBookingUpdateRequestToJSON(value?: HotelBookingUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'confirmation_number': value['confirmationNumber'],
        'cancellation_source': value['cancellationSource'],
        'cancelled_at': value['cancelledAt'] == null ? undefined : ((value['cancelledAt']).toISOString()),
    };
}

