/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EssentialInformation } from './EssentialInformation';
import {
    EssentialInformationFromJSON,
    EssentialInformationFromJSONTyped,
    EssentialInformationToJSON,
} from './EssentialInformation';
import type { BillingContact1 } from './BillingContact1';
import {
    BillingContact1FromJSON,
    BillingContact1FromJSONTyped,
    BillingContact1ToJSON,
} from './BillingContact1';
import type { RoomItinerary } from './RoomItinerary';
import {
    RoomItineraryFromJSON,
    RoomItineraryFromJSONTyped,
    RoomItineraryToJSON,
} from './RoomItinerary';
import type { Adjustment } from './Adjustment';
import {
    AdjustmentFromJSON,
    AdjustmentFromJSONTyped,
    AdjustmentToJSON,
} from './Adjustment';
import type { ItineraryHistoryItem } from './ItineraryHistoryItem';
import {
    ItineraryHistoryItemFromJSON,
    ItineraryHistoryItemFromJSONTyped,
    ItineraryHistoryItemToJSON,
} from './ItineraryHistoryItem';
import type { Phone } from './Phone';
import {
    PhoneFromJSON,
    PhoneFromJSONTyped,
    PhoneToJSON,
} from './Phone';
import type { RoomHistoryItem } from './RoomHistoryItem';
import {
    RoomHistoryItemFromJSON,
    RoomHistoryItemFromJSONTyped,
    RoomHistoryItemToJSON,
} from './RoomHistoryItem';
import type { TraderInformation } from './TraderInformation';
import {
    TraderInformationFromJSON,
    TraderInformationFromJSONTyped,
    TraderInformationToJSON,
} from './TraderInformation';
import type { ItineraryLinks } from './ItineraryLinks';
import {
    ItineraryLinksFromJSON,
    ItineraryLinksFromJSONTyped,
    ItineraryLinksToJSON,
} from './ItineraryLinks';
import type { Conversations } from './Conversations';
import {
    ConversationsFromJSON,
    ConversationsFromJSONTyped,
    ConversationsToJSON,
} from './Conversations';

/**
 * The itinerary object.
 * @export
 * @interface Itinerary
 */
export interface Itinerary {
    /**
     * The itinerary id.
     * @type {string}
     * @memberof Itinerary
     */
    itineraryId?: string;
    /**
     * The property id.
     * @type {string}
     * @memberof Itinerary
     */
    propertyId?: string;
    /**
     * 
     * @type {ItineraryLinks}
     * @memberof Itinerary
     */
    links?: ItineraryLinks;
    /**
     * Email address for the customer.
     * @type {string}
     * @memberof Itinerary
     */
    email?: string;
    /**
     * 
     * @type {Phone}
     * @memberof Itinerary
     */
    phone?: Phone;
    /**
     * 
     * @type {Array<RoomItinerary>}
     * @memberof Itinerary
     */
    rooms?: Array<RoomItinerary>;
    /**
     * 
     * @type {BillingContact1}
     * @memberof Itinerary
     */
    billingContact?: BillingContact1;
    /**
     * 
     * @type {Adjustment}
     * @memberof Itinerary
     */
    adjustment?: Adjustment;
    /**
     * The creation date/time of the booking.
     * @type {string}
     * @memberof Itinerary
     */
    creationDateTime?: string;
    /**
     * Your unique reference value. This field supports from 3 to a maximum of 28 characters.
     * @type {string}
     * @memberof Itinerary
     */
    affiliateReferenceId?: string;
    /**
     * Field that stores up to 256 characters of additional metadata with the itinerary, uniqueness is not required.
     * @type {string}
     * @memberof Itinerary
     */
    affiliateMetadata?: string;
    /**
     * 
     * @type {Conversations}
     * @memberof Itinerary
     */
    conversations?: Conversations;
    /**
     * 
     * @type {TraderInformation}
     * @memberof Itinerary
     */
    traderInformation?: TraderInformation;
    /**
     * 
     * @type {EssentialInformation}
     * @memberof Itinerary
     */
    essentialInformation?: EssentialInformation;
    /**
     * Value potentially passed in during the availability request to indicate the purpose of the trip designated
     * by the traveler.
     * 
     * @type {string}
     * @memberof Itinerary
     */
    travelPurpose?: ItineraryTravelPurposeEnum;
    /**
     * 
     * @type {Array<ItineraryHistoryItem>}
     * @memberof Itinerary
     */
    itineraryHistory?: Array<ItineraryHistoryItem>;
    /**
     * An array of rooms each containing an array of room history events.
     * @type {Array<Array<RoomHistoryItem>>}
     * @memberof Itinerary
     */
    roomHistory?: Array<Array<RoomHistoryItem>>;
}


/**
 * @export
 */
export const ItineraryTravelPurposeEnum = {
    Leisure: 'leisure',
    Business: 'business',
    Unspecified: 'unspecified'
} as const;
export type ItineraryTravelPurposeEnum = typeof ItineraryTravelPurposeEnum[keyof typeof ItineraryTravelPurposeEnum];


/**
 * Check if a given object implements the Itinerary interface.
 */
export function instanceOfItinerary(value: object): value is Itinerary {
    return true;
}

export function ItineraryFromJSON(json: any): Itinerary {
    return ItineraryFromJSONTyped(json, false);
}

export function ItineraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Itinerary {
    if (json == null) {
        return json;
    }
    return {
        
        'itineraryId': json['itinerary_id'] == null ? undefined : json['itinerary_id'],
        'propertyId': json['property_id'] == null ? undefined : json['property_id'],
        'links': json['links'] == null ? undefined : ItineraryLinksFromJSON(json['links']),
        'email': json['email'] == null ? undefined : json['email'],
        'phone': json['phone'] == null ? undefined : PhoneFromJSON(json['phone']),
        'rooms': json['rooms'] == null ? undefined : ((json['rooms'] as Array<any>).map(RoomItineraryFromJSON)),
        'billingContact': json['billing_contact'] == null ? undefined : BillingContact1FromJSON(json['billing_contact']),
        'adjustment': json['adjustment'] == null ? undefined : AdjustmentFromJSON(json['adjustment']),
        'creationDateTime': json['creation_date_time'] == null ? undefined : json['creation_date_time'],
        'affiliateReferenceId': json['affiliate_reference_id'] == null ? undefined : json['affiliate_reference_id'],
        'affiliateMetadata': json['affiliate_metadata'] == null ? undefined : json['affiliate_metadata'],
        'conversations': json['conversations'] == null ? undefined : ConversationsFromJSON(json['conversations']),
        'traderInformation': json['trader_information'] == null ? undefined : TraderInformationFromJSON(json['trader_information']),
        'essentialInformation': json['essential_information'] == null ? undefined : EssentialInformationFromJSON(json['essential_information']),
        'travelPurpose': json['travel_purpose'] == null ? undefined : json['travel_purpose'],
        'itineraryHistory': json['itinerary_history'] == null ? undefined : ((json['itinerary_history'] as Array<any>).map(ItineraryHistoryItemFromJSON)),
        'roomHistory': json['room_history'] == null ? undefined : json['room_history'],
    };
}

export function ItineraryToJSON(value?: Itinerary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'itinerary_id': value['itineraryId'],
        'property_id': value['propertyId'],
        'links': ItineraryLinksToJSON(value['links']),
        'email': value['email'],
        'phone': PhoneToJSON(value['phone']),
        'rooms': value['rooms'] == null ? undefined : ((value['rooms'] as Array<any>).map(RoomItineraryToJSON)),
        'billing_contact': BillingContact1ToJSON(value['billingContact']),
        'adjustment': AdjustmentToJSON(value['adjustment']),
        'creation_date_time': value['creationDateTime'],
        'affiliate_reference_id': value['affiliateReferenceId'],
        'affiliate_metadata': value['affiliateMetadata'],
        'conversations': ConversationsToJSON(value['conversations']),
        'trader_information': TraderInformationToJSON(value['traderInformation']),
        'essential_information': EssentialInformationToJSON(value['essentialInformation']),
        'travel_purpose': value['travelPurpose'],
        'itinerary_history': value['itineraryHistory'] == null ? undefined : ((value['itineraryHistory'] as Array<any>).map(ItineraryHistoryItemToJSON)),
        'room_history': value['roomHistory'],
    };
}

