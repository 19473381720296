/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The reason category for this reviewer's trip.
 * @export
 */
export const TripReason = {
    Business: 'business',
    Leisure: 'leisure',
    FriendsAndFamily: 'friends_and_family',
    BusinessAndLeisure: 'business_and_leisure'
} as const;
export type TripReason = typeof TripReason[keyof typeof TripReason];


export function instanceOfTripReason(value: any): boolean {
    for (const key in TripReason) {
        if (Object.prototype.hasOwnProperty.call(TripReason, key)) {
            if ((TripReason as Record<string, TripReason>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TripReasonFromJSON(json: any): TripReason {
    return TripReasonFromJSONTyped(json, false);
}

export function TripReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripReason {
    return json as TripReason;
}

export function TripReasonToJSON(value?: TripReason | null): any {
    return value as any;
}
