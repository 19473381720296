/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelContentAttributesInner } from './HotelContentAttributesInner';
import {
    HotelContentAttributesInnerFromJSON,
    HotelContentAttributesInnerFromJSONTyped,
    HotelContentAttributesInnerToJSON,
} from './HotelContentAttributesInner';
import type { HotelContentPointOfInterestsInner } from './HotelContentPointOfInterestsInner';
import {
    HotelContentPointOfInterestsInnerFromJSON,
    HotelContentPointOfInterestsInnerFromJSONTyped,
    HotelContentPointOfInterestsInnerToJSON,
} from './HotelContentPointOfInterestsInner';
import type { HotelContentDescriptionsInner } from './HotelContentDescriptionsInner';
import {
    HotelContentDescriptionsInnerFromJSON,
    HotelContentDescriptionsInnerFromJSONTyped,
    HotelContentDescriptionsInnerToJSON,
} from './HotelContentDescriptionsInner';
import type { HotelContentProviderGeocodes } from './HotelContentProviderGeocodes';
import {
    HotelContentProviderGeocodesFromJSON,
    HotelContentProviderGeocodesFromJSONTyped,
    HotelContentProviderGeocodesToJSON,
} from './HotelContentProviderGeocodes';
import type { HotelContentPoliciesInner } from './HotelContentPoliciesInner';
import {
    HotelContentPoliciesInnerFromJSON,
    HotelContentPoliciesInnerFromJSONTyped,
    HotelContentPoliciesInnerToJSON,
} from './HotelContentPoliciesInner';
import type { HotelContentReviewsInner } from './HotelContentReviewsInner';
import {
    HotelContentReviewsInnerFromJSON,
    HotelContentReviewsInnerFromJSONTyped,
    HotelContentReviewsInnerToJSON,
} from './HotelContentReviewsInner';
import type { HotelContentContact } from './HotelContentContact';
import {
    HotelContentContactFromJSON,
    HotelContentContactFromJSONTyped,
    HotelContentContactToJSON,
} from './HotelContentContact';
import type { HotelContentStatisticsInner } from './HotelContentStatisticsInner';
import {
    HotelContentStatisticsInnerFromJSON,
    HotelContentStatisticsInnerFromJSONTyped,
    HotelContentStatisticsInnerToJSON,
} from './HotelContentStatisticsInner';
import type { HotelContentFeesInner } from './HotelContentFeesInner';
import {
    HotelContentFeesInnerFromJSON,
    HotelContentFeesInnerFromJSONTyped,
    HotelContentFeesInnerToJSON,
} from './HotelContentFeesInner';
import type { HotelContentGeoCode } from './HotelContentGeoCode';
import {
    HotelContentGeoCodeFromJSON,
    HotelContentGeoCodeFromJSONTyped,
    HotelContentGeoCodeToJSON,
} from './HotelContentGeoCode';
import type { HotelContentCheckout } from './HotelContentCheckout';
import {
    HotelContentCheckoutFromJSON,
    HotelContentCheckoutFromJSONTyped,
    HotelContentCheckoutToJSON,
} from './HotelContentCheckout';
import type { Facility } from './Facility';
import {
    FacilityFromJSON,
    FacilityFromJSONTyped,
    FacilityToJSON,
} from './Facility';
import type { HotelContentImagesInner } from './HotelContentImagesInner';
import {
    HotelContentImagesInnerFromJSON,
    HotelContentImagesInnerFromJSONTyped,
    HotelContentImagesInnerToJSON,
} from './HotelContentImagesInner';
import type { HotelContentCheckin } from './HotelContentCheckin';
import {
    HotelContentCheckinFromJSON,
    HotelContentCheckinFromJSONTyped,
    HotelContentCheckinToJSON,
} from './HotelContentCheckin';
import type { Room } from './Room';
import {
    RoomFromJSON,
    RoomFromJSONTyped,
    RoomToJSON,
} from './Room';

/**
 * An individual property object.
 * @export
 * @interface HotelContent
 */
export interface HotelContent {
    /**
     * Provider Hotel Id.
     * @type {string}
     * @memberof HotelContent
     */
    providerHotelId?: string;
    /**
     * Vervotech Mappings hotel id for the hotel.
     * @type {number}
     * @memberof HotelContent
     */
    vervotechId?: number;
    /**
     * Language in which hotel details are present.
     * @type {string}
     * @memberof HotelContent
     */
    languageCode?: string;
    /**
     * Property name.
     * @type {string}
     * @memberof HotelContent
     */
    name?: string;
    /**
     * Provider name.
     * @type {string}
     * @memberof HotelContent
     */
    providerFamily?: string;
    /**
     * 
     * @type {HotelContentContact}
     * @memberof HotelContent
     */
    contact?: HotelContentContact;
    /**
     * The standard 'star' rating
     * @type {number}
     * @memberof HotelContent
     */
    rating?: number;
    /**
     * 
     * @type {Array<HotelContentReviewsInner>}
     * @memberof HotelContent
     */
    reviews?: Array<HotelContentReviewsInner>;
    /**
     * 
     * @type {HotelContentGeoCode}
     * @memberof HotelContent
     */
    geoCode?: HotelContentGeoCode;
    /**
     * 
     * @type {HotelContentProviderGeocodes}
     * @memberof HotelContent
     */
    providerGeocodes?: HotelContentProviderGeocodes;
    /**
     * Hotel brand name.
     * @type {string}
     * @memberof HotelContent
     */
    brandName?: string;
    /**
     * Hotel brand code.
     * @type {string}
     * @memberof HotelContent
     */
    brandCode?: string;
    /**
     * Hotel chain code.
     * @type {string}
     * @memberof HotelContent
     */
    chainCode?: string;
    /**
     * Hotel chain name
     * @type {string}
     * @memberof HotelContent
     */
    chainName?: string;
    /**
     * Master Hotel Property type.
     * @type {string}
     * @memberof HotelContent
     */
    masterPropertyType?: string;
    /**
     * Hotel property type. Each property is associated with only one property type. Example - Hotel, Hotel-unknown, Motel, Hotel resort, Inn, Bed and Breakfast, Guest house, Cabin, Cottage, Villa, Apartment, Tree house, House boat, Townhouse, etc.
     * @type {string}
     * @memberof HotelContent
     */
    propertyType?: string;
    /**
     * 
     * @type {Array<Facility>}
     * @memberof HotelContent
     */
    facilities?: Array<Facility>;
    /**
     * Hero image of hotel. If hero Image from provider is null then the first/default image from the image array is declared as hero image of hotel.
     * @type {string}
     * @memberof HotelContent
     */
    heroImage?: string;
    /**
     * Provider hero image URL.
     * @type {string}
     * @memberof HotelContent
     */
    providerHeroImageHref?: string;
    /**
     * Contains all property images available.
     * @type {Array<HotelContentImagesInner>}
     * @memberof HotelContent
     */
    images?: Array<HotelContentImagesInner>;
    /**
     * 
     * @type {Array<HotelContentDescriptionsInner>}
     * @memberof HotelContent
     */
    descriptions?: Array<HotelContentDescriptionsInner>;
    /**
     * 
     * @type {HotelContentCheckin}
     * @memberof HotelContent
     */
    checkin?: HotelContentCheckin;
    /**
     * 
     * @type {HotelContentCheckout}
     * @memberof HotelContent
     */
    checkout?: HotelContentCheckout;
    /**
     * 
     * @type {Array<HotelContentFeesInner>}
     * @memberof HotelContent
     */
    fees?: Array<HotelContentFeesInner>;
    /**
     * 
     * @type {Array<HotelContentPoliciesInner>}
     * @memberof HotelContent
     */
    policies?: Array<HotelContentPoliciesInner>;
    /**
     * Room information
     * @type {Array<Room>}
     * @memberof HotelContent
     */
    rooms?: Array<Room>;
    /**
     * 
     * @type {Array<HotelContentPointOfInterestsInner>}
     * @memberof HotelContent
     */
    pointOfInterests?: Array<HotelContentPointOfInterestsInner>;
    /**
     * Master Hotel Chain Name.
     * @type {string}
     * @memberof HotelContent
     */
    masterChainName?: string;
    /**
     * Popularity score defines how much popular the given property is.
     * @type {number}
     * @memberof HotelContent
     */
    popularityScore?: number;
    /**
     * Score is calculated based on content quality. Some weightage is assigned to different content fields like images, address, facilities etc. So, if that part of content is present for the property, its associated weightage score gets added into content score.
     * @type {number}
     * @memberof HotelContent
     */
    contentScore?: number;
    /**
     * License number.
     * @type {string}
     * @memberof HotelContent
     */
    licenseNumber?: string;
    /**
     * Any additional information related to hotel in the form of key value pair.
     * @type {Array<HotelContentAttributesInner>}
     * @memberof HotelContent
     */
    attributes?: Array<HotelContentAttributesInner>;
    /**
     * 
     * @type {Array<HotelContentStatisticsInner>}
     * @memberof HotelContent
     */
    statistics?: Array<HotelContentStatisticsInner>;
}

/**
 * Check if a given object implements the HotelContent interface.
 */
export function instanceOfHotelContent(value: object): value is HotelContent {
    return true;
}

export function HotelContentFromJSON(json: any): HotelContent {
    return HotelContentFromJSONTyped(json, false);
}

export function HotelContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContent {
    if (json == null) {
        return json;
    }
    return {
        
        'providerHotelId': json['ProviderHotelId'] == null ? undefined : json['ProviderHotelId'],
        'vervotechId': json['VervotechId'] == null ? undefined : json['VervotechId'],
        'languageCode': json['LanguageCode'] == null ? undefined : json['LanguageCode'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'providerFamily': json['ProviderFamily'] == null ? undefined : json['ProviderFamily'],
        'contact': json['Contact'] == null ? undefined : HotelContentContactFromJSON(json['Contact']),
        'rating': json['Rating'] == null ? undefined : json['Rating'],
        'reviews': json['Reviews'] == null ? undefined : ((json['Reviews'] as Array<any>).map(HotelContentReviewsInnerFromJSON)),
        'geoCode': json['GeoCode'] == null ? undefined : HotelContentGeoCodeFromJSON(json['GeoCode']),
        'providerGeocodes': json['ProviderGeocodes'] == null ? undefined : HotelContentProviderGeocodesFromJSON(json['ProviderGeocodes']),
        'brandName': json['BrandName'] == null ? undefined : json['BrandName'],
        'brandCode': json['BrandCode'] == null ? undefined : json['BrandCode'],
        'chainCode': json['ChainCode'] == null ? undefined : json['ChainCode'],
        'chainName': json['ChainName'] == null ? undefined : json['ChainName'],
        'masterPropertyType': json['MasterPropertyType'] == null ? undefined : json['MasterPropertyType'],
        'propertyType': json['PropertyType'] == null ? undefined : json['PropertyType'],
        'facilities': json['Facilities'] == null ? undefined : ((json['Facilities'] as Array<any>).map(FacilityFromJSON)),
        'heroImage': json['HeroImage'] == null ? undefined : json['HeroImage'],
        'providerHeroImageHref': json['ProviderHeroImageHref'] == null ? undefined : json['ProviderHeroImageHref'],
        'images': json['Images'] == null ? undefined : ((json['Images'] as Array<any>).map(HotelContentImagesInnerFromJSON)),
        'descriptions': json['Descriptions'] == null ? undefined : ((json['Descriptions'] as Array<any>).map(HotelContentDescriptionsInnerFromJSON)),
        'checkin': json['Checkin'] == null ? undefined : HotelContentCheckinFromJSON(json['Checkin']),
        'checkout': json['Checkout'] == null ? undefined : HotelContentCheckoutFromJSON(json['Checkout']),
        'fees': json['Fees'] == null ? undefined : ((json['Fees'] as Array<any>).map(HotelContentFeesInnerFromJSON)),
        'policies': json['Policies'] == null ? undefined : ((json['Policies'] as Array<any>).map(HotelContentPoliciesInnerFromJSON)),
        'rooms': json['Rooms'] == null ? undefined : ((json['Rooms'] as Array<any>).map(RoomFromJSON)),
        'pointOfInterests': json['PointOfInterests'] == null ? undefined : ((json['PointOfInterests'] as Array<any>).map(HotelContentPointOfInterestsInnerFromJSON)),
        'masterChainName': json['MasterChainName'] == null ? undefined : json['MasterChainName'],
        'popularityScore': json['PopularityScore'] == null ? undefined : json['PopularityScore'],
        'contentScore': json['ContentScore'] == null ? undefined : json['ContentScore'],
        'licenseNumber': json['LicenseNumber'] == null ? undefined : json['LicenseNumber'],
        'attributes': json['Attributes'] == null ? undefined : ((json['Attributes'] as Array<any>).map(HotelContentAttributesInnerFromJSON)),
        'statistics': json['Statistics'] == null ? undefined : ((json['Statistics'] as Array<any>).map(HotelContentStatisticsInnerFromJSON)),
    };
}

export function HotelContentToJSON(value?: HotelContent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ProviderHotelId': value['providerHotelId'],
        'VervotechId': value['vervotechId'],
        'LanguageCode': value['languageCode'],
        'Name': value['name'],
        'ProviderFamily': value['providerFamily'],
        'Contact': HotelContentContactToJSON(value['contact']),
        'Rating': value['rating'],
        'Reviews': value['reviews'] == null ? undefined : ((value['reviews'] as Array<any>).map(HotelContentReviewsInnerToJSON)),
        'GeoCode': HotelContentGeoCodeToJSON(value['geoCode']),
        'ProviderGeocodes': HotelContentProviderGeocodesToJSON(value['providerGeocodes']),
        'BrandName': value['brandName'],
        'BrandCode': value['brandCode'],
        'ChainCode': value['chainCode'],
        'ChainName': value['chainName'],
        'MasterPropertyType': value['masterPropertyType'],
        'PropertyType': value['propertyType'],
        'Facilities': value['facilities'] == null ? undefined : ((value['facilities'] as Array<any>).map(FacilityToJSON)),
        'HeroImage': value['heroImage'],
        'ProviderHeroImageHref': value['providerHeroImageHref'],
        'Images': value['images'] == null ? undefined : ((value['images'] as Array<any>).map(HotelContentImagesInnerToJSON)),
        'Descriptions': value['descriptions'] == null ? undefined : ((value['descriptions'] as Array<any>).map(HotelContentDescriptionsInnerToJSON)),
        'Checkin': HotelContentCheckinToJSON(value['checkin']),
        'Checkout': HotelContentCheckoutToJSON(value['checkout']),
        'Fees': value['fees'] == null ? undefined : ((value['fees'] as Array<any>).map(HotelContentFeesInnerToJSON)),
        'Policies': value['policies'] == null ? undefined : ((value['policies'] as Array<any>).map(HotelContentPoliciesInnerToJSON)),
        'Rooms': value['rooms'] == null ? undefined : ((value['rooms'] as Array<any>).map(RoomToJSON)),
        'PointOfInterests': value['pointOfInterests'] == null ? undefined : ((value['pointOfInterests'] as Array<any>).map(HotelContentPointOfInterestsInnerToJSON)),
        'MasterChainName': value['masterChainName'],
        'PopularityScore': value['popularityScore'],
        'ContentScore': value['contentScore'],
        'LicenseNumber': value['licenseNumber'],
        'Attributes': value['attributes'] == null ? undefined : ((value['attributes'] as Array<any>).map(HotelContentAttributesInnerToJSON)),
        'Statistics': value['statistics'] == null ? undefined : ((value['statistics'] as Array<any>).map(HotelContentStatisticsInnerToJSON)),
    };
}

