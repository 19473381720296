/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Review } from './Review';
import {
    ReviewFromJSON,
    ReviewFromJSONTyped,
    ReviewToJSON,
} from './Review';

/**
 * A property's verified guest reviews.
 * @export
 * @interface GuestReviewsVerified
 */
export interface GuestReviewsVerified {
    /**
     * A collection of the guest reviews which have been verified, in order, starting with the newest.
     * @type {Array<Review>}
     * @memberof GuestReviewsVerified
     */
    recent?: Array<Review>;
}

/**
 * Check if a given object implements the GuestReviewsVerified interface.
 */
export function instanceOfGuestReviewsVerified(value: object): value is GuestReviewsVerified {
    return true;
}

export function GuestReviewsVerifiedFromJSON(json: any): GuestReviewsVerified {
    return GuestReviewsVerifiedFromJSONTyped(json, false);
}

export function GuestReviewsVerifiedFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestReviewsVerified {
    if (json == null) {
        return json;
    }
    return {
        
        'recent': json['recent'] == null ? undefined : ((json['recent'] as Array<any>).map(ReviewFromJSON)),
    };
}

export function GuestReviewsVerifiedToJSON(value?: GuestReviewsVerified | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'recent': value['recent'] == null ? undefined : ((value['recent'] as Array<any>).map(ReviewToJSON)),
    };
}

