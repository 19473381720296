/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Static flight route metadata
 * @export
 * @interface FlightRoutes
 */
export interface FlightRoutes {
    /**
     * 
     * @type {number}
     * @memberof FlightRoutes
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FlightRoutes
     */
    originAirport: string;
    /**
     * 
     * @type {string}
     * @memberof FlightRoutes
     */
    originRegion: string;
    /**
     * 
     * @type {string}
     * @memberof FlightRoutes
     */
    destinationAirport: string;
    /**
     * 
     * @type {string}
     * @memberof FlightRoutes
     */
    destinationRegion: string;
    /**
     * 
     * @type {number}
     * @memberof FlightRoutes
     */
    distance?: number;
    /**
     * 
     * @type {string}
     * @memberof FlightRoutes
     */
    source: string;
}

/**
 * Check if a given object implements the FlightRoutes interface.
 */
export function instanceOfFlightRoutes(value: object): value is FlightRoutes {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('originAirport' in value) || value['originAirport'] === undefined) return false;
    if (!('originRegion' in value) || value['originRegion'] === undefined) return false;
    if (!('destinationAirport' in value) || value['destinationAirport'] === undefined) return false;
    if (!('destinationRegion' in value) || value['destinationRegion'] === undefined) return false;
    if (!('source' in value) || value['source'] === undefined) return false;
    return true;
}

export function FlightRoutesFromJSON(json: any): FlightRoutes {
    return FlightRoutesFromJSONTyped(json, false);
}

export function FlightRoutesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightRoutes {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'originAirport': json['origin_airport'],
        'originRegion': json['origin_region'],
        'destinationAirport': json['destination_airport'],
        'destinationRegion': json['destination_region'],
        'distance': json['distance'] == null ? undefined : json['distance'],
        'source': json['source'],
    };
}

export function FlightRoutesToJSON(value?: FlightRoutes | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'origin_airport': value['originAirport'],
        'origin_region': value['originRegion'],
        'destination_airport': value['destinationAirport'],
        'destination_region': value['destinationRegion'],
        'distance': value['distance'],
        'source': value['source'],
    };
}

