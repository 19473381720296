/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RawRateSummary } from './RawRateSummary';
import {
    RawRateSummaryFromJSON,
    RawRateSummaryFromJSONTyped,
    RawRateSummaryToJSON,
} from './RawRateSummary';

/**
 * A response to a rate validation request
 * @export
 * @interface RateValidationResponse
 */
export interface RateValidationResponse {
    /**
     * 
     * @type {number}
     * @memberof RateValidationResponse
     */
    vervotechPropertyId?: number;
    /**
     * 
     * @type {string}
     * @memberof RateValidationResponse
     */
    bookingToken?: string;
    /**
     * 
     * @type {RawRateSummary}
     * @memberof RateValidationResponse
     */
    rateSummary?: RawRateSummary;
}

/**
 * Check if a given object implements the RateValidationResponse interface.
 */
export function instanceOfRateValidationResponse(value: object): value is RateValidationResponse {
    return true;
}

export function RateValidationResponseFromJSON(json: any): RateValidationResponse {
    return RateValidationResponseFromJSONTyped(json, false);
}

export function RateValidationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateValidationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'bookingToken': json['booking_token'] == null ? undefined : json['booking_token'],
        'rateSummary': json['rate_summary'] == null ? undefined : RawRateSummaryFromJSON(json['rate_summary']),
    };
}

export function RateValidationResponseToJSON(value?: RateValidationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_id': value['vervotechPropertyId'],
        'booking_token': value['bookingToken'],
        'rate_summary': RawRateSummaryToJSON(value['rateSummary']),
    };
}

