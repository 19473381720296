/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Information related to a property's fees.
 * @export
 * @interface HotelContentFeesInner
 */
export interface HotelContentFeesInner {
    /**
     * Either describes resort fees and other mandatory taxes or charges. May describe which services are covered by any fees, such as fitness centres or internet access or describes additional optional fees for items such as breakfast, Wi-Fi, parking, pets etc.
     * @type {string}
     * @memberof HotelContentFeesInner
     */
    type?: HotelContentFeesInnerTypeEnum;
    /**
     * Information of Fee type.
     * @type {string}
     * @memberof HotelContentFeesInner
     */
    text?: string;
    /**
     * Fee amount
     * @type {string}
     * @memberof HotelContentFeesInner
     */
    fees?: string;
    /**
     * Frequency in which the fee amount is to be paid
     * @type {string}
     * @memberof HotelContentFeesInner
     */
    frequency?: string;
    /**
     * Basis on which the fee amount would be charged
     * @type {string}
     * @memberof HotelContentFeesInner
     */
    assignment?: string;
}


/**
 * @export
 */
export const HotelContentFeesInnerTypeEnum = {
    Mandatory: 'Mandatory',
    Optional: 'Optional'
} as const;
export type HotelContentFeesInnerTypeEnum = typeof HotelContentFeesInnerTypeEnum[keyof typeof HotelContentFeesInnerTypeEnum];


/**
 * Check if a given object implements the HotelContentFeesInner interface.
 */
export function instanceOfHotelContentFeesInner(value: object): value is HotelContentFeesInner {
    return true;
}

export function HotelContentFeesInnerFromJSON(json: any): HotelContentFeesInner {
    return HotelContentFeesInnerFromJSONTyped(json, false);
}

export function HotelContentFeesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentFeesInner {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['Type'] == null ? undefined : json['Type'],
        'text': json['Text'] == null ? undefined : json['Text'],
        'fees': json['Fees'] == null ? undefined : json['Fees'],
        'frequency': json['Frequency'] == null ? undefined : json['Frequency'],
        'assignment': json['Assignment'] == null ? undefined : json['Assignment'],
    };
}

export function HotelContentFeesInnerToJSON(value?: HotelContentFeesInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Type': value['type'],
        'Text': value['text'],
        'Fees': value['fees'],
        'Frequency': value['frequency'],
        'Assignment': value['assignment'],
    };
}

