/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Whether the whole offer can be changed before the departure of the first slice.
 * If all of the slices on the offer can be changed then the `allowed` property will be `true`. Refer to the `slices` for information about change penalties.
 * If any of the slices on the offer can't be changed then the `allowed` property will be `false`. In this case you should refer to the `slices` conditions to determine if any part of the offer is changeable.
 * If the airline hasn't provided any information about whether this offer can be changed then this property will be `null`.
 * 
 * @export
 * @interface OfferConditionsChangeBeforeDeparture
 */
export interface OfferConditionsChangeBeforeDeparture {
    /**
     * Whether this kind of modification is allowed post-booking
     * @type {boolean}
     * @memberof OfferConditionsChangeBeforeDeparture
     */
    allowed?: boolean;
    /**
     * If the modification is `allowed` then this is the amount payable to apply the modification to all passengers. If there is no penalty, the value will be zero.
     * If the modification isn't `allowed` or the penalty is not known then this field will be `null`.
     * If this is `null` then the `penalty_currency` will also be `null`.
     * 
     * @type {string}
     * @memberof OfferConditionsChangeBeforeDeparture
     */
    penaltyAmount?: string;
    /**
     * The currency of the `penalty_amount` as an [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code.
     * This will be in a currency determined by the airline, which is not necessarily the same as the currency of the offer or order.
     * If this is `null` then `penalty_amount` will also be `null`.
     * 
     * @type {string}
     * @memberof OfferConditionsChangeBeforeDeparture
     */
    penaltyCurrency?: string;
}

/**
 * Check if a given object implements the OfferConditionsChangeBeforeDeparture interface.
 */
export function instanceOfOfferConditionsChangeBeforeDeparture(value: object): value is OfferConditionsChangeBeforeDeparture {
    return true;
}

export function OfferConditionsChangeBeforeDepartureFromJSON(json: any): OfferConditionsChangeBeforeDeparture {
    return OfferConditionsChangeBeforeDepartureFromJSONTyped(json, false);
}

export function OfferConditionsChangeBeforeDepartureFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferConditionsChangeBeforeDeparture {
    if (json == null) {
        return json;
    }
    return {
        
        'allowed': json['allowed'] == null ? undefined : json['allowed'],
        'penaltyAmount': json['penalty_amount'] == null ? undefined : json['penalty_amount'],
        'penaltyCurrency': json['penalty_currency'] == null ? undefined : json['penalty_currency'],
    };
}

export function OfferConditionsChangeBeforeDepartureToJSON(value?: OfferConditionsChangeBeforeDeparture | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allowed': value['allowed'],
        'penalty_amount': value['penaltyAmount'],
        'penalty_currency': value['penaltyCurrency'],
    };
}

