/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An object containing metadata about the service, like the maximum weight and dimensions of the baggage.
 * @export
 * @interface ServiceMetadataForABaggage
 */
export interface ServiceMetadataForABaggage {
    /**
     * The maximum depth that the baggage can have in centimetres
     * @type {number}
     * @memberof ServiceMetadataForABaggage
     */
    maximumDepthCm?: number;
    /**
     * The maximum height that the baggage can have in centimetres
     * @type {number}
     * @memberof ServiceMetadataForABaggage
     */
    maximumHeightCm?: number;
    /**
     * The maximum length that the baggage can have in centimetres
     * @type {number}
     * @memberof ServiceMetadataForABaggage
     */
    maximumLengthCm?: number;
    /**
     * The maximum weight that the baggage can have in kilograms
     * @type {number}
     * @memberof ServiceMetadataForABaggage
     */
    maximumWeightKg?: number;
    /**
     * The type of the baggage
     * @type {string}
     * @memberof ServiceMetadataForABaggage
     */
    type: ServiceMetadataForABaggageTypeEnum;
}


/**
 * @export
 */
export const ServiceMetadataForABaggageTypeEnum = {
    Checked: 'checked',
    CarryOn: 'carry_on'
} as const;
export type ServiceMetadataForABaggageTypeEnum = typeof ServiceMetadataForABaggageTypeEnum[keyof typeof ServiceMetadataForABaggageTypeEnum];


/**
 * Check if a given object implements the ServiceMetadataForABaggage interface.
 */
export function instanceOfServiceMetadataForABaggage(value: object): value is ServiceMetadataForABaggage {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function ServiceMetadataForABaggageFromJSON(json: any): ServiceMetadataForABaggage {
    return ServiceMetadataForABaggageFromJSONTyped(json, false);
}

export function ServiceMetadataForABaggageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMetadataForABaggage {
    if (json == null) {
        return json;
    }
    return {
        
        'maximumDepthCm': json['maximum_depth_cm'] == null ? undefined : json['maximum_depth_cm'],
        'maximumHeightCm': json['maximum_height_cm'] == null ? undefined : json['maximum_height_cm'],
        'maximumLengthCm': json['maximum_length_cm'] == null ? undefined : json['maximum_length_cm'],
        'maximumWeightKg': json['maximum_weight_kg'] == null ? undefined : json['maximum_weight_kg'],
        'type': json['type'],
    };
}

export function ServiceMetadataForABaggageToJSON(value?: ServiceMetadataForABaggage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'maximum_depth_cm': value['maximumDepthCm'],
        'maximum_height_cm': value['maximumHeightCm'],
        'maximum_length_cm': value['maximumLengthCm'],
        'maximum_weight_kg': value['maximumWeightKg'],
        'type': value['type'],
    };
}

