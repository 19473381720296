/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The general category that this value add promotion falls into.
 * @export
 */
export const CategoryValueAdd = {
    FoodAndBeverage: 'food_and_beverage',
    Entertainment: 'entertainment',
    Service: 'service',
    Activity: 'activity',
    Credit: 'credit'
} as const;
export type CategoryValueAdd = typeof CategoryValueAdd[keyof typeof CategoryValueAdd];


export function instanceOfCategoryValueAdd(value: any): boolean {
    for (const key in CategoryValueAdd) {
        if (Object.prototype.hasOwnProperty.call(CategoryValueAdd, key)) {
            if ((CategoryValueAdd as Record<string, CategoryValueAdd>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CategoryValueAddFromJSON(json: any): CategoryValueAdd {
    return CategoryValueAddFromJSONTyped(json, false);
}

export function CategoryValueAddFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryValueAdd {
    return json as CategoryValueAdd;
}

export function CategoryValueAddToJSON(value?: CategoryValueAdd | null): any {
    return value as any;
}
