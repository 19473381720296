/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';

/**
 * A map of links -
 * * `cancel` - Cancel the booking for this room
 * * `change` - A PUT call to modify the details of the booking for this room (soft change)
 * * `shop_for_change` - Shop for rates to evaluate for possible rebooking. This shop call will show the estimated financial impact of the change.<br>
 *   Current parameters supported in shop for change: `checkin`, `checkout`, `occupancy` See: [additional rates](#get-/properties/-property_id-/availability)
 * 
 * @export
 * @interface RoomItineraryLinks
 */
export interface RoomItineraryLinks {
    [key: string]: Link | any;
    /**
     * 
     * @type {Link}
     * @memberof RoomItineraryLinks
     */
    cancel?: Link;
    /**
     * 
     * @type {Link}
     * @memberof RoomItineraryLinks
     */
    change?: Link;
    /**
     * 
     * @type {Link}
     * @memberof RoomItineraryLinks
     */
    shopForChange?: Link;
}

/**
 * Check if a given object implements the RoomItineraryLinks interface.
 */
export function instanceOfRoomItineraryLinks(value: object): value is RoomItineraryLinks {
    return true;
}

export function RoomItineraryLinksFromJSON(json: any): RoomItineraryLinks {
    return RoomItineraryLinksFromJSONTyped(json, false);
}

export function RoomItineraryLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomItineraryLinks {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'cancel': json['cancel'] == null ? undefined : LinkFromJSON(json['cancel']),
        'change': json['change'] == null ? undefined : LinkFromJSON(json['change']),
        'shopForChange': json['shop_for_change'] == null ? undefined : LinkFromJSON(json['shop_for_change']),
    };
}

export function RoomItineraryLinksToJSON(value?: RoomItineraryLinks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'cancel': LinkToJSON(value['cancel']),
        'change': LinkToJSON(value['change']),
        'shop_for_change': LinkToJSON(value['shopForChange']),
    };
}

