/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FlightSearchRequest,
  FlightSearchResponse,
  Status,
} from '../models/index';
import {
    FlightSearchRequestFromJSON,
    FlightSearchRequestToJSON,
    FlightSearchResponseFromJSON,
    FlightSearchResponseToJSON,
    StatusFromJSON,
    StatusToJSON,
} from '../models/index';

export interface SearchFlightsPostRequest {
    flightSearchRequest: FlightSearchRequest;
}

/**
 * 
 */
export class FlightsApi extends runtime.BaseAPI {

    /**
     * Returns a list offers for the given slice
     */
    async searchFlightsPostRaw(requestParameters: SearchFlightsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FlightSearchResponse>> {
        if (requestParameters['flightSearchRequest'] == null) {
            throw new runtime.RequiredError(
                'flightSearchRequest',
                'Required parameter "flightSearchRequest" was null or undefined when calling searchFlightsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/search/flights`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FlightSearchRequestToJSON(requestParameters['flightSearchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FlightSearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list offers for the given slice
     */
    async searchFlightsPost(requestParameters: SearchFlightsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FlightSearchResponse> {
        const response = await this.searchFlightsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a status indicating that the service is operational
     */
    async statusGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Returns a status indicating that the service is operational
     */
    async statusGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
        const response = await this.statusGetRaw(initOverrides);
        return await response.value();
    }

}
