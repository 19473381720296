/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SliceSegmentPassengerBaggage
 */
export interface SliceSegmentPassengerBaggage {
    /**
     * The number of items of baggage of the specified `type` allowed on the segment. Where zero items of a particular `type` are allowed, this may be represented as `quantity: 0`, or there may be no entry for the `type` at all.
     * @type {number}
     * @memberof SliceSegmentPassengerBaggage
     */
    quantity?: number;
    /**
     * The type of the baggage allowance
     * @type {string}
     * @memberof SliceSegmentPassengerBaggage
     */
    type?: SliceSegmentPassengerBaggageTypeEnum;
}


/**
 * @export
 */
export const SliceSegmentPassengerBaggageTypeEnum = {
    Checked: 'checked',
    CarryOn: 'carry_on'
} as const;
export type SliceSegmentPassengerBaggageTypeEnum = typeof SliceSegmentPassengerBaggageTypeEnum[keyof typeof SliceSegmentPassengerBaggageTypeEnum];


/**
 * Check if a given object implements the SliceSegmentPassengerBaggage interface.
 */
export function instanceOfSliceSegmentPassengerBaggage(value: object): value is SliceSegmentPassengerBaggage {
    return true;
}

export function SliceSegmentPassengerBaggageFromJSON(json: any): SliceSegmentPassengerBaggage {
    return SliceSegmentPassengerBaggageFromJSONTyped(json, false);
}

export function SliceSegmentPassengerBaggageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceSegmentPassengerBaggage {
    if (json == null) {
        return json;
    }
    return {
        
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function SliceSegmentPassengerBaggageToJSON(value?: SliceSegmentPassengerBaggage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'quantity': value['quantity'],
        'type': value['type'],
    };
}

