/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AffiliateCollect } from './AffiliateCollect';
import {
    AffiliateCollectFromJSON,
    AffiliateCollectFromJSONTyped,
    AffiliateCollectToJSON,
} from './AffiliateCollect';
import type { CreditCard1 } from './CreditCard1';
import {
    CreditCard1FromJSON,
    CreditCard1FromJSONTyped,
    CreditCard1ToJSON,
} from './CreditCard1';

/**
 * The payment option response.
 * @export
 * @interface PaymentOption
 */
export interface PaymentOption {
    /**
     * 
     * @type {AffiliateCollect}
     * @memberof PaymentOption
     */
    affiliateCollect?: AffiliateCollect;
    /**
     * 
     * @type {CreditCard1}
     * @memberof PaymentOption
     */
    creditCard?: CreditCard1;
}

/**
 * Check if a given object implements the PaymentOption interface.
 */
export function instanceOfPaymentOption(value: object): value is PaymentOption {
    return true;
}

export function PaymentOptionFromJSON(json: any): PaymentOption {
    return PaymentOptionFromJSONTyped(json, false);
}

export function PaymentOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentOption {
    if (json == null) {
        return json;
    }
    return {
        
        'affiliateCollect': json['affiliate_collect'] == null ? undefined : AffiliateCollectFromJSON(json['affiliate_collect']),
        'creditCard': json['credit_card'] == null ? undefined : CreditCard1FromJSON(json['credit_card']),
    };
}

export function PaymentOptionToJSON(value?: PaymentOption | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'affiliate_collect': AffiliateCollectToJSON(value['affiliateCollect']),
        'credit_card': CreditCard1ToJSON(value['creditCard']),
    };
}

