/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoyaltyProgrammeAccount
 */
export interface LoyaltyProgrammeAccount {
    /**
     * The passenger's account number for this loyalty programme account
     * @type {string}
     * @memberof LoyaltyProgrammeAccount
     */
    accountNumber?: string;
    /**
     * The IATA code for the airline that this loyalty programme account belongs to
     * @type {string}
     * @memberof LoyaltyProgrammeAccount
     */
    airlineIataCode?: string;
}

/**
 * Check if a given object implements the LoyaltyProgrammeAccount interface.
 */
export function instanceOfLoyaltyProgrammeAccount(value: object): value is LoyaltyProgrammeAccount {
    return true;
}

export function LoyaltyProgrammeAccountFromJSON(json: any): LoyaltyProgrammeAccount {
    return LoyaltyProgrammeAccountFromJSONTyped(json, false);
}

export function LoyaltyProgrammeAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyProgrammeAccount {
    if (json == null) {
        return json;
    }
    return {
        
        'accountNumber': json['account_number'] == null ? undefined : json['account_number'],
        'airlineIataCode': json['airline_iata_code'] == null ? undefined : json['airline_iata_code'],
    };
}

export function LoyaltyProgrammeAccountToJSON(value?: LoyaltyProgrammeAccount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'account_number': value['accountNumber'],
        'airline_iata_code': value['airlineIataCode'],
    };
}

