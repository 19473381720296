/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Container for the property's address information.
 * @export
 * @interface HotelContentContactAddress
 */
export interface HotelContentContactAddress {
    /**
     * Address line 1.
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    line1?: string;
    /**
     * Address line 2.
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    line2?: string;
    /**
     * City.
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    city?: string;
    /**
     * City Code.
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    code?: string;
    /**
     * City Code.
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    destinationCode?: string;
    /**
     * 2-letter code used for Australia, Canada, and the USA.
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    stateCode?: string;
    /**
     * Text name of the State/Province - more common for additional countries.
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    state?: string;
    /**
     * Postal/zip code.
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    postalCode?: string;
    /**
     * Country
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    country?: string;
    /**
     * 2-letter country code, in ISO 3166-1 alpha-2 format.
     * @type {string}
     * @memberof HotelContentContactAddress
     */
    countryCode?: string;
}

/**
 * Check if a given object implements the HotelContentContactAddress interface.
 */
export function instanceOfHotelContentContactAddress(value: object): value is HotelContentContactAddress {
    return true;
}

export function HotelContentContactAddressFromJSON(json: any): HotelContentContactAddress {
    return HotelContentContactAddressFromJSONTyped(json, false);
}

export function HotelContentContactAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentContactAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'line1': json['Line1'] == null ? undefined : json['Line1'],
        'line2': json['Line2'] == null ? undefined : json['Line2'],
        'city': json['City'] == null ? undefined : json['City'],
        'code': json['Code'] == null ? undefined : json['Code'],
        'destinationCode': json['DestinationCode'] == null ? undefined : json['DestinationCode'],
        'stateCode': json['StateCode'] == null ? undefined : json['StateCode'],
        'state': json['State'] == null ? undefined : json['State'],
        'postalCode': json['PostalCode'] == null ? undefined : json['PostalCode'],
        'country': json['Country'] == null ? undefined : json['Country'],
        'countryCode': json['CountryCode'] == null ? undefined : json['CountryCode'],
    };
}

export function HotelContentContactAddressToJSON(value?: HotelContentContactAddress | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Line1': value['line1'],
        'Line2': value['line2'],
        'City': value['city'],
        'Code': value['code'],
        'DestinationCode': value['destinationCode'],
        'StateCode': value['stateCode'],
        'State': value['state'],
        'PostalCode': value['postalCode'],
        'Country': value['country'],
        'CountryCode': value['countryCode'],
    };
}

