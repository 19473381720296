/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Filters for a search request
 * @export
 * @interface SearchFilters
 */
export interface SearchFilters {
    /**
     * A list of ratings to filter by
     * @type {Array<number>}
     * @memberof SearchFilters
     */
    ratings?: Array<number>;
    /**
     * If true, only show hotels that the user can afford with points
     * @type {boolean}
     * @memberof SearchFilters
     */
    requireCanAffordPoints?: boolean;
    /**
     * If true, only show hotels that the user can afford with cash
     * @type {boolean}
     * @memberof SearchFilters
     */
    requireCanAffordCash?: boolean;
    /**
     * The minimum percentile of the points deal
     * @type {number}
     * @memberof SearchFilters
     */
    minPointsPercentile?: number;
    /**
     * The maximum percentile of the points deal
     * @type {number}
     * @memberof SearchFilters
     */
    maxPointsPercentile?: number;
    /**
     * The minimum percentile of the cash deal
     * @type {number}
     * @memberof SearchFilters
     */
    minCashPercentile?: number;
    /**
     * The maximum percentile of the cash deal
     * @type {number}
     * @memberof SearchFilters
     */
    maxCashPercentile?: number;
    /**
     * If true, only show hotels from brands that are recommended
     * @type {boolean}
     * @memberof SearchFilters
     */
    onlyRecommendedBrands?: boolean;
    /**
     * If true, include airport hotels in the search results
     * @type {boolean}
     * @memberof SearchFilters
     */
    includeAirportHotels?: boolean;
}

/**
 * Check if a given object implements the SearchFilters interface.
 */
export function instanceOfSearchFilters(value: object): value is SearchFilters {
    return true;
}

export function SearchFiltersFromJSON(json: any): SearchFilters {
    return SearchFiltersFromJSONTyped(json, false);
}

export function SearchFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFilters {
    if (json == null) {
        return json;
    }
    return {
        
        'ratings': json['ratings'] == null ? undefined : json['ratings'],
        'requireCanAffordPoints': json['require_can_afford_points'] == null ? undefined : json['require_can_afford_points'],
        'requireCanAffordCash': json['require_can_afford_cash'] == null ? undefined : json['require_can_afford_cash'],
        'minPointsPercentile': json['min_points_percentile'] == null ? undefined : json['min_points_percentile'],
        'maxPointsPercentile': json['max_points_percentile'] == null ? undefined : json['max_points_percentile'],
        'minCashPercentile': json['min_cash_percentile'] == null ? undefined : json['min_cash_percentile'],
        'maxCashPercentile': json['max_cash_percentile'] == null ? undefined : json['max_cash_percentile'],
        'onlyRecommendedBrands': json['only_recommended_brands'] == null ? undefined : json['only_recommended_brands'],
        'includeAirportHotels': json['include_airport_hotels'] == null ? undefined : json['include_airport_hotels'],
    };
}

export function SearchFiltersToJSON(value?: SearchFilters | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ratings': value['ratings'],
        'require_can_afford_points': value['requireCanAffordPoints'],
        'require_can_afford_cash': value['requireCanAffordCash'],
        'min_points_percentile': value['minPointsPercentile'],
        'max_points_percentile': value['maxPointsPercentile'],
        'min_cash_percentile': value['minCashPercentile'],
        'max_cash_percentile': value['maxCashPercentile'],
        'only_recommended_brands': value['onlyRecommendedBrands'],
        'include_airport_hotels': value['includeAirportHotels'],
    };
}

