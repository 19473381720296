/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The trader address.
 * @export
 * @interface TraderAddress
 */
export interface TraderAddress {
    /**
     * First line of customer's street address.
     * @type {string}
     * @memberof TraderAddress
     */
    line1?: string;
    /**
     * Second line of customer's street address.
     * @type {string}
     * @memberof TraderAddress
     */
    line2?: string;
    /**
     * Third line of customer's street address.
     * @type {string}
     * @memberof TraderAddress
     */
    line3?: string;
    /**
     * Customer's city.
     * @type {string}
     * @memberof TraderAddress
     */
    city?: string;
    /**
     * Customer's state or province code.
     * @type {string}
     * @memberof TraderAddress
     */
    stateProvinceCode?: string;
    /**
     * Customer's postal code.
     * @type {string}
     * @memberof TraderAddress
     */
    postalCode?: string;
    /**
     * Customer's country code, in two-letter ISO 3166-1 alpha-2 format.
     * @type {string}
     * @memberof TraderAddress
     */
    countryCode?: string;
}

/**
 * Check if a given object implements the TraderAddress interface.
 */
export function instanceOfTraderAddress(value: object): value is TraderAddress {
    return true;
}

export function TraderAddressFromJSON(json: any): TraderAddress {
    return TraderAddressFromJSONTyped(json, false);
}

export function TraderAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): TraderAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'line1': json['line_1'] == null ? undefined : json['line_1'],
        'line2': json['line_2'] == null ? undefined : json['line_2'],
        'line3': json['line_3'] == null ? undefined : json['line_3'],
        'city': json['city'] == null ? undefined : json['city'],
        'stateProvinceCode': json['state_province_code'] == null ? undefined : json['state_province_code'],
        'postalCode': json['postal_code'] == null ? undefined : json['postal_code'],
        'countryCode': json['country_code'] == null ? undefined : json['country_code'],
    };
}

export function TraderAddressToJSON(value?: TraderAddress | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'line_1': value['line1'],
        'line_2': value['line2'],
        'line_3': value['line3'],
        'city': value['city'],
        'state_province_code': value['stateProvinceCode'],
        'postal_code': value['postalCode'],
        'country_code': value['countryCode'],
    };
}

