/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An individual guest review.
 * @export
 * @interface GuestReview
 */
export interface GuestReview {
    /**
     * The name of the person who wrote this review.
     * @type {string}
     * @memberof GuestReview
     */
    reviewerName?: string;
    /**
     * Where this review has been verified from.
     * @type {string}
     * @memberof GuestReview
     */
    source?: string;
    /**
     * Title of the review.
     * @type {string}
     * @memberof GuestReview
     */
    title?: string;
    /**
     * Review description.
     * @type {string}
     * @memberof GuestReview
     */
    text?: string;
    /**
     * Rating for this property given by reviewer.
     * @type {string}
     * @memberof GuestReview
     */
    rating?: string;
    /**
     * The companion category for any travelers that accompanied this reviewer.
     * @type {string}
     * @memberof GuestReview
     */
    travelCompanion?: string;
}

/**
 * Check if a given object implements the GuestReview interface.
 */
export function instanceOfGuestReview(value: object): value is GuestReview {
    return true;
}

export function GuestReviewFromJSON(json: any): GuestReview {
    return GuestReviewFromJSONTyped(json, false);
}

export function GuestReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestReview {
    if (json == null) {
        return json;
    }
    return {
        
        'reviewerName': json['ReviewerName'] == null ? undefined : json['ReviewerName'],
        'source': json['Source'] == null ? undefined : json['Source'],
        'title': json['Title'] == null ? undefined : json['Title'],
        'text': json['Text'] == null ? undefined : json['Text'],
        'rating': json['Rating'] == null ? undefined : json['Rating'],
        'travelCompanion': json['TravelCompanion'] == null ? undefined : json['TravelCompanion'],
    };
}

export function GuestReviewToJSON(value?: GuestReview | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ReviewerName': value['reviewerName'],
        'Source': value['source'],
        'Title': value['title'],
        'Text': value['text'],
        'Rating': value['rating'],
        'TravelCompanion': value['travelCompanion'],
    };
}

