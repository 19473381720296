/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The entire phone number must be represented across the three fields in this object.
 * @export
 * @interface Phone
 */
export interface Phone {
    /**
     * The numerical portion of the country code from the phone number. Do not include the leading '+' character.
     * @type {string}
     * @memberof Phone
     */
    countryCode?: string;
    /**
     * The area code of the phone number.
     * @type {string}
     * @memberof Phone
     */
    areaCode?: string;
    /**
     * The remaining digits of the phone number.
     * @type {string}
     * @memberof Phone
     */
    number?: string;
}

/**
 * Check if a given object implements the Phone interface.
 */
export function instanceOfPhone(value: object): value is Phone {
    return true;
}

export function PhoneFromJSON(json: any): Phone {
    return PhoneFromJSONTyped(json, false);
}

export function PhoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Phone {
    if (json == null) {
        return json;
    }
    return {
        
        'countryCode': json['country_code'] == null ? undefined : json['country_code'],
        'areaCode': json['area_code'] == null ? undefined : json['area_code'],
        'number': json['number'] == null ? undefined : json['number'],
    };
}

export function PhoneToJSON(value?: Phone | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'country_code': value['countryCode'],
        'area_code': value['areaCode'],
        'number': value['number'],
    };
}

