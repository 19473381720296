/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A theme for a hotel
 * @export
 */
export const HotelTheme = {
    All: 'all',
    AdventureSport: 'adventure sport',
    Beach: 'beach',
    Boutique: 'boutique',
    Business: 'business',
    Casino: 'casino',
    City: 'city',
    EcoCertified: 'eco-certified',
    Family: 'family',
    Golf: 'golf',
    GondolaRecommended: 'gondola recommended',
    Historic: 'historic',
    HotSprings: 'hot springs',
    Luxury: 'luxury',
    Natural: 'natural',
    PetFriendly: 'pet-friendly',
    Romantic: 'romantic',
    Shopping: 'shopping',
    Ski: 'ski',
    Spa: 'spa',
    Winery: 'winery'
} as const;
export type HotelTheme = typeof HotelTheme[keyof typeof HotelTheme];


export function instanceOfHotelTheme(value: any): boolean {
    for (const key in HotelTheme) {
        if (Object.prototype.hasOwnProperty.call(HotelTheme, key)) {
            if ((HotelTheme as Record<string, HotelTheme>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function HotelThemeFromJSON(json: any): HotelTheme {
    return HotelThemeFromJSONTyped(json, false);
}

export function HotelThemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelTheme {
    return json as HotelTheme;
}

export function HotelThemeToJSON(value?: HotelTheme | null): any {
    return value as any;
}
