/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchLocation } from './SearchLocation';
import {
    SearchLocationFromJSON,
    SearchLocationFromJSONTyped,
    SearchLocationToJSON,
} from './SearchLocation';
import type { SearchParams } from './SearchParams';
import {
    SearchParamsFromJSON,
    SearchParamsFromJSONTyped,
    SearchParamsToJSON,
} from './SearchParams';
import type { SearchOccupants } from './SearchOccupants';
import {
    SearchOccupantsFromJSON,
    SearchOccupantsFromJSONTyped,
    SearchOccupantsToJSON,
} from './SearchOccupants';
import type { SearchMetadata } from './SearchMetadata';
import {
    SearchMetadataFromJSON,
    SearchMetadataFromJSONTyped,
    SearchMetadataToJSON,
} from './SearchMetadata';
import type { UserEnvironment } from './UserEnvironment';
import {
    UserEnvironmentFromJSON,
    UserEnvironmentFromJSONTyped,
    UserEnvironmentToJSON,
} from './UserEnvironment';
import type { SearchDates } from './SearchDates';
import {
    SearchDatesFromJSON,
    SearchDatesFromJSONTyped,
    SearchDatesToJSON,
} from './SearchDates';

/**
 * A hotel search.
 * @export
 * @interface HotelSearchRequest
 */
export interface HotelSearchRequest {
    /**
     * 
     * @type {UserEnvironment}
     * @memberof HotelSearchRequest
     */
    userEnvironment?: UserEnvironment;
    /**
     * 
     * @type {SearchMetadata}
     * @memberof HotelSearchRequest
     */
    searchMetadata?: SearchMetadata;
    /**
     * 
     * @type {SearchLocation}
     * @memberof HotelSearchRequest
     */
    searchLocation: SearchLocation;
    /**
     * 
     * @type {SearchDates}
     * @memberof HotelSearchRequest
     */
    searchDates?: SearchDates;
    /**
     * 
     * @type {SearchOccupants}
     * @memberof HotelSearchRequest
     */
    searchOccupants?: SearchOccupants;
    /**
     * 
     * @type {SearchParams}
     * @memberof HotelSearchRequest
     */
    searchParams?: SearchParams;
}

/**
 * Check if a given object implements the HotelSearchRequest interface.
 */
export function instanceOfHotelSearchRequest(value: object): value is HotelSearchRequest {
    if (!('searchLocation' in value) || value['searchLocation'] === undefined) return false;
    return true;
}

export function HotelSearchRequestFromJSON(json: any): HotelSearchRequest {
    return HotelSearchRequestFromJSONTyped(json, false);
}

export function HotelSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelSearchRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userEnvironment': json['user_environment'] == null ? undefined : UserEnvironmentFromJSON(json['user_environment']),
        'searchMetadata': json['search_metadata'] == null ? undefined : SearchMetadataFromJSON(json['search_metadata']),
        'searchLocation': SearchLocationFromJSON(json['search_location']),
        'searchDates': json['search_dates'] == null ? undefined : SearchDatesFromJSON(json['search_dates']),
        'searchOccupants': json['search_occupants'] == null ? undefined : SearchOccupantsFromJSON(json['search_occupants']),
        'searchParams': json['search_params'] == null ? undefined : SearchParamsFromJSON(json['search_params']),
    };
}

export function HotelSearchRequestToJSON(value?: HotelSearchRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_environment': UserEnvironmentToJSON(value['userEnvironment']),
        'search_metadata': SearchMetadataToJSON(value['searchMetadata']),
        'search_location': SearchLocationToJSON(value['searchLocation']),
        'search_dates': SearchDatesToJSON(value['searchDates']),
        'search_occupants': SearchOccupantsToJSON(value['searchOccupants']),
        'search_params': SearchParamsToJSON(value['searchParams']),
    };
}

