/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MerchantOfRecord } from './MerchantOfRecord';
import {
    MerchantOfRecordFromJSON,
    MerchantOfRecordFromJSONTyped,
    MerchantOfRecordToJSON,
} from './MerchantOfRecord';
import type { RateSummaryCharge } from './RateSummaryCharge';
import {
    RateSummaryChargeFromJSON,
    RateSummaryChargeFromJSONTyped,
    RateSummaryChargeToJSON,
} from './RateSummaryCharge';
import type { RateType } from './RateType';
import {
    RateTypeFromJSON,
    RateTypeFromJSONTyped,
    RateTypeToJSON,
} from './RateType';

/**
 * 
 * @export
 * @interface RateSummary
 */
export interface RateSummary {
    /**
     * 
     * @type {string}
     * @memberof RateSummary
     */
    rateId: string;
    /**
     * If the rate is refundable or not
     * @type {boolean}
     * @memberof RateSummary
     */
    refundable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RateSummary
     */
    totalRoomBaseRate: string;
    /**
     * The total dollar amount (exclusiveAmount) divided by the number of nights formatted as a string with currency
     * @type {string}
     * @memberof RateSummary
     */
    averageNightlyRate?: string;
    /**
     * 
     * @type {Array<RateSummaryCharge>}
     * @memberof RateSummary
     */
    nightlyRoomBaseRates: Array<RateSummaryCharge>;
    /**
     * 
     * @type {string}
     * @memberof RateSummary
     */
    taxesAndFees?: string;
    /**
     * 
     * @type {string}
     * @memberof RateSummary
     */
    propertyFee?: string;
    /**
     * 
     * @type {string}
     * @memberof RateSummary
     */
    subTotal: string;
    /**
     * 
     * @type {string}
     * @memberof RateSummary
     */
    totalDueToday?: string;
    /**
     * 
     * @type {string}
     * @memberof RateSummary
     */
    totalDueAtProperty?: string;
    /**
     * 
     * @type {Array<RateSummaryCharge>}
     * @memberof RateSummary
     */
    deposits: Array<RateSummaryCharge>;
    /**
     * 
     * @type {Array<RateSummaryCharge>}
     * @memberof RateSummary
     */
    chargesDueAtProperty: Array<RateSummaryCharge>;
    /**
     * 
     * @type {string}
     * @memberof RateSummary
     */
    total?: string;
    /**
     * 
     * @type {MerchantOfRecord}
     * @memberof RateSummary
     */
    merchantOfRecord: MerchantOfRecord;
    /**
     * If refundable, simplified information about cancellation. This is not the full cancellation policy.
     * @type {string}
     * @memberof RateSummary
     */
    cancellationSummary?: string;
    /**
     * The full cancellation policy
     * @type {Array<string>}
     * @memberof RateSummary
     */
    cancellationPolicyFull?: Array<string>;
    /**
     * Loyalty earnings
     * @type {string}
     * @memberof RateSummary
     */
    earnings?: string;
    /**
     * The number of nights earned for a given stay
     * @type {string}
     * @memberof RateSummary
     */
    statusNights?: string;
    /**
     * 
     * @type {RateType}
     * @memberof RateSummary
     */
    rateType?: RateType;
    /**
     * The rate token required for a price check
     * @type {string}
     * @memberof RateSummary
     */
    rateToken?: string;
    /**
     * The cash amount earned from this booking as part of gondola rewards
     * @type {string}
     * @memberof RateSummary
     */
    gondolaEarnings?: string;
    /**
     * The total cash amount earned from this booking. This is the sum of gondola_earnings and the cash equivalent of points earnings.
     * @type {string}
     * @memberof RateSummary
     */
    totalEarnings?: string;
}

/**
 * Check if a given object implements the RateSummary interface.
 */
export function instanceOfRateSummary(value: object): value is RateSummary {
    if (!('rateId' in value) || value['rateId'] === undefined) return false;
    if (!('totalRoomBaseRate' in value) || value['totalRoomBaseRate'] === undefined) return false;
    if (!('nightlyRoomBaseRates' in value) || value['nightlyRoomBaseRates'] === undefined) return false;
    if (!('subTotal' in value) || value['subTotal'] === undefined) return false;
    if (!('deposits' in value) || value['deposits'] === undefined) return false;
    if (!('chargesDueAtProperty' in value) || value['chargesDueAtProperty'] === undefined) return false;
    if (!('merchantOfRecord' in value) || value['merchantOfRecord'] === undefined) return false;
    return true;
}

export function RateSummaryFromJSON(json: any): RateSummary {
    return RateSummaryFromJSONTyped(json, false);
}

export function RateSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'rateId': json['rate_id'],
        'refundable': json['refundable'] == null ? undefined : json['refundable'],
        'totalRoomBaseRate': json['total_room_base_rate'],
        'averageNightlyRate': json['average_nightly_rate'] == null ? undefined : json['average_nightly_rate'],
        'nightlyRoomBaseRates': ((json['nightly_room_base_rates'] as Array<any>).map(RateSummaryChargeFromJSON)),
        'taxesAndFees': json['taxes_and_fees'] == null ? undefined : json['taxes_and_fees'],
        'propertyFee': json['property_fee'] == null ? undefined : json['property_fee'],
        'subTotal': json['sub_total'],
        'totalDueToday': json['total_due_today'] == null ? undefined : json['total_due_today'],
        'totalDueAtProperty': json['total_due_at_property'] == null ? undefined : json['total_due_at_property'],
        'deposits': ((json['deposits'] as Array<any>).map(RateSummaryChargeFromJSON)),
        'chargesDueAtProperty': ((json['charges_due_at_property'] as Array<any>).map(RateSummaryChargeFromJSON)),
        'total': json['total'] == null ? undefined : json['total'],
        'merchantOfRecord': MerchantOfRecordFromJSON(json['merchant_of_record']),
        'cancellationSummary': json['cancellation_summary'] == null ? undefined : json['cancellation_summary'],
        'cancellationPolicyFull': json['cancellation_policy_full'] == null ? undefined : json['cancellation_policy_full'],
        'earnings': json['earnings'] == null ? undefined : json['earnings'],
        'statusNights': json['status_nights'] == null ? undefined : json['status_nights'],
        'rateType': json['rate_type'] == null ? undefined : RateTypeFromJSON(json['rate_type']),
        'rateToken': json['rate_token'] == null ? undefined : json['rate_token'],
        'gondolaEarnings': json['gondola_earnings'] == null ? undefined : json['gondola_earnings'],
        'totalEarnings': json['total_earnings'] == null ? undefined : json['total_earnings'],
    };
}

export function RateSummaryToJSON(value?: RateSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rate_id': value['rateId'],
        'refundable': value['refundable'],
        'total_room_base_rate': value['totalRoomBaseRate'],
        'average_nightly_rate': value['averageNightlyRate'],
        'nightly_room_base_rates': ((value['nightlyRoomBaseRates'] as Array<any>).map(RateSummaryChargeToJSON)),
        'taxes_and_fees': value['taxesAndFees'],
        'property_fee': value['propertyFee'],
        'sub_total': value['subTotal'],
        'total_due_today': value['totalDueToday'],
        'total_due_at_property': value['totalDueAtProperty'],
        'deposits': ((value['deposits'] as Array<any>).map(RateSummaryChargeToJSON)),
        'charges_due_at_property': ((value['chargesDueAtProperty'] as Array<any>).map(RateSummaryChargeToJSON)),
        'total': value['total'],
        'merchant_of_record': MerchantOfRecordToJSON(value['merchantOfRecord']),
        'cancellation_summary': value['cancellationSummary'],
        'cancellation_policy_full': value['cancellationPolicyFull'],
        'earnings': value['earnings'],
        'status_nights': value['statusNights'],
        'rate_type': RateTypeToJSON(value['rateType']),
        'rate_token': value['rateToken'],
        'gondola_earnings': value['gondolaEarnings'],
        'total_earnings': value['totalEarnings'],
    };
}

