/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferSliceSegmentStopAirport } from './OfferSliceSegmentStopAirport';
import {
    OfferSliceSegmentStopAirportFromJSON,
    OfferSliceSegmentStopAirportFromJSONTyped,
    OfferSliceSegmentStopAirportToJSON,
} from './OfferSliceSegmentStopAirport';

/**
 * 
 * @export
 * @interface OfferSliceSegmentStop
 */
export interface OfferSliceSegmentStop {
    /**
     * 
     * @type {OfferSliceSegmentStopAirport}
     * @memberof OfferSliceSegmentStop
     */
    airport: OfferSliceSegmentStopAirport;
    /**
     * The [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) datetime at which the stop is scheduled to arrive, in the airport's timezone (see `destination.timezone`)
     * @type {string}
     * @memberof OfferSliceSegmentStop
     */
    arrivingAt: string;
    /**
     * The [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) datetime at which the stop is scheduled to depart, in the airport's timezone (see `origin.timezone`)
     * @type {string}
     * @memberof OfferSliceSegmentStop
     */
    departingAt: string;
    /**
     * The duration of the stop, represented as a [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601#Durations) duration
     * @type {string}
     * @memberof OfferSliceSegmentStop
     */
    duration: string;
    /**
     * Duffel's unique identifier for the resource
     * @type {string}
     * @memberof OfferSliceSegmentStop
     */
    id: string;
}

/**
 * Check if a given object implements the OfferSliceSegmentStop interface.
 */
export function instanceOfOfferSliceSegmentStop(value: object): value is OfferSliceSegmentStop {
    if (!('airport' in value) || value['airport'] === undefined) return false;
    if (!('arrivingAt' in value) || value['arrivingAt'] === undefined) return false;
    if (!('departingAt' in value) || value['departingAt'] === undefined) return false;
    if (!('duration' in value) || value['duration'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function OfferSliceSegmentStopFromJSON(json: any): OfferSliceSegmentStop {
    return OfferSliceSegmentStopFromJSONTyped(json, false);
}

export function OfferSliceSegmentStopFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentStop {
    if (json == null) {
        return json;
    }
    return {
        
        'airport': OfferSliceSegmentStopAirportFromJSON(json['airport']),
        'arrivingAt': json['arriving_at'],
        'departingAt': json['departing_at'],
        'duration': json['duration'],
        'id': json['id'],
    };
}

export function OfferSliceSegmentStopToJSON(value?: OfferSliceSegmentStop | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'airport': OfferSliceSegmentStopAirportToJSON(value['airport']),
        'arriving_at': value['arrivingAt'],
        'departing_at': value['departingAt'],
        'duration': value['duration'],
        'id': value['id'],
    };
}

