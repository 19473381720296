/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingStatusesRequest
 */
export interface BookingStatusesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof BookingStatusesRequest
     */
    bookingIds: Array<number>;
}

/**
 * Check if a given object implements the BookingStatusesRequest interface.
 */
export function instanceOfBookingStatusesRequest(value: object): value is BookingStatusesRequest {
    if (!('bookingIds' in value) || value['bookingIds'] === undefined) return false;
    return true;
}

export function BookingStatusesRequestFromJSON(json: any): BookingStatusesRequest {
    return BookingStatusesRequestFromJSONTyped(json, false);
}

export function BookingStatusesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingStatusesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'bookingIds': json['booking_ids'],
    };
}

export function BookingStatusesRequestToJSON(value?: BookingStatusesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'booking_ids': value['bookingIds'],
    };
}

