/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchSessionV2Results } from './SearchSessionV2Results';
import {
    SearchSessionV2ResultsFromJSON,
    SearchSessionV2ResultsFromJSONTyped,
    SearchSessionV2ResultsToJSON,
} from './SearchSessionV2Results';
import type { SearchPromptResponse } from './SearchPromptResponse';
import {
    SearchPromptResponseFromJSON,
    SearchPromptResponseFromJSONTyped,
    SearchPromptResponseToJSON,
} from './SearchPromptResponse';
import type { SearchSessionStatus } from './SearchSessionStatus';
import {
    SearchSessionStatusFromJSON,
    SearchSessionStatusFromJSONTyped,
    SearchSessionStatusToJSON,
} from './SearchSessionStatus';
import type { SearchRequestV2 } from './SearchRequestV2';
import {
    SearchRequestV2FromJSON,
    SearchRequestV2FromJSONTyped,
    SearchRequestV2ToJSON,
} from './SearchRequestV2';

/**
 * A search session
 * @export
 * @interface SearchSessionV2
 */
export interface SearchSessionV2 {
    /**
     * The search session id
     * @type {string}
     * @memberof SearchSessionV2
     */
    id?: string;
    /**
     * 
     * @type {SearchSessionStatus}
     * @memberof SearchSessionV2
     */
    sessionStatus?: SearchSessionStatus;
    /**
     * 
     * @type {SearchRequestV2}
     * @memberof SearchSessionV2
     */
    request?: SearchRequestV2;
    /**
     * 
     * @type {SearchPromptResponse}
     * @memberof SearchSessionV2
     */
    promptResponse?: SearchPromptResponse;
    /**
     * 
     * @type {SearchSessionV2Results}
     * @memberof SearchSessionV2
     */
    results?: SearchSessionV2Results;
    /**
     * The time the search session was created
     * @type {Date}
     * @memberof SearchSessionV2
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the SearchSessionV2 interface.
 */
export function instanceOfSearchSessionV2(value: object): value is SearchSessionV2 {
    return true;
}

export function SearchSessionV2FromJSON(json: any): SearchSessionV2 {
    return SearchSessionV2FromJSONTyped(json, false);
}

export function SearchSessionV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSessionV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'sessionStatus': json['session_status'] == null ? undefined : SearchSessionStatusFromJSON(json['session_status']),
        'request': json['request'] == null ? undefined : SearchRequestV2FromJSON(json['request']),
        'promptResponse': json['prompt_response'] == null ? undefined : SearchPromptResponseFromJSON(json['prompt_response']),
        'results': json['results'] == null ? undefined : SearchSessionV2ResultsFromJSON(json['results']),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
    };
}

export function SearchSessionV2ToJSON(value?: SearchSessionV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'session_status': SearchSessionStatusToJSON(value['sessionStatus']),
        'request': SearchRequestV2ToJSON(value['request']),
        'prompt_response': SearchPromptResponseToJSON(value['promptResponse']),
        'results': SearchSessionV2ResultsToJSON(value['results']),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
    };
}

