/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferSliceSegmentPassengerCabinAmenitiesPower } from './OfferSliceSegmentPassengerCabinAmenitiesPower';
import {
    OfferSliceSegmentPassengerCabinAmenitiesPowerFromJSON,
    OfferSliceSegmentPassengerCabinAmenitiesPowerFromJSONTyped,
    OfferSliceSegmentPassengerCabinAmenitiesPowerToJSON,
} from './OfferSliceSegmentPassengerCabinAmenitiesPower';
import type { OfferSliceSegmentPassengerCabinAmenitiesSeat } from './OfferSliceSegmentPassengerCabinAmenitiesSeat';
import {
    OfferSliceSegmentPassengerCabinAmenitiesSeatFromJSON,
    OfferSliceSegmentPassengerCabinAmenitiesSeatFromJSONTyped,
    OfferSliceSegmentPassengerCabinAmenitiesSeatToJSON,
} from './OfferSliceSegmentPassengerCabinAmenitiesSeat';
import type { OfferSliceSegmentPassengerCabinAmenitiesWifi } from './OfferSliceSegmentPassengerCabinAmenitiesWifi';
import {
    OfferSliceSegmentPassengerCabinAmenitiesWifiFromJSON,
    OfferSliceSegmentPassengerCabinAmenitiesWifiFromJSONTyped,
    OfferSliceSegmentPassengerCabinAmenitiesWifiToJSON,
} from './OfferSliceSegmentPassengerCabinAmenitiesWifi';

/**
 * The amenities specific to this cabin class on this plane
 * @export
 * @interface OfferSliceSegmentPassengerCabinAmenities
 */
export interface OfferSliceSegmentPassengerCabinAmenities {
    /**
     * 
     * @type {OfferSliceSegmentPassengerCabinAmenitiesPower}
     * @memberof OfferSliceSegmentPassengerCabinAmenities
     */
    power?: OfferSliceSegmentPassengerCabinAmenitiesPower;
    /**
     * 
     * @type {OfferSliceSegmentPassengerCabinAmenitiesSeat}
     * @memberof OfferSliceSegmentPassengerCabinAmenities
     */
    seat?: OfferSliceSegmentPassengerCabinAmenitiesSeat;
    /**
     * 
     * @type {OfferSliceSegmentPassengerCabinAmenitiesWifi}
     * @memberof OfferSliceSegmentPassengerCabinAmenities
     */
    wifi?: OfferSliceSegmentPassengerCabinAmenitiesWifi;
}

/**
 * Check if a given object implements the OfferSliceSegmentPassengerCabinAmenities interface.
 */
export function instanceOfOfferSliceSegmentPassengerCabinAmenities(value: object): value is OfferSliceSegmentPassengerCabinAmenities {
    return true;
}

export function OfferSliceSegmentPassengerCabinAmenitiesFromJSON(json: any): OfferSliceSegmentPassengerCabinAmenities {
    return OfferSliceSegmentPassengerCabinAmenitiesFromJSONTyped(json, false);
}

export function OfferSliceSegmentPassengerCabinAmenitiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentPassengerCabinAmenities {
    if (json == null) {
        return json;
    }
    return {
        
        'power': json['power'] == null ? undefined : OfferSliceSegmentPassengerCabinAmenitiesPowerFromJSON(json['power']),
        'seat': json['seat'] == null ? undefined : OfferSliceSegmentPassengerCabinAmenitiesSeatFromJSON(json['seat']),
        'wifi': json['wifi'] == null ? undefined : OfferSliceSegmentPassengerCabinAmenitiesWifiFromJSON(json['wifi']),
    };
}

export function OfferSliceSegmentPassengerCabinAmenitiesToJSON(value?: OfferSliceSegmentPassengerCabinAmenities | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'power': OfferSliceSegmentPassengerCabinAmenitiesPowerToJSON(value['power']),
        'seat': OfferSliceSegmentPassengerCabinAmenitiesSeatToJSON(value['seat']),
        'wifi': OfferSliceSegmentPassengerCabinAmenitiesWifiToJSON(value['wifi']),
    };
}

