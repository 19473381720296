/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelContentImagesInnerLinksInner } from './HotelContentImagesInnerLinksInner';
import {
    HotelContentImagesInnerLinksInnerFromJSON,
    HotelContentImagesInnerLinksInnerFromJSONTyped,
    HotelContentImagesInnerLinksInnerToJSON,
} from './HotelContentImagesInnerLinksInner';

/**
 * An individual image.
 * @export
 * @interface HotelContentImagesInner
 */
export interface HotelContentImagesInner {
    /**
     * Image caption.
     * @type {string}
     * @memberof HotelContentImagesInner
     */
    caption?: string;
    /**
     * The category of the image.
     * @type {string}
     * @memberof HotelContentImagesInner
     */
    category?: string;
    /**
     * The list of room ids against which this image should be tagged.
     * @type {Array<string>}
     * @memberof HotelContentImagesInner
     */
    roomId?: Array<string>;
    /**
     * Contains URLs for all of the image sizes available.  Sizes include: 70 px, 350 px, and 1,000 px
     * @type {Array<HotelContentImagesInnerLinksInner>}
     * @memberof HotelContentImagesInner
     */
    links?: Array<HotelContentImagesInnerLinksInner>;
}

/**
 * Check if a given object implements the HotelContentImagesInner interface.
 */
export function instanceOfHotelContentImagesInner(value: object): value is HotelContentImagesInner {
    return true;
}

export function HotelContentImagesInnerFromJSON(json: any): HotelContentImagesInner {
    return HotelContentImagesInnerFromJSONTyped(json, false);
}

export function HotelContentImagesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentImagesInner {
    if (json == null) {
        return json;
    }
    return {
        
        'caption': json['Caption'] == null ? undefined : json['Caption'],
        'category': json['Category'] == null ? undefined : json['Category'],
        'roomId': json['RoomId'] == null ? undefined : json['RoomId'],
        'links': json['Links'] == null ? undefined : ((json['Links'] as Array<any>).map(HotelContentImagesInnerLinksInnerFromJSON)),
    };
}

export function HotelContentImagesInnerToJSON(value?: HotelContentImagesInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Caption': value['caption'],
        'Category': value['category'],
        'RoomId': value['roomId'],
        'Links': value['links'] == null ? undefined : ((value['links'] as Array<any>).map(HotelContentImagesInnerLinksInnerToJSON)),
    };
}

