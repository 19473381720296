/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * A search for flights
 * @export
 * @interface FlightSearchRequest
 */
export interface FlightSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof FlightSearchRequest
     */
    id?: string;
    /**
     * 
     * @type {Location}
     * @memberof FlightSearchRequest
     */
    origin: Location;
    /**
     * 
     * @type {Date}
     * @memberof FlightSearchRequest
     */
    departureDate: Date;
    /**
     * 
     * @type {Location}
     * @memberof FlightSearchRequest
     */
    destination: Location;
    /**
     * 
     * @type {Date}
     * @memberof FlightSearchRequest
     */
    returnDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof FlightSearchRequest
     */
    cabinClass?: FlightSearchRequestCabinClassEnum;
    /**
     * 
     * @type {number}
     * @memberof FlightSearchRequest
     */
    numPassengers: number;
}


/**
 * @export
 */
export const FlightSearchRequestCabinClassEnum = {
    First: 'first',
    Business: 'business',
    PremiumEconomy: 'premium economy',
    Economy: 'economy'
} as const;
export type FlightSearchRequestCabinClassEnum = typeof FlightSearchRequestCabinClassEnum[keyof typeof FlightSearchRequestCabinClassEnum];


/**
 * Check if a given object implements the FlightSearchRequest interface.
 */
export function instanceOfFlightSearchRequest(value: object): value is FlightSearchRequest {
    if (!('origin' in value) || value['origin'] === undefined) return false;
    if (!('departureDate' in value) || value['departureDate'] === undefined) return false;
    if (!('destination' in value) || value['destination'] === undefined) return false;
    if (!('numPassengers' in value) || value['numPassengers'] === undefined) return false;
    return true;
}

export function FlightSearchRequestFromJSON(json: any): FlightSearchRequest {
    return FlightSearchRequestFromJSONTyped(json, false);
}

export function FlightSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightSearchRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'origin': LocationFromJSON(json['origin']),
        'departureDate': (new Date(json['departure_date'])),
        'destination': LocationFromJSON(json['destination']),
        'returnDate': json['return_date'] == null ? undefined : (new Date(json['return_date'])),
        'cabinClass': json['cabin_class'] == null ? undefined : json['cabin_class'],
        'numPassengers': json['numPassengers'],
    };
}

export function FlightSearchRequestToJSON(value?: FlightSearchRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'origin': LocationToJSON(value['origin']),
        'departure_date': ((value['departureDate']).toISOString().substring(0,10)),
        'destination': LocationToJSON(value['destination']),
        'return_date': value['returnDate'] == null ? undefined : ((value['returnDate']).toISOString().substring(0,10)),
        'cabin_class': value['cabinClass'],
        'numPassengers': value['numPassengers'],
    };
}

