/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * A request for prompt-related information for the current search in progress
 * @export
 * @interface SearchPromptRequest
 */
export interface SearchPromptRequest {
    /**
     * The google place ID of the search location
     * @type {string}
     * @memberof SearchPromptRequest
     */
    placeId: string;
    /**
     * The main query string, corresponds to structured_formatting.main_text
     * @type {string}
     * @memberof SearchPromptRequest
     */
    mainQuery: string;
    /**
     * The secondary query string, corresponds to structured_formatting.secondary_text
     * @type {string}
     * @memberof SearchPromptRequest
     */
    secondaryQuery?: string;
    /**
     * The types of the place ID
     * @type {Array<string>}
     * @memberof SearchPromptRequest
     */
    types: Array<string>;
    /**
     * 
     * @type {Coordinates}
     * @memberof SearchPromptRequest
     */
    coordinates: Coordinates;
}

/**
 * Check if a given object implements the SearchPromptRequest interface.
 */
export function instanceOfSearchPromptRequest(value: object): value is SearchPromptRequest {
    if (!('placeId' in value) || value['placeId'] === undefined) return false;
    if (!('mainQuery' in value) || value['mainQuery'] === undefined) return false;
    if (!('types' in value) || value['types'] === undefined) return false;
    if (!('coordinates' in value) || value['coordinates'] === undefined) return false;
    return true;
}

export function SearchPromptRequestFromJSON(json: any): SearchPromptRequest {
    return SearchPromptRequestFromJSONTyped(json, false);
}

export function SearchPromptRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPromptRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'placeId': json['place_id'],
        'mainQuery': json['main_query'],
        'secondaryQuery': json['secondary_query'] == null ? undefined : json['secondary_query'],
        'types': json['types'],
        'coordinates': CoordinatesFromJSON(json['coordinates']),
    };
}

export function SearchPromptRequestToJSON(value?: SearchPromptRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'place_id': value['placeId'],
        'main_query': value['mainQuery'],
        'secondary_query': value['secondaryQuery'],
        'types': value['types'],
        'coordinates': CoordinatesToJSON(value['coordinates']),
    };
}

