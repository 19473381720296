/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GuestReviews } from './GuestReviews';
import {
    GuestReviewsFromJSON,
    GuestReviewsFromJSONTyped,
    GuestReviewsToJSON,
} from './GuestReviews';

/**
 * A hotel review
 * @export
 * @interface HotelReview
 */
export interface HotelReview {
    /**
     * 
     * @type {string}
     * @memberof HotelReview
     */
    vervotechPropertyId?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelReview
     */
    providerPropertyId?: string;
    /**
     * 
     * @type {GuestReviews}
     * @memberof HotelReview
     */
    reviews?: GuestReviews;
    /**
     * 
     * @type {Error}
     * @memberof HotelReview
     */
    error?: Error;
}

/**
 * Check if a given object implements the HotelReview interface.
 */
export function instanceOfHotelReview(value: object): value is HotelReview {
    return true;
}

export function HotelReviewFromJSON(json: any): HotelReview {
    return HotelReviewFromJSONTyped(json, false);
}

export function HotelReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelReview {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'providerPropertyId': json['provider_property_id'] == null ? undefined : json['provider_property_id'],
        'reviews': json['reviews'] == null ? undefined : GuestReviewsFromJSON(json['reviews']),
        'error': json['error'] == null ? undefined : json['error'],
    };
}

export function HotelReviewToJSON(value?: HotelReview | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_id': value['vervotechPropertyId'],
        'provider_property_id': value['providerPropertyId'],
        'reviews': GuestReviewsToJSON(value['reviews']),
        'error': value['error'],
    };
}

