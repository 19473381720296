/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The metropolitan area where the airport is located. Only present for airports which are registered with IATA as belonging to a [metropolitan area](https://portal.iata.org/faq/articles/en_US/FAQ/How-do-I-create-a-new-Metropolitan-Area).
 * @export
 * @interface City
 */
export interface City {
    /**
     * The three-character IATA code for the city
     * @type {string}
     * @memberof City
     */
    iataCode?: string;
    /**
     * The [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) code for the country where the city is located
     * @type {string}
     * @memberof City
     */
    iataCountryCode?: string;
    /**
     * Duffel's unique identifier for the resource
     * @type {string}
     * @memberof City
     */
    id?: string;
    /**
     * The name of the city
     * @type {string}
     * @memberof City
     */
    name?: string;
}

/**
 * Check if a given object implements the City interface.
 */
export function instanceOfCity(value: object): value is City {
    return true;
}

export function CityFromJSON(json: any): City {
    return CityFromJSONTyped(json, false);
}

export function CityFromJSONTyped(json: any, ignoreDiscriminator: boolean): City {
    if (json == null) {
        return json;
    }
    return {
        
        'iataCode': json['iata_code'] == null ? undefined : json['iata_code'],
        'iataCountryCode': json['iata_country_code'] == null ? undefined : json['iata_country_code'],
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function CityToJSON(value?: City | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'iata_code': value['iataCode'],
        'iata_country_code': value['iataCountryCode'],
        'id': value['id'],
        'name': value['name'],
    };
}

