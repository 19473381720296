/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GuestReviewsVerified } from './GuestReviewsVerified';
import {
    GuestReviewsVerifiedFromJSON,
    GuestReviewsVerifiedFromJSONTyped,
    GuestReviewsVerifiedToJSON,
} from './GuestReviewsVerified';

/**
 * A property's guest reviews.
 * @export
 * @interface GuestReviews
 */
export interface GuestReviews {
    /**
     * 
     * @type {GuestReviewsVerified}
     * @memberof GuestReviews
     */
    verified?: GuestReviewsVerified;
}

/**
 * Check if a given object implements the GuestReviews interface.
 */
export function instanceOfGuestReviews(value: object): value is GuestReviews {
    return true;
}

export function GuestReviewsFromJSON(json: any): GuestReviews {
    return GuestReviewsFromJSONTyped(json, false);
}

export function GuestReviewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestReviews {
    if (json == null) {
        return json;
    }
    return {
        
        'verified': json['verified'] == null ? undefined : GuestReviewsVerifiedFromJSON(json['verified']),
    };
}

export function GuestReviewsToJSON(value?: GuestReviews | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'verified': GuestReviewsVerifiedToJSON(value['verified']),
    };
}

