/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Offer } from './Offer';
import {
    OfferFromJSON,
    OfferFromJSONTyped,
    OfferToJSON,
} from './Offer';

/**
 * 
 * @export
 * @interface SearchSessionResultsFlights
 */
export interface SearchSessionResultsFlights {
    /**
     * 
     * @type {Array<Offer>}
     * @memberof SearchSessionResultsFlights
     */
    items?: Array<Offer>;
    /**
     * 
     * @type {number}
     * @memberof SearchSessionResultsFlights
     */
    numberOfItems: number;
}

/**
 * Check if a given object implements the SearchSessionResultsFlights interface.
 */
export function instanceOfSearchSessionResultsFlights(value: object): value is SearchSessionResultsFlights {
    if (!('numberOfItems' in value) || value['numberOfItems'] === undefined) return false;
    return true;
}

export function SearchSessionResultsFlightsFromJSON(json: any): SearchSessionResultsFlights {
    return SearchSessionResultsFlightsFromJSONTyped(json, false);
}

export function SearchSessionResultsFlightsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSessionResultsFlights {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(OfferFromJSON)),
        'numberOfItems': json['number_of_items'],
    };
}

export function SearchSessionResultsFlightsToJSON(value?: SearchSessionResultsFlights | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(OfferToJSON)),
        'number_of_items': value['numberOfItems'],
    };
}

