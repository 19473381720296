/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';

/**
 * A map of links, including a link to request payment options.
 * @export
 * @interface RateLinks
 */
export interface RateLinks {
    [key: string]: Link | any;
    /**
     * 
     * @type {Link}
     * @memberof RateLinks
     */
    paymentOptions?: Link;
}

/**
 * Check if a given object implements the RateLinks interface.
 */
export function instanceOfRateLinks(value: object): value is RateLinks {
    return true;
}

export function RateLinksFromJSON(json: any): RateLinks {
    return RateLinksFromJSONTyped(json, false);
}

export function RateLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateLinks {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'paymentOptions': json['payment_options'] == null ? undefined : LinkFromJSON(json['payment_options']),
    };
}

export function RateLinksToJSON(value?: RateLinks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'payment_options': LinkToJSON(value['paymentOptions']),
    };
}

