/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UnavailableReason } from './UnavailableReason';
import {
    UnavailableReasonFromJSON,
    UnavailableReasonFromJSONTyped,
    UnavailableReasonToJSON,
} from './UnavailableReason';
import type { Property } from './Property';
import {
    PropertyFromJSON,
    PropertyFromJSONTyped,
    PropertyToJSON,
} from './Property';

/**
 * 
 * @export
 * @interface PropertyUnavailability
 */
export interface PropertyUnavailability extends Property {
    /**
     * 
     * @type {UnavailableReason}
     * @memberof PropertyUnavailability
     */
    unavailableReason?: UnavailableReason;
}



/**
 * Check if a given object implements the PropertyUnavailability interface.
 */
export function instanceOfPropertyUnavailability(value: object): value is PropertyUnavailability {
    return true;
}

export function PropertyUnavailabilityFromJSON(json: any): PropertyUnavailability {
    return PropertyUnavailabilityFromJSONTyped(json, false);
}

export function PropertyUnavailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyUnavailability {
    if (json == null) {
        return json;
    }
    return {
        ...PropertyFromJSONTyped(json, ignoreDiscriminator),
        'unavailableReason': json['unavailable_reason'] == null ? undefined : UnavailableReasonFromJSON(json['unavailable_reason']),
    };
}

export function PropertyUnavailabilityToJSON(value?: PropertyUnavailability | null): any {
    if (value == null) {
        return value;
    }
    return {
        ...PropertyToJSON(value),
        'unavailable_reason': UnavailableReasonToJSON(value['unavailableReason']),
    };
}

