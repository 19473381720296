/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RateHistory } from './RateHistory';
import {
    RateHistoryFromJSON,
    RateHistoryFromJSONTyped,
    RateHistoryToJSON,
} from './RateHistory';
import type { ConfirmationId } from './ConfirmationId';
import {
    ConfirmationIdFromJSON,
    ConfirmationIdFromJSONTyped,
    ConfirmationIdToJSON,
} from './ConfirmationId';
import type { StatusItinerary } from './StatusItinerary';
import {
    StatusItineraryFromJSON,
    StatusItineraryFromJSONTyped,
    StatusItineraryToJSON,
} from './StatusItinerary';
import type { Charge } from './Charge';
import {
    ChargeFromJSON,
    ChargeFromJSONTyped,
    ChargeToJSON,
} from './Charge';

/**
 * A room history event, representing a change made to a specific room.
 * @export
 * @interface RoomHistoryItem
 */
export interface RoomHistoryItem {
    /**
     * Room history id for particular change.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    historyId?: string;
    /**
     * Date and time in UTC of the change event, in extended ISO 8601 format.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    eventTimestamp?: string;
    /**
     * Type type of event associated with this history item such as modified or canceled.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    eventType?: RoomHistoryItemEventTypeEnum;
    /**
     * The source of the event. If `voyager_agent`, `agent_id` will be supplied.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    eventSource?: RoomHistoryItemEventSourceEnum;
    /**
     * Optional identifier provided during changes via Rapid.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    changeReferenceId?: string;
    /**
     * An agent user id number associated with a modification.
     * @type {number}
     * @memberof RoomHistoryItem
     */
    agentId?: number;
    /**
     * The room id.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    roomId?: string;
    /**
     * 
     * @type {ConfirmationId}
     * @memberof RoomHistoryItem
     */
    confirmationId?: ConfirmationId;
    /**
     * Unique identifier for a bed type.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    bedGroupId?: string;
    /**
     * The check-in date of the itinerary.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    checkin?: string;
    /**
     * The check-out date of the itinerary.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    checkout?: string;
    /**
     * The number of adults staying in the room.
     * @type {number}
     * @memberof RoomHistoryItem
     */
    numberOfAdults?: number;
    /**
     * The ages of children for the room.
     * @type {Array<number>}
     * @memberof RoomHistoryItem
     */
    childAges?: Array<number>;
    /**
     * The first name of the main guest staying in the room.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    givenName?: string;
    /**
     * The last name of the main guest staying in the room.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    familyName?: string;
    /**
     * 
     * @type {StatusItinerary}
     * @memberof RoomHistoryItem
     */
    status?: StatusItinerary;
    /**
     * Any special request info associated with the room.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    specialRequest?: string;
    /**
     * Indicates if the room is smoking or non-smoking.
     * @type {boolean}
     * @memberof RoomHistoryItem
     */
    smoking?: boolean;
    /**
     * A loyalty identifier for a hotel loyalty program associated with this room guest.
     * @type {string}
     * @memberof RoomHistoryItem
     */
    loyaltyId?: string;
    /**
     * 
     * @type {Charge}
     * @memberof RoomHistoryItem
     */
    amountCharged?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof RoomHistoryItem
     */
    amountRefunded?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof RoomHistoryItem
     */
    penalty?: Charge;
    /**
     * 
     * @type {RateHistory}
     * @memberof RoomHistoryItem
     */
    rate?: RateHistory;
}


/**
 * @export
 */
export const RoomHistoryItemEventTypeEnum = {
    Created: 'created',
    Modified: 'modified',
    Canceled: 'canceled'
} as const;
export type RoomHistoryItemEventTypeEnum = typeof RoomHistoryItemEventTypeEnum[keyof typeof RoomHistoryItemEventTypeEnum];

/**
 * @export
 */
export const RoomHistoryItemEventSourceEnum = {
    RapidApi: 'rapid_api',
    VoyagerAgent: 'voyager_agent',
    Other: 'other'
} as const;
export type RoomHistoryItemEventSourceEnum = typeof RoomHistoryItemEventSourceEnum[keyof typeof RoomHistoryItemEventSourceEnum];


/**
 * Check if a given object implements the RoomHistoryItem interface.
 */
export function instanceOfRoomHistoryItem(value: object): value is RoomHistoryItem {
    return true;
}

export function RoomHistoryItemFromJSON(json: any): RoomHistoryItem {
    return RoomHistoryItemFromJSONTyped(json, false);
}

export function RoomHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomHistoryItem {
    if (json == null) {
        return json;
    }
    return {
        
        'historyId': json['history_id'] == null ? undefined : json['history_id'],
        'eventTimestamp': json['event_timestamp'] == null ? undefined : json['event_timestamp'],
        'eventType': json['event_type'] == null ? undefined : json['event_type'],
        'eventSource': json['event_source'] == null ? undefined : json['event_source'],
        'changeReferenceId': json['change_reference_id'] == null ? undefined : json['change_reference_id'],
        'agentId': json['agent_id'] == null ? undefined : json['agent_id'],
        'roomId': json['room_id'] == null ? undefined : json['room_id'],
        'confirmationId': json['confirmation_id'] == null ? undefined : ConfirmationIdFromJSON(json['confirmation_id']),
        'bedGroupId': json['bed_group_id'] == null ? undefined : json['bed_group_id'],
        'checkin': json['checkin'] == null ? undefined : json['checkin'],
        'checkout': json['checkout'] == null ? undefined : json['checkout'],
        'numberOfAdults': json['number_of_adults'] == null ? undefined : json['number_of_adults'],
        'childAges': json['child_ages'] == null ? undefined : json['child_ages'],
        'givenName': json['given_name'] == null ? undefined : json['given_name'],
        'familyName': json['family_name'] == null ? undefined : json['family_name'],
        'status': json['status'] == null ? undefined : StatusItineraryFromJSON(json['status']),
        'specialRequest': json['special_request'] == null ? undefined : json['special_request'],
        'smoking': json['smoking'] == null ? undefined : json['smoking'],
        'loyaltyId': json['loyalty_id'] == null ? undefined : json['loyalty_id'],
        'amountCharged': json['amount_charged'] == null ? undefined : ChargeFromJSON(json['amount_charged']),
        'amountRefunded': json['amount_refunded'] == null ? undefined : ChargeFromJSON(json['amount_refunded']),
        'penalty': json['penalty'] == null ? undefined : ChargeFromJSON(json['penalty']),
        'rate': json['rate'] == null ? undefined : RateHistoryFromJSON(json['rate']),
    };
}

export function RoomHistoryItemToJSON(value?: RoomHistoryItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'history_id': value['historyId'],
        'event_timestamp': value['eventTimestamp'],
        'event_type': value['eventType'],
        'event_source': value['eventSource'],
        'change_reference_id': value['changeReferenceId'],
        'agent_id': value['agentId'],
        'room_id': value['roomId'],
        'confirmation_id': ConfirmationIdToJSON(value['confirmationId']),
        'bed_group_id': value['bedGroupId'],
        'checkin': value['checkin'],
        'checkout': value['checkout'],
        'number_of_adults': value['numberOfAdults'],
        'child_ages': value['childAges'],
        'given_name': value['givenName'],
        'family_name': value['familyName'],
        'status': StatusItineraryToJSON(value['status']),
        'special_request': value['specialRequest'],
        'smoking': value['smoking'],
        'loyalty_id': value['loyaltyId'],
        'amount_charged': ChargeToJSON(value['amountCharged']),
        'amount_refunded': ChargeToJSON(value['amountRefunded']),
        'penalty': ChargeToJSON(value['penalty']),
        'rate': RateHistoryToJSON(value['rate']),
    };
}

