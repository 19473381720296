/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingStatus
 */
export interface BookingStatus {
    /**
     * 
     * @type {number}
     * @memberof BookingStatus
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BookingStatus
     */
    vervotechPropertyId?: number;
    /**
     * If this booking has been commpleted, which is when the booking's checkout date is in the past.
     * @type {boolean}
     * @memberof BookingStatus
     */
    isCompleted: boolean;
    /**
     * If the booking has been cancelled.
     * @type {boolean}
     * @memberof BookingStatus
     */
    isCancelled: boolean;
}

/**
 * Check if a given object implements the BookingStatus interface.
 */
export function instanceOfBookingStatus(value: object): value is BookingStatus {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('isCompleted' in value) || value['isCompleted'] === undefined) return false;
    if (!('isCancelled' in value) || value['isCancelled'] === undefined) return false;
    return true;
}

export function BookingStatusFromJSON(json: any): BookingStatus {
    return BookingStatusFromJSONTyped(json, false);
}

export function BookingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'isCompleted': json['is_completed'],
        'isCancelled': json['is_cancelled'],
    };
}

export function BookingStatusToJSON(value?: BookingStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'vervotech_property_id': value['vervotechPropertyId'],
        'is_completed': value['isCompleted'],
        'is_cancelled': value['isCancelled'],
    };
}

