/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchSessionResultsHotels } from './SearchSessionResultsHotels';
import {
    SearchSessionResultsHotelsFromJSON,
    SearchSessionResultsHotelsFromJSONTyped,
    SearchSessionResultsHotelsToJSON,
} from './SearchSessionResultsHotels';
import type { SearchSessionResultsFlights } from './SearchSessionResultsFlights';
import {
    SearchSessionResultsFlightsFromJSON,
    SearchSessionResultsFlightsFromJSONTyped,
    SearchSessionResultsFlightsToJSON,
} from './SearchSessionResultsFlights';

/**
 * The results of the search
 * @export
 * @interface SearchSessionResults
 */
export interface SearchSessionResults {
    /**
     * 
     * @type {SearchSessionResultsHotels}
     * @memberof SearchSessionResults
     */
    hotels?: SearchSessionResultsHotels;
    /**
     * 
     * @type {SearchSessionResultsFlights}
     * @memberof SearchSessionResults
     */
    flights?: SearchSessionResultsFlights;
}

/**
 * Check if a given object implements the SearchSessionResults interface.
 */
export function instanceOfSearchSessionResults(value: object): value is SearchSessionResults {
    return true;
}

export function SearchSessionResultsFromJSON(json: any): SearchSessionResults {
    return SearchSessionResultsFromJSONTyped(json, false);
}

export function SearchSessionResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSessionResults {
    if (json == null) {
        return json;
    }
    return {
        
        'hotels': json['hotels'] == null ? undefined : SearchSessionResultsHotelsFromJSON(json['hotels']),
        'flights': json['flights'] == null ? undefined : SearchSessionResultsFlightsFromJSON(json['flights']),
    };
}

export function SearchSessionResultsToJSON(value?: SearchSessionResults | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotels': SearchSessionResultsHotelsToJSON(value['hotels']),
        'flights': SearchSessionResultsFlightsToJSON(value['flights']),
    };
}

