/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Rate } from './Rate';
import {
    RateFromJSON,
    RateFromJSONTyped,
    RateToJSON,
} from './Rate';

/**
 * The room information.
 * @export
 * @interface RoomAvailability
 */
export interface RoomAvailability {
    /**
     * Unique Identifier for a room type.
     * @type {string}
     * @memberof RoomAvailability
     */
    id?: string;
    /**
     * Name of the room type.
     * @type {string}
     * @memberof RoomAvailability
     */
    roomName?: string;
    /**
     * Array of objects containing rate information.
     * @type {Array<Rate>}
     * @memberof RoomAvailability
     */
    rates?: Array<Rate>;
}

/**
 * Check if a given object implements the RoomAvailability interface.
 */
export function instanceOfRoomAvailability(value: object): value is RoomAvailability {
    return true;
}

export function RoomAvailabilityFromJSON(json: any): RoomAvailability {
    return RoomAvailabilityFromJSONTyped(json, false);
}

export function RoomAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomAvailability {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'roomName': json['room_name'] == null ? undefined : json['room_name'],
        'rates': json['rates'] == null ? undefined : ((json['rates'] as Array<any>).map(RateFromJSON)),
    };
}

export function RoomAvailabilityToJSON(value?: RoomAvailability | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'room_name': value['roomName'],
        'rates': value['rates'] == null ? undefined : ((value['rates'] as Array<any>).map(RateToJSON)),
    };
}

