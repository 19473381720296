/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An individual bed configuration.
 * @export
 * @interface BedGroupConfiguration
 */
export interface BedGroupConfiguration {
    /**
     * The type of this bed configuration in the room.
     * @type {string}
     * @memberof BedGroupConfiguration
     */
    type?: string;
    /**
     * The size of this bed configuration in the room.
     * @type {string}
     * @memberof BedGroupConfiguration
     */
    size?: string;
    /**
     * The number of this bed configuration in the room.
     * @type {number}
     * @memberof BedGroupConfiguration
     */
    quantity?: number;
}

/**
 * Check if a given object implements the BedGroupConfiguration interface.
 */
export function instanceOfBedGroupConfiguration(value: object): value is BedGroupConfiguration {
    return true;
}

export function BedGroupConfigurationFromJSON(json: any): BedGroupConfiguration {
    return BedGroupConfigurationFromJSONTyped(json, false);
}

export function BedGroupConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): BedGroupConfiguration {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'size': json['size'] == null ? undefined : json['size'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
    };
}

export function BedGroupConfigurationToJSON(value?: BedGroupConfiguration | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'size': value['size'],
        'quantity': value['quantity'],
    };
}

