/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Charge } from './Charge';
import {
    ChargeFromJSON,
    ChargeFromJSONTyped,
    ChargeToJSON,
} from './Charge';

/**
 * The total price of charges, given various critera.
 * * `inclusive` - provides the total price including taxes and fees. This does not include hotel collected fees such as resort, mandatory taxes, and mandatory fees.
 * * `exclusive` - provides the total price excluding taxes and fees.
 * * `inclusive_strikethrough` - provides the tax `inclusive` total price with any hotel funded discounts added back. Can be used to merchandise the savings due to a discount.
 * * `strikethrough` - provides the tax `exclusive` total price with any hotel funded discounts added back. Can be used to merchandise the savings due to a discount.
 * * `marketing_fee` - provides the potential owed earnings per transaction.
 * * `gross_profit` - provides the estimated gross profit per transaction.
 * * `minimum_selling_price` - provides the minimum selling price.
 * * `property_fees` - provides the total of the fees collected by the property.
 * 
 * @export
 * @interface Totals
 */
export interface Totals {
    /**
     * 
     * @type {Charge}
     * @memberof Totals
     */
    inclusive?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof Totals
     */
    exclusive?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof Totals
     */
    inclusiveStrikethrough?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof Totals
     */
    strikethrough?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof Totals
     */
    marketingFee?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof Totals
     */
    grossProfit?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof Totals
     */
    minimumSellingPrice?: Charge;
    /**
     * 
     * @type {Charge}
     * @memberof Totals
     */
    propertyFees?: Charge;
}

/**
 * Check if a given object implements the Totals interface.
 */
export function instanceOfTotals(value: object): value is Totals {
    return true;
}

export function TotalsFromJSON(json: any): Totals {
    return TotalsFromJSONTyped(json, false);
}

export function TotalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Totals {
    if (json == null) {
        return json;
    }
    return {
        
        'inclusive': json['inclusive'] == null ? undefined : ChargeFromJSON(json['inclusive']),
        'exclusive': json['exclusive'] == null ? undefined : ChargeFromJSON(json['exclusive']),
        'inclusiveStrikethrough': json['inclusive_strikethrough'] == null ? undefined : ChargeFromJSON(json['inclusive_strikethrough']),
        'strikethrough': json['strikethrough'] == null ? undefined : ChargeFromJSON(json['strikethrough']),
        'marketingFee': json['marketing_fee'] == null ? undefined : ChargeFromJSON(json['marketing_fee']),
        'grossProfit': json['gross_profit'] == null ? undefined : ChargeFromJSON(json['gross_profit']),
        'minimumSellingPrice': json['minimum_selling_price'] == null ? undefined : ChargeFromJSON(json['minimum_selling_price']),
        'propertyFees': json['property_fees'] == null ? undefined : ChargeFromJSON(json['property_fees']),
    };
}

export function TotalsToJSON(value?: Totals | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'inclusive': ChargeToJSON(value['inclusive']),
        'exclusive': ChargeToJSON(value['exclusive']),
        'inclusive_strikethrough': ChargeToJSON(value['inclusiveStrikethrough']),
        'strikethrough': ChargeToJSON(value['strikethrough']),
        'marketing_fee': ChargeToJSON(value['marketingFee']),
        'gross_profit': ChargeToJSON(value['grossProfit']),
        'minimum_selling_price': ChargeToJSON(value['minimumSellingPrice']),
        'property_fees': ChargeToJSON(value['propertyFees']),
    };
}

