/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchFilters } from './SearchFilters';
import {
    SearchFiltersFromJSON,
    SearchFiltersFromJSONTyped,
    SearchFiltersToJSON,
} from './SearchFilters';
import type { SearchDatesV2 } from './SearchDatesV2';
import {
    SearchDatesV2FromJSON,
    SearchDatesV2FromJSONTyped,
    SearchDatesV2ToJSON,
} from './SearchDatesV2';
import type { SearchOccupants } from './SearchOccupants';
import {
    SearchOccupantsFromJSON,
    SearchOccupantsFromJSONTyped,
    SearchOccupantsToJSON,
} from './SearchOccupants';
import type { SearchUser } from './SearchUser';
import {
    SearchUserFromJSON,
    SearchUserFromJSONTyped,
    SearchUserToJSON,
} from './SearchUser';
import type { SearchLocationV2 } from './SearchLocationV2';
import {
    SearchLocationV2FromJSON,
    SearchLocationV2FromJSONTyped,
    SearchLocationV2ToJSON,
} from './SearchLocationV2';

/**
 * A generic search request for hotels
 * @export
 * @interface SearchRequestV3
 */
export interface SearchRequestV3 {
    /**
     * 
     * @type {SearchUser}
     * @memberof SearchRequestV3
     */
    searchUser: SearchUser;
    /**
     * 
     * @type {SearchLocationV2}
     * @memberof SearchRequestV3
     */
    searchLocation: SearchLocationV2;
    /**
     * 
     * @type {SearchOccupants}
     * @memberof SearchRequestV3
     */
    searchOccupants?: SearchOccupants;
    /**
     * 
     * @type {SearchFilters}
     * @memberof SearchRequestV3
     */
    searchFilters?: SearchFilters;
    /**
     * 
     * @type {SearchDatesV2}
     * @memberof SearchRequestV3
     */
    searchDates?: SearchDatesV2;
}

/**
 * Check if a given object implements the SearchRequestV3 interface.
 */
export function instanceOfSearchRequestV3(value: object): value is SearchRequestV3 {
    if (!('searchUser' in value) || value['searchUser'] === undefined) return false;
    if (!('searchLocation' in value) || value['searchLocation'] === undefined) return false;
    return true;
}

export function SearchRequestV3FromJSON(json: any): SearchRequestV3 {
    return SearchRequestV3FromJSONTyped(json, false);
}

export function SearchRequestV3FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequestV3 {
    if (json == null) {
        return json;
    }
    return {
        
        'searchUser': SearchUserFromJSON(json['search_user']),
        'searchLocation': SearchLocationV2FromJSON(json['search_location']),
        'searchOccupants': json['search_occupants'] == null ? undefined : SearchOccupantsFromJSON(json['search_occupants']),
        'searchFilters': json['search_filters'] == null ? undefined : SearchFiltersFromJSON(json['search_filters']),
        'searchDates': json['search_dates'] == null ? undefined : SearchDatesV2FromJSON(json['search_dates']),
    };
}

export function SearchRequestV3ToJSON(value?: SearchRequestV3 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'search_user': SearchUserToJSON(value['searchUser']),
        'search_location': SearchLocationV2ToJSON(value['searchLocation']),
        'search_occupants': SearchOccupantsToJSON(value['searchOccupants']),
        'search_filters': SearchFiltersToJSON(value['searchFilters']),
        'search_dates': SearchDatesV2ToJSON(value['searchDates']),
    };
}

