/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferSliceSegmentOperatingCarrier } from './OfferSliceSegmentOperatingCarrier';
import {
    OfferSliceSegmentOperatingCarrierFromJSON,
    OfferSliceSegmentOperatingCarrierFromJSONTyped,
    OfferSliceSegmentOperatingCarrierToJSON,
} from './OfferSliceSegmentOperatingCarrier';
import type { OfferSliceSegmentDestination } from './OfferSliceSegmentDestination';
import {
    OfferSliceSegmentDestinationFromJSON,
    OfferSliceSegmentDestinationFromJSONTyped,
    OfferSliceSegmentDestinationToJSON,
} from './OfferSliceSegmentDestination';
import type { OfferSliceSegmentOrigin } from './OfferSliceSegmentOrigin';
import {
    OfferSliceSegmentOriginFromJSON,
    OfferSliceSegmentOriginFromJSONTyped,
    OfferSliceSegmentOriginToJSON,
} from './OfferSliceSegmentOrigin';
import type { OfferSliceSegmentStop } from './OfferSliceSegmentStop';
import {
    OfferSliceSegmentStopFromJSON,
    OfferSliceSegmentStopFromJSONTyped,
    OfferSliceSegmentStopToJSON,
} from './OfferSliceSegmentStop';
import type { OfferSliceSegmentMarketingCarrier } from './OfferSliceSegmentMarketingCarrier';
import {
    OfferSliceSegmentMarketingCarrierFromJSON,
    OfferSliceSegmentMarketingCarrierFromJSONTyped,
    OfferSliceSegmentMarketingCarrierToJSON,
} from './OfferSliceSegmentMarketingCarrier';
import type { OfferSliceSegmentPassenger } from './OfferSliceSegmentPassenger';
import {
    OfferSliceSegmentPassengerFromJSON,
    OfferSliceSegmentPassengerFromJSONTyped,
    OfferSliceSegmentPassengerToJSON,
} from './OfferSliceSegmentPassenger';
import type { OfferSliceSegmentAircraft } from './OfferSliceSegmentAircraft';
import {
    OfferSliceSegmentAircraftFromJSON,
    OfferSliceSegmentAircraftFromJSONTyped,
    OfferSliceSegmentAircraftToJSON,
} from './OfferSliceSegmentAircraft';

/**
 * 
 * @export
 * @interface OfferSliceSegment
 */
export interface OfferSliceSegment {
    /**
     * 
     * @type {OfferSliceSegmentAircraft}
     * @memberof OfferSliceSegment
     */
    aircraft?: OfferSliceSegmentAircraft;
    /**
     * The [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) datetime at which the segment is scheduled to arrive, in the destination airport timezone (see `destination.timezone`)
     * @type {Date}
     * @memberof OfferSliceSegment
     */
    arrivingAt?: Date;
    /**
     * The [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) datetime at which the segment is scheduled to depart, in the origin airport timezone (see `origin.timezone`)
     * @type {Date}
     * @memberof OfferSliceSegment
     */
    departingAt?: Date;
    /**
     * 
     * @type {OfferSliceSegmentDestination}
     * @memberof OfferSliceSegment
     */
    destination?: OfferSliceSegmentDestination;
    /**
     * The terminal at the `destination` airport where the segment is scheduled to arrive
     * @type {string}
     * @memberof OfferSliceSegment
     */
    destinationTerminal?: string;
    /**
     * The distance of the segment in kilometres
     * @type {string}
     * @memberof OfferSliceSegment
     */
    distance?: string;
    /**
     * The duration of the segment, represented as a [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601#Durations) duration
     * @type {string}
     * @memberof OfferSliceSegment
     */
    duration?: string;
    /**
     * Duffel's unique identifier for the resource
     * @type {string}
     * @memberof OfferSliceSegment
     */
    id?: string;
    /**
     * 
     * @type {OfferSliceSegmentMarketingCarrier}
     * @memberof OfferSliceSegment
     */
    marketingCarrier?: OfferSliceSegmentMarketingCarrier;
    /**
     * The flight number assigned by the marketing carrier
     * @type {string}
     * @memberof OfferSliceSegment
     */
    marketingCarrierFlightNumber?: string;
    /**
     * 
     * @type {OfferSliceSegmentOperatingCarrier}
     * @memberof OfferSliceSegment
     */
    operatingCarrier?: OfferSliceSegmentOperatingCarrier;
    /**
     * The flight number assigned by the operating carrier. This may not be present, in which case you should display the `marketing_carrier`'s information and the `marketing_carrier_flight_number`, and simply state the name of the `operating_carrier`.
     * @type {string}
     * @memberof OfferSliceSegment
     */
    operatingCarrierFlightNumber?: string;
    /**
     * 
     * @type {OfferSliceSegmentOrigin}
     * @memberof OfferSliceSegment
     */
    origin?: OfferSliceSegmentOrigin;
    /**
     * The terminal at the `origin` airport from which the segment is scheduled to depart
     * @type {string}
     * @memberof OfferSliceSegment
     */
    originTerminal?: string;
    /**
     * Additional segment-specific information about the passengers included in the offer (e.g. their baggage allowance and the cabin class they will be travelling in)
     * @type {Array<OfferSliceSegmentPassenger>}
     * @memberof OfferSliceSegment
     */
    passengers?: Array<OfferSliceSegmentPassenger>;
    /**
     * Additional segment-specific information about the stops, if any, included in the segment
     * @type {Array<OfferSliceSegmentStop>}
     * @memberof OfferSliceSegment
     */
    stops?: Array<OfferSliceSegmentStop>;
}

/**
 * Check if a given object implements the OfferSliceSegment interface.
 */
export function instanceOfOfferSliceSegment(value: object): value is OfferSliceSegment {
    return true;
}

export function OfferSliceSegmentFromJSON(json: any): OfferSliceSegment {
    return OfferSliceSegmentFromJSONTyped(json, false);
}

export function OfferSliceSegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegment {
    if (json == null) {
        return json;
    }
    return {
        
        'aircraft': json['aircraft'] == null ? undefined : OfferSliceSegmentAircraftFromJSON(json['aircraft']),
        'arrivingAt': json['arriving_at'] == null ? undefined : (new Date(json['arriving_at'])),
        'departingAt': json['departing_at'] == null ? undefined : (new Date(json['departing_at'])),
        'destination': json['destination'] == null ? undefined : OfferSliceSegmentDestinationFromJSON(json['destination']),
        'destinationTerminal': json['destination_terminal'] == null ? undefined : json['destination_terminal'],
        'distance': json['distance'] == null ? undefined : json['distance'],
        'duration': json['duration'] == null ? undefined : json['duration'],
        'id': json['id'] == null ? undefined : json['id'],
        'marketingCarrier': json['marketing_carrier'] == null ? undefined : OfferSliceSegmentMarketingCarrierFromJSON(json['marketing_carrier']),
        'marketingCarrierFlightNumber': json['marketing_carrier_flight_number'] == null ? undefined : json['marketing_carrier_flight_number'],
        'operatingCarrier': json['operating_carrier'] == null ? undefined : OfferSliceSegmentOperatingCarrierFromJSON(json['operating_carrier']),
        'operatingCarrierFlightNumber': json['operating_carrier_flight_number'] == null ? undefined : json['operating_carrier_flight_number'],
        'origin': json['origin'] == null ? undefined : OfferSliceSegmentOriginFromJSON(json['origin']),
        'originTerminal': json['origin_terminal'] == null ? undefined : json['origin_terminal'],
        'passengers': json['passengers'] == null ? undefined : ((json['passengers'] as Array<any>).map(OfferSliceSegmentPassengerFromJSON)),
        'stops': json['stops'] == null ? undefined : ((json['stops'] as Array<any>).map(OfferSliceSegmentStopFromJSON)),
    };
}

export function OfferSliceSegmentToJSON(value?: OfferSliceSegment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aircraft': OfferSliceSegmentAircraftToJSON(value['aircraft']),
        'arriving_at': value['arrivingAt'] == null ? undefined : ((value['arrivingAt']).toISOString()),
        'departing_at': value['departingAt'] == null ? undefined : ((value['departingAt']).toISOString()),
        'destination': OfferSliceSegmentDestinationToJSON(value['destination']),
        'destination_terminal': value['destinationTerminal'],
        'distance': value['distance'],
        'duration': value['duration'],
        'id': value['id'],
        'marketing_carrier': OfferSliceSegmentMarketingCarrierToJSON(value['marketingCarrier']),
        'marketing_carrier_flight_number': value['marketingCarrierFlightNumber'],
        'operating_carrier': OfferSliceSegmentOperatingCarrierToJSON(value['operatingCarrier']),
        'operating_carrier_flight_number': value['operatingCarrierFlightNumber'],
        'origin': OfferSliceSegmentOriginToJSON(value['origin']),
        'origin_terminal': value['originTerminal'],
        'passengers': value['passengers'] == null ? undefined : ((value['passengers'] as Array<any>).map(OfferSliceSegmentPassengerToJSON)),
        'stops': value['stops'] == null ? undefined : ((value['stops'] as Array<any>).map(OfferSliceSegmentStopToJSON)),
    };
}

