/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StayTag } from './StayTag';
import {
    StayTagFromJSON,
    StayTagFromJSONTyped,
    StayTagToJSON,
} from './StayTag';

/**
 * Tag information
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {StayTag}
     * @memberof Tag
     */
    stayTag: StayTag;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tagReason: string;
}

/**
 * Check if a given object implements the Tag interface.
 */
export function instanceOfTag(value: object): value is Tag {
    if (!('stayTag' in value) || value['stayTag'] === undefined) return false;
    if (!('tagReason' in value) || value['tagReason'] === undefined) return false;
    return true;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if (json == null) {
        return json;
    }
    return {
        
        'stayTag': StayTagFromJSON(json['stay_tag']),
        'tagReason': json['tag_reason'],
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stay_tag': StayTagToJSON(value['stayTag']),
        'tag_reason': value['tagReason'],
    };
}

