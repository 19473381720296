/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckinInstructions
 */
export interface CheckinInstructions {
    /**
     * 
     * @type {string}
     * @memberof CheckinInstructions
     */
    beginTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckinInstructions
     */
    endTime?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckinInstructions
     */
    instructions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckinInstructions
     */
    specialInstructions?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CheckinInstructions
     */
    minAge?: number;
}

/**
 * Check if a given object implements the CheckinInstructions interface.
 */
export function instanceOfCheckinInstructions(value: object): value is CheckinInstructions {
    return true;
}

export function CheckinInstructionsFromJSON(json: any): CheckinInstructions {
    return CheckinInstructionsFromJSONTyped(json, false);
}

export function CheckinInstructionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckinInstructions {
    if (json == null) {
        return json;
    }
    return {
        
        'beginTime': json['begin_time'] == null ? undefined : json['begin_time'],
        'endTime': json['end_time'] == null ? undefined : json['end_time'],
        'instructions': json['instructions'] == null ? undefined : json['instructions'],
        'specialInstructions': json['special_instructions'] == null ? undefined : json['special_instructions'],
        'minAge': json['min_age'] == null ? undefined : json['min_age'],
    };
}

export function CheckinInstructionsToJSON(value?: CheckinInstructions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'begin_time': value['beginTime'],
        'end_time': value['endTime'],
        'instructions': value['instructions'],
        'special_instructions': value['specialInstructions'],
        'min_age': value['minAge'],
    };
}

