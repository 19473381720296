/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RateType } from './RateType';
import {
    RateTypeFromJSON,
    RateTypeFromJSONTyped,
    RateTypeToJSON,
} from './RateType';
import type { CurrencyAmount } from './CurrencyAmount';
import {
    CurrencyAmountFromJSON,
    CurrencyAmountFromJSONTyped,
    CurrencyAmountToJSON,
} from './CurrencyAmount';
import type { RawRateSummary } from './RawRateSummary';
import {
    RawRateSummaryFromJSON,
    RawRateSummaryFromJSONTyped,
    RawRateSummaryToJSON,
} from './RawRateSummary';

/**
 * A set of rates that fall into predefined categories. One refundable, one nonrefundable, and the price differential (refundable - nonrefundable)
 * @export
 * @interface RawRateGroup
 */
export interface RawRateGroup {
    /**
     * 
     * @type {RawRateSummary}
     * @memberof RawRateGroup
     */
    refundableRate?: RawRateSummary;
    /**
     * 
     * @type {RawRateSummary}
     * @memberof RawRateGroup
     */
    nonrefundableRate?: RawRateSummary;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateGroup
     */
    differential?: CurrencyAmount;
    /**
     * 
     * @type {RateType}
     * @memberof RawRateGroup
     */
    rateType: RateType;
    /**
     * 
     * @type {boolean}
     * @memberof RawRateGroup
     */
    recommended?: boolean;
}

/**
 * Check if a given object implements the RawRateGroup interface.
 */
export function instanceOfRawRateGroup(value: object): value is RawRateGroup {
    if (!('rateType' in value) || value['rateType'] === undefined) return false;
    return true;
}

export function RawRateGroupFromJSON(json: any): RawRateGroup {
    return RawRateGroupFromJSONTyped(json, false);
}

export function RawRateGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawRateGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'refundableRate': json['refundable_rate'] == null ? undefined : RawRateSummaryFromJSON(json['refundable_rate']),
        'nonrefundableRate': json['nonrefundable_rate'] == null ? undefined : RawRateSummaryFromJSON(json['nonrefundable_rate']),
        'differential': json['differential'] == null ? undefined : CurrencyAmountFromJSON(json['differential']),
        'rateType': RateTypeFromJSON(json['rate_type']),
        'recommended': json['recommended'] == null ? undefined : json['recommended'],
    };
}

export function RawRateGroupToJSON(value?: RawRateGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'refundable_rate': RawRateSummaryToJSON(value['refundableRate']),
        'nonrefundable_rate': RawRateSummaryToJSON(value['nonrefundableRate']),
        'differential': CurrencyAmountToJSON(value['differential']),
        'rate_type': RateTypeToJSON(value['rateType']),
        'recommended': value['recommended'],
    };
}

