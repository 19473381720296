/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransferPartner
 */
export interface TransferPartner {
    /**
     * 
     * @type {string}
     * @memberof TransferPartner
     */
    fromLoyaltyProgram: string;
    /**
     * 
     * @type {string}
     * @memberof TransferPartner
     */
    toLoyaltyProgram: string;
    /**
     * 
     * @type {number}
     * @memberof TransferPartner
     */
    transferRatio: number;
    /**
     * 
     * @type {string}
     * @memberof TransferPartner
     */
    transferTime?: TransferPartnerTransferTimeEnum;
}


/**
 * @export
 */
export const TransferPartnerTransferTimeEnum = {
    Instant: 'instant',
    OneDay: 'one_day',
    TwoDays: 'two_days',
    ThreeDays: 'three_days'
} as const;
export type TransferPartnerTransferTimeEnum = typeof TransferPartnerTransferTimeEnum[keyof typeof TransferPartnerTransferTimeEnum];


/**
 * Check if a given object implements the TransferPartner interface.
 */
export function instanceOfTransferPartner(value: object): value is TransferPartner {
    if (!('fromLoyaltyProgram' in value) || value['fromLoyaltyProgram'] === undefined) return false;
    if (!('toLoyaltyProgram' in value) || value['toLoyaltyProgram'] === undefined) return false;
    if (!('transferRatio' in value) || value['transferRatio'] === undefined) return false;
    return true;
}

export function TransferPartnerFromJSON(json: any): TransferPartner {
    return TransferPartnerFromJSONTyped(json, false);
}

export function TransferPartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferPartner {
    if (json == null) {
        return json;
    }
    return {
        
        'fromLoyaltyProgram': json['from_loyalty_program'],
        'toLoyaltyProgram': json['to_loyalty_program'],
        'transferRatio': json['transfer_ratio'],
        'transferTime': json['transfer_time'] == null ? undefined : json['transfer_time'],
    };
}

export function TransferPartnerToJSON(value?: TransferPartner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'from_loyalty_program': value['fromLoyaltyProgram'],
        'to_loyalty_program': value['toLoyaltyProgram'],
        'transfer_ratio': value['transferRatio'],
        'transfer_time': value['transferTime'],
    };
}

