/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An amount of money
 * @export
 * @interface CurrencyAmount
 */
export interface CurrencyAmount {
    /**
     * 
     * @type {number}
     * @memberof CurrencyAmount
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyAmount
     */
    currency: string;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof CurrencyAmount
     */
    localizedAmount?: CurrencyAmount;
}

/**
 * Check if a given object implements the CurrencyAmount interface.
 */
export function instanceOfCurrencyAmount(value: object): value is CurrencyAmount {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    return true;
}

export function CurrencyAmountFromJSON(json: any): CurrencyAmount {
    return CurrencyAmountFromJSONTyped(json, false);
}

export function CurrencyAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyAmount {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'currency': json['currency'],
        'localizedAmount': json['localized_amount'] == null ? undefined : CurrencyAmountFromJSON(json['localized_amount']),
    };
}

export function CurrencyAmountToJSON(value?: CurrencyAmount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'currency': value['currency'],
        'localized_amount': CurrencyAmountToJSON(value['localizedAmount']),
    };
}

