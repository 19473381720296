/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChargeCalculated } from './ChargeCalculated';
import {
    ChargeCalculatedFromJSON,
    ChargeCalculatedFromJSONTyped,
    ChargeCalculatedToJSON,
} from './ChargeCalculated';

/**
 * The fees collected by the property. The values for each type of fee are the total for that type.
 * 
 * Mandatory fees are collected by the property at check-in or check-out.
 * Resort fees are charged for amenities and extras and collected by the property at check-in or check-out.
 * Mandatory taxes are taxes collected by the property at check-in or check-out.
 * 
 * @export
 * @interface FeesPricingInformation
 */
export interface FeesPricingInformation {
    /**
     * 
     * @type {ChargeCalculated}
     * @memberof FeesPricingInformation
     */
    mandatoryFee?: ChargeCalculated;
    /**
     * 
     * @type {ChargeCalculated}
     * @memberof FeesPricingInformation
     */
    resortFee?: ChargeCalculated;
    /**
     * 
     * @type {ChargeCalculated}
     * @memberof FeesPricingInformation
     */
    mandatoryTax?: ChargeCalculated;
}

/**
 * Check if a given object implements the FeesPricingInformation interface.
 */
export function instanceOfFeesPricingInformation(value: object): value is FeesPricingInformation {
    return true;
}

export function FeesPricingInformationFromJSON(json: any): FeesPricingInformation {
    return FeesPricingInformationFromJSONTyped(json, false);
}

export function FeesPricingInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeesPricingInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'mandatoryFee': json['mandatory_fee'] == null ? undefined : ChargeCalculatedFromJSON(json['mandatory_fee']),
        'resortFee': json['resort_fee'] == null ? undefined : ChargeCalculatedFromJSON(json['resort_fee']),
        'mandatoryTax': json['mandatory_tax'] == null ? undefined : ChargeCalculatedFromJSON(json['mandatory_tax']),
    };
}

export function FeesPricingInformationToJSON(value?: FeesPricingInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mandatory_fee': ChargeCalculatedToJSON(value['mandatoryFee']),
        'resort_fee': ChargeCalculatedToJSON(value['resortFee']),
        'mandatory_tax': ChargeCalculatedToJSON(value['mandatoryTax']),
    };
}

