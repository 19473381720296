/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelSummary } from './HotelSummary';
import {
    HotelSummaryFromJSON,
    HotelSummaryFromJSONTyped,
    HotelSummaryToJSON,
} from './HotelSummary';

/**
 * Response to the /hotel/landing_page request
 * @export
 * @interface LandingPageResponse
 */
export interface LandingPageResponse {
    /**
     * 
     * @type {Array<HotelSummary>}
     * @memberof LandingPageResponse
     */
    results?: Array<HotelSummary>;
}

/**
 * Check if a given object implements the LandingPageResponse interface.
 */
export function instanceOfLandingPageResponse(value: object): value is LandingPageResponse {
    return true;
}

export function LandingPageResponseFromJSON(json: any): LandingPageResponse {
    return LandingPageResponseFromJSONTyped(json, false);
}

export function LandingPageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandingPageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'results': json['results'] == null ? undefined : ((json['results'] as Array<any>).map(HotelSummaryFromJSON)),
    };
}

export function LandingPageResponseToJSON(value?: LandingPageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'results': value['results'] == null ? undefined : ((value['results'] as Array<any>).map(HotelSummaryToJSON)),
    };
}

