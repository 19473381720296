/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A geographic region
 * @export
 */
export const Region = {
    Africa: 'Africa',
    Asia: 'Asia',
    Europe: 'Europe',
    NorthAmerica: 'North America',
    Oceania: 'Oceania',
    SouthAmerica: 'South America'
} as const;
export type Region = typeof Region[keyof typeof Region];


export function instanceOfRegion(value: any): boolean {
    for (const key in Region) {
        if (Object.prototype.hasOwnProperty.call(Region, key)) {
            if ((Region as Record<string, Region>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RegionFromJSON(json: any): Region {
    return RegionFromJSONTyped(json, false);
}

export function RegionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Region {
    return json as Region;
}

export function RegionToJSON(value?: Region | null): any {
    return value as any;
}
