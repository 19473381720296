/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Approximate percentile of a points detal
 * @export
 */
export const ApproximatePercentile = {
    Low: 'low',
    Average: 'average',
    High: 'high'
} as const;
export type ApproximatePercentile = typeof ApproximatePercentile[keyof typeof ApproximatePercentile];


export function instanceOfApproximatePercentile(value: any): boolean {
    for (const key in ApproximatePercentile) {
        if (Object.prototype.hasOwnProperty.call(ApproximatePercentile, key)) {
            if ((ApproximatePercentile as Record<string, ApproximatePercentile>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ApproximatePercentileFromJSON(json: any): ApproximatePercentile {
    return ApproximatePercentileFromJSONTyped(json, false);
}

export function ApproximatePercentileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproximatePercentile {
    return json as ApproximatePercentile;
}

export function ApproximatePercentileToJSON(value?: ApproximatePercentile | null): any {
    return value as any;
}
