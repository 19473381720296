/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An unavailable reason that suggests ways that the request could be modified to locate available rooms and rates.
 * @export
 * @interface UnavailableReason
 */
export interface UnavailableReason {
    /**
     * The code representing the reason.
     * * `adults_exceed_threshold` - Number of adults requested exceeds room threshold. `data` will contain the maximum value.
     * * `children_exceed_threshold` - Number of children requested exceeds room threshold. `data` will contain the maximum value.
     * * `infants_exceed_threshold` - Number of infants requested exceeds room threshold. `data` will contain the maximum value.
     * * `minimum_child_age` - Child age requested is less than the minimum age specified for the room. `data` will contain the minimum value.
     * * `maximum_occupancy` - Number of occupants exceed the specified room limit. `data` will contain the maximum value.
     * * `checkin_not_allowed` - Check-in not allowed for this stay date.
     * * `checkout_not_allowed` - Check-out not allowed for this stay date.
     * * `minimum_stay` - Length of stay is less than minimum. `data` will contain the minimum value.
     * * `maximum_stay` - Length of stay is greater than maximum. `data` will contain the maximum value.
     * * `restricted_stay_lengths` - Some stay lengths are not available for this check-in date. `data` will contain the allowed lengths separated by pipe `|`. eg. `3|5|6`
     * * `same_day_restrictions` - Room is not available due to same day booking restrictions.
     * * `maximum_rooms` - Room count exceeds provider limit. `data` will contain the maximum value.
     * * `children_not_supported` - The property is restricted to adults only.
     * * `minimum_advance_purchase` - Minimum Advance Purchase requirement not met. `data` will contain the minimum value.
     * * `maximum_advance_purchase` - Maximum Advance Purchase requirement not met. `data` will contain the maximum value.
     * * `partial_inventory_available` - Some of the dates have no inventory available for the specified duration. `data` will contain the unavailable dates separated by pipe `|`. eg. `2023-10-21|2023-10-23`
     * * `no_inventory_available` - No inventory is available for the specified duration.
     * 
     * @type {string}
     * @memberof UnavailableReason
     */
    code?: UnavailableReasonCodeEnum;
    /**
     * An associated value that provides helpful information for some codes. Not present for all codes.
     * @type {string}
     * @memberof UnavailableReason
     */
    data?: string;
}


/**
 * @export
 */
export const UnavailableReasonCodeEnum = {
    AdultsExceedThreshold: 'adults_exceed_threshold',
    ChildrenExceedThreshold: 'children_exceed_threshold',
    InfantsExceedThreshold: 'infants_exceed_threshold',
    MinimumChildAge: 'minimum_child_age',
    MaximumOccupancy: 'maximum_occupancy',
    CheckinNotAllowed: 'checkin_not_allowed',
    CheckoutNotAllowed: 'checkout_not_allowed',
    MinimumStay: 'minimum_stay',
    MaximumStay: 'maximum_stay',
    RestrictedDates: 'restricted_dates',
    SameDayRestrictions: 'same_day_restrictions',
    MaximumRooms: 'maximum_rooms',
    ChildrenNotSupported: 'children_not_supported',
    MinimumAdvancePurchase: 'minimum_advance_purchase',
    MaximumAdvancePurchase: 'maximum_advance_purchase',
    PartialInventoryAvailable: 'partial_inventory_available',
    NoInventoryAvailable: 'no_inventory_available'
} as const;
export type UnavailableReasonCodeEnum = typeof UnavailableReasonCodeEnum[keyof typeof UnavailableReasonCodeEnum];


/**
 * Check if a given object implements the UnavailableReason interface.
 */
export function instanceOfUnavailableReason(value: object): value is UnavailableReason {
    return true;
}

export function UnavailableReasonFromJSON(json: any): UnavailableReason {
    return UnavailableReasonFromJSONTyped(json, false);
}

export function UnavailableReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnavailableReason {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'] == null ? undefined : json['code'],
        'data': json['data'] == null ? undefined : json['data'],
    };
}

export function UnavailableReasonToJSON(value?: UnavailableReason | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'data': value['data'],
    };
}

