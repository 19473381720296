/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Offer } from './Offer';
import {
    OfferFromJSON,
    OfferFromJSONTyped,
    OfferToJSON,
} from './Offer';

/**
 * The response for a flight search
 * @export
 * @interface FlightSearchResponse
 */
export interface FlightSearchResponse {
    /**
     * 
     * @type {Array<Offer>}
     * @memberof FlightSearchResponse
     */
    items?: Array<Offer>;
}

/**
 * Check if a given object implements the FlightSearchResponse interface.
 */
export function instanceOfFlightSearchResponse(value: object): value is FlightSearchResponse {
    return true;
}

export function FlightSearchResponseFromJSON(json: any): FlightSearchResponse {
    return FlightSearchResponseFromJSONTyped(json, false);
}

export function FlightSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightSearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(OfferFromJSON)),
    };
}

export function FlightSearchResponseToJSON(value?: FlightSearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(OfferToJSON)),
    };
}

