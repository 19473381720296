/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of offer this value add promotion is.
 * @export
 */
export const OfferType = {
    BuyOneGetOneFree: 'buy_one_get_one_free',
    Credit: 'credit',
    Discount: 'discount',
    Free: 'free',
    Voucher: 'voucher'
} as const;
export type OfferType = typeof OfferType[keyof typeof OfferType];


export function instanceOfOfferType(value: any): boolean {
    for (const key in OfferType) {
        if (Object.prototype.hasOwnProperty.call(OfferType, key)) {
            if ((OfferType as Record<string, OfferType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OfferTypeFromJSON(json: any): OfferType {
    return OfferTypeFromJSONTyped(json, false);
}

export function OfferTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferType {
    return json as OfferType;
}

export function OfferTypeToJSON(value?: OfferType | null): any {
    return value as any;
}
