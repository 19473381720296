/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelAmenity } from './HotelAmenity';
import {
    HotelAmenityFromJSON,
    HotelAmenityFromJSONTyped,
    HotelAmenityToJSON,
} from './HotelAmenity';
import type { Hotel } from './Hotel';
import {
    HotelFromJSON,
    HotelFromJSONTyped,
    HotelToJSON,
} from './Hotel';

/**
 * 
 * @export
 * @interface SearchSessionResultsHotels
 */
export interface SearchSessionResultsHotels {
    /**
     * 
     * @type {Array<Hotel>}
     * @memberof SearchSessionResultsHotels
     */
    items?: Array<Hotel>;
    /**
     * 
     * @type {number}
     * @memberof SearchSessionResultsHotels
     */
    numberOfItems: number;
    /**
     * All amenities available in the search results
     * @type {Array<HotelAmenity>}
     * @memberof SearchSessionResultsHotels
     */
    availableAmenities?: Array<HotelAmenity>;
    /**
     * All image categories available in the search results
     * @type {Array<string>}
     * @memberof SearchSessionResultsHotels
     */
    availableImageCategories?: Array<string>;
}

/**
 * Check if a given object implements the SearchSessionResultsHotels interface.
 */
export function instanceOfSearchSessionResultsHotels(value: object): value is SearchSessionResultsHotels {
    if (!('numberOfItems' in value) || value['numberOfItems'] === undefined) return false;
    return true;
}

export function SearchSessionResultsHotelsFromJSON(json: any): SearchSessionResultsHotels {
    return SearchSessionResultsHotelsFromJSONTyped(json, false);
}

export function SearchSessionResultsHotelsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSessionResultsHotels {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(HotelFromJSON)),
        'numberOfItems': json['number_of_items'],
        'availableAmenities': json['available_amenities'] == null ? undefined : ((json['available_amenities'] as Array<any>).map(HotelAmenityFromJSON)),
        'availableImageCategories': json['available_image_categories'] == null ? undefined : json['available_image_categories'],
    };
}

export function SearchSessionResultsHotelsToJSON(value?: SearchSessionResultsHotels | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(HotelToJSON)),
        'number_of_items': value['numberOfItems'],
        'available_amenities': value['availableAmenities'] == null ? undefined : ((value['availableAmenities'] as Array<any>).map(HotelAmenityToJSON)),
        'available_image_categories': value['availableImageCategories'],
    };
}

