/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateRange } from './DateRange';
import {
    DateRangeFromJSON,
    DateRangeFromJSONTyped,
    DateRangeToJSON,
} from './DateRange';

/**
 * A set of search dates
 * @export
 * @interface SearchDatesV2
 */
export interface SearchDatesV2 {
    /**
     * 
     * @type {Array<DateRange>}
     * @memberof SearchDatesV2
     */
    searchDates: Array<DateRange>;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDatesV2
     */
    flexible: boolean;
}

/**
 * Check if a given object implements the SearchDatesV2 interface.
 */
export function instanceOfSearchDatesV2(value: object): value is SearchDatesV2 {
    if (!('searchDates' in value) || value['searchDates'] === undefined) return false;
    if (!('flexible' in value) || value['flexible'] === undefined) return false;
    return true;
}

export function SearchDatesV2FromJSON(json: any): SearchDatesV2 {
    return SearchDatesV2FromJSONTyped(json, false);
}

export function SearchDatesV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchDatesV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'searchDates': ((json['search_dates'] as Array<any>).map(DateRangeFromJSON)),
        'flexible': json['flexible'],
    };
}

export function SearchDatesV2ToJSON(value?: SearchDatesV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'search_dates': ((value['searchDates'] as Array<any>).map(DateRangeToJSON)),
        'flexible': value['flexible'],
    };
}

