/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FlightSearchRequest } from './FlightSearchRequest';
import {
    FlightSearchRequestFromJSON,
    FlightSearchRequestFromJSONTyped,
    FlightSearchRequestToJSON,
} from './FlightSearchRequest';
import type { UserEnvironment } from './UserEnvironment';
import {
    UserEnvironmentFromJSON,
    UserEnvironmentFromJSONTyped,
    UserEnvironmentToJSON,
} from './UserEnvironment';
import type { HotelSearchRequest } from './HotelSearchRequest';
import {
    HotelSearchRequestFromJSON,
    HotelSearchRequestFromJSONTyped,
    HotelSearchRequestToJSON,
} from './HotelSearchRequest';

/**
 * A generic search request for flights, hotels or both
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {UserEnvironment}
     * @memberof SearchRequest
     */
    userEnvironment: UserEnvironment;
    /**
     * 
     * @type {FlightSearchRequest}
     * @memberof SearchRequest
     */
    flights?: FlightSearchRequest;
    /**
     * 
     * @type {HotelSearchRequest}
     * @memberof SearchRequest
     */
    hotels?: HotelSearchRequest;
}

/**
 * Check if a given object implements the SearchRequest interface.
 */
export function instanceOfSearchRequest(value: object): value is SearchRequest {
    if (!('userEnvironment' in value) || value['userEnvironment'] === undefined) return false;
    return true;
}

export function SearchRequestFromJSON(json: any): SearchRequest {
    return SearchRequestFromJSONTyped(json, false);
}

export function SearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userEnvironment': UserEnvironmentFromJSON(json['user_environment']),
        'flights': json['flights'] == null ? undefined : FlightSearchRequestFromJSON(json['flights']),
        'hotels': json['hotels'] == null ? undefined : HotelSearchRequestFromJSON(json['hotels']),
    };
}

export function SearchRequestToJSON(value?: SearchRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_environment': UserEnvironmentToJSON(value['userEnvironment']),
        'flights': FlightSearchRequestToJSON(value['flights']),
        'hotels': HotelSearchRequestToJSON(value['hotels']),
    };
}

