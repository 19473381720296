/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The frequency of when this applies.
 * @export
 */
export const Frequency = {
    Unknown: 'unknown',
    PerNight: 'per_night',
    PerDay: 'per_day',
    PerStay: 'per_stay',
    PerWeek: 'per_week',
    RoundTrip: 'round_trip',
    OneWay: 'one_way'
} as const;
export type Frequency = typeof Frequency[keyof typeof Frequency];


export function instanceOfFrequency(value: any): boolean {
    for (const key in Frequency) {
        if (Object.prototype.hasOwnProperty.call(Frequency, key)) {
            if ((Frequency as Record<string, Frequency>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FrequencyFromJSON(json: any): Frequency {
    return FrequencyFromJSONTyped(json, false);
}

export function FrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Frequency {
    return json as Frequency;
}

export function FrequencyToJSON(value?: Frequency | null): any {
    return value as any;
}
