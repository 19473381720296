/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';

/**
 * Details about initiating conversations.
 * @export
 * @interface Conversations
 */
export interface Conversations {
    /**
     * Contains urls for links to initiate conversations via EPS.
     * @type {{ [key: string]: Link; }}
     * @memberof Conversations
     */
    links?: { [key: string]: Link; };
}

/**
 * Check if a given object implements the Conversations interface.
 */
export function instanceOfConversations(value: object): value is Conversations {
    return true;
}

export function ConversationsFromJSON(json: any): Conversations {
    return ConversationsFromJSONTyped(json, false);
}

export function ConversationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Conversations {
    if (json == null) {
        return json;
    }
    return {
        
        'links': json['links'] == null ? undefined : (mapValues(json['links'], LinkFromJSON)),
    };
}

export function ConversationsToJSON(value?: Conversations | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'links': value['links'] == null ? undefined : (mapValues(value['links'], LinkToJSON)),
    };
}

