/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { City } from './City';
import {
    CityFromJSON,
    CityFromJSONTyped,
    CityToJSON,
} from './City';

/**
 * 
 * @export
 * @interface OfferSliceSegmentOrigin
 */
export interface OfferSliceSegmentOrigin {
    /**
     * 
     * @type {City}
     * @memberof OfferSliceSegmentOrigin
     */
    city?: City;
    /**
     * The name of the city (or cities separated by a `/`) where the airport is located
     * @type {string}
     * @memberof OfferSliceSegmentOrigin
     */
    cityName: string;
    /**
     * The three-character IATA code for the city where the airport is located
     * @type {string}
     * @memberof OfferSliceSegmentOrigin
     */
    iataCityCode: string;
    /**
     * The three-character IATA code for the airport
     * @type {string}
     * @memberof OfferSliceSegmentOrigin
     */
    iataCode: string;
    /**
     * The [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) code for the country where the airport is located
     * @type {string}
     * @memberof OfferSliceSegmentOrigin
     */
    iataCountryCode: string;
    /**
     * The four-character ICAO code for the airport
     * @type {string}
     * @memberof OfferSliceSegmentOrigin
     */
    icaoCode: string | null;
    /**
     * Duffel's unique identifier for the resource
     * @type {string}
     * @memberof OfferSliceSegmentOrigin
     */
    id: string;
    /**
     * The latitude position of the airport represented in [Decimal degrees](https://en.wikipedia.org/wiki/Decimal_degrees) with 6 decimal points with a range between -90° and 90°
     * @type {number}
     * @memberof OfferSliceSegmentOrigin
     */
    latitude: number;
    /**
     * The longitude position of the airport represented in [Decimal degrees](https://en.wikipedia.org/wiki/Decimal_degrees) with 6 decimal points with a range between -180° and 180°
     * @type {number}
     * @memberof OfferSliceSegmentOrigin
     */
    longitude: number;
    /**
     * The name of the airport
     * @type {string}
     * @memberof OfferSliceSegmentOrigin
     */
    name: string;
    /**
     * The time zone of the airport, specified by name from the [tz database](https://en.wikipedia.org/wiki/Tz_database)
     * @type {string}
     * @memberof OfferSliceSegmentOrigin
     */
    timeZone: string;
}

/**
 * Check if a given object implements the OfferSliceSegmentOrigin interface.
 */
export function instanceOfOfferSliceSegmentOrigin(value: object): value is OfferSliceSegmentOrigin {
    if (!('cityName' in value) || value['cityName'] === undefined) return false;
    if (!('iataCityCode' in value) || value['iataCityCode'] === undefined) return false;
    if (!('iataCode' in value) || value['iataCode'] === undefined) return false;
    if (!('iataCountryCode' in value) || value['iataCountryCode'] === undefined) return false;
    if (!('icaoCode' in value) || value['icaoCode'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('timeZone' in value) || value['timeZone'] === undefined) return false;
    return true;
}

export function OfferSliceSegmentOriginFromJSON(json: any): OfferSliceSegmentOrigin {
    return OfferSliceSegmentOriginFromJSONTyped(json, false);
}

export function OfferSliceSegmentOriginFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentOrigin {
    if (json == null) {
        return json;
    }
    return {
        
        'city': json['city'] == null ? undefined : CityFromJSON(json['city']),
        'cityName': json['city_name'],
        'iataCityCode': json['iata_city_code'],
        'iataCode': json['iata_code'],
        'iataCountryCode': json['iata_country_code'],
        'icaoCode': json['icao_code'],
        'id': json['id'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'name': json['name'],
        'timeZone': json['time_zone'],
    };
}

export function OfferSliceSegmentOriginToJSON(value?: OfferSliceSegmentOrigin | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'city': CityToJSON(value['city']),
        'city_name': value['cityName'],
        'iata_city_code': value['iataCityCode'],
        'iata_code': value['iataCode'],
        'iata_country_code': value['iataCountryCode'],
        'icao_code': value['icaoCode'],
        'id': value['id'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'name': value['name'],
        'time_zone': value['timeZone'],
    };
}

