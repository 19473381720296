/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NightCharge } from './NightCharge';
import {
    NightChargeFromJSON,
    NightChargeFromJSONTyped,
    NightChargeToJSON,
} from './NightCharge';
import type { FeesPricingInformation } from './FeesPricingInformation';
import {
    FeesPricingInformationFromJSON,
    FeesPricingInformationFromJSONTyped,
    FeesPricingInformationToJSON,
} from './FeesPricingInformation';
import type { Totals } from './Totals';
import {
    TotalsFromJSON,
    TotalsFromJSONTyped,
    TotalsToJSON,
} from './Totals';
import type { Stay } from './Stay';
import {
    StayFromJSON,
    StayFromJSONTyped,
    StayToJSON,
} from './Stay';

/**
 * The pricing information object.
 * @export
 * @interface PricingInformation
 */
export interface PricingInformation {
    /**
     * Array of arrays of amount objects. Each sub-array of amount objects represents a single night's charges.
     * @type {Array<Array<NightCharge>>}
     * @memberof PricingInformation
     */
    nightly?: Array<Array<NightCharge>>;
    /**
     * Array of amount objects. Details any charges that apply to the entire stay (not divided per-night). Any per-room adjustments are applied to the `base_rate` amount within this object.
     * @type {Array<Stay>}
     * @memberof PricingInformation
     */
    stay?: Array<Stay>;
    /**
     * 
     * @type {Totals}
     * @memberof PricingInformation
     */
    totals?: Totals;
    /**
     * 
     * @type {FeesPricingInformation}
     * @memberof PricingInformation
     */
    fees?: FeesPricingInformation;
}

/**
 * Check if a given object implements the PricingInformation interface.
 */
export function instanceOfPricingInformation(value: object): value is PricingInformation {
    return true;
}

export function PricingInformationFromJSON(json: any): PricingInformation {
    return PricingInformationFromJSONTyped(json, false);
}

export function PricingInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'nightly': json['nightly'] == null ? undefined : json['nightly'],
        'stay': json['stay'] == null ? undefined : ((json['stay'] as Array<any>).map(StayFromJSON)),
        'totals': json['totals'] == null ? undefined : TotalsFromJSON(json['totals']),
        'fees': json['fees'] == null ? undefined : FeesPricingInformationFromJSON(json['fees']),
    };
}

export function PricingInformationToJSON(value?: PricingInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nightly': value['nightly'],
        'stay': value['stay'] == null ? undefined : ((value['stay'] as Array<any>).map(StayToJSON)),
        'totals': TotalsToJSON(value['totals']),
        'fees': FeesPricingInformationToJSON(value['fees']),
    };
}

