/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TripReason } from './TripReason';
import {
    TripReasonFromJSON,
    TripReasonFromJSONTyped,
    TripReasonToJSON,
} from './TripReason';
import type { TravelCompanion } from './TravelCompanion';
import {
    TravelCompanionFromJSON,
    TravelCompanionFromJSONTyped,
    TravelCompanionToJSON,
} from './TravelCompanion';

/**
 * A review object for a property.
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * Where this review has been verified from.
     * @type {string}
     * @memberof Review
     */
    verificationSource?: string;
    /**
     * Title of this review.
     * @type {string}
     * @memberof Review
     */
    title?: string;
    /**
     * When this review was made, in ISO 8601 format.
     * @type {string}
     * @memberof Review
     */
    dateSubmitted?: string;
    /**
     * The rating for this property given by the reviewer. Returns a value between 1.0 and 5.0.
     * @type {string}
     * @memberof Review
     */
    rating?: string;
    /**
     * The name of the person who wrote this review.
     * @type {string}
     * @memberof Review
     */
    reviewerName?: string;
    /**
     * 
     * @type {TripReason}
     * @memberof Review
     */
    tripReason?: TripReason;
    /**
     * 
     * @type {TravelCompanion}
     * @memberof Review
     */
    travelCompanion?: TravelCompanion;
    /**
     * The text of the review itself.
     * @type {string}
     * @memberof Review
     */
    text?: string;
}

/**
 * Check if a given object implements the Review interface.
 */
export function instanceOfReview(value: object): value is Review {
    return true;
}

export function ReviewFromJSON(json: any): Review {
    return ReviewFromJSONTyped(json, false);
}

export function ReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): Review {
    if (json == null) {
        return json;
    }
    return {
        
        'verificationSource': json['verification_source'] == null ? undefined : json['verification_source'],
        'title': json['title'] == null ? undefined : json['title'],
        'dateSubmitted': json['date_submitted'] == null ? undefined : json['date_submitted'],
        'rating': json['rating'] == null ? undefined : json['rating'],
        'reviewerName': json['reviewer_name'] == null ? undefined : json['reviewer_name'],
        'tripReason': json['trip_reason'] == null ? undefined : TripReasonFromJSON(json['trip_reason']),
        'travelCompanion': json['travel_companion'] == null ? undefined : TravelCompanionFromJSON(json['travel_companion']),
        'text': json['text'] == null ? undefined : json['text'],
    };
}

export function ReviewToJSON(value?: Review | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'verification_source': value['verificationSource'],
        'title': value['title'],
        'date_submitted': value['dateSubmitted'],
        'rating': value['rating'],
        'reviewer_name': value['reviewerName'],
        'trip_reason': TripReasonToJSON(value['tripReason']),
        'travel_companion': TravelCompanionToJSON(value['travelCompanion']),
        'text': value['text'],
    };
}

