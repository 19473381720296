/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HotelContentPointOfInterestsInner
 */
export interface HotelContentPointOfInterestsInner {
    /**
     * 
     * @type {string}
     * @memberof HotelContentPointOfInterestsInner
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelContentPointOfInterestsInner
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelContentPointOfInterestsInner
     */
    distance?: string;
    /**
     * Geo code. Format - `<Lat>, <Lon>`
     * @type {string}
     * @memberof HotelContentPointOfInterestsInner
     */
    geocode?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelContentPointOfInterestsInner
     */
    unit?: HotelContentPointOfInterestsInnerUnitEnum;
}


/**
 * @export
 */
export const HotelContentPointOfInterestsInnerUnitEnum = {
    Km: 'Km',
    Miles: 'Miles',
    Meters: 'Meters'
} as const;
export type HotelContentPointOfInterestsInnerUnitEnum = typeof HotelContentPointOfInterestsInnerUnitEnum[keyof typeof HotelContentPointOfInterestsInnerUnitEnum];


/**
 * Check if a given object implements the HotelContentPointOfInterestsInner interface.
 */
export function instanceOfHotelContentPointOfInterestsInner(value: object): value is HotelContentPointOfInterestsInner {
    return true;
}

export function HotelContentPointOfInterestsInnerFromJSON(json: any): HotelContentPointOfInterestsInner {
    return HotelContentPointOfInterestsInnerFromJSONTyped(json, false);
}

export function HotelContentPointOfInterestsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelContentPointOfInterestsInner {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['Name'] == null ? undefined : json['Name'],
        'type': json['Type'] == null ? undefined : json['Type'],
        'distance': json['Distance'] == null ? undefined : json['Distance'],
        'geocode': json['Geocode'] == null ? undefined : json['Geocode'],
        'unit': json['Unit'] == null ? undefined : json['Unit'],
    };
}

export function HotelContentPointOfInterestsInnerToJSON(value?: HotelContentPointOfInterestsInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Name': value['name'],
        'Type': value['type'],
        'Distance': value['distance'],
        'Geocode': value['geocode'],
        'Unit': value['unit'],
    };
}

