/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An individual bed.
 * @export
 * @interface Bed
 */
export interface Bed {
    /**
     * Bed Id provided by provider.
     * @type {string}
     * @memberof Bed
     */
    providerBedId?: string;
    /**
     * Bed type.
     * @type {string}
     * @memberof Bed
     */
    type?: string;
    /**
     * Description of bed.
     * @type {string}
     * @memberof Bed
     */
    description?: string;
    /**
     * Bed size.
     * @type {string}
     * @memberof Bed
     */
    size?: string;
    /**
     * Bed count.
     * @type {number}
     * @memberof Bed
     */
    count?: number;
}

/**
 * Check if a given object implements the Bed interface.
 */
export function instanceOfBed(value: object): value is Bed {
    return true;
}

export function BedFromJSON(json: any): Bed {
    return BedFromJSONTyped(json, false);
}

export function BedFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bed {
    if (json == null) {
        return json;
    }
    return {
        
        'providerBedId': json['ProviderBedId'] == null ? undefined : json['ProviderBedId'],
        'type': json['Type'] == null ? undefined : json['Type'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'size': json['Size'] == null ? undefined : json['Size'],
        'count': json['Count'] == null ? undefined : json['Count'],
    };
}

export function BedToJSON(value?: Bed | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ProviderBedId': value['providerBedId'],
        'Type': value['type'],
        'Description': value['description'],
        'Size': value['size'],
        'Count': value['count'],
    };
}

