/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * If Wi-Fi is available, information on its cost, availability, etc.
 * @export
 * @interface OfferSliceSegmentPassengerCabinAmenitiesWifi
 */
export interface OfferSliceSegmentPassengerCabinAmenitiesWifi {
    /**
     * Whether Wi-Fi is available in this cabin
     * @type {boolean}
     * @memberof OfferSliceSegmentPassengerCabinAmenitiesWifi
     */
    available?: boolean;
    /**
     * The cost, if any, to use the Wi-Fi
     * @type {string}
     * @memberof OfferSliceSegmentPassengerCabinAmenitiesWifi
     */
    cost?: OfferSliceSegmentPassengerCabinAmenitiesWifiCostEnum;
}


/**
 * @export
 */
export const OfferSliceSegmentPassengerCabinAmenitiesWifiCostEnum = {
    Free: 'free',
    FreeOrPaid: 'free or paid',
    Paid: 'paid',
    NA: 'n/a'
} as const;
export type OfferSliceSegmentPassengerCabinAmenitiesWifiCostEnum = typeof OfferSliceSegmentPassengerCabinAmenitiesWifiCostEnum[keyof typeof OfferSliceSegmentPassengerCabinAmenitiesWifiCostEnum];


/**
 * Check if a given object implements the OfferSliceSegmentPassengerCabinAmenitiesWifi interface.
 */
export function instanceOfOfferSliceSegmentPassengerCabinAmenitiesWifi(value: object): value is OfferSliceSegmentPassengerCabinAmenitiesWifi {
    return true;
}

export function OfferSliceSegmentPassengerCabinAmenitiesWifiFromJSON(json: any): OfferSliceSegmentPassengerCabinAmenitiesWifi {
    return OfferSliceSegmentPassengerCabinAmenitiesWifiFromJSONTyped(json, false);
}

export function OfferSliceSegmentPassengerCabinAmenitiesWifiFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentPassengerCabinAmenitiesWifi {
    if (json == null) {
        return json;
    }
    return {
        
        'available': json['available'] == null ? undefined : json['available'],
        'cost': json['cost'] == null ? undefined : json['cost'],
    };
}

export function OfferSliceSegmentPassengerCabinAmenitiesWifiToJSON(value?: OfferSliceSegmentPassengerCabinAmenitiesWifi | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'available': value['available'],
        'cost': value['cost'],
    };
}

