/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Viewport } from './Viewport';
import {
    ViewportFromJSON,
    ViewportFromJSONTyped,
    ViewportToJSON,
} from './Viewport';

/**
 * Metadata that is not used to find properties, but may be used in other parts of search
 * @export
 * @interface SearchMetadata
 */
export interface SearchMetadata {
    /**
     * The raw query string
     * @type {string}
     * @memberof SearchMetadata
     */
    rawQuery?: string;
    /**
     * The Google Place ID of the search location
     * @type {string}
     * @memberof SearchMetadata
     */
    googlePlaceId?: string;
    /**
     * 
     * @type {Viewport}
     * @memberof SearchMetadata
     */
    viewport?: Viewport;
}

/**
 * Check if a given object implements the SearchMetadata interface.
 */
export function instanceOfSearchMetadata(value: object): value is SearchMetadata {
    return true;
}

export function SearchMetadataFromJSON(json: any): SearchMetadata {
    return SearchMetadataFromJSONTyped(json, false);
}

export function SearchMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'rawQuery': json['raw_query'] == null ? undefined : json['raw_query'],
        'googlePlaceId': json['google_place_id'] == null ? undefined : json['google_place_id'],
        'viewport': json['viewport'] == null ? undefined : ViewportFromJSON(json['viewport']),
    };
}

export function SearchMetadataToJSON(value?: SearchMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'raw_query': value['rawQuery'],
        'google_place_id': value['googlePlaceId'],
        'viewport': ViewportToJSON(value['viewport']),
    };
}

