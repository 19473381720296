/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckoutInstructions
 */
export interface CheckoutInstructions {
    /**
     * 
     * @type {string}
     * @memberof CheckoutInstructions
     */
    checkoutTime?: string;
}

/**
 * Check if a given object implements the CheckoutInstructions interface.
 */
export function instanceOfCheckoutInstructions(value: object): value is CheckoutInstructions {
    return true;
}

export function CheckoutInstructionsFromJSON(json: any): CheckoutInstructions {
    return CheckoutInstructionsFromJSONTyped(json, false);
}

export function CheckoutInstructionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutInstructions {
    if (json == null) {
        return json;
    }
    return {
        
        'checkoutTime': json['checkout_time'] == null ? undefined : json['checkout_time'],
    };
}

export function CheckoutInstructionsToJSON(value?: CheckoutInstructions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'checkout_time': value['checkoutTime'],
    };
}

