/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferPassenger } from './OfferPassenger';
import {
    OfferPassengerFromJSON,
    OfferPassengerFromJSONTyped,
    OfferPassengerToJSON,
} from './OfferPassenger';
import type { OfferPrivateFares } from './OfferPrivateFares';
import {
    OfferPrivateFaresFromJSON,
    OfferPrivateFaresFromJSONTyped,
    OfferPrivateFaresToJSON,
} from './OfferPrivateFares';
import type { OfferOwner } from './OfferOwner';
import {
    OfferOwnerFromJSON,
    OfferOwnerFromJSONTyped,
    OfferOwnerToJSON,
} from './OfferOwner';
import type { OfferConditions } from './OfferConditions';
import {
    OfferConditionsFromJSON,
    OfferConditionsFromJSONTyped,
    OfferConditionsToJSON,
} from './OfferConditions';
import type { OfferSlice } from './OfferSlice';
import {
    OfferSliceFromJSON,
    OfferSliceFromJSONTyped,
    OfferSliceToJSON,
} from './OfferSlice';
import type { OfferPaymentRequirements } from './OfferPaymentRequirements';
import {
    OfferPaymentRequirementsFromJSON,
    OfferPaymentRequirementsFromJSONTyped,
    OfferPaymentRequirementsToJSON,
} from './OfferPaymentRequirements';
import type { OfferService } from './OfferService';
import {
    OfferServiceFromJSON,
    OfferServiceFromJSONTyped,
    OfferServiceToJSON,
} from './OfferService';

/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * The types of identity documents that may be provided for the passengers when creating an order based on this offer.
     * Currently, possible types are `passport`, `tax_id`, `known_traveler_number`, and `passenger_redress_number`.
     * If this is `[]`, then you must not provide identity documents.
     * This field has been deprecated and the `supported_passenger_identity_document_types` field provides equivalent behaviour.
     * @type {Array<string>}
     * @memberof Offer
     */
    allowedPassengerIdentityDocumentTypes?: Array<OfferAllowedPassengerIdentityDocumentTypesEnum>;
    /**
     * The services that can be booked along with the offer but are not included by default, for example an additional checked bag. This field is only returned in the [Get single offer](/docs/api/offers/get-offer-by-id) endpoint. When there are no services available, or we don't support services for the airline, this list will be empty. If you want to know which airlines we support services for, please get in touch with the Duffel support team at <help@duffel.com>.
     * @type {Array<OfferService>}
     * @memberof Offer
     */
    availableServices?: Array<OfferService>;
    /**
     * The base price of the offer for all passengers, excluding taxes. It does not include the base amount of any service(s) that might be booked with the offer.
     * @type {string}
     * @memberof Offer
     */
    baseAmount?: string;
    /**
     * The currency of the `base_amount`, as an [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code.
     * It will match your organisation's billing currency unless you’re using Duffel as an accredited IATA agent, in which case it will be in the currency provided by the airline (which will usually be based on the country where your IATA agency is registered).
     * 
     * @type {string}
     * @memberof Offer
     */
    baseCurrency?: string;
    /**
     * 
     * @type {OfferConditions}
     * @memberof Offer
     */
    conditions?: OfferConditions;
    /**
     * The [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) datetime at which the offer was created
     * @type {Date}
     * @memberof Offer
     */
    createdAt?: Date;
    /**
     * The [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) datetime expiry of the offer before which the traveller should use this offer to create an order. After this time the offer can no longer be used to create an order.
     * @type {Date}
     * @memberof Offer
     */
    expiresAt?: Date;
    /**
     * Duffel's unique identifier for the resource
     * @type {string}
     * @memberof Offer
     */
    id?: string;
    /**
     * Whether the offer request was created in live mode. This field will be set to `true` if the offer request was created in live mode, or `false` if it was created in test mode.
     * @type {boolean}
     * @memberof Offer
     */
    liveMode?: boolean;
    /**
     * 
     * @type {OfferOwner}
     * @memberof Offer
     */
    owner?: OfferOwner;
    /**
     * Partial offers are a new concept we're introducing as a part of a new multi-step search flow that we're currently experimenting with.
     * A partial offer can't be booked directly, but it can be combined with other partial offers to form a full offer.
     * Partial offers are only ever returned through the multi-step search flow. So there's no need to add any handling to deal with partial offers if you're using the traditional `OfferRequest` search flow to create offers.
     * 
     * @type {boolean}
     * @memberof Offer
     */
    partial?: boolean;
    /**
     * Whether identity documents must be provided for each of the passengers when creating an order based on this offer. If this is `true`, you must provide a passport document for every passenger.
     * @type {boolean}
     * @memberof Offer
     */
    passengerIdentityDocumentsRequired?: boolean;
    /**
     * The passengers included in the offer
     * @type {Array<OfferPassenger>}
     * @memberof Offer
     */
    passengers?: Array<OfferPassenger>;
    /**
     * 
     * @type {OfferPaymentRequirements}
     * @memberof Offer
     */
    paymentRequirements?: OfferPaymentRequirements;
    /**
     * The private fares applied on this offer.
     * @type {Array<OfferPrivateFares>}
     * @memberof Offer
     */
    privateFares?: Array<OfferPrivateFares>;
    /**
     * The [slices](/docs/api/overview/key-principles) that make up this offer. Each slice will include one or more [segments](/docs/api/overview/key-principles), the specific flights that the airline is offering to take the passengers from the slice's `origin` to its `destination`.
     * [Partial](#offers-schema-partial) offers contain a single slice as each partial offer is for a particular slice of the journey.
     * @type {Array<OfferSlice>}
     * @memberof Offer
     */
    slices?: Array<OfferSlice>;
    /**
     * The types of identity documents supported by the airline and may be provided for the passengers when creating an order based on this offer.
     * Currently, possible types are `passport`, `tax_id`, `known_traveler_number`, and `passenger_redress_number`.
     * @type {Array<string>}
     * @memberof Offer
     */
    supportedPassengerIdentityDocumentTypes?: Array<OfferSupportedPassengerIdentityDocumentTypesEnum>;
    /**
     * The amount of tax payable on the offer for all passengers
     * @type {string}
     * @memberof Offer
     */
    taxAmount?: string;
    /**
     * The currency of the `tax_amount`, as an [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code.
     * It will match your organisation's billing currency unless you’re using Duffel as an accredited IATA agent, in which case it will be in the currency provided by the airline (which will usually be based on the country where your IATA agency is registered).
     * 
     * @type {string}
     * @memberof Offer
     */
    taxCurrency?: string;
    /**
     * The total price of the offer for all passengers, including taxes. It does not include the total price of any service(s) that might be booked with the offer.
     * @type {string}
     * @memberof Offer
     */
    totalAmount?: string;
    /**
     * The currency of the `total_amount`, as an [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code.
     * It will match your organisation's billing currency unless you’re using Duffel as an accredited IATA agent, in which case it will be in the currency provided by the airline (which will usually be based on the country where your IATA agency is registered).
     * 
     * @type {string}
     * @memberof Offer
     */
    totalCurrency?: string;
    /**
     * An estimate of the total carbon dioxide (CO₂) emissions when all of the passengers fly this offer's itinerary, measured in kilograms
     * @type {string}
     * @memberof Offer
     */
    totalEmissionsKg?: string;
    /**
     * The [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) datetime at which the offer was last updated
     * @type {Date}
     * @memberof Offer
     */
    updatedAt?: Date;
}


/**
 * @export
 */
export const OfferAllowedPassengerIdentityDocumentTypesEnum = {
    Passport: 'passport',
    TaxId: 'tax_id',
    KnownTravelerNumber: 'known_traveler_number',
    PassengerRedressNumber: 'passenger_redress_number'
} as const;
export type OfferAllowedPassengerIdentityDocumentTypesEnum = typeof OfferAllowedPassengerIdentityDocumentTypesEnum[keyof typeof OfferAllowedPassengerIdentityDocumentTypesEnum];

/**
 * @export
 */
export const OfferSupportedPassengerIdentityDocumentTypesEnum = {
    Passport: 'passport',
    TaxId: 'tax_id',
    KnownTravelerNumber: 'known_traveler_number',
    PassengerRedressNumber: 'passenger_redress_number'
} as const;
export type OfferSupportedPassengerIdentityDocumentTypesEnum = typeof OfferSupportedPassengerIdentityDocumentTypesEnum[keyof typeof OfferSupportedPassengerIdentityDocumentTypesEnum];


/**
 * Check if a given object implements the Offer interface.
 */
export function instanceOfOffer(value: object): value is Offer {
    return true;
}

export function OfferFromJSON(json: any): Offer {
    return OfferFromJSONTyped(json, false);
}

export function OfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): Offer {
    if (json == null) {
        return json;
    }
    return {
        
        'allowedPassengerIdentityDocumentTypes': json['allowed_passenger_identity_document_types'] == null ? undefined : json['allowed_passenger_identity_document_types'],
        'availableServices': json['available_services'] == null ? undefined : ((json['available_services'] as Array<any>).map(OfferServiceFromJSON)),
        'baseAmount': json['base_amount'] == null ? undefined : json['base_amount'],
        'baseCurrency': json['base_currency'] == null ? undefined : json['base_currency'],
        'conditions': json['conditions'] == null ? undefined : OfferConditionsFromJSON(json['conditions']),
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'expiresAt': json['expires_at'] == null ? undefined : (new Date(json['expires_at'])),
        'id': json['id'] == null ? undefined : json['id'],
        'liveMode': json['live_mode'] == null ? undefined : json['live_mode'],
        'owner': json['owner'] == null ? undefined : OfferOwnerFromJSON(json['owner']),
        'partial': json['partial'] == null ? undefined : json['partial'],
        'passengerIdentityDocumentsRequired': json['passenger_identity_documents_required'] == null ? undefined : json['passenger_identity_documents_required'],
        'passengers': json['passengers'] == null ? undefined : ((json['passengers'] as Array<any>).map(OfferPassengerFromJSON)),
        'paymentRequirements': json['payment_requirements'] == null ? undefined : OfferPaymentRequirementsFromJSON(json['payment_requirements']),
        'privateFares': json['private_fares'] == null ? undefined : ((json['private_fares'] as Array<any>).map(OfferPrivateFaresFromJSON)),
        'slices': json['slices'] == null ? undefined : ((json['slices'] as Array<any>).map(OfferSliceFromJSON)),
        'supportedPassengerIdentityDocumentTypes': json['supported_passenger_identity_document_types'] == null ? undefined : json['supported_passenger_identity_document_types'],
        'taxAmount': json['tax_amount'] == null ? undefined : json['tax_amount'],
        'taxCurrency': json['tax_currency'] == null ? undefined : json['tax_currency'],
        'totalAmount': json['total_amount'] == null ? undefined : json['total_amount'],
        'totalCurrency': json['total_currency'] == null ? undefined : json['total_currency'],
        'totalEmissionsKg': json['total_emissions_kg'] == null ? undefined : json['total_emissions_kg'],
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
    };
}

export function OfferToJSON(value?: Offer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'allowed_passenger_identity_document_types': value['allowedPassengerIdentityDocumentTypes'],
        'available_services': value['availableServices'] == null ? undefined : ((value['availableServices'] as Array<any>).map(OfferServiceToJSON)),
        'base_amount': value['baseAmount'],
        'base_currency': value['baseCurrency'],
        'conditions': OfferConditionsToJSON(value['conditions']),
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'expires_at': value['expiresAt'] == null ? undefined : ((value['expiresAt']).toISOString()),
        'id': value['id'],
        'live_mode': value['liveMode'],
        'owner': OfferOwnerToJSON(value['owner']),
        'partial': value['partial'],
        'passenger_identity_documents_required': value['passengerIdentityDocumentsRequired'],
        'passengers': value['passengers'] == null ? undefined : ((value['passengers'] as Array<any>).map(OfferPassengerToJSON)),
        'payment_requirements': OfferPaymentRequirementsToJSON(value['paymentRequirements']),
        'private_fares': value['privateFares'] == null ? undefined : ((value['privateFares'] as Array<any>).map(OfferPrivateFaresToJSON)),
        'slices': value['slices'] == null ? undefined : ((value['slices'] as Array<any>).map(OfferSliceToJSON)),
        'supported_passenger_identity_document_types': value['supportedPassengerIdentityDocumentTypes'],
        'tax_amount': value['taxAmount'],
        'tax_currency': value['taxCurrency'],
        'total_amount': value['totalAmount'],
        'total_currency': value['totalCurrency'],
        'total_emissions_kg': value['totalEmissionsKg'],
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
    };
}

