/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An amenity specific to a hotel, in contrast to room amenities or rate amenities.
 * @export
 * @interface HotelAmenity
 */
export interface HotelAmenity {
    /**
     * Name of the amenity
     * @type {string}
     * @memberof HotelAmenity
     */
    name: string;
}

/**
 * Check if a given object implements the HotelAmenity interface.
 */
export function instanceOfHotelAmenity(value: object): value is HotelAmenity {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function HotelAmenityFromJSON(json: any): HotelAmenity {
    return HotelAmenityFromJSONTyped(json, false);
}

export function HotelAmenityFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelAmenity {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function HotelAmenityToJSON(value?: HotelAmenity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

