/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';

/**
 * A map of links, including links to continue booking this rate or to shop for additional rates.
 * 
 * If this rate is still available for booking then a book link will be present if PSD2 is not a requirement for you or a payment_session link will be present if PSD2 is a requirement for you.
 * 
 * @export
 * @interface RoomPriceCheckLinks
 */
export interface RoomPriceCheckLinks {
    [key: string]: Link | any;
    /**
     * 
     * @type {Link}
     * @memberof RoomPriceCheckLinks
     */
    book?: Link;
    /**
     * 
     * @type {Link}
     * @memberof RoomPriceCheckLinks
     */
    commit?: Link;
    /**
     * 
     * @type {Link}
     * @memberof RoomPriceCheckLinks
     */
    paymentSession?: Link;
    /**
     * 
     * @type {Link}
     * @memberof RoomPriceCheckLinks
     */
    additionalRates?: Link;
}

/**
 * Check if a given object implements the RoomPriceCheckLinks interface.
 */
export function instanceOfRoomPriceCheckLinks(value: object): value is RoomPriceCheckLinks {
    return true;
}

export function RoomPriceCheckLinksFromJSON(json: any): RoomPriceCheckLinks {
    return RoomPriceCheckLinksFromJSONTyped(json, false);
}

export function RoomPriceCheckLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomPriceCheckLinks {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'book': json['book'] == null ? undefined : LinkFromJSON(json['book']),
        'commit': json['commit'] == null ? undefined : LinkFromJSON(json['commit']),
        'paymentSession': json['payment_session'] == null ? undefined : LinkFromJSON(json['payment_session']),
        'additionalRates': json['additional_rates'] == null ? undefined : LinkFromJSON(json['additional_rates']),
    };
}

export function RoomPriceCheckLinksToJSON(value?: RoomPriceCheckLinks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'book': LinkToJSON(value['book']),
        'commit': LinkToJSON(value['commit']),
        'payment_session': LinkToJSON(value['paymentSession']),
        'additional_rates': LinkToJSON(value['additionalRates']),
    };
}

