/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelStats } from './HotelStats';
import {
    HotelStatsFromJSON,
    HotelStatsFromJSONTyped,
    HotelStatsToJSON,
} from './HotelStats';

/**
 * Hotel stats
 * @export
 * @interface HotelStatsResponse
 */
export interface HotelStatsResponse {
    /**
     * 
     * @type {HotelStats}
     * @memberof HotelStatsResponse
     */
    hotelStats: HotelStats;
}

/**
 * Check if a given object implements the HotelStatsResponse interface.
 */
export function instanceOfHotelStatsResponse(value: object): value is HotelStatsResponse {
    if (!('hotelStats' in value) || value['hotelStats'] === undefined) return false;
    return true;
}

export function HotelStatsResponseFromJSON(json: any): HotelStatsResponse {
    return HotelStatsResponseFromJSONTyped(json, false);
}

export function HotelStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelStatsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hotelStats': HotelStatsFromJSON(json['hotel_stats']),
    };
}

export function HotelStatsResponseToJSON(value?: HotelStatsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotel_stats': HotelStatsToJSON(value['hotelStats']),
    };
}

