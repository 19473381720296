/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address1 } from './Address1';
import {
    Address1FromJSON,
    Address1FromJSONTyped,
    Address1ToJSON,
} from './Address1';

/**
 * 
 * @export
 * @interface BillingContact1
 */
export interface BillingContact1 {
    /**
     * First/given name of the payment type account holder.
     * @type {string}
     * @memberof BillingContact1
     */
    givenName?: string;
    /**
     * Last/family name of the payment type account holder.
     * @type {string}
     * @memberof BillingContact1
     */
    familyName?: string;
    /**
     * 
     * @type {Address1}
     * @memberof BillingContact1
     */
    address?: Address1;
}

/**
 * Check if a given object implements the BillingContact1 interface.
 */
export function instanceOfBillingContact1(value: object): value is BillingContact1 {
    return true;
}

export function BillingContact1FromJSON(json: any): BillingContact1 {
    return BillingContact1FromJSONTyped(json, false);
}

export function BillingContact1FromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingContact1 {
    if (json == null) {
        return json;
    }
    return {
        
        'givenName': json['given_name'] == null ? undefined : json['given_name'],
        'familyName': json['family_name'] == null ? undefined : json['family_name'],
        'address': json['address'] == null ? undefined : Address1FromJSON(json['address']),
    };
}

export function BillingContact1ToJSON(value?: BillingContact1 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'given_name': value['givenName'],
        'family_name': value['familyName'],
        'address': Address1ToJSON(value['address']),
    };
}

