/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelSummary } from './HotelSummary';
import {
    HotelSummaryFromJSON,
    HotelSummaryFromJSONTyped,
    HotelSummaryToJSON,
} from './HotelSummary';
import type { HotelTheme } from './HotelTheme';
import {
    HotelThemeFromJSON,
    HotelThemeFromJSONTyped,
    HotelThemeToJSON,
} from './HotelTheme';
import type { Viewport } from './Viewport';
import {
    ViewportFromJSON,
    ViewportFromJSONTyped,
    ViewportToJSON,
} from './Viewport';
import type { TransferPartner } from './TransferPartner';
import {
    TransferPartnerFromJSON,
    TransferPartnerFromJSONTyped,
    TransferPartnerToJSON,
} from './TransferPartner';
import type { HotelSummaryV2 } from './HotelSummaryV2';
import {
    HotelSummaryV2FromJSON,
    HotelSummaryV2FromJSONTyped,
    HotelSummaryV2ToJSON,
} from './HotelSummaryV2';

/**
 * The results of a search
 * @export
 * @interface HotelSearchResults
 */
export interface HotelSearchResults {
    /**
     * 
     * @type {Array<HotelSummary>}
     * @memberof HotelSearchResults
     */
    results: Array<HotelSummary>;
    /**
     * 
     * @type {Array<HotelSummaryV2>}
     * @memberof HotelSearchResults
     */
    resultsV2?: Array<HotelSummaryV2>;
    /**
     * The total number of results possible for this search
     * @type {number}
     * @memberof HotelSearchResults
     */
    totalResultCount: number;
    /**
     * All transfer partners available in the search results
     * @type {Array<TransferPartner>}
     * @memberof HotelSearchResults
     */
    availableTransferPartners?: Array<TransferPartner>;
    /**
     * All amenities available in the search results
     * @type {Array<string>}
     * @memberof HotelSearchResults
     */
    availableAmenities: Array<string>;
    /**
     * All image categories available in the search results
     * @type {Array<string>}
     * @memberof HotelSearchResults
     */
    availableImageCategories: Array<string>;
    /**
     * All hotel themes available in the search results
     * @type {Array<HotelTheme>}
     * @memberof HotelSearchResults
     */
    availableHotelThemes: Array<HotelTheme>;
    /**
     * 
     * @type {Viewport}
     * @memberof HotelSearchResults
     */
    recommendedViewport?: Viewport;
    /**
     * 
     * @type {Viewport}
     * @memberof HotelSearchResults
     */
    searchRegion?: Viewport;
}

/**
 * Check if a given object implements the HotelSearchResults interface.
 */
export function instanceOfHotelSearchResults(value: object): value is HotelSearchResults {
    if (!('results' in value) || value['results'] === undefined) return false;
    if (!('totalResultCount' in value) || value['totalResultCount'] === undefined) return false;
    if (!('availableAmenities' in value) || value['availableAmenities'] === undefined) return false;
    if (!('availableImageCategories' in value) || value['availableImageCategories'] === undefined) return false;
    if (!('availableHotelThemes' in value) || value['availableHotelThemes'] === undefined) return false;
    return true;
}

export function HotelSearchResultsFromJSON(json: any): HotelSearchResults {
    return HotelSearchResultsFromJSONTyped(json, false);
}

export function HotelSearchResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelSearchResults {
    if (json == null) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(HotelSummaryFromJSON)),
        'resultsV2': json['results_v2'] == null ? undefined : ((json['results_v2'] as Array<any>).map(HotelSummaryV2FromJSON)),
        'totalResultCount': json['total_result_count'],
        'availableTransferPartners': json['available_transfer_partners'] == null ? undefined : ((json['available_transfer_partners'] as Array<any>).map(TransferPartnerFromJSON)),
        'availableAmenities': json['available_amenities'],
        'availableImageCategories': json['available_image_categories'],
        'availableHotelThemes': ((json['available_hotel_themes'] as Array<any>).map(HotelThemeFromJSON)),
        'recommendedViewport': json['recommended_viewport'] == null ? undefined : ViewportFromJSON(json['recommended_viewport']),
        'searchRegion': json['search_region'] == null ? undefined : ViewportFromJSON(json['search_region']),
    };
}

export function HotelSearchResultsToJSON(value?: HotelSearchResults | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'results': ((value['results'] as Array<any>).map(HotelSummaryToJSON)),
        'results_v2': value['resultsV2'] == null ? undefined : ((value['resultsV2'] as Array<any>).map(HotelSummaryV2ToJSON)),
        'total_result_count': value['totalResultCount'],
        'available_transfer_partners': value['availableTransferPartners'] == null ? undefined : ((value['availableTransferPartners'] as Array<any>).map(TransferPartnerToJSON)),
        'available_amenities': value['availableAmenities'],
        'available_image_categories': value['availableImageCategories'],
        'available_hotel_themes': ((value['availableHotelThemes'] as Array<any>).map(HotelThemeToJSON)),
        'recommended_viewport': ViewportToJSON(value['recommendedViewport']),
        'search_region': ViewportToJSON(value['searchRegion']),
    };
}

