/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An object containing metadata about the service
 * @export
 * @interface ServiceMetadataForAMeal
 */
export interface ServiceMetadataForAMeal {
    /**
     * Data about the meal
     * @type {string}
     * @memberof ServiceMetadataForAMeal
     */
    meal: ServiceMetadataForAMealMealEnum;
}


/**
 * @export
 */
export const ServiceMetadataForAMealMealEnum = {
    BabyMeal: 'baby_meal',
    BlandMeal: 'bland_meal',
    AsianVegetarianMeal: 'asian_vegetarian_meal',
    DiabeticMeal: 'diabetic_meal',
    GlutenFreeMeal: 'gluten_free_meal',
    HinduMeal: 'hindu_meal',
    KosherMeal: 'kosher_meal',
    MuslimMeal: 'muslim_meal',
    VeganMeal: 'vegan_meal',
    VegetarianLactoOvoMeal: 'vegetarian_lacto_ovo_meal',
    TraditionalMeal: 'traditional_meal',
    LowFatMeal: 'low_fat_meal',
    LowSaltMeal: 'low_salt_meal',
    LactoseFreeMeal: 'lactose_free_meal',
    HealthyMeal: 'healthy_meal',
    SwissColdMeal: 'swiss_cold_meal',
    SwissBrunch: 'swiss_brunch',
    JapaneseMeal: 'japanese_meal',
    ChildMeal: 'child_meal',
    AllergenMeal: 'allergen_meal',
    VegetarianMeal: 'vegetarian_meal',
    Meal: 'meal'
} as const;
export type ServiceMetadataForAMealMealEnum = typeof ServiceMetadataForAMealMealEnum[keyof typeof ServiceMetadataForAMealMealEnum];


/**
 * Check if a given object implements the ServiceMetadataForAMeal interface.
 */
export function instanceOfServiceMetadataForAMeal(value: object): value is ServiceMetadataForAMeal {
    if (!('meal' in value) || value['meal'] === undefined) return false;
    return true;
}

export function ServiceMetadataForAMealFromJSON(json: any): ServiceMetadataForAMeal {
    return ServiceMetadataForAMealFromJSONTyped(json, false);
}

export function ServiceMetadataForAMealFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMetadataForAMeal {
    if (json == null) {
        return json;
    }
    return {
        
        'meal': json['meal'],
    };
}

export function ServiceMetadataForAMealToJSON(value?: ServiceMetadataForAMeal | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'meal': value['meal'],
    };
}

