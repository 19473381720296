/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Link } from './Link';
import {
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';

/**
 * A map of links, including links to confirm pricing and availability for the selected rate.
 * @export
 * @interface BedGroupAvailabilityLinks
 */
export interface BedGroupAvailabilityLinks {
    [key: string]: Link | any;
    /**
     * 
     * @type {Link}
     * @memberof BedGroupAvailabilityLinks
     */
    priceCheck?: Link;
}

/**
 * Check if a given object implements the BedGroupAvailabilityLinks interface.
 */
export function instanceOfBedGroupAvailabilityLinks(value: object): value is BedGroupAvailabilityLinks {
    return true;
}

export function BedGroupAvailabilityLinksFromJSON(json: any): BedGroupAvailabilityLinks {
    return BedGroupAvailabilityLinksFromJSONTyped(json, false);
}

export function BedGroupAvailabilityLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): BedGroupAvailabilityLinks {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'priceCheck': json['price_check'] == null ? undefined : LinkFromJSON(json['price_check']),
    };
}

export function BedGroupAvailabilityLinksToJSON(value?: BedGroupAvailabilityLinks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'price_check': LinkToJSON(value['priceCheck']),
    };
}

