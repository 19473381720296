/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
// IMPORTANT (dsidharta, 2024/07/22): When there's a discriminator, the following generates an import for PropertyAvailabilityFromJSONTyped that's already imported above.
import { PropertyAvailabilityFromJSONTyped } from './PropertyAvailability';
// IMPORTANT (dsidharta, 2024/07/22): When there's a discriminator, the following generates an import for PropertyUnavailabilityFromJSONTyped that's already imported above.
import { PropertyUnavailabilityFromJSONTyped } from './PropertyUnavailability';
/**
 * 
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * Expedia property ID.
     * @type {string}
     * @memberof Property
     */
    propertyId?: string;
    /**
     * Helps determine which type of property response is returned.
     * @type {string}
     * @memberof Property
     */
    status?: PropertyStatusEnum;
    /**
     * A score to sort properties where the higher the value the better. It can be used to:<br> * Sort the properties on the response<br> * Sort properties across multiple responses in parallel searches for large regions<br>
     * @type {number}
     * @memberof Property
     */
    score?: number;
}


/**
 * @export
 */
export const PropertyStatusEnum = {
    Available: 'available',
    PartiallyUnavailable: 'partially_unavailable'
} as const;
export type PropertyStatusEnum = typeof PropertyStatusEnum[keyof typeof PropertyStatusEnum];


/**
 * Check if a given object implements the Property interface.
 */
export function instanceOfProperty(value: object): value is Property {
    return true;
}

export function PropertyFromJSON(json: any): Property {
    return PropertyFromJSONTyped(json, false);
}

export function PropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Property {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['status'] === 'available') {
            return PropertyAvailabilityFromJSONTyped(json, true);
        }
        if (json['status'] === 'partially_unavailable') {
            return PropertyUnavailabilityFromJSONTyped(json, true);
        }
    }
    return {
        
        'propertyId': json['property_id'] == null ? undefined : json['property_id'],
        'status': json['status'] == null ? undefined : json['status'],
        'score': json['score'] == null ? undefined : json['score'],
    };
}

export function PropertyToJSON(value?: Property | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'property_id': value['propertyId'],
        'status': value['status'],
        'score': value['score'],
    };
}

