/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferPaymentRequirements
 */
export interface OfferPaymentRequirements {
    /**
     * The [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) datetime by which you must pay for this offer. At this time, if still unpaid, the reserved space on the flight(s) will be released and you will have to create a new order.
     * This will be `null` when the offer requires immediate payment - that is, when `requires_instant_payment` is `true`.
     * 
     * @type {Date}
     * @memberof OfferPaymentRequirements
     */
    paymentRequiredBy?: Date;
    /**
     * The [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) datetime at which the price associated with the order will no longer be guaranteed by the airline and may change before payment.
     * This will be `null` when `requires_instant_payment` is `true`.
     * 
     * @type {Date}
     * @memberof OfferPaymentRequirements
     */
    priceGuaranteeExpiresAt?: Date;
    /**
     * When payment is required at the time of booking this will be `true` and `payment_required_by` and `price_guarantee_expires_at` will be `null`. When payment can be made at a time after booking, this will be `false` and the time limits on the payment will be provided in `payment_required_by` and `price_guarantee_expires_at`.
     * 
     * @type {boolean}
     * @memberof OfferPaymentRequirements
     */
    requiresInstantPayment?: boolean;
}

/**
 * Check if a given object implements the OfferPaymentRequirements interface.
 */
export function instanceOfOfferPaymentRequirements(value: object): value is OfferPaymentRequirements {
    return true;
}

export function OfferPaymentRequirementsFromJSON(json: any): OfferPaymentRequirements {
    return OfferPaymentRequirementsFromJSONTyped(json, false);
}

export function OfferPaymentRequirementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferPaymentRequirements {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentRequiredBy': json['payment_required_by'] == null ? undefined : (new Date(json['payment_required_by'])),
        'priceGuaranteeExpiresAt': json['price_guarantee_expires_at'] == null ? undefined : (new Date(json['price_guarantee_expires_at'])),
        'requiresInstantPayment': json['requires_instant_payment'] == null ? undefined : json['requires_instant_payment'],
    };
}

export function OfferPaymentRequirementsToJSON(value?: OfferPaymentRequirements | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'payment_required_by': value['paymentRequiredBy'] == null ? undefined : ((value['paymentRequiredBy'] as any).toISOString()),
        'price_guarantee_expires_at': value['priceGuaranteeExpiresAt'] == null ? undefined : ((value['priceGuaranteeExpiresAt'] as any).toISOString()),
        'requires_instant_payment': value['requiresInstantPayment'],
    };
}

