/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The booking status of the room.
 * @export
 */
export const StatusItinerary = {
    Pending: 'pending',
    Booked: 'booked',
    Canceled: 'canceled'
} as const;
export type StatusItinerary = typeof StatusItinerary[keyof typeof StatusItinerary];


export function instanceOfStatusItinerary(value: any): boolean {
    for (const key in StatusItinerary) {
        if (Object.prototype.hasOwnProperty.call(StatusItinerary, key)) {
            if ((StatusItinerary as Record<string, StatusItinerary>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StatusItineraryFromJSON(json: any): StatusItinerary {
    return StatusItineraryFromJSONTyped(json, false);
}

export function StatusItineraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusItinerary {
    return json as StatusItinerary;
}

export function StatusItineraryToJSON(value?: StatusItinerary | null): any {
    return value as any;
}
