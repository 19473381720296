/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The refund information for cancelling the itinerary.
 * @export
 * @interface CancelRefund
 */
export interface CancelRefund {
    /**
     * The amount of the refund on cancelling the itinerary.
     * @type {string}
     * @memberof CancelRefund
     */
    amount?: string;
    /**
     * The currency of the refund amount.
     * @type {string}
     * @memberof CancelRefund
     */
    currency?: string;
}

/**
 * Check if a given object implements the CancelRefund interface.
 */
export function instanceOfCancelRefund(value: object): value is CancelRefund {
    return true;
}

export function CancelRefundFromJSON(json: any): CancelRefund {
    return CancelRefundFromJSONTyped(json, false);
}

export function CancelRefundFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelRefund {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'] == null ? undefined : json['amount'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function CancelRefundToJSON(value?: CancelRefund | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'currency': value['currency'],
    };
}

