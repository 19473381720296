/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SearchPromptRequest,
  SearchPromptResponse,
  SearchRequestV2,
  SearchRequestV3,
  SearchSessionCollection,
  SearchSessionV2,
  SearchSessionV3,
} from '../models/index';
import {
    SearchPromptRequestFromJSON,
    SearchPromptRequestToJSON,
    SearchPromptResponseFromJSON,
    SearchPromptResponseToJSON,
    SearchRequestV2FromJSON,
    SearchRequestV2ToJSON,
    SearchRequestV3FromJSON,
    SearchRequestV3ToJSON,
    SearchSessionCollectionFromJSON,
    SearchSessionCollectionToJSON,
    SearchSessionV2FromJSON,
    SearchSessionV2ToJSON,
    SearchSessionV3FromJSON,
    SearchSessionV3ToJSON,
} from '../models/index';

export interface SearchPromptPostRequest {
    searchPromptRequest?: SearchPromptRequest;
}

export interface V2SearchPostRequest {
    searchRequestV2?: SearchRequestV2;
}

export interface V2SearchSearchSessionIdGetRequest {
    searchSessionId: string;
}

export interface V3SearchPostRequest {
    searchRequestV3?: SearchRequestV3;
}

export interface V3SearchSearchSessionIdGetRequest {
    searchSessionId: string;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     * Returns prompt-related information for the current search in progress
     */
    async recentSearchesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchSessionCollection>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/recent/searches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchSessionCollectionFromJSON(jsonValue));
    }

    /**
     * Returns prompt-related information for the current search in progress
     */
    async recentSearchesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchSessionCollection> {
        const response = await this.recentSearchesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns prompt-related information for the current search in progress
     */
    async searchPromptPostRaw(requestParameters: SearchPromptPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchPromptResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/search/prompt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchPromptRequestToJSON(requestParameters['searchPromptRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchPromptResponseFromJSON(jsonValue));
    }

    /**
     * Returns prompt-related information for the current search in progress
     */
    async searchPromptPost(requestParameters: SearchPromptPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchPromptResponse> {
        const response = await this.searchPromptPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a session id for the given search. Querying for this session id will return the results of the search.
     */
    async v2SearchPostRaw(requestParameters: V2SearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchSessionV2>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/v2/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestV2ToJSON(requestParameters['searchRequestV2']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchSessionV2FromJSON(jsonValue));
    }

    /**
     * Returns a session id for the given search. Querying for this session id will return the results of the search.
     */
    async v2SearchPost(requestParameters: V2SearchPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchSessionV2> {
        const response = await this.v2SearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the results of a search
     */
    async v2SearchSearchSessionIdGetRaw(requestParameters: V2SearchSearchSessionIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchSessionV2>> {
        if (requestParameters['searchSessionId'] == null) {
            throw new runtime.RequiredError(
                'searchSessionId',
                'Required parameter "searchSessionId" was null or undefined when calling v2SearchSearchSessionIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/v2/search/{search_session_id}`.replace(`{${"search_session_id"}}`, encodeURIComponent(String(requestParameters['searchSessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchSessionV2FromJSON(jsonValue));
    }

    /**
     * Returns the results of a search
     */
    async v2SearchSearchSessionIdGet(requestParameters: V2SearchSearchSessionIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchSessionV2> {
        const response = await this.v2SearchSearchSessionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a session id for the given search. Querying for this session id will return the results of the search.
     */
    async v3SearchPostRaw(requestParameters: V3SearchPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchSessionV3>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/v3/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestV3ToJSON(requestParameters['searchRequestV3']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchSessionV3FromJSON(jsonValue));
    }

    /**
     * Returns a session id for the given search. Querying for this session id will return the results of the search.
     */
    async v3SearchPost(requestParameters: V3SearchPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchSessionV3> {
        const response = await this.v3SearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the results of a search
     */
    async v3SearchSearchSessionIdGetRaw(requestParameters: V3SearchSearchSessionIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchSessionV3>> {
        if (requestParameters['searchSessionId'] == null) {
            throw new runtime.RequiredError(
                'searchSessionId',
                'Required parameter "searchSessionId" was null or undefined when calling v3SearchSearchSessionIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("common_auth", []);
        }

        const response = await this.request({
            path: `/v3/search/{search_session_id}`.replace(`{${"search_session_id"}}`, encodeURIComponent(String(requestParameters['searchSessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchSessionV3FromJSON(jsonValue));
    }

    /**
     * Returns the results of a search
     */
    async v3SearchSearchSessionIdGet(requestParameters: V3SearchSearchSessionIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchSessionV3> {
        const response = await this.v3SearchSearchSessionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
