/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The price breakout type.
 *   * `base_rate` - The room rate without any taxes and fees applied.
 *   * `tax_and_service_fee` - Tax recovery charges, service fees, and taxes. Ensure that you capture these values and display as:
 *                             "Taxes and Fees"
 *   * `extra_person_fee` - A per night fee that is charged by a hotel for additional adults over the nightly rate. This fee is included as part of the total.
 *   * `property_fee` - The property fee surcharge type must be displayed beginning on the initial hotel room selection page, immediately after your hotel search results page. This placement is required by the U.S. Federal Trade Commission (FTC).
 *                      Display this surcharge as "Property Fee" on your room selection page, as described above, and in all subsequent price breakdowns for the following Points of Sale:
 *                      * `US`
 *                      * `Canada`
 *                      * `Brazil`
 *                      * `LATAM`
 *   * `sales_tax` - Taxes that must be displayed by certain jurisdictional laws. Ensure that you capture these values and display as "Taxes".
 *   * `adjustment` - This is the amount that the individual night have been adjusted/discounted.
 *   * `recovery_charges_and_fees` - Tax recovery charges, service fees, and taxes. Ensure that you capture these values and display as:
 *                                   "Taxes and Fees"
 *   * `traveler_service_fee` - Fee charged by Vrbo to support use of online tools, services and functions on its platform which enable guest self service. May be displayed as 'Traveler Service Fee' or 'Service Fee' any time fees are broken out for display on partner sites.
 * 
 * @export
 */
export const NightChargeType = {
    BaseRate: 'base_rate',
    TaxAndServiceFee: 'tax_and_service_fee',
    ExtraPersonFee: 'extra_person_fee',
    PropertyFee: 'property_fee',
    SalesTax: 'sales_tax',
    Adjustment: 'adjustment',
    RecoveryChargesAndFees: 'recovery_charges_and_fees',
    TravelerServiceFee: 'traveler_service_fee'
} as const;
export type NightChargeType = typeof NightChargeType[keyof typeof NightChargeType];


export function instanceOfNightChargeType(value: any): boolean {
    for (const key in NightChargeType) {
        if (Object.prototype.hasOwnProperty.call(NightChargeType, key)) {
            if ((NightChargeType as Record<string, NightChargeType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function NightChargeTypeFromJSON(json: any): NightChargeType {
    return NightChargeTypeFromJSONTyped(json, false);
}

export function NightChargeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NightChargeType {
    return json as NightChargeType;
}

export function NightChargeTypeToJSON(value?: NightChargeType | null): any {
    return value as any;
}
