/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The deal that applies to this rate.
 * @export
 * @interface Deal
 */
export interface Deal {
    /**
     * Unique identifier for the deal.
     * @type {string}
     * @memberof Deal
     */
    id?: string;
    /**
     * The description for the deal.
     * @type {string}
     * @memberof Deal
     */
    description?: string;
}

/**
 * Check if a given object implements the Deal interface.
 */
export function instanceOfDeal(value: object): value is Deal {
    return true;
}

export function DealFromJSON(json: any): Deal {
    return DealFromJSONTyped(json, false);
}

export function DealFromJSONTyped(json: any, ignoreDiscriminator: boolean): Deal {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function DealToJSON(value?: Deal | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'description': value['description'],
    };
}

