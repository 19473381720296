/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TraderDetailsInner } from './TraderDetailsInner';
import {
    TraderDetailsInnerFromJSON,
    TraderDetailsInnerFromJSONTyped,
    TraderDetailsInnerToJSON,
} from './TraderDetailsInner';

/**
 * The professional entity or entities that sells the property inventory or related services.
 * @export
 * @interface TraderInformation
 */
export interface TraderInformation {
    /**
     * An array of traders.
     * @type {Array<TraderDetailsInner>}
     * @memberof TraderInformation
     */
    traders?: Array<TraderDetailsInner>;
    /**
     * The url linking to the full text terms and conditions.
     * @type {string}
     * @memberof TraderInformation
     */
    termsAndConditions: string;
}

/**
 * Check if a given object implements the TraderInformation interface.
 */
export function instanceOfTraderInformation(value: object): value is TraderInformation {
    if (!('termsAndConditions' in value) || value['termsAndConditions'] === undefined) return false;
    return true;
}

export function TraderInformationFromJSON(json: any): TraderInformation {
    return TraderInformationFromJSONTyped(json, false);
}

export function TraderInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TraderInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'traders': json['traders'] == null ? undefined : ((json['traders'] as Array<any>).map(TraderDetailsInnerFromJSON)),
        'termsAndConditions': json['terms_and_conditions'],
    };
}

export function TraderInformationToJSON(value?: TraderInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'traders': value['traders'] == null ? undefined : ((value['traders'] as Array<any>).map(TraderDetailsInnerToJSON)),
        'terms_and_conditions': value['termsAndConditions'],
    };
}

