/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RoomPriceCheck } from './RoomPriceCheck';
import {
    RoomPriceCheckFromJSON,
    RoomPriceCheckFromJSONTyped,
    RoomPriceCheckToJSON,
} from './RoomPriceCheck';

/**
 * A room rate plus booking token. If the booking token is absent, the user needs to search again.
 * @export
 * @interface RoomRate
 */
export interface RoomRate {
    /**
     * 
     * @type {string}
     * @memberof RoomRate
     */
    bookingToken?: string;
    /**
     * 
     * @type {RoomPriceCheck}
     * @memberof RoomRate
     */
    roomRate?: RoomPriceCheck;
}

/**
 * Check if a given object implements the RoomRate interface.
 */
export function instanceOfRoomRate(value: object): value is RoomRate {
    return true;
}

export function RoomRateFromJSON(json: any): RoomRate {
    return RoomRateFromJSONTyped(json, false);
}

export function RoomRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomRate {
    if (json == null) {
        return json;
    }
    return {
        
        'bookingToken': json['booking_token'] == null ? undefined : json['booking_token'],
        'roomRate': json['room_rate'] == null ? undefined : RoomPriceCheckFromJSON(json['room_rate']),
    };
}

export function RoomRateToJSON(value?: RoomRate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'booking_token': value['bookingToken'],
        'room_rate': RoomPriceCheckToJSON(value['roomRate']),
    };
}

