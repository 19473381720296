/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BedGroupAvailabilityLinks } from './BedGroupAvailabilityLinks';
import {
    BedGroupAvailabilityLinksFromJSON,
    BedGroupAvailabilityLinksFromJSONTyped,
    BedGroupAvailabilityLinksToJSON,
} from './BedGroupAvailabilityLinks';
import type { BedGroupConfiguration } from './BedGroupConfiguration';
import {
    BedGroupConfigurationFromJSON,
    BedGroupConfigurationFromJSONTyped,
    BedGroupConfigurationToJSON,
} from './BedGroupConfiguration';

/**
 * 
 * @export
 * @interface BedGroupAvailability
 */
export interface BedGroupAvailability {
    /**
     * 
     * @type {BedGroupAvailabilityLinks}
     * @memberof BedGroupAvailability
     */
    links?: BedGroupAvailabilityLinks;
    /**
     * Unique identifier for a bed group.
     * @type {string}
     * @memberof BedGroupAvailability
     */
    id?: string;
    /**
     * This is a display ready description of a bed configuration for this room.
     * @type {string}
     * @memberof BedGroupAvailability
     */
    description?: string;
    /**
     * The bed configuration for a given room. This array can be empty for the related bed group.
     * @type {Array<BedGroupConfiguration>}
     * @memberof BedGroupAvailability
     */
    _configuration?: Array<BedGroupConfiguration>;
}

/**
 * Check if a given object implements the BedGroupAvailability interface.
 */
export function instanceOfBedGroupAvailability(value: object): value is BedGroupAvailability {
    return true;
}

export function BedGroupAvailabilityFromJSON(json: any): BedGroupAvailability {
    return BedGroupAvailabilityFromJSONTyped(json, false);
}

export function BedGroupAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BedGroupAvailability {
    if (json == null) {
        return json;
    }
    return {
        
        'links': json['links'] == null ? undefined : BedGroupAvailabilityLinksFromJSON(json['links']),
        'id': json['id'] == null ? undefined : json['id'],
        'description': json['description'] == null ? undefined : json['description'],
        '_configuration': json['configuration'] == null ? undefined : ((json['configuration'] as Array<any>).map(BedGroupConfigurationFromJSON)),
    };
}

export function BedGroupAvailabilityToJSON(value?: BedGroupAvailability | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'links': BedGroupAvailabilityLinksToJSON(value['links']),
        'id': value['id'],
        'description': value['description'],
        'configuration': value['_configuration'] == null ? undefined : ((value['_configuration'] as Array<any>).map(BedGroupConfigurationToJSON)),
    };
}

