/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The environment the user is in when making the request
 * @export
 */
export const UserEnvironment = {
    Web: 'web',
    MobileWeb: 'mobile_web',
    MobileApp: 'mobile_app'
} as const;
export type UserEnvironment = typeof UserEnvironment[keyof typeof UserEnvironment];


export function instanceOfUserEnvironment(value: any): boolean {
    for (const key in UserEnvironment) {
        if (Object.prototype.hasOwnProperty.call(UserEnvironment, key)) {
            if ((UserEnvironment as Record<string, UserEnvironment>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function UserEnvironmentFromJSON(json: any): UserEnvironment {
    return UserEnvironmentFromJSONTyped(json, false);
}

export function UserEnvironmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEnvironment {
    return json as UserEnvironment;
}

export function UserEnvironmentToJSON(value?: UserEnvironment | null): any {
    return value as any;
}
