/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferSliceSegmentAircraft
 */
export interface OfferSliceSegmentAircraft {
    /**
     * The three-character IATA code for the aircraft
     * @type {string}
     * @memberof OfferSliceSegmentAircraft
     */
    iataCode: string;
    /**
     * Duffel's unique identifier for the resource
     * @type {string}
     * @memberof OfferSliceSegmentAircraft
     */
    id: string;
    /**
     * The name of the aircraft
     * @type {string}
     * @memberof OfferSliceSegmentAircraft
     */
    name: string;
}

/**
 * Check if a given object implements the OfferSliceSegmentAircraft interface.
 */
export function instanceOfOfferSliceSegmentAircraft(value: object): value is OfferSliceSegmentAircraft {
    if (!('iataCode' in value) || value['iataCode'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function OfferSliceSegmentAircraftFromJSON(json: any): OfferSliceSegmentAircraft {
    return OfferSliceSegmentAircraftFromJSONTyped(json, false);
}

export function OfferSliceSegmentAircraftFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferSliceSegmentAircraft {
    if (json == null) {
        return json;
    }
    return {
        
        'iataCode': json['iata_code'],
        'id': json['id'],
        'name': json['name'],
    };
}

export function OfferSliceSegmentAircraftToJSON(value?: OfferSliceSegmentAircraft | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'iata_code': value['iataCode'],
        'id': value['id'],
        'name': value['name'],
    };
}

